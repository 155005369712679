import { Carousel } from 'antd'
import { observer } from 'mobx-react-lite'
import IssueCard from './IssueCard'
import { flagsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { AssetListObjType } from 'store/asset/types'
import { NarrativeListDataType } from 'types/types'
import { useEffect, useMemo, useState } from 'react'
import { FlagListParamsType, SuggestFlagObject } from 'store/flags/types'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'
import { openNotification } from 'services/Util/openNotification'
import { store } from 'store'
import './SuggestedIssues.scss'
import InfoTooltip from '../InfoTooltip/InfoTooltip'

type Props = {
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  item: string
}

const SuggestedIssues = ({ selectedItem, item = '' }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowCalled, setIsFollowCalled] = useState<string>('')
  const [suggestedIssues, setSuggestedIssues] = useState([])
  const { flagsStore } = store
  const { fetchFlagsListWithConditions } = flagsStore

  useEffect(() => {
    fetchSuggestedIssues()
  }, [selectedItem])

  const fetchSuggestedIssues = () => {
    if (!selectedItem) return
    setIsLoading(true)
    flagsAPI
      .getSuggestedTopics({ id: selectedItem.id })
      .then((response) => {
        setSuggestedIssues(response.data.items)
      })
      .catch((error) => {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const chunkArray = (array: any[], chunkSize: number) => {
    const result: any[] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  const followIssue = async (issueId: string) => {
    try {
      setIsFollowCalled(issueId)
      await flagsAPI.followIssue(issueId)
      const filteredSuggestedIssues = suggestedIssues.filter((issue: any) => issue.id !== issueId)
      setSuggestedIssues(filteredSuggestedIssues)
      openNotification({ type: 'info', message: 'Topic followed' })
      const requestParams: FlagListParamsType = {
        page: 1,
        per_page: 5,
        sort: 'name:asc',
        is_active: true,
      }
      fetchFlagsListWithConditions({ params: requestParams })
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to follow topic!' })
    } finally {
      setIsFollowCalled('')
    }
  }

  // Memoizing the grouped issues
  const groupedIssues = useMemo(() => chunkArray(suggestedIssues, 3), [suggestedIssues])

  if (isLoading) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Discovered topics</span>
        <div className='carousel-wrapper'>
          <Carousel dots>
            <div>
              <div className='issues-wrapper'>
                <IssueCard emptyState followIssue={() => {}} />
                <IssueCard emptyState followIssue={() => {}} />
                <IssueCard emptyState followIssue={() => {}} />
              </div>
            </div>
          </Carousel>
        </div>
      </>
    )
  }

  if (!isLoading && suggestedIssues.length === 0) {
    return (
      <>
        <span className='suggested-issues-heading'>
          <span className='monitor-assets__graph__title'>Discovered topics</span>
          <InfoTooltip text='Uses generative AI to identify trending topics relevant to your interests and shows key metrics to help you decide which new topics are worth following.' />
        </span>
        <div className='influencers-empty-state'>
          <InfluencerEmptyStateImage />
          <h5>Nothing to Show</h5>
          <p>
            No Discovered Topics are currently available for this {item}. This section may take up to 24 hours to update
            after creating a {item}.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='suggested-issues-heading'>
        <span className='monitor-assets__graph__title'>Discovered topics</span>
        <InfoTooltip text='Uses generative AI to identify trending topics relevant to your interests and shows key metrics to help you decide which new topics are worth following.' />
      </span>
      <div className='carousel-wrapper'>
        <Carousel dots>
          {groupedIssues.map((group, index) => (
            <div key={index}>
              <div className='issues-wrapper'>
                {group.map((issue: SuggestFlagObject) => (
                  <IssueCard
                    key={issue.id}
                    issue={issue}
                    followIssue={followIssue}
                    isFollowCalled={issue.id === isFollowCalled}
                  />
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default observer(SuggestedIssues)
