import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import millify from 'millify'
import './DetailPanelRightSection.scss'
import classNames from 'classnames'

const DetailPanelRightSection = observer(({ selectedSubSection }: { selectedSubSection?: string }) => {
  const { reportMainViewStore } = store
  const { snippetsTotal, snippetsFilter } = reportMainViewStore

  return (
    <div className='detail-panel-snippet-section'>
      <div className='detail-panel-snippet-section__container'>
        <div className='detail-panel-snippet-section__container__top-container'>
          <div className='detail-panel-snippet-section__container__header'>
            <p className='detail-panel-snippet-section__container__header__title'>Data sources</p>
            {!!snippetsTotal && (
              <p className='detail-panel-snippet-section__container__header__count'>
                {millify(snippetsTotal || 0)} snippets
              </p>
            )}
          </div>
          {!selectedSubSection && (
            <SnippetFilter
              mode={'report'}
              activeTab={''}
              page={'reportMainView'}
              pageType={'list'}
              subStore={'reportMainView'}
              showDashboardActions
              searchPlaceholder='Search snippets'
              showDateFilter={false}
              showResetButton={false}
            />
          )}
        </div>
        <div
          className={classNames('detail-panel-snippet-section__snippet-list', {
            'detail-panel-snippet-section__snippet-list-sub': selectedSubSection,
          })}>
          <SnippetList
            mode={'report'}
            isDashboardSnippet
            showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
            searchedDataResults={snippetsTotal}
            subStore={'reportMainView'}
            uniqueScrollId='report-main-view__id'
          />
        </div>
      </div>
    </div>
  )
})

export default DetailPanelRightSection
