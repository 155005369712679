import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import EmptyState from '../../PortfolioShareChart/EmptyState'
import { CustomPieChart } from 'components/common/PieChart/PieChart'

import { SubStore } from 'types/types'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { SnippetFilterPayload } from 'models/models'

import './Assets.scss'
import useDelayedLoading from 'utils/useDelayedLoading'

type DataRecord = { name: string; total_value: number }
type ChartMetricInfo = { data: DataRecord[]; totalCount: number; title: string }
type ChartDataByMetric = { [x: string]: ChartMetricInfo }

type Props = {
  subStore: SubStore
  detailText: string
  isBrandDashboard?: boolean
  skipBrandIntersection?: boolean
}

export const BrandAssets = observer(
  ({ subStore, detailText, isBrandDashboard, skipBrandIntersection = false }: Props) => {
    const metrics = ['impression', 'mentions', 'engagement']

    const [chartData, setChartData] = useState<ChartDataByMetric>({
      impression: {
        title: 'Share of Voice Views',
        data: [],
        totalCount: 0,
      },
      mentions: {
        title: 'Share of Voice Mentions',
        data: [],
        totalCount: 0,
      },
      engagement: {
        title: 'Share of Voice Engagement',
        data: [],
        totalCount: 0,
      },
    })
    const [loading, setLoading] = useState<boolean>(false)
    const { assetsStore, tenantsStore } = store
    const { isLoadingAttentionFlags, isLoadingNoOfPosts } = store.loaderStore

    const { assetsList, graphColorPalette, assetsCategories, fetchAssetsList, fetchCategories } = assetsStore
    const {
      activeItem,
      snippetsFilter,
      filterChips,
      fetchInsightsMetrics,
      setSnippetsFilter,
      setFilterChips,
      fetchPowerInsights,
      fetchSnippetsInfo,
      getSnippetFilterPayload,
    } = store[`${subStore}Store`]
    const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

    useEffectOnce(() => {
      if (assetsCategories?.length === 0 && !isBrandDashboard) {
        const requestParams = {
          is_asset: true,
        }
        fetchCategories(requestParams)
      }
    })

    useEffect(() => {
      if (assetsCategories.length === 0 || isBrandDashboard) return
      const brandCategory = assetsCategories.find((item) => item.name === 'Brands')
      if (!brandCategory) return
      const params: AssetListParamsType = {
        page: 1,
        per_page: 100,
        sort: 'name:asc',
        is_asset: true,
        is_active: true,
        q: `category_id:eq:${brandCategory?.category_id}`,
      }
      fetchAssetsList({ params: params })
    }, [assetsCategories])

    useEffect(() => {
      if (!isLoadingAttentionFlags && assetsList.total_count > 0) {
        createDataForChart()
      }
    }, [isLoadingAttentionFlags, assetsList, snippetsFilter])

    const createDataForChart = useCallback(async () => {
      try {
        setLoading(true)
        const payload: SnippetFilterPayload = await getSnippetFilterPayload('', skipBrandIntersection)
        const assetsToFetch = assetsList.items.filter(
          (asset: AssetListObjType) => Object.keys(asset.conditions).length > 0,
        )
        for (const metric of metrics) {
          fetchDataForMetric(metric, assetsToFetch, payload)
        }
      } catch (error) {
        console.error('Failed to fetch insights metrics', error)
      } finally {
        setLoading(false)
      }
    }, [assetsList.items, snippetsFilter])

    const fetchDataForMetric = async (metric: string, assets: AssetListObjType[], payload: SnippetFilterPayload) => {
      try {
        const query = payload.query
        const requestParams: any = {
          q: `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`,
        }
        if (metric !== 'mentions') requestParams.measure = metric
        const responses = await Promise.all(
          assets.map((asset) =>
            fetchInsightsMetrics({
              params: requestParams,
              data: { conditions: [asset.conditions, ...payload.conditions] },
            }),
          ),
        )

        const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
        const updatedResponse = assets.map((asset, index) => {
          return {
            name: asset.name,
            conditions: asset.conditions,
            total_value: responses[index] === 'error' ? 0 : responses[index],
          }
        })
        setChartData((prev) => {
          return { ...prev, [metric]: { ...prev[metric], data: updatedResponse, totalCount } }
        })
      } catch (error) {
        console.log(error)
      }
    }

    const onPieChartClick = (data?: any) => {
      if (snippetsFilter.assets?.name === data.name) {
        let { assets, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
        setFilterChips({
          ...filterChips,
          assets: {
            ...filterChips.assets,
            value: '',
          },
        })
      } else {
        setSnippetsFilter({ ...snippetsFilter, assets: { name: data?.name, conditions: data?.conditions } })
        setFilterChips({
          ...filterChips,
          assets: {
            ...filterChips.assets,
            value: data.name,
          },
        })
      }

      fetchPowerInsights(activeItem!)
      fetchSnippetsInfo(activeItem!)
    }

    const isLoading = useDelayedLoading([loading || isLoadingAttentionFlags || isLoadingNoOfPosts])

    return (
      <div className='brands-assets__container'>
        <div className='brands-assets__header-div'>
          <span className={'brands-assets__header-div__title'}>
            {changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme)}
          </span>
          <span className='brands-assets__header-div__description'>{detailText || ''}</span>
        </div>
        <div className='brands-assets__body'>
          {isLoading ? (
            <>
              <div className='brands-assets__graph-container'>
                <EmptyState title='Share of Voice Views' />
              </div>
              <div className='brands-assets__graph-container'>
                <EmptyState title='Share of Voice Mentions' />
              </div>
              <div className='brands-assets__graph-container'>
                <EmptyState title='Share of Voice Engagement' />
              </div>
            </>
          ) : (
            <>
              {Object.keys(chartData)?.map((key, index) => {
                const metricData = chartData[key]
                return (
                  <div className='brands-assets__graph-container'>
                    <span className='brands-assets__graph__title'>{metricData.title}</span>
                    {
                      <CustomPieChart
                        height={192}
                        width={350}
                        cx={65}
                        data={metricData.data}
                        colorPalette={Object.values(graphColorPalette)[index]}
                        totalCount={metricData.totalCount}
                        narrativeTotal={metricData.totalCount}
                        onClickCell={onPieChartClick}
                        millifyStats
                        showLabelsBasedOnSelection
                        customLegendWrapperClass='custom-pie-chart__scrollable'
                        snippetsFilter={snippetsFilter}
                      />
                    }
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    )
  },
)
