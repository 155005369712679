import { observer } from 'mobx-react-lite'
import IssueCard from './IssueCard'
import { useEffect, useState } from 'react'
import { Pagination } from 'antd'
import { store } from 'store'
import { FlagListParamsType } from 'store/flags/types'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { openNotification } from 'services/Util/openNotification'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'
import { NarrativeListDataType } from 'types/types'
import { SnippetFilterPayload } from 'models/models'
import './BrandDiscussions.scss'
import InfoTooltip from 'components/Asset/BrandsDashboard/components/InfoTooltip/InfoTooltip'

const BRANDS = 'Brands'

const BrandDiscussions = ({
  selectedItem,
  onSelectItem,
  subStore = 'assets',
}: {
  selectedItem: AssetListObjType | NarrativeListDataType
  onSelectItem: (flag: AssetListObjType) => void
  subStore?: 'assets' | 'vectors'
}) => {
  const { assetsStore } = store
  const { fetchAssetsList, assetsCategories, assetsList, assetsLoading } = assetsStore
  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    per_page: 50,
  })
  const { snippetsFilter, getDate, fetchInsightsMetrics, getSnippetFilterPayload, fetchSubjectSentimentMetrics } =
    store[`${subStore}Store`]
  const [isAssetMetricLoading, setIsAssetMetricLoading] = useState(false)
  const [assetsWithMetrics, setAssestWithMetrics] = useState<any>([])

  useEffect(() => {
    if (assetsCategories.length === 0) return
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    const params: AssetListParamsType = {
      page: paginationValues.page,
      per_page: paginationValues.per_page,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory?.category_id}`,
    }
    fetchAssetsList({ params: params })
  }, [assetsCategories])

  useEffect(() => {
    if (assetsList.items.length > 0 && selectedItem) {
      fetchMetricsForAllBrands(assetsList.items)
    }
  }, [assetsList, selectedItem, snippetsFilter.days])

  const fetchMetricsForFlag = async (asset: AssetListObjType, requestParams: { q: string; measure?: string }) => {
    if (!asset.conditions) {
      return {
        id: asset.id,
        metrics: {
          views: 0,
          impressions: 0,
          skip: true,
        },
      }
    }

    const requestData = { conditions: [selectedItem.conditions, asset.conditions] }
    const views = await fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true })

    let results
    if (views.total_value > 0) {
      results = await Promise.allSettled([
        fetchInsightsMetrics({
          params: { ...requestParams, measure: 'impression' },
          data: requestData,
          fullResponse: true,
        }),
        fetchInsightsMetrics({
          params: { ...requestParams, measure: 'engagement' },
          data: requestData,
          fullResponse: true,
        }),
        fetchSubjectSentimentMetrics({ params: requestParams, data: { ...requestData, subject_to_score: asset.name } }),
      ])
    }

    const getResultValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error' ? 0 : result.value.total_value
      }
      return 0
    }

    const getSentimentValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error'
          ? { positive: 0, negative: 0, neutral: 0 }
          : {
              positive: result.value?.data?.sent_counts?.positive || 0,
              negative: result.value?.data.sent_counts?.negative || 0,
              neutral: result.value?.data.sent_counts?.neutral || 0,
            }
      }
      return { positive: 0, negative: 0, neutral: 0 }
    }

    const viewsCount = views.total_value
    const impressionsCount = views.total_value > 0 && results ? getResultValue(results[0]) : 0
    const engagementCount = views.total_value > 0 && results ? getResultValue(results[1]) : 0
    const sentimentCount = views.total_value > 0 && results ? getSentimentValue(results[2]) : 0

    if (viewsCount === 0 && impressionsCount === 0 && engagementCount === 0) {
      return {
        id: asset.id,
        metrics: {
          views: 0,
          impressions: 0,
          engagement: 0,
          sentiment: sentimentCount,
          skip: true,
        },
      }
    }

    return {
      id: asset.id,
      metrics: {
        views: viewsCount,
        impressions: impressionsCount,
        engagement: engagementCount,
        sentiment: sentimentCount,
        skip: false,
      },
    }
  }

  const fetchMetricsForAllBrands = async (assets: AssetListObjType[]) => {
    setIsAssetMetricLoading(true)
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate

      const requestParams: { q: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate},risk_score:gte:0,risk_score:lte:100${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }

      const promises = assets.map((asset) => fetchMetricsForFlag(asset, requestParams))
      const results = await Promise.allSettled(promises)

      const metrics: Record<string, any> = {}

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })

      const onlyAssetsWithMetrics = assets
        .map((asset) => ({ ...asset, metric: metrics[asset.id] }))
        .filter((asset) => !asset.metric.skip)

      setAssestWithMetrics(onlyAssetsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
      setIsAssetMetricLoading(false)
    }
  }

  if (!assetsLoading && !isAssetMetricLoading && assetsWithMetrics.length === 0) {
    return (
      <>
        <span className='brand-discussions-heading'>
          <span className='monitor-assets__graph__title'>Brand Discussions</span>
          <InfoTooltip text='This widget displays conversations about brands mentioned within conversations about the selected topic, whether you are following them or not.' />
        </span>
        <div className='influencers-empty-state'>
          <InfluencerEmptyStateImage />
          <h5>Nothing to Show</h5>
          <p>
            It looks like there’s no data available for the time range you selected. Try adjusting the date range or
            check back later.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='brand-discussions-heading'>
        <span className='monitor-assets__graph__title'>Brand Discussions</span>
        <InfoTooltip text='This widget displays conversations about brands mentioned within conversations about the selected topic, whether you are following them or not.' />
      </span>
      <div className='followed-issues'>
        {!assetsLoading &&
          !isAssetMetricLoading &&
          assetsWithMetrics.map((asset: AssetListObjType & { sentiment: any }) => (
            <IssueCard item={asset} onSelectItem={onSelectItem} />
          ))}
        {!assetsLoading && !isAssetMetricLoading && (
          <Pagination
            defaultCurrent={paginationValues.page}
            pageSize={paginationValues.per_page}
            hideOnSinglePage
            total={assetsList.total_count}
            onChange={(page) => {
              let requestParams: FlagListParamsType = {
                page,
                per_page: paginationValues.per_page,
                sort: 'name:asc',
                is_active: true,
              }
              setPaginationValues({ page, per_page: paginationValues.per_page })
              fetchAssetsList({ params: requestParams })
            }}
          />
        )}
        {(assetsLoading || isAssetMetricLoading) &&
          Array(5)
            .fill(null)
            .map(() => <IssueCard emptyState onSelectItem={() => {}} />)}
      </div>
    </>
  )
}

export default observer(BrandDiscussions)
