import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Popover, Tag } from 'antd'

import { ReactComponent as ResetIcon } from 'assets/images/reset-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'

import { DetailsObjType } from 'types/types'
import { CreatorInfo } from './CreatorInfo'
import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { ICreatorData, IFilterChips } from 'models/models'
import { SubStore } from 'types/types'

import './FilterChips.scss'
import classNames from 'classnames'

interface FilterInfoPopoverProps {
  filter: any
  filterChips: IFilterChips
  onCloseFilter: (name: keyof IFilterChips) => void
}

interface Props {
  subStore: SubStore
  detailObj: DetailsObjType | null
  activePowerInsightTab: string
}
export const FilterChips = observer((props: Props) => {
  const { subStore, detailObj, activePowerInsightTab } = props
  const { tenantsStore } = store
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const combineDetailsViewDefaultFeedsFilters: IFilterChips = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    language: { label: 'Language', value: '' },
    assets: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
    creators: {
      label: 'Creators',
      value: [],
    },
  }

  const {
    fetchPowerInsights,
    fetchSnippetsInfo,
    snippetsFilter,
    filterChips,
    setSnippetsFilter,
    setSelectedCommunityInfo,
    setSelectedExecutiveCategory,
    resetSnippetFilters,
    setFilterChips,
    fetchSnippets,
  } = store[`${subStore}Store`]

  const getFilterInfo = (filter: any) => {
    const value = filterChips[filter as keyof IFilterChips].value
    if (Array.isArray(value)) {
      return <FilterInfoPopover filter={filter} filterChips={filterChips} onCloseFilter={onCloseFilter} />
    } else {
      return (
        <Tag
          closable
          closeIcon={<CloseIcon />}
          onClose={() => onCloseFilter(filter)}
          className={'filters-chips__applied__tag'}>
          {filter !== 'language' ? value : SUPPORTED_LANGUAGES_CODE[value]}
        </Tag>
      )
    }
  }

  const onCloseFilter = (name: keyof typeof filterChips) => {
    let obj: { [x: string]: { label: string; value: string | string[] } } = {
      [name]: { ...filterChips[name], value: '' },
    }

    if (name === 'creators') obj[name].value = []

    let updatedSnippetsFilter = { ...snippetsFilter }
    if (name === 'language') {
      delete updatedSnippetsFilter.filter_language
    } else if (name === 'languageRisk') {
      delete updatedSnippetsFilter[name as keyof typeof updatedSnippetsFilter]
      delete updatedSnippetsFilter.filter_sentiment
      updatedSnippetsFilter = { ...updatedSnippetsFilter, filter_risk: [0, 100] }
    } else {
      delete updatedSnippetsFilter[name as keyof typeof updatedSnippetsFilter]
    }

    setSnippetsFilter(updatedSnippetsFilter)

    if (name === 'community') {
      setSelectedCommunityInfo(undefined)
    } else if (name === 'languageRisk') {
      setSelectedExecutiveCategory('All')
    }

    setFilterChips({ ...filterChips, ...obj })
    if (detailObj) fetchPowerInsights({ ...detailObj, activeTab: activePowerInsightTab }, false)
    fetchSnippets()
  }

  const resetFilter = async () => {
    setSelectedCommunityInfo(undefined)
    setSelectedExecutiveCategory('All')
    resetSnippetFilters()
    setFilterChips(combineDetailsViewDefaultFeedsFilters)
    fetchSnippetsInfo(detailObj as DetailsObjType, false)
    if (detailObj) fetchPowerInsights({ ...detailObj, activeTab: activePowerInsightTab }, false)
  }

  const filterExists = Object.values(filterChips).filter((item) => item.value.length > 0)?.length > 0
  return (
    <div
      className={classNames('filters-chips', {
        'filters-chips-margin': filterExists,
      })}>
      <div className={'filters-chips__container'}>
        {filterExists && (
          <div>
            <div className={'filters-chips__container'}>
              {Object.keys(filterChips).map((filter: any) => {
                return filterChips[filter as keyof IFilterChips].value?.length > 0 ? (
                  <div className={'filters-chips__applied'}>
                    <span className={'filters-chips__applied__label'}>{`${changeLabelTextBasedOnTheme(
                      filterChips[filter as keyof IFilterChips].label,
                      isCorporateCommunicationsTheme,
                    )}:`}</span>
                    {getFilterInfo(filter)}
                  </div>
                ) : (
                  <></>
                )
              })}
            </div>
          </div>
        )}
      </div>
      {filterExists && (
        <div className={'filters-chips__resetContainer'} onClick={resetFilter}>
          <ResetIcon />
          <span className={'filters-chips__reset'}>{'Reset Filters'}</span>
        </div>
      )}
    </div>
  )
})

const FilterInfoPopover = (props: FilterInfoPopoverProps) => {
  const { filter, filterChips, onCloseFilter } = props
  let content
  const label = filterChips[filter as keyof IFilterChips].label
  const values = filterChips[filter as keyof IFilterChips].value as Array<string | ICreatorData>

  switch (filter) {
    case 'creators':
      content = (
        <div className='filters-chips_creator_info'>
          <span className='filters-chips_popover_title'>Creators selected:</span>
          {values.map((value) => (
            <CreatorInfo creator={value as ICreatorData} />
          ))}
        </div>
      )
      break

    default:
      content = (
        <div className='filters-chips_popover__default'>
          {values.map((value) => {
            if (typeof value === 'string') return <span className='filters-chips_popover_title'>{value}</span>
            else return ''
          })}
        </div>
      )
      break
  }
  return (
    <Tag
      closable
      closeIcon={<CloseIcon />}
      onClose={() => onCloseFilter(filter)}
      className={'filters-chips__applied__tag'}>
      <Popover
        key={filter}
        content={content}
        trigger='click'
        overlayClassName='filters-chips_popover'
        placement={'bottom'}>{`${label} (${values.length})`}</Popover>
    </Tag>
  )
}
