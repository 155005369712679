import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/report/reports-empty-state.svg'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as ReportButtonIcon } from 'assets/images/icons/report/report-button-stars-icon.svg'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'hooks/usePermissions'
import styles from './InvestigateReportsMainViewPage.module.scss'

export default function EmptyState() {
  const navigate = useNavigate()
  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  return (
    <div className={styles.emptyState}>
      <div className={styles.card}>
        <EmptyStateIcon />
        <h5>Nothing to show here</h5>
        <p>No reports have been added yet. You can start by creating a new report.</p>

        <ButtonImage
          onClick={() => {
            if (canReviewAndReceive) return
            navigate('/reports/create')
          }}
          icon={ReportButtonIcon}
          disabled={canReviewAndReceive}>
          Create Report
        </ButtonImage>
      </div>
    </div>
  )
}
