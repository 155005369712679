import { observer } from 'mobx-react-lite'
import { InputNumber, Popover, Slider } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { store } from 'store'

import { ReactComponent as AddIcon } from 'assets/images/add-circle-icon.svg'

import { SubStore } from 'types/types'
import { CreatorFilterRange, ICreatorFilters, FilterTypes } from 'models/models'

import './FilterAndSorterDropdown.scss'

interface ContentPopoverProps {
  option: string
  value: [number, number]
  range: [number, number]
  onInputChange: (value: number | string, key: string, type: 'start' | 'end') => void
  onSliderChange: (value: number | number[], key: string) => void
  onConfirm: () => void
  setOpenedFilter: (state: FilterTypes | 'none') => void
}
// eslint-disable-next-line no-unused-vars
const RangeIndex: { [key in FilterTypes]: (keyof CreatorFilterRange)[] } = {
  followers: ['follower_min', 'follower_max'],
  snippets: ['snippet_min', 'snippet_max'],
  impressions: ['impression_min', 'impression_max'],
}
interface Props {
  subStore: SubStore
}

export const FilterAndSorterDropdown = observer((props: Props) => {
  const { subStore } = props
  const filterOptions: FilterTypes[] = ['followers', 'snippets', 'impressions']

  const { powerInsightsData, setPowerInsightCreatorFilter, fetchPowerInsightsCreators } = store[`${subStore}Store`]

  const [filters, setFilters] = useState<Partial<ICreatorFilters>>({})
  const [localFilters, setLocalFilters] = useState<ICreatorFilters>({
    followers: [0, 0],
    snippets: [0, 0],
    impressions: [0, 0],
  })
  const [openedFilter, setOpenedFilter] = useState<FilterTypes | 'none'>('none')
  const [selectedFilter, setSelectedFilter] = useState<string[]>([])
  const [filterRanges, setFilterRanges] = useState<{ [x: string]: [number, number] } | null>(null)
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (filterRanges === null && powerInsightsData.creatorsData) {
      let obj: { [x: string]: [number, number] } = {}
      filterOptions.forEach((option) => {
        obj[option] = [
          powerInsightsData.creatorsData?.range?.[RangeIndex[option as FilterTypes][0]] || 0,
          powerInsightsData.creatorsData?.range?.[RangeIndex[option as FilterTypes][1]] || 0,
        ]
      })
      setFilterRanges(obj)

      let obj2: ICreatorFilters = {
        followers: [
          powerInsightsData.creatorsData?.range?.[RangeIndex['followers'][0]],
          powerInsightsData.creatorsData?.range?.[RangeIndex['followers'][0]],
        ],
        snippets: [
          powerInsightsData.creatorsData?.range?.[RangeIndex['snippets'][0]],
          powerInsightsData.creatorsData?.range?.[RangeIndex['snippets'][0]],
        ],
        impressions: [
          powerInsightsData.creatorsData?.range?.[RangeIndex['impressions'][0]],
          powerInsightsData.creatorsData?.range?.[RangeIndex['impressions'][0]],
        ],
      }
      setLocalFilters(obj2)
    }
  }, [powerInsightsData.creatorsData])

  useEffect(() => {
    if (powerInsightsData.creatorsData && powerInsightsData.creatorsData.type === 'feed') {
      let obj: { [x: string]: [number, number] } = {}
      filterOptions.forEach((option) => {
        obj[option] = [
          powerInsightsData.creatorsData?.range?.[RangeIndex[option as FilterTypes][0]] || 0,
          powerInsightsData.creatorsData?.range?.[RangeIndex[option as FilterTypes][1]] || 0,
        ]
      })
      setFilterRanges(obj)
    }
  }, [powerInsightsData.creatorsData])

  useEffect(() => {
    if (!firstRender) {
      const appliedFilters = selectedFilter.reduce((acc: any, key) => {
        if (filters?.hasOwnProperty(key)) {
          acc[key] = filters[key as FilterTypes]
        }
        return acc
      }, {})
      fetchPowerInsightsCreators('PI', appliedFilters)
    } else setFirstRender(false)
  }, [filters])

  const onChangeSlider = (value: number | number[], key: string) => {
    setLocalFilters((prev) => {
      return { ...prev, [key]: value }
    })
  }

  const onChangeInput = (value: number | string, key: string, type: 'start' | 'end') => {
    if (Number.isInteger(value)) {
      let obj = localFilters[key as FilterTypes]
      if (type === 'start') {
        obj = Number(value || 0) <= obj[1] ? [Number(value || 0), obj[1]] : [obj[1], obj[1]]
      } else {
        obj = Number(value || 0) >= obj[0] ? [obj[0], Number(value || 0)] : [obj[0], obj[0]]
      }

      setLocalFilters((prev) => {
        return { ...prev, [key]: obj }
      })
    }
  }

  const onConfirm = () => {
    try {
      if (openedFilter !== 'none') {
        const newValues = { ...filters, [openedFilter]: localFilters[openedFilter] }
        setFilters(newValues)
        setPowerInsightCreatorFilter(newValues)
        setOpenedFilter('none')

        if (!selectedFilter.includes(openedFilter)) {
          const appliedFiltersName = [...selectedFilter, openedFilter]
          setSelectedFilter(appliedFiltersName)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onClickFilter = (filter: FilterTypes) => {
    setOpenedFilter(filter)
  }

  const onClear = () => {
    setLocalFilters({
      followers: [
        powerInsightsData.creatorsData?.range?.[RangeIndex['followers'][0]] || 0,
        powerInsightsData.creatorsData?.range?.[RangeIndex['followers'][0]] || 0,
      ],
      snippets: [
        powerInsightsData.creatorsData?.range?.[RangeIndex['snippets'][0]] || 0,
        powerInsightsData.creatorsData?.range?.[RangeIndex['snippets'][0]] || 0,
      ],
      impressions: [
        powerInsightsData.creatorsData?.range?.[RangeIndex['impressions'][0]] || 0,
        powerInsightsData.creatorsData?.range?.[RangeIndex['impressions'][0]] || 0,
      ],
    })

    setFilters({})
    setPowerInsightCreatorFilter({})
    setSelectedFilter([])
  }

  const removeFilter = (event: React.MouseEvent, filter: FilterTypes) => {
    event.stopPropagation()
    event.preventDefault()
    setLocalFilters({
      ...localFilters,
      [filter]: [filterRanges?.[filter][0], filterRanges?.[filter][0]] || [0, 0],
    })
    const obj = { ...filters }
    delete obj[filter]
    setFilters(obj)
    setPowerInsightCreatorFilter(obj)
    setSelectedFilter(selectedFilter.filter((el) => el !== filter))
  }

  const onCloseFilter = (filter: FilterTypes) => {
    if (selectedFilter.includes(filter)) {
      setLocalFilters({ ...localFilters, [filter]: filters[filter] })
      setLocalFilters({ ...localFilters, [filter]: filters[filter] })
    } else {
      setLocalFilters({
        ...localFilters,
        [filter]: [filterRanges?.[filter][0], filterRanges?.[filter][0]] || [0, 0],
      })
    }
  }

  return (
    <div className='filter-sorter-dropdown'>
      {filterOptions.map((option) => {
        return (
          <Popover
            open={openedFilter === option}
            content={
              <ContentPopover
                option={option}
                value={localFilters[option as FilterTypes]}
                range={filterRanges?.[option] || [0, 0]}
                onInputChange={onChangeInput}
                onSliderChange={onChangeSlider}
                onConfirm={onConfirm}
                setOpenedFilter={setOpenedFilter}
              />
            }
            trigger='click'
            overlayClassName='filter-sorter-dropdown__popover'
            placement={'bottomRight'}
            onOpenChange={() => onCloseFilter(option)}
            destroyTooltipOnHide>
            <div
              className='filter-sorter-dropdown__container'
              onClick={() => {
                onClickFilter(option as FilterTypes)
              }}>
              <AddIcon
                className={classNames({
                  'filter-sorter-dropdown__info__icon': selectedFilter.includes(option),
                })}
                onClick={(e) => {
                  if (selectedFilter.includes(option)) removeFilter(e, option as FilterTypes)
                }}
              />
              <div className='filter-sorter-dropdown__info'>
                <span className='filter-sorter-dropdown__info__title'>{option}</span>
                {selectedFilter.includes(option) && (
                  <>
                    <div className='filter-sorter-dropdown__info__lineBreaker'></div>
                    <span className='filter-sorter-dropdown__info__value'>{`
                    ${filters[option as FilterTypes]?.[0].toLocaleString()} to ${filters[
                      option as FilterTypes
                    ]?.[1].toLocaleString()}`}</span>
                  </>
                )}
              </div>
            </div>
          </Popover>
        )
      })}
      {selectedFilter.length > 1 && (
        <span className='filter-sorter-dropdown__clearButton' onClick={onClear}>
          Clear all
        </span>
      )}
    </div>
  )
})

const ContentPopover = (props: ContentPopoverProps) => {
  const { option, value, range, onInputChange, onSliderChange, onConfirm, setOpenedFilter } = props
  const popoverRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setOpenedFilter('none')
      }
    },
    [setOpenedFilter],
  )

  useEffect(() => {
    if (popoverRef.current) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, popoverRef])

  return (
    <div className='filter-sorter-dropdown__content' ref={popoverRef} id={`${option}-creator-filter-popover`}>
      <div className='filter-sorter-dropdown__content__optionContainer'>
        <span className='filter-sorter-dropdown__content__title'>Count</span>
        <div className='filter-sorter-dropdown__content__sliderContainer'>
          <InputNumber
            className='filter-sorter-dropdown__content__input'
            min={range[0]}
            max={range[1]}
            value={value[0]}
            onChange={(value) => onInputChange(value || 0, option, 'start')}
            controls={false}
            formatter={(value) => (value || 0).toLocaleString()}
          />
          <Slider
            className='filter-sorter-dropdown__content__slider'
            onChange={(value) => onSliderChange(value, option)}
            value={value}
            range
            min={range[0]}
            max={range[1]}
          />
          <InputNumber
            className='filter-sorter-dropdown__content__input'
            min={range[0]}
            max={range[1]}
            value={value[1]}
            onChange={(value) => onInputChange(value || 0, option, 'end')}
            controls={false}
            formatter={(value) => (value || 0).toLocaleString()}
          />
        </div>
      </div>
      <div className='filter-sorter-dropdown__content__footer'>
        <button className='filter-sorter-dropdown__content__footer__applyButton' onClick={onConfirm}>
          Apply
        </button>
      </div>
    </div>
  )
}
