import { observer } from 'mobx-react-lite'
import styles from './Filters.module.scss'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as ResetIcon } from 'assets/images/reset-icon.svg'
import { store } from 'store'
import { IFilterChips, sourcePlatform } from 'models/models'
import { Tag } from 'antd'
import { useMemo } from 'react'
import { getPlatformBrandName } from 'services/Util/getPlatformBrandName'

const combineDetailsViewDefaultFeedsFilters: IFilterChips = {
  attentionFlags: {
    label: 'Attention flags',
    value: '',
  },
  watchlist: { label: 'Watchlist', value: '' },
  community: { label: 'Community', value: '' },
  languageRisk: { label: 'Language Risk', value: '' },
  people: { label: 'People', value: '' },
  politician: { label: 'Politician', value: '' },
  country: { label: 'Country', value: '' },
  company: { label: 'Company', value: '' },
  language: { label: 'Language', value: '' },
  assets: { label: 'Asset', value: '' },
  flags: { label: 'Flag', value: '' },
  creators: { label: 'Flag', value: [] },
}

const corporateCommunicationSpecificLabels = (label: string) => {
  if (label === 'Flag') return 'Topic'
  if (label === 'Asset') return 'Brand'
  if (label === 'Community') return 'Audience'
  return label
}
const Filters = observer(() => {
  const { assetsStore } = store
  const {
    filterChips,
    setSelectedCommunityInfo,
    setFilterChips,
    setSnippetsFilter,
    setSnippetsFilterInfo,
    snippetsFilter,
    defaultPlatforms,
    snippetsFilterInfo,
  } = assetsStore

  const resetFilter = () => {
    setSelectedCommunityInfo(undefined)
    setSnippetsFilterInfo({
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    })
    if (defaultPlatforms.length) {
      setSnippetsFilter({
        days: snippetsFilter.days,
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
        filter_platform: defaultPlatforms,
      })
    } else {
      setSnippetsFilter({
        days: snippetsFilter.days,
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
      })
    }
    setFilterChips(combineDetailsViewDefaultFeedsFilters)
  }

  const onCloseFilter = (name: keyof typeof filterChips) => {
    let obj = { [name]: { ...filterChips[name], value: '' } }
    let updatedSnippetsFilter = { ...snippetsFilter }
    delete updatedSnippetsFilter[name as keyof typeof updatedSnippetsFilter]

    setSnippetsFilter(updatedSnippetsFilter)

    if (name === 'community') {
      setSelectedCommunityInfo(undefined)
    }

    setFilterChips({ ...filterChips, ...obj })
  }

  const onClosePlatformFilter = () => {
    setSnippetsFilter({
      ...snippetsFilter,
      filter_platform: defaultPlatforms,
    })
    const filter = { platform: 0 }
    setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
  }

  const onCloseSentimentFilter = () => {
    setSnippetsFilter({
      ...snippetsFilter,
      filter_sentiment: '',
    })
    const filter = { sentiment: 0 }
    setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
  }

  const sentimentFilters = useMemo(() => {
    if (snippetsFilter?.filter_sentiment?.length === 0) return []
    return typeof snippetsFilter.filter_sentiment === 'string'
      ? snippetsFilter?.filter_sentiment?.split(',')
      : snippetsFilter?.filter_sentiment
  }, [snippetsFilter?.filter_sentiment])

  const platformFilters = useMemo(() => {
    if (snippetsFilter?.filter_platform?.length === 0) return []
    const filters =
      typeof snippetsFilter.filter_platform === 'string'
        ? snippetsFilter?.filter_platform?.split(',')
        : snippetsFilter?.filter_platform
    if (filters?.length === 1 && filters[0] === 'websearch') return []
    return filters
  }, [snippetsFilter?.filter_platform])

  if (
    Object.values(filterChips).filter((item) => item.value.length > 0)?.length === 0 &&
    platformFilters?.length !== 1 &&
    sentimentFilters?.length !== 1
  )
    return null

  return (
    <div className={styles.filtersDiv}>
      <div className={styles.filtersContainer}>
        <div className='cdvc_rc_chips_div'>
          <div className={styles.filtersContainer}>
            {Object.keys(filterChips).map((filter: any) => {
              return filterChips[filter as keyof IFilterChips].value?.length > 0 ? (
                <div className={styles.appliedFilters}>
                  <span className={styles.filterLabel}>{`${corporateCommunicationSpecificLabels(
                    filterChips[filter as keyof IFilterChips].label,
                  )}:`}</span>
                  <Tag closable closeIcon={<CloseIcon />} onClose={() => onCloseFilter(filter)} className={styles.tag}>
                    {filterChips[filter as keyof IFilterChips].value as string}
                  </Tag>
                </div>
              ) : (
                <></>
              )
            })}
            {platformFilters?.length === 1 && (
              <div className={styles.appliedFilters}>
                <span className={styles.filterLabel}>Platform:</span>
                <Tag closable closeIcon={<CloseIcon />} onClose={onClosePlatformFilter} className={styles.tag}>
                  {getPlatformBrandName(platformFilters[0] as Lowercase<sourcePlatform>)}
                </Tag>
              </div>
            )}
            {sentimentFilters?.length === 1 && (
              <div className={styles.appliedFilters}>
                <span className={styles.filterLabel}>Sentiment:</span>
                <Tag closable closeIcon={<CloseIcon />} onClose={onCloseSentimentFilter} className={styles.tag}>
                  {sentimentFilters[0]}
                </Tag>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.resetContainer} onClick={resetFilter}>
        <ResetIcon />
        <span className={styles.reset}>{'Reset Filters'}</span>
      </div>
    </div>
  )
})

export default Filters
