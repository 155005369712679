/* eslint-disable prettier/prettier */
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form, Input, Select, Tooltip, Switch, Menu, Spin } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import * as countries from 'i18n-iso-countries'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { DefaultOptionType } from 'antd/lib/select'
import notification from 'antd/lib/notification'
import { ContactUs } from 'components/common/ContactUs/ContactUs'
import CreateNarrativeAdvancedModals from '../CreateNarrativeAdvancedModals/CreateNarrativeAdvancedModals'
import { defineNarrativeAPI } from 'api/api'
import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'

import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info-tooltip-icon.svg'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/sparkle.svg'
import { ReactComponent as ConvertIcon } from 'assets/images/icons/convert.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icon-notification.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as DislikeFilledIcon } from 'assets/images/dislike-thumb-solid-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as LikeFilledIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/search-result.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/outline/check.svg'
import { ReactComponent as ScrollArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import SearchResultIcon from 'assets/images/search-result.svg'

import TagManager from 'react-gtm-module'
import useClickAway from 'hooks/useClickAway'
import './CreateNarrativeForm.scss'

type CreateNarrativeFormProps = {
  onSearchClick?: (callback: () => void) => void
  onBackClick?: () => void
  formData: any
  addFormData: (newFormData: any) => void
  onEditMode?: boolean
  narrativeID?: string
}

const CreateNarrativeForm = observer((props: CreateNarrativeFormProps) => {
  const location = useLocation()
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  const { onSearchClick, onBackClick, formData, addFormData, onEditMode, narrativeID } = props

  const isEditMode = !!narrativeID
  const [form] = Form.useForm()
  const wrapperRef = useRef<any>()
  const copilotTooltipRef = useRef<any>()
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  const countriesListObject = countries.getNames('en', { select: 'official' })
  const countriesList = Object.keys(countriesListObject)
  const selectRef = useRef(null)
  const tweetCountryRef = useRef<HTMLSpanElement>(null)

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isCopilotOpened, setIsCopilotOpened] = useState(true)
  const [copilotPromptText, setCopilotPromptText] = useState('')
  const [isCopilotActionBtnDisabled, setIsCopilotActionBtnDisabled] = useState(true)
  const [isSearchTextGeneratedByCopilot, setIsSearchTextGeneratedByCopilot] = useState(false)
  const [copilotResultsFeedback, setCopilotResultsFeedback] = useState<null | 'like' | 'dislike'>(null)
  const [displayTwitterMode, setDisplayTwitterMode] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isSearchDisabled, setIsSearchDisabled] = useState(true)
  const [showFilter, setShowFilter] = useState(false)
  const [filterNarrativeCount, setFilterNarrativeCount] = useState(0)
  const [filterCommunityCount, setFilterCommunityCount] = useState(0)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [countryTooltipVisible, setCountryTooltipVisible] = useState(false)
  const [isCopilotTooltipVisible, setIsCopilotTooltipVisible] = useState(false)
  const [openContactModal, setOpenContactModal] = useState(false)
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState<null | 'article' | 'publish' | 'save'>(null)
  const [languageState, setLanguageState] = useState<'on' | 'off' | 'selected'>('off')
  const [selectedLanguagesCode, setSelectedLanguagesCode] = useState<string[]>([])
  const [languageRef, setLanguageRef] = useState<HTMLElement | null>(null)
  const [translatedLanguageText, setTranslatedLanguageText] = useState('')
  const [languageScrollOffset, setLanguageScrollOffset] = useState(0)

  const { defineNarrativeStore, userStore, loaderStore, navViewStore, tenantsStore } = store
  const {
    showResults,
    matchingResults,
    normalUniqueContent,
    narrativeFilterData,
    communityFilterData,
    selectedLanguages,
    booleanSearchTranslation,
    selectedTranslationTab,
    snippetsFilter,
    searchedText,
    selectedTabLanguage,
    prevValue,
    setSelectedTabLanguage,
    setTenantId,
    setShowResults,
    setSelectedLanguages,
    setBooleanSearchTranslation,
    setSelectedTranslationTab,
    setSearchedText,
    setSnippetsFilter,
    fetchDropdowns,
    publishNarrative,
    saveNarrative,
    republishNarrative,
    publishNarrativeLoading,
    fetchBooleanSearchTranslation,
  } = defineNarrativeStore
  const {
    isAdditionalPlatformsFetching,
    hasTwitterAsAdditionalPlatform,
    changeLabelTextBasedOnTheme,
    isCorporateCommunicationsTheme,
  } = tenantsStore
  const { isNavOpen } = navViewStore

  const navigate = useNavigate()

  const { isLoadingSnippetSearch, isLoadingTwitterPosts, isLoadingTranslation, isPublishingData } = loaderStore

  useEffect(() => {
    fetchDropdowns()
  }, [])

  useEffect(() => {
    if (onEditMode) {
      form.setFieldsValue({ booleanSearch: formData['booleanSearch'] || '' })
      form.setFieldsValue({ booleanQuery: formData['booleanQuery'] || '' })
      setSearchText(formData['booleanSearch'] || '')
      form.setFieldsValue({ copilotText: formData['copilotText'] || '' })
      form.setFieldsValue({ profileCountry: formData['profileCountry'] || '' })
      form.setFieldsValue({ name: formData['name'] || '' })
      form.setFieldsValue({ description: formData['description'] || '' })

      if (formData['subset']?.length) {
        form.setFieldsValue({ subset: formData['subset'] || '' })
        setFilterNarrativeCount(form.getFieldValue('subset').length)
      }
      if (formData['focusCommunities']?.length) {
        form.setFieldsValue({ focusCommunities: formData['focusCommunities'] || '' })
        setFilterCommunityCount(form.getFieldValue('focusCommunities').length)
      }

      if (formData['languages']?.length) {
        setLanguageState('selected')
        form.setFieldsValue({ languages: formData['languages'] || '' })
        setTranslatedLanguageText(form.getFieldValue('booleanSearch'))
        setSelectedLanguagesCode(form.getFieldValue('languages'))
      }
    } else {
      if (hasTwitterAsAdditionalPlatform) {
        addFormData({ ...formData, twitterExpansion: true })
      }
    }
  }, [onEditMode])

  const onClickOutside = () => {
    setCountryTooltipVisible(false)
  }

  useClickAway(tweetCountryRef, onClickOutside)

  useEffect(() => {
    function handleClickOutsideTooltip(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event?.target)) {
        setIsTooltipVisible(false)
      }

      if (copilotTooltipRef.current && !copilotTooltipRef.current.contains(event?.target)) {
        setIsCopilotTooltipVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideTooltip)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTooltip)
    }
  })

  useEffect(() => {
    if (userStore.tenantId) {
      setTenantId(userStore.tenantId)

      if (!process.env.REACT_APP_PILOT_TENANTS_REGEXP) return

      const rex = new RegExp(process.env.REACT_APP_PILOT_TENANTS_REGEXP)
      if (userStore.tenantId.match(rex)) {
        setDisplayTwitterMode(true)
      } else {
        setDisplayTwitterMode(false)
      }
    }
  }, [userStore.tenantId])

  useEffect(() => {
    const handleResize = () => {
      if (selectRef.current) {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 0)
      }
    }

    if (languageState === 'on') {
      handleResize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isCopilotOpened, languageState])

  const openNotification = () => {
    notification.info({
      icon: <NotificationIcon />,
      message: <span className='create-narrative__notification__title'>Narrative created successfully</span>,
      description: (
        <span className='create-narrative__notification__description'>
          Great! Pendulum is fetching data for this narrative - once done, you will be notified.
        </span>
      ),
      placement: 'bottomLeft',
      className: classNames({ 'create-narrative__notification__margin': isNavOpen }),
    })
  }

  const openCopilotHandler = () => {
    const isOpen = isCopilotOpened
    setIsCopilotOpened((prevState) => !prevState)
    setIsCopilotActionBtnDisabled(true)
    setSelectedTranslationTab('Default')

    if (!isOpen) {
      form.setFieldsValue({ booleanSearch: formData['booleanSearch'] || '' })
    } else form.setFieldsValue({ copilotText: copilotPromptText || '' })
  }

  const showFeedbackModal = () => {
    setIsTooltipVisible(false)
    setIsCopilotTooltipVisible(false)
    setOpenContactModal(true)
  }

  const handleCommunityChange = (value: string[]) => {
    setIsFirstRender(false)
    setIsSearchDisabled(false)
    setFilterCommunityCount(value?.length)
  }

  const handleNarrativeChange = (value: string[]) => {
    setIsFirstRender(false)
    setIsSearchDisabled(false)
    setFilterNarrativeCount(value?.length)
  }

  const onCopilotPromptChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsCopilotActionBtnDisabled(event.target.value.length === 0)
    setCopilotPromptText(event.target.value)
  }

  const convertToBooleanClickHandler = async () => {
    setSearchText('')

    form.setFieldsValue({ booleanSearch: '' })
    addFormData({ ...formData, booleanSearch: '', booleanQuery: '' })

    if (!copilotPromptText || isCopilotActionBtnDisabled) {
      return
    }

    try {
      setIsCopilotActionBtnDisabled(true)
      addFormData({ ...formData, copilotText: copilotPromptText })

      const response = await defineNarrativeAPI.getBooleanSearchTextFromCopilot({ prompt_text: copilotPromptText })
      setIsCopilotActionBtnDisabled(false)

      if (!response.prompt_response) {
        return
      }

      setIsSearchTextGeneratedByCopilot(true)
      setSelectedTranslationTab('Default')

      // set the boolean search text in form input, as well as in the MobX store
      form.setFieldsValue({ booleanSearch: response.prompt_response })
      form.setFieldsValue({ booleanQuery: response.prompt_response })
      addFormData({ ...formData, booleanSearch: response.prompt_response, booleanQuery: response.prompt_response })

      // also update the local state values to enable the search button
      setSearchText(response.prompt_response)
      setShowResults(false)
      setIsSearchDisabled(false)
      if (isEditMode) {
        setIsFirstRender(false)
      }
      // google analytics for copilot boolean search translation
      TagManager.dataLayer({
        dataLayer: {
          event: 'copilot_boolean_query_translation',
          promptText: copilotPromptText,
          translatedResult: response.prompt_response,
          user_id: userStore.userId,
          user_name: userStore.userName,
          tenantId: userStore.tenantId,
          roleId: userStore.roleId,
        },
      })
    } catch {
      setIsCopilotActionBtnDisabled(false)
    }
  }

  const onSearchTextChange = (event: any) => {
    setIsSaveDisabled(true)
    setShowResults(false)
    setIsFirstRender(false)
    setSearchText(event.target.value)
    if (selectedTranslationTab === 'Default') {
      form.setFieldValue('booleanQuery', event.target.value)
    }
    setSelectedTabLanguage(selectedTranslationTab)
    if (isEditMode) setIsSearchDisabled(false)
    else setIsSearchDisabled(!(event.target?.value?.length > 0) || isLoadingSnippetSearch || isLoadingTwitterPosts)
  }

  const showSearch = () => {
    if (isEditMode && isFirstRender) return false
    else return true
  }

  const onClickSearch = () => {
    setIsSearchDisabled(true)
    if (selectedTranslationTab !== 'Default') {
      setBooleanSearchTranslation({ ...booleanSearchTranslation, [selectedTranslationTab]: searchText })
    }
    onSearchClick?.(() => setIsSaveDisabled(false))
  }

  const publish = async () => {
    try {
      if (isEditMode) {
        await form.validateFields()
        setIsAdvancedModalOpen(null)
        const response = await republishNarrative(narrativeID)
        if (response) {
          setSelectedTranslationTab('Default')
          let previousPath = location.pathname?.includes('vectors') ? '/vectors' : '/influencers'
          if (location.state?.editMode && location.state?.comingFrom) {
            previousPath = location.state.comingFrom
          }
          navigate(previousPath)
        } else return
      }

      if (isAdvancedModalOpen === 'publish') {
        await form.validateFields()
        setIsAdvancedModalOpen(null)
        const response = await publishNarrative()
        if (response) {
          setSelectedTranslationTab('Default')
          openNotification()
          return navigate('/vectors')
        } else return
      }

      setIsAdvancedModalOpen('publish')
    } catch (e: any) {}
  }

  const save = async () => {
    try {
      if (isAdvancedModalOpen === 'save') {
        await form.validateFields()
        setIsAdvancedModalOpen(null)
        return saveNarrative()
      }

      setIsAdvancedModalOpen('save')
    } catch (e: any) {}
  }

  const searchFunction = (inputValue: string, option: DefaultOptionType | undefined) =>
    option?.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())

  function numFormatter(num: number) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'
    } else if (num < 900) {
      return num
    }
  }

  const closeLanguageOption = () => {
    setLanguageState('off')
    setSelectedLanguages([])
    setSelectedLanguagesCode([])
    addFormData({ ...formData, languages: [] })
    form.setFieldValue('languages', [])
    setIsSaveDisabled(false)
    setBooleanSearchTranslation({ Default: booleanSearchTranslation['Default'] })
    setSearchText(booleanSearchTranslation['Default'] || searchText)
    setSelectedTranslationTab('Default')
    if (!isCopilotOpened) {
      form.setFieldsValue({ booleanSearch: booleanSearchTranslation['Default'] || searchText || '' })
    } else form.setFieldsValue({ copilotText: copilotPromptText || '' })
  }

  const cancelLanguageOption = () => {
    if (selectedLanguages.length) {
      form.setFieldValue('languages', selectedLanguages)
      setLanguageState('selected')
      setSelectedLanguagesCode(selectedLanguages)
    } else closeLanguageOption()
  }

  const addLanguages = (language: string[]) => {
    if (language.length <= 10) setSelectedLanguagesCode(language)
  }

  const saveLanguage = async (language?: string) => {
    if (languageRef) {
      setLanguageScrollOffset(0)
      languageRef.scrollLeft = 0
    }
    if (selectedLanguagesCode.length === 0) {
      setSelectedLanguages([])
      setLanguageState('off')
      setTranslatedLanguageText('')
      setIsSaveDisabled(false)
      setSelectedTranslationTab('Default')
      setBooleanSearchTranslation({})
    } else if (selectedLanguagesCode.length <= 10) {
      let booleanSearchValue = ''
      if (language) {
        booleanSearchValue = formData['booleanSearch']
      } else if (formData['booleanSearch'] !== booleanSearchTranslation[selectedTranslationTab]) {
        booleanSearchValue = formData['booleanSearch']
      } else {
        booleanSearchValue = booleanSearchTranslation[selectedTranslationTab] || formData['booleanSearch'] || ''
      }
      setSelectedLanguages(selectedLanguagesCode)
      setLanguageState('selected')
      setTranslatedLanguageText(formData['booleanSearch'])
      setIsSaveDisabled(false)
      const response = await fetchBooleanSearchTranslation(language)

      if (!isCopilotOpened)
        form.setFieldsValue({
          booleanSearch: booleanSearchValue,
        })
      else form.setFieldsValue({ copilotText: copilotPromptText || '' })

      return response
    }
  }

  const scrollHandler = (scrollOffset: number, direction: 'right' | 'left') => {
    if (languageRef) {
      const currentScroll = languageRef.scrollLeft
      const maxScroll = languageRef.scrollWidth - languageRef.clientWidth
      setLanguageScrollOffset(currentScroll)

      if (currentScroll > 0 && direction === 'left') {
        languageRef.scrollLeft = Math.max(0, currentScroll - scrollOffset)
      } else if (currentScroll <= maxScroll && direction === 'right') {
        languageRef.scrollLeft = Math.min(maxScroll, currentScroll + scrollOffset)
      }
    }
  }

  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setLanguageRef(node)
  }, [])

  const selectLanguageTab = async ({ key }: any) => {
    let translation = booleanSearchTranslation
    if (translatedLanguageText !== formData['booleanSearch']) {
      const response = await saveLanguage(selectedTabLanguage)
      translation = {
        ...booleanSearchTranslation,
        ...response,
      }
    }
    setSelectedTranslationTab(key)
    form.setFieldsValue({ booleanSearch: translation[key] || '' })
    setSearchText(translation[key] || '')
    setSnippetsFilter({ ...snippetsFilter, filter_language: key === 'Default' ? 'en' : key })

    setSearchedText(translation[key] || '')
    setIsSearchDisabled(false)
    if (isEditMode) {
      setIsFirstRender(false)
    }
    // google analytics for copilot language translation
    TagManager.dataLayer({
      dataLayer: {
        event: 'copilot_language_translation',
        translatedLanguage: key,
        booleanSearch: translatedLanguageText,
        translatedResult: form.getFieldValue('booleanSearch'),
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
      },
    })
  }

  const editLanguage = () => {
    if (languageState !== 'on') setLanguageState('on')
    else {
      selectedLanguagesCode.length ? setLanguageState('selected') : setLanguageState('off')
    }
  }

  // google analytics for narrative search
  const searchNarrativeGtm = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'search_narrative',
        booleanSearch: searchText,
        user_id: userStore.userId,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
        user_name: userStore.userName,
      },
    })
  }

  const customPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode
  }

  const isDisabled = useMemo(() => {
    if (
      form.getFieldValue(['name']) === prevValue.name &&
      form.getFieldValue(['description']) === prevValue.description
    ) {
      return true
    }
    return false
  }, [form.getFieldValue(['name']), form.getFieldValue(['description']), prevValue.name, prevValue.description])

  const onChangeTwitterExpansion = (status: boolean) => {
    addFormData({ ...formData, twitterExpansion: status })
  }

  return (
    <Spin spinning={isLoadingTranslation || isPublishingData}>
      <div className='create-narrative create-narrative_container'>
        <div className='create-narrative__header'>
          <div className='create-narrative__header__back-btn'>
            {onBackClick ? (
              <div
                className='create-narrative__header__back-btn--container'
                onClick={() => {
                  onBackClick?.()
                }}>
                <BackBtnIcon className='create-narrative__header__back-btn--container--icon' />
                <span className='create-narrative__header__back-btn--container--text'>Back</span>
              </div>
            ) : null}

            {isEditMode && (
              <h3 className='create-narrative__header__title'>
                Edit {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)}
              </h3>
            )}
            {!isEditMode && (
              <h3 className='create-narrative__header__title'>
                Create {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)}
              </h3>
            )}
          </div>
        </div>
        <div className={classNames('create-narrative__main-body', { 'create-narrative__main-body--edit': isEditMode })}>
          <Form
            form={form}
            onValuesChange={(newFormData: any) => {
              if (
                newFormData?.hasOwnProperty('booleanSearch') &&
                (selectedTranslationTab === 'Default' || languageState === 'off')
              ) {
                addFormData({
                  ...newFormData,
                  booleanQuery: newFormData['booleanSearch'],
                })
              } else addFormData(newFormData)
            }}>
            {isEditMode && (
              <div className='create-narrative__edit-warning'>
                <WarningIcon />

                <span className='create-narrative__edit-warning__text'>
                  {`Editing a narrative does not impact its history and only applies going forward.`}
                </span>
              </div>
            )}

            {isEditMode && (
              <>
                <div className='create-narrative__input-wrapper'>
                  <label className='create-narrative__input-wrapper__label'>
                    <span className='create-narrative__form--span greyed create-narrative__form--span__no-margin'>
                      Narrative Name
                    </span>
                  </label>

                  <Form.Item name='name'>
                    <Input />
                  </Form.Item>
                </div>

                <div className='create-narrative__input-wrapper'>
                  <label className='create-narrative__input-wrapper__label'>
                    <span className='create-narrative__form--span greyed'>Description</span>
                  </label>

                  <Form.Item name='description'>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </div>
              </>
            )}

            <div className='create-narrative__copilot-switch-btn__wrapper'>
              <Button type='text' className='create-narrative__copilot-switch-btn' onClick={openCopilotHandler}>
                {isCopilotOpened ? (
                  <>Close Co-Pilot</>
                ) : (
                  <span>
                    <SparkleIcon className='create-narrative__copilot-switch-btn__sparkle-icon' />
                    Pendulum Co-Pilot
                    <InfoIcon
                      style={{ cursor: 'initial' }}
                      className='create-narrative__copilot-switch-btn__info-icon'
                    />
                  </span>
                )}
              </Button>
            </div>

            {isCopilotOpened && (
              <div>
                <div className='create-narrative__input-wrapper'>
                  <label className='create-narrative__input-wrapper__label'>
                    <span className='create-narrative__form--span'>
                      <SparkleIcon className='create-narrative__form--span__prefix-icon' />
                      Pendulum Co-Pilot
                      <Tooltip
                        placement='right'
                        overlayClassName='pendulum-themed-tooltip'
                        overlayStyle={{ width: '250px' }}
                        overlayInnerStyle={{ height: '300px', width: '250px', overflow: 'auto' }}
                        open={isCopilotTooltipVisible}
                        trigger={['click']}
                        title={
                          <div ref={copilotTooltipRef} className='create-narrative__copilot-tooltip'>
                            <p>
                              Describe the topic you are looking for like
                              <br />
                              you would when talking to a very junior
                              <br />
                              analyst who is helping you. Feel free to be
                              <br />
                              as descriptive as you like. You can also call
                              <br />
                              out things that you may not want to include
                              <br />
                              in the search results. Some examples
                              <br />
                              below:
                            </p>

                            <ul>
                              <li>
                                Discussions relating to China and
                                <br />
                                Taiwan relationships and any impacts
                                <br />
                                that can have on geopolitical and
                                <br />
                                economic stability
                              </li>
                              <li>
                                Conversations about various Qanon
                                <br />
                                topics and conspiracy theories
                              </li>
                              <li>
                                Conversations discussing various forms
                                <br />
                                of human rights violations related with
                                <br />
                                the Kingdom of Saudi Arabia
                              </li>
                            </ul>
                          </div>
                        }>
                        <InfoIcon
                          className='create-narrative__form--span__info-icon'
                          onClick={() => {
                            setIsCopilotTooltipVisible(true)
                          }}
                        />
                      </Tooltip>
                    </span>
                  </label>

                  <Form.Item name='copilotText'>
                    <Input.TextArea
                      rows={6}
                      onChange={onCopilotPromptChangeHandler}
                      placeholder='Describe the topic you are looking for as you would to a junior analyst. E.g. Conversations around China and Taiwan political tensions'
                    />
                  </Form.Item>
                </div>
              </div>
            )}

            {isCopilotOpened && (
              <div className='create-narrative__copilot-action-btn__wrapper'>
                <Button
                  type='default'
                  icon={<ConvertIcon className='btn-prefix' />}
                  loading={copilotPromptText !== '' && isCopilotActionBtnDisabled}
                  onClick={convertToBooleanClickHandler}
                  className={classNames('create-narrative__copilot-action-btn', {
                    disabled: isCopilotActionBtnDisabled,
                  })}
                  disabled={isCopilotActionBtnDisabled}>
                  Convert to boolean
                </Button>
              </div>
            )}

            <div
              className={classNames(
                { 'create-narrative__selected-language': languageState === 'selected' },
                {
                  'create-narrative__unselected-language': languageState === 'off',
                },
              )}>
              {languageState === 'selected' && (
                <div className={'create-narrative__languages-nav-container'}>
                  {languageScrollOffset > 0 && (
                    <ScrollArrowIcon
                      className='create-narrative__languages-nav-container--left'
                      onClick={() => {
                        scrollHandler(25, 'left')
                      }}
                    />
                  )}
                  <nav className='create-narrative__languages-nav' ref={listRefCallback}>
                    <div>
                      <Menu
                        mode='horizontal'
                        defaultSelectedKeys={['Default']}
                        triggerSubMenuAction='click'
                        disabledOverflow
                        selectedKeys={[selectedTranslationTab]}>
                        {['Default', ...selectedLanguages].map((language) => {
                          return (
                            <Menu.Item key={language} id={language} onClick={selectLanguageTab}>
                              <span>{language === 'Default' ? 'Default' : SUPPORTED_LANGUAGES_CODE[language]}</span>
                            </Menu.Item>
                          )
                        })}
                      </Menu>
                    </div>
                  </nav>

                  {languageRef && languageScrollOffset < languageRef?.scrollWidth - languageRef?.clientWidth && (
                    <ScrollArrowIcon
                      className='create-narrative__languages-nav-container--right'
                      onClick={() => {
                        scrollHandler(25, 'right')
                      }}
                    />
                  )}
                </div>
              )}

              <div className='create-narrative__language-switch-container'>
                {languageState === 'selected' && (
                  <EditIcon className='create-narrative__language-switch-container__edit-icon' onClick={editLanguage} />
                )}
                <Tooltip placement='bottom' title='Translate'>
                  <LanguageIcon
                    aria-label='Translate'
                    className={classNames({
                      'create-narrative__language-switch-container__black-icon': languageState !== 'off',
                    })}
                  />
                </Tooltip>

                <Switch
                  size={'small'}
                  checked={languageState !== 'off' ? true : false}
                  onChange={(value) => {
                    value ? setLanguageState('on') : closeLanguageOption()
                  }}
                  disabled={!isEditMode && (searchText.length <= 0 || form.getFieldValue('booleanSearch')?.length <= 0)}
                />
              </div>
            </div>

            {languageState !== 'on' && (
              <div className='create-narrative__input-wrapper'>
                <label className='create-narrative__input-wrapper__label'>
                  <div className='boolean-search-label-div'>
                    <span className='create-narrative__form--span'>
                      Boolean Keyword Search
                      <Tooltip
                        placement='right'
                        overlayClassName='pendulum-themed-tooltip'
                        overlayStyle={{ width: '370px' }}
                        overlayInnerStyle={{ height: '660px', width: '370px', overflow: 'auto' }}
                        open={isTooltipVisible}
                        trigger={['click']}
                        title={
                          <>
                            <ul ref={wrapperRef} className='create-narrative__boolean-search-tooltip'>
                              <li>
                                <p>
                                  Pendulum uses boolean operators to allow you to define what you are looking for. If
                                  you have not used boolean operators before, you can watch this short
                                  <a
                                    href='https://www.youtube.com/watch?v=bCAULDuMcso'
                                    target='_blank'
                                    rel='noreferrer'>
                                    {' '}
                                    video
                                  </a>
                                  . Other useful resources are
                                  <a
                                    href='https://www.youtube.com/watch?v=Ed7EswsnEbM'
                                    target='_blank'
                                    rel='noreferrer'>
                                    {' '}
                                    boolean modifiers
                                  </a>{' '}
                                  and{' '}
                                  <a
                                    href='https://www.youtube.com/watch?v=BoujP8KoK7E'
                                    target='_blank'
                                    rel='noreferrer'>
                                    keyword selection
                                  </a>
                                  .
                                </p>
                                <p>
                                  Boolean searches are very useful for a quick access to the data but can be very
                                  challenging if the topic you are evaluating is very complex and nuanced. If you need
                                  any assistance{' '}
                                  <button className='here-button' onClick={showFeedbackModal}>
                                    click here
                                  </button>{' '}
                                  and tell us how we can help.
                                </p>
                                <p>
                                  The data in this screen is limited to the last 90 days and is intended to give you
                                  examples of what your search results will look like. You can also edit right away as
                                  you see things to remove or add. Once you click Publish, your narrative will run
                                  against all of Pendulum's data and will return all matching results.
                                </p>
                                <p>
                                  Pendulum uses the following operators and it is important that they are all UPPERCASE
                                  when included.
                                </p>
                                <span>AND: search for the presence of all terms in your search.</span>
                                <br />
                                <span>OR: search for the presence of either term.</span>
                                <br />
                                <span>NOT: remove items that contain a specific term.</span>
                                <br />
                                <span>
                                  ~N (proximity): find two words that are within N words of each other. Write the words
                                  in quotes, followed by ~N. Ex: "Black White" ~2 shows results where "black" and
                                  "white" are no more than two words apart.
                                </span>
                                <br />
                                <span>
                                  * (wildcard): search for variations of the term, like plurals. Works only for a single
                                  word, not a phrase.
                                </span>
                              </li>
                              <br />
                              <span>Some search examples:</span>
                              <ul className='create-narrative__boolean-search-example'>
                                <li>
                                  <span> Hans Niemann and Magnus Carlsen recent scandal:</span>
                                </li>
                                <ul>
                                  <li>
                                    <p>
                                      ((("Hans Demon" OR "Hans Niemann" OR ((Hans OR Niemann OR Neiman OR Nieman) AND
                                      Chess)) OR ("Magnus Carlsen" OR ((Magnus OR Carlsen OR Carlson OR Carlton) AND
                                      Chess))) AND (Cheat* OR Scandal OR Withdr* OR Drama OR Device OR Vibr*))
                                    </p>
                                  </li>
                                </ul>
                                <li>
                                  <span>
                                    Narrowing down to the Elite 8 men's basketball game in 2022 march madness
                                    tournament:
                                  </span>
                                </li>
                                <ul>
                                  <li>
                                    <p>
                                      (Arkansas OR Razorbacks) AND (Duke OR “Blue Devils”) AND “march madness” NOT “1994
                                      final”
                                    </p>
                                  </li>
                                </ul>
                              </ul>
                            </ul>
                          </>
                        }>
                        <InfoIcon
                          className='create-narrative__form--span__info-icon'
                          onClick={() => {
                            setIsTooltipVisible(true)
                          }}
                        />
                      </Tooltip>
                    </span>

                    {isSearchTextGeneratedByCopilot && (
                      <span className='feedback-icons'>
                        <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Dislike'>
                          {copilotResultsFeedback === 'dislike' ? (
                            <DislikeFilledIcon
                              className='feedback-icons__icon'
                              onClick={() => setCopilotResultsFeedback(null)}
                            />
                          ) : (
                            <DislikeIcon
                              className='feedback-icons__icon'
                              onClick={() => setCopilotResultsFeedback('dislike')}
                            />
                          )}
                        </Tooltip>

                        <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Like'>
                          {copilotResultsFeedback === 'like' ? (
                            <LikeFilledIcon
                              className='feedback-icons__icon'
                              onClick={() => setCopilotResultsFeedback(null)}
                            />
                          ) : (
                            <LikeIcon
                              className='feedback-icons__icon'
                              onClick={() => setCopilotResultsFeedback('like')}
                            />
                          )}
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </label>

                <Form.Item
                  name='booleanSearch'
                  rules={[
                    {
                      message: 'There is an issue with your syntax.',
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length === 0) {
                          setIsSearchDisabled(true)
                          return Promise.reject('Please type an expression.')
                        }

                        const stack: string[] = []
                        const values = value.split('')
                        let extra: boolean = false

                        values.forEach((el: string) => {
                          if (extra) return
                          if (el === '(') return stack.push('(')
                          if (el === ')') {
                            if (stack.length === 0) {
                              extra = true
                            } else return stack.pop()
                          }
                        })

                        if (extra) {
                          setIsSearchDisabled(true)
                          return Promise.reject(new Error("You're missing a “(” from your expression."))
                        }

                        if (value.match(/"/g)?.length % 2) {
                          setIsSearchDisabled(true)
                          return Promise.reject(new Error(`You have an extra ' " ' in your expression.`))
                        }

                        if (stack.length > 0) {
                          setIsSearchDisabled(true)
                          return Promise.reject(new Error("You're missing a “)” from your expression."))
                        }

                        setIsSearchDisabled(false)
                        return Promise.resolve()
                      },
                    }),
                  ]}>
                  <Input.TextArea
                    name='booleanSearch'
                    rows={6}
                    placeholder='("China" OR "Chinese" OR "PRC") AND ("Taiwan" OR "Taiwanese" OR "ROC") AND ("political tension*" OR "sovereignty dispute*")'
                    onChange={onSearchTextChange}
                    value={searchText}
                  />
                </Form.Item>
              </div>
            )}

            {languageState === 'on' && (
              <div
                className={classNames('create-narrative__select-container', {
                  'create-narrative__select-container--open': true,
                })}>
                <div className='create-narrative__select-container__description-div'>
                  {selectedLanguagesCode.length < 10 ? (
                    <span className='create-narrative__select-container__description'>
                      {
                        'You can select up to 10 languages at a time for each narrative. Pendulum will automatically translate your search in the selected languages.'
                      }
                    </span>
                  ) : (
                    <div className='create-narrative__select-container__warning'>
                      <WarningIcon />
                      <span>{'You can add 10 languages at a time. Please unselect one to include another.'}</span>
                    </div>
                  )}

                  <div className='create-narrative__select-container__search-div'>
                    <Form.Item name='languages' style={{ width: '100%' }}>
                      <Select
                        ref={selectRef}
                        style={{ width: '95%' }}
                        className='create-narrative__select-container__div'
                        popupClassName='create-narrative__select-container__dropdown'
                        getPopupContainer={customPopupContainer}
                        placeholder={
                          <>
                            <SearchIcon />
                            <span>Search language</span>
                          </>
                        }
                        suffixIcon={<></>}
                        open={true}
                        mode='multiple'
                        menuItemSelectedIcon={<CheckIcon />}
                        onChange={(value) => {
                          if (value?.length > 10) {
                            value.pop()
                          } else addLanguages(value)
                        }}
                        value={selectedLanguagesCode}
                        defaultValue={selectedLanguages}
                        filterOption={(input: string, option?: { label: string; value: string }) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        optionFilterProp='label'
                        placement='bottomLeft'>
                        {Object.keys(SUPPORTED_LANGUAGES_CODE)?.map((key) => {
                          return (
                            <Select.Option value={key} key={key} label={SUPPORTED_LANGUAGES_CODE[key]}>
                              <span>{SUPPORTED_LANGUAGES_CODE[key]}</span>
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <CloseIcon onClick={() => closeLanguageOption()} />
                  </div>
                </div>
                <div className='create-narrative__select-container__buttons'>
                  <button
                    className='create-narrative__select-container__buttons__cancel'
                    onClick={() => cancelLanguageOption()}>
                    Cancel
                  </button>
                  <button className='create-narrative__select-container__buttons__add' onClick={() => saveLanguage()}>
                    <span> Add language</span>
                    {selectedLanguagesCode.length > 0 && (
                      <div className='create-narrative__language-count'>{selectedLanguagesCode.length}</div>
                    )}
                  </button>
                </div>
              </div>
            )}

            <Form.Item hidden name='booleanQuery'>
              <Input.TextArea name='booleanQuery' />
            </Form.Item>

            {!isEditMode && !isAdditionalPlatformsFetching && hasTwitterAsAdditionalPlatform && (
              <div className='create-narrative__filter-applied twitter-expansion'>
                <div className='twitter-expansion-header'>
                  <div className='create-narrative__filter-applied__header--title'>
                    <TwitterIcon className='twitter-icon' />
                    <h2>Twitter Expansion</h2>
                    <Tooltip
                      placement='top'
                      title={
                        <p>
                          By enabling X (Twitter) expansion, Pendulum will query X directly once your{' '}
                          {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)} is published, and
                          pull data on a continuous basis. Without enabling X (Twitter) Expansion, your
                          {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)} will be matched
                          against Tweets in Pendulum’s existing data lake.
                        </p>
                      }>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <Switch size='small' checked={formData['twitterExpansion']} onChange={onChangeTwitterExpansion} />
                </div>
              </div>
            )}

            {languageState !== 'on' && (
              <div className='create-narrative__filter-applied'>
                <div
                  className={
                    showFilter
                      ? 'create-narrative__filter-applied__header--rotate-icon'
                      : 'create-narrative__filter-applied__header'
                  }
                  onClick={() => setShowFilter(!showFilter)}>
                  <div className='create-narrative__filter-applied__header--title'>
                    <h2>{'Filters'}</h2>
                    {filterNarrativeCount + filterCommunityCount > 0 && (
                      <span>{filterNarrativeCount + filterCommunityCount}</span>
                    )}
                  </div>
                  <DropdownIcon />
                </div>
                {showFilter ? (
                  <div className='create-narrative__filter-applied__body'>
                    {displayTwitterMode && !isEditMode && (
                      <div className='create-narrative__select-wrapper'>
                        <label>
                          Tweet Countries <span>- Optional</span>
                          <Tooltip
                            placement='right'
                            overlayClassName='pendulum-themed-tooltip'
                            open={countryTooltipVisible}
                            trigger={['click']}
                            title={<span ref={tweetCountryRef}>Applies only for Twitter data</span>}>
                            <InfoIcon
                              className='cn_sw_info_icon'
                              onClick={() => {
                                setCountryTooltipVisible(true)
                              }}
                            />
                          </Tooltip>
                        </label>
                        <Form.Item className='c-define__item c-define__item--1 no-margin' name='profileCountry'>
                          <Select
                            className='filter-applied-select'
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Choose Countries'
                            filterOption={searchFunction}
                            suffixIcon={<DropdownIcon />}>
                            {countriesList.map((country: any) => (
                              <Select.Option key={uuidv4()} value={country}>
                                {countriesListObject[country]}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    <div className='create-narrative__select-wrapper'>
                      <label>
                        Subset {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)}{' '}
                        <span>- Optional</span>
                      </label>
                      <Form.Item className='c-define__item c-define__item--1 no-margin' name='subset'>
                        <Select
                          className='filter-applied-select'
                          mode='multiple'
                          allowClear
                          style={{ width: '100%' }}
                          placeholder='Choose an existing narrative'
                          filterOption={searchFunction}
                          onChange={handleNarrativeChange}>
                          {narrativeFilterData.map((narrative: any) => (
                            <Select.Option key={uuidv4()} value={narrative.id}>
                              {narrative.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className='create-narrative__select-wrapper'>
                      <label>
                        {changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)}{' '}
                        <span>- Optional</span>
                      </label>
                      <Form.Item className='c-define__item c-define__item--1 no-margin' name='focusCommunities'>
                        <Select
                          className='filter-applied-select'
                          mode='multiple'
                          allowClear
                          style={{ width: '100%' }}
                          placeholder='Choose Communities'
                          filterOption={searchFunction}
                          onChange={handleCommunityChange}>
                          {communityFilterData.map((comm: any) => (
                            <Select.Option key={uuidv4()} value={comm.id}>
                              {comm.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            <CreateNarrativeAdvancedModals
              openModal={isAdvancedModalOpen}
              setOpenModal={setIsAdvancedModalOpen}
              publishNarrative={publish}
              saveNarrative={save}
              publishNarrativeLoading={publishNarrativeLoading}
              uniqueContentList={normalUniqueContent}
            />
          </Form>

          {languageState !== 'on' && (
            <div>
              {showResults && (
                <div className='create-narrative__result-found__container'>
                  <div className='create-narrative__result-found'>
                    <strong>{numFormatter(matchingResults)}</strong>
                    <p>results found for {searchedText || form.getFieldValue('booleanSearch')} </p>
                  </div>
                  {matchingResults > 1000000 && (
                    <div className='create-narrative__result-found create-narrative__result-found--warning'>
                      <img src={SearchResultIcon} alt='' />
                      <p>Your search results in too many items to be useful. Consider refining your keywords.</p>
                    </div>
                  )}
                </div>
              )}
              {showSearch() && (
                <button
                  className={classNames(
                    {
                      'create-narrative__search-btn--primary':
                        !isSearchDisabled && !isLoadingSnippetSearch && !isLoadingTwitterPosts,
                    },
                    'create-narrative__search-btn--result-found',
                    'create-narrative__search-btn',
                  )}
                  disabled={isSearchDisabled}
                  onClick={() => {
                    onClickSearch()
                    searchNarrativeGtm()
                  }}>
                  {'Search'}
                </button>
              )}
            </div>
          )}
        </div>

        {isEditMode && (
          <div className='create-narrative__footer'>
            <button
              type='button'
              className='create-narrative__footer__save-btn'
              onClick={publish}
              disabled={isSaveDisabled && isDisabled}>
              Save Changes
            </button>
          </div>
        )}

        <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />
      </div>
    </Spin>
  )
})

export default CreateNarrativeForm
