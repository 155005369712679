import { store } from 'store'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as TrendIcon } from 'assets/images/powerinsight-tabs/trend.svg'
import { ReactComponent as PeopleIcon } from 'assets/images/powerinsight-tabs/people.svg'
import { ReactComponent as AssetsIcon } from 'assets/images/powerinsight-tabs/assets.svg'
import { ReactComponent as MetricsIcon } from 'assets/images/powerinsight-tabs/metrics.svg'
import { ReactComponent as CreatorIcon } from 'assets/images/powerinsight-tabs/creators.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/powerinsight-tabs/languages.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/powerinsight-tabs/sentiments.svg'
import { ReactComponent as HiveIcon } from 'assets/images/powerinsight-tabs/watchlist_hive.svg'
import { ReactComponent as CountriesIcon } from 'assets/images/powerinsight-tabs/countries.svg'
import { ReactComponent as CompaniesIcon } from 'assets/images/powerinsight-tabs/companies.svg'
import { ReactComponent as PoliticianIcon } from 'assets/images/powerinsight-tabs/politicians.svg'
import { ReactComponent as CommunitiesAudiencesIcon } from 'assets/images/powerinsight-tabs/audience.svg'
import { ReactComponent as WatchListIcon } from 'assets/images/powerinsight-tabs/influencer_watchlist.svg'
import { ReactComponent as FlagsIcon } from 'assets/images/powerinsight-tabs/flag.svg'
import { DetailsObjType } from 'types/types'

import './PowerInsightTabsListVertical.scss'
import { GenericDispatch } from 'utils/commonTypes'

const PowerInsightTabsListVertical = observer(
  ({
    setSelectedPowerInsightTab,
    selectedPowerInsightTab,
  }: {
    setSelectedPowerInsightTab: GenericDispatch<string>
    selectedPowerInsightTab: string
  }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const detailObj = location.state as DetailsObjType | null

    const { tenantsStore } = store

    const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

    const POWER_INSIGHT_TABS = [
      {
        key: 'metrics',
        label: 'Metrics',
        show: true,
        path: 'metrics',
        fill: true,
        icon: <MetricsIcon color='red' />,
      },
      {
        key: 'assets',
        label: changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme),
        show: true,
        path: 'assets',
        fill: true,
        icon: <AssetsIcon />,
      },
      {
        key: 'topics',
        label: changeLabelTextBasedOnTheme('Topics', isCorporateCommunicationsTheme),
        show: true,
        path: 'topics_flags',
        icon: <FlagsIcon />,
      },
      {
        key: 'trends-timeline',
        label: 'Trends',
        show: true,
        path: 'trends',
        icon: <TrendIcon />,
      },
      {
        key: 'creators',
        label: 'Creators',
        path: 'creators',
        icon: <CreatorIcon />,
        show: true,
      },
      {
        key: 'watchlist',
        path: changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme),
        icon: <WatchListIcon />,
      },
      {
        key: 'communities',
        path: changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme),
        icon: <CommunitiesAudiencesIcon />,
      },
      {
        key: 'creator-hive',
        path: 'creator_hive',
        fill: true,
        label: changeLabelTextBasedOnTheme('Creator Hive', isCorporateCommunicationsTheme),
        icon: <HiveIcon />,
      },
      {
        key: 'map',
        path: changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme),
        show: true,
        icon: <CountriesIcon />,
      },
      {
        key: 'language-risks',
        path: changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme),
        show: true,
        icon: <SentimentIcon />,
      },
      {
        key: 'companies',
        label: 'Companies',
        path: 'companies',
        show: true,
        icon: <CompaniesIcon />,
      },
      {
        key: 'people',
        label: 'People',
        path: 'people',
        show: true,
        icon: <PeopleIcon />,
      },
      {
        key: 'politician',
        label: 'Politicians',
        path: 'politician',
        show: true,
        icon: <PoliticianIcon />,
      },
      {
        key: 'languages',
        label: 'Languages',
        path: 'languages',
        show: true,
        icon: <LanguageIcon />,
      },
    ]

    return (
      <div className={classNames('PowerInsightTabsListVertical')}>
        <div className={classNames('fixed', { height: detailObj?.mode === 'bookmark' })}>
          {POWER_INSIGHT_TABS.filter((tab) => tab.show)?.map((tab) => (
            <div
              onClick={() => {
                setSelectedPowerInsightTab(tab?.key || '')
                const state = {
                  id: detailObj?.id,
                  name: detailObj?.name,
                  mode: detailObj?.mode,
                  ownerId: detailObj?.ownerId,
                  conditions: detailObj?.conditions,
                  tabName: tab.path,
                  activeTab: tab.path,
                }
                navigate(tab.path as string, { state: JSON.parse(JSON.stringify(state)) })
              }}
              className={classNames(`tab-item`, {
                'tab-item-selected': tab.path
                  ? location.pathname?.includes(tab.path)
                  : !!(!tab.path && !selectedPowerInsightTab),
                fill: tab.fill,
              })}>
              {tab.icon}
              <h6 className='title'>{tab.label}</h6>
            </div>
          ))}
        </div>
      </div>
    )
  },
)

export default PowerInsightTabsListVertical
