import { useMemo } from 'react'
import { Button, Form, Input, Spin, Table, TableProps } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import Platform from 'components/Vectors/Platform'
import CreatorInfo from 'components/Vectors/CreatorInfo'
import Checkbox from 'components/common/Checkbox/Checkbox'

import { AddBrandFormDataType } from './types'
import { GenericDispatch, Unknown } from 'utils/commonTypes'
import { ChannelDataType, FormDataType } from 'pages/Vectors/CreateEditWatchListStepper/types'

const AddLinksAndLogoStep = ({
  formData,
  activeStep,
  setFormData,
  filteredData,
  handleEditURL,
  handleDeleteURL,
  selectedRowKeys,
  handleSelectedRows,
  validateURLLoading,
  verifyAndGetPlatform,
  handleBulkRowsSelection,
}: {
  activeStep: number
  selectedRowKeys: string[]
  validateURLLoading: boolean
  formData: AddBrandFormDataType
  handleBulkRowsSelection: () => void
  handleDeleteURL: (val: string) => void
  filteredData: FormDataType['addedURLs']
  verifyAndGetPlatform: (val: string) => void
  setFormData: GenericDispatch<AddBrandFormDataType>
  handleSelectedRows: (val: string) => void
  handleEditURL: (val: string, platform?: string) => void
}) => {
  const columns = useMemo(
    () => [
      {
        title: () => <span className='creators-list__table__title'>Platform</span>,
        dataIndex: 'platform',
        key: 'platform',
        render: (platform: string) => <Platform platform={platform} />,
      },
      {
        title: () => <span className='creators-list__table__title'>Creator</span>,
        dataIndex: 'channel_url',
        key: 'channel_url',
        render: (_: Unknown, data: ChannelDataType) => (
          <span className='creator-info-column'>
            <CreatorInfo
              setFormData={setFormData as Unknown}
              urlData={data}
              handleEditURL={handleEditURL}
              handleDeleteURL={handleDeleteURL}
            />
          </span>
        ),
      },
    ],
    [activeStep, handleEditURL, handleDeleteURL],
  )

  const rowSelection: TableProps<Unknown>['rowSelection'] = {
    selectedRowKeys,
    renderCell: (_, record) => {
      return (
        <Checkbox
          checked={selectedRowKeys?.includes(record?.channel_url)}
          onClick={() => handleSelectedRows(record?.channel_url)}
        />
      )
    },
    columnTitle: (
      <Checkbox checked={selectedRowKeys.length === filteredData?.length} onClick={handleBulkRowsSelection} />
    ),
  }

  return (
    <>
      {activeStep === 2 && (
        <div className='creation-flow__logo-url'>
          <label className='creation-flow__logo-url'>
            <span className='input-label'>Brand logo</span>
            <Form.Item name='logoURL'>
              <Input
                size='large'
                prefix={<LinkOutlined />}
                onKeyDown={(e) => console.log(e)}
                placeholder='Paste the link here...'
              />
            </Form.Item>
          </label>
          <span className='input-supporting-text'>
            Please add the link to the brand logo. This link should be publicly accessible.
          </span>
        </div>
      )}

      {activeStep === 2 && (
        <div className='creation-flow__urls-links'>
          <label className='creation-flow__logo-url'>
            <span className='input-label'>Brand social accounts</span>
            <Input
              size='large'
              prefix={<LinkOutlined />}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  verifyAndGetPlatform((e.target as HTMLInputElement).value)
                }
              }}
              placeholder='Paste the link(s) here...'
            />
          </label>
          <Spin spinning={validateURLLoading} />
        </div>
      )}

      {activeStep === 3 && formData.logoURL && (
        <div className='creation-flow__logo-container'>
          <div className='creation-flow__logo-container__title'>Brand Logo</div>
          <div className='creation-flow__logo-container__div'>
            <div className='creation-flow__logo-container__div__logo'>
              <img src={formData.logoURL} alt='' />
            </div>
            <Button type='primary' onClick={() => setFormData((prev) => ({ ...prev, logoURL: '' }))}>
              Remove Logo
            </Button>
          </div>
        </div>
      )}

      <div className='add-links__platform-table add-brand-screen'>
        <Table
          columns={columns}
          pagination={false}
          rowKey={'channel_url'}
          dataSource={filteredData || []}
          className='creators-list__table'
          rowSelection={activeStep === 2 ? { type: 'checkbox', ...rowSelection } : undefined}
        />
      </div>
    </>
  )
}

export default AddLinksAndLogoStep
