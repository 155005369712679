import { Dropdown, Menu, MenuProps, Modal, Select, DatePicker } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import 'moment/locale/en-gb'
import moment from 'moment'
import classNames from 'classnames'
import { store } from 'store'
import { monitorAPI } from 'api/api'

import { ReactComponent as DescendingIcon } from 'assets/images/descending-chevron-icon.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/executive-intelligence/icons/add-person/dropdown-arrow.svg'
import { ReactComponent as PositiveSentimentIcon } from 'assets/images/positive-sentiment-icon.svg'
import { ReactComponent as NegativeIconSentimentIcon } from 'assets/images/negative-sentiments-icon.svg'
import { ReactComponent as NeutralIconSentimentIcon } from 'assets/images/neutral-sentiment-icon.svg'
import { ReactComponent as CalenderIcon } from 'assets/images/calendar-icon.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/outline/check.svg'

import { SuccessModal } from './SuccessModal/SuccessModal'
import { SnippetFilterPayload } from 'models/models'
import {
  MONITOR_SNIPPET_MODE_VALYE_BY_LABEL,
  MONITOR_SNIPPET_SORT_LABEL,
  MONITOR_SNIPPET_SORT_VALUE_BY_LABEL,
  PLATFORM_ICONS_GREY,
} from 'settings/settings'
import { WarningModal } from './WarningModal/WarningModal'
import { SubStore } from 'types/types'

import './FeedExport.scss'

const { RangePicker } = DatePicker
moment.locale('en-gb', {
  week: {
    dow: 0,
  },
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
})

interface Props {
  subStore: SubStore
  openModal: boolean
  isForModal?: boolean
  setOpenModal: (open: boolean) => void
}

export const FeedExport = observer(({ subStore, openModal, setOpenModal, isForModal = false }: Props) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openExclusionModal, setOpenExclusionModal] = useState(false)
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [openDateBox, setOpenDateBox] = useState(false)

  const { snippetsFilter, exportFeed, snippetsTotal, getSnippetFilterPayload } =
    store[`${subStore}${isForModal ? 'ModalStore' : 'Store'}`]

  const getCurrentSelectedDate = () => {
    let date = [moment().subtract(90, 'd'), moment()]
    if (snippetsFilter.days.custom.isApplied) {
      date = [moment(snippetsFilter.days.custom.dateRange[0]), moment(snippetsFilter.days.custom.dateRange[1])]
    } else {
      date = [moment().subtract(snippetsFilter.days.noOfDays, 'day'), moment()]
    }
    return { date, dateString: [date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD')] }
  }

  const [total_snippets, setTotal_snippets] = useState<number>(snippetsTotal)
  const [filters, setFilters] = useState<{ sort: string; sort_mode: string; time: string[] }>({
    sort: snippetsFilter.sort,
    sort_mode: snippetsFilter.sort_mode,
    time: getCurrentSelectedDate().dateString,
  })
  const [date, setDate] = useState<moment.Moment[] | null>(getCurrentSelectedDate().date)

  const SENTIMENT_ICONS = [
    { title: 'Positive', icon: <PositiveSentimentIcon /> },
    { title: 'Neutral', icon: <NeutralIconSentimentIcon height={20} /> },
    { title: 'Negative', icon: <NegativeIconSentimentIcon /> },
  ]

  useEffect(() => {
    setFilters({
      ...filters,
      sort: snippetsFilter.sort,
      sort_mode: snippetsFilter.sort_mode,
      time: getCurrentSelectedDate().dateString,
    })
  }, [snippetsFilter])

  useEffect(() => {
    setTotal_snippets(snippetsTotal)
  }, [snippetsTotal])

  useEffect(() => {
    if (openModal) {
      getSnippetFilterPayload().then((payload: SnippetFilterPayload) => {
        payload.sort =
          filters.sort?.length && filters.sort_mode?.length
            ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[filters.sort]}:${
                MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[filters.sort_mode]
              }`
            : ''
        payload.query.date = `start_date:gte:${filters.time[0]},end_date:lte:${filters.time[1]}`
        monitorAPI.loadFeed(payload).then(({ data }) => {
          setTotal_snippets(data.total_count)
        })
      })
    }
  }, [filters])

  const onChange = (dates: any, dateStrings: any) => {
    if (dates) {
      setDate(dates)
    }
  }

  const onApply = () => {
    setOpenDateBox(false)
    if (date) {
      const newValues = [date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD')]
      setFilters({ ...filters, time: newValues })
    }
  }

  const onCancelDate = () => {
    setOpenDateBox(false)
  }

  const getFilters = () => {
    const filters: MenuProps['items'] = []
    if ((snippetsFilter.filter_platform?.length || 0) > 0) {
      filters.push({
        key: '3',
        label: <span className='feed-export__filter-selection__filters__label'>Platform</span>,
        disabled: true,
      })

      const platforms = snippetsFilter.filter_platform?.toString()?.split(',')
      platforms?.forEach((platform) => {
        filters.push({
          key: 'platform' + platform,
          className: 'feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin',
          label: (
            <button
              className={classNames(
                'feed-filters__dropdown-item__mode-btn',
                'feed-filters__dropdown-item__mode-btn--active',
              )}>
              <span className='feed-filters__dropdown-item__mode-btn__title'>
                {PLATFORM_ICONS_GREY.find((el) => el.title.toLowerCase() === platform)?.icon || <></>}
                {platform}
              </span>
            </button>
          ),
        })
      })
    }

    if ((snippetsFilter.filter_sentiment?.length || 0) > 0) {
      filters.push({
        key: '3',
        label: <span className='feed-export__filter-selection__filters__label'>Sentiments</span>,
        disabled: true,
      })

      const sentiments = snippetsFilter.filter_sentiment?.toString()?.split(',')
      sentiments?.forEach((sentiment) => {
        filters.push({
          key: 'sentiment' + sentiment,
          className: 'feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin',
          label: (
            <button
              className={classNames(
                'feed-filters__dropdown-item__mode-btn',
                'feed-filters__dropdown-item__mode-btn--active',
              )}>
              <span className='feed-filters__dropdown-item__mode-btn__title'>
                {SENTIMENT_ICONS.find((el) => el.title === sentiment)?.icon || <></>}
                {sentiment}
              </span>
            </button>
          ),
        })
      })
    }
    return filters
  }

  const onSuccess = async () => {
    exportFeed({ ...filters, total_snippets })
    setOpenModal(false)
    setOpenExclusionModal(false)
    setOpenWarningModal(false)
    setOpenSuccessModal(true)
  }

  const onCancel = async () => {
    setOpenModal(false)
    setOpenExclusionModal(false)
    setOpenWarningModal(true)
    setOpenSuccessModal(false)
    setOpenModal(true)
  }

  const calenderFooter = () => {
    return (
      <div className='feed-export__calendar'>
        <div className='feed-export__calendar__date'>
          <div className='feed-export__calendar__date__container'>
            <span>{moment(filters.time[0]).format('MMM DD, YYYY')}</span>
          </div>
          <span>-</span>
          <div className='feed-export__calendar__date__container'>
            <span>{moment(filters.time[1]).format('MMM DD, YYYY')}</span>
          </div>
        </div>
        <div className='feed-export__calendar__date__button'>
          <button className='feed-export__calendar__date__button__cancel' onClick={onCancelDate}>
            Cancel
          </button>
          <button className='feed-export__calendar__date__button__apply' onClick={onApply}>
            Apply
          </button>
        </div>
      </div>
    )
  }

  const getModalContent = () => {
    return (
      <div className='feed-export__body'>
        <span className='feed-export__description'>
          {'You can customize your data export by applying filters and settings on Snippets Feed page.'}
          <br />

          {'Note: Export is limited to the first 10,000 snippets based on your sort order.'}
        </span>
        <div className='feed-export__filters'>
          <div className='feed-export__filters__left-div'>
            <div className='feed-export__filters__left-div__inner'>
              <span className='feed-export__filters__left-div__inner__export-text'>{'Exporting'}</span>
              <span className='feed-export__filters__left-div__inner__days-text'>{`${moment(filters.time[0]).format(
                'MMM DD, YYYY',
              )} to ${moment(filters.time[1]).format('MMM DD, YYYY')}`}</span>
              <span className='feed-export__filters__left-div__inner__description-text'>
                {<b>{total_snippets} </b>}
                {'snippets selected'}

                {total_snippets > 10000 && (
                  <>
                    {'/ First '}
                    {<b>10,000</b>}
                    {' snippets will be exported'}
                  </>
                )}
              </span>
            </div>
          </div>
          {getFilters().length > 0 && (
            <div>
              <Dropdown
                overlayClassName='feed-export__filter-selection__filters'
                overlay={<Menu items={getFilters()}></Menu>}>
                <button className='feed-export__filters__right-div__filters-btn'>{'3 filters applied'} </button>
              </Dropdown>
            </div>
          )}
        </div>
        <div className='feed-export__filter-selection'>
          <div className='feed-export__filter-selection__hidden'>
            <span className='feed-export__filter-selection__hidden__label'>{'Time period'}</span>
            <div className='feed-export__date-span' onClick={() => setOpenDateBox(true)}>
              <span>
                {date &&
                  `${moment(filters.time[0]).format('MMM DD, YYYY')}  -  ${moment(filters.time[1]).format(
                    'MMM DD, YYYY',
                  )}`}
              </span>
              <CalenderIcon />
            </div>
            <RangePicker
              open={openDateBox}
              onClick={() => setOpenDateBox(true)}
              defaultValue={[getCurrentSelectedDate().date[0], getCurrentSelectedDate().date[1]]}
              format={'DD-MM-YYYY'}
              size='large'
              dropdownClassName='feed-export'
              onChange={onChange}
              renderExtraFooter={() => calenderFooter()}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                'Last 90 Days': [moment().subtract(90, 'days'), moment()],
                'Last year': [moment().subtract(1, 'year'), moment()],
              }}
              disabledDate={(current) => {
                return current > moment().endOf('day')
              }}
            />
          </div>
          <div className='feed-export__filter-selection__container'>
            <span className='feed-export__filter-selection__container__label'>{'Sorting'}</span>
            <Select
              popupClassName='feed-export__dropdown'
              defaultValue={snippetsFilter.sort}
              suffixIcon={<DropdownIcon className='feed-export__filter-selection__order__icon' />}
              onChange={(value: any) => setFilters({ ...filters, sort: value })}
              menuItemSelectedIcon={<CheckIcon className='feed-export__filter-selection__checked-icon' />}>
              {Object.values(MONITOR_SNIPPET_SORT_LABEL)?.map((value) => {
                return (
                  <Select.Option key={value} value={value}>
                    <span className='feed-export__filter-selection__order__label'>{value}</span>
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <div className='feed-export__filter-selection__container'>
            <span className='feed-export__filter-selection__container__label'>{'Order'}</span>
            <Select
              popupClassName='feed-export__dropdown'
              defaultValue={snippetsFilter.sort_mode}
              suffixIcon={<DropdownIcon className='feed-export__filter-selection__order__icon' />}
              onChange={(value: any) => setFilters({ ...filters, sort_mode: value })}>
              <Select.Option key='Descending' value='Descending'>
                <DescendingIcon />
                <span className='feed-export__filter-selection__order__label'>{'Descending'}</span>
              </Select.Option>
              <Select.Option key='Ascending' value='Ascending'>
                <DescendingIcon className='feed-export__filter-selection__order__svg-icon' />
                <span className='feed-export__filter-selection__order__label'>{'Ascending'}</span>
              </Select.Option>
            </Select>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal
        width={718}
        className='feed-export'
        title={
          <div className='feed-export__title'>{openExclusionModal ? 'Preview excluded content' : 'Export Data'}</div>
        }
        open={openModal}
        afterClose={() => setOpenExclusionModal(false)}
        destroyOnClose={true}
        footer={
          <div className='feed-export__footer'>
            <button
              className='feed-export__footer__cancel'
              onClick={() => {
                setOpenModal(false)
                setOpenExclusionModal(false)
              }}>
              Cancel
            </button>
            <button className='feed-export__footer__send' onClick={onSuccess}>
              Export Data
            </button>
          </div>
        }
        onCancel={() => {
          onCancel()
        }}>
        {getModalContent()}
      </Modal>
      <SuccessModal isModalVisible={openSuccessModal} setIsModalVisible={setOpenSuccessModal} />
      <WarningModal
        isModalVisible={openWarningModal}
        setIsModalVisible={setOpenWarningModal}
        setMainModal={setOpenModal}
      />
    </>
  )
})
