import DetailViewSectionDetail from '../DetailViewSectionDetail/DetailViewSectionDetail'
import { sectionObj } from 'store/report/valueTypes'
import { useState } from 'react'

type tagsObj = {
  id: string
  name: string
  color: string
}
type bookmarksbj = {
  id: string
  name: string
  link: string
}

export type collectionObj = {
  watchlists: bookmarksbj[]
  narratives: bookmarksbj[]
  bookmarks: bookmarksbj[]
  tags: tagsObj[]
  summary: string
}

export type contentObj = {
  id: string
  desc: string
}

type DetailViewSectionsListProps = {
  data: sectionObj[]
  feedView?: boolean
  selectedSubSection: string
  setSelectedSubSection: (sectionName: string) => void
}
function DetailViewSectionsList(props: DetailViewSectionsListProps) {
  const { data, feedView = false, selectedSubSection, setSelectedSubSection } = props
  const [editableId, setEditableId] = useState<string>('')
  const [selectedTextChunkId, setSelectedTextChunkId] = useState<string>('')
  const [textChunkValue, setTextChunkValue] = useState<string>('')

  return (
    <div>
      {data.map((section, index) => {
        return (
          <>
            <DetailViewSectionDetail
              key={section.id}
              {...section}
              sectionData={section}
              currentIndex={index}
              editableId={editableId}
              setEditableId={setEditableId}
              selectedTextChunkId={selectedTextChunkId}
              setSelectedTextChunkId={setSelectedTextChunkId}
              textChunkValue={textChunkValue}
              setTextChunkValue={setTextChunkValue}
              feedView={feedView}
              selectedSubSection={selectedSubSection}
              setSelectedSubSection={setSelectedSubSection}
            />
          </>
        )
      })}
    </div>
  )
}

export default DetailViewSectionsList
