import { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { ReactComponent as ReportButtonIcon } from 'assets/images/icons/report/report-button-stars-icon.svg'
import { ReactComponent as DropdownArrowIcon } from 'assets/images/icons/downArrowGrey.svg'

import styles from './DetailViewCollectionAdded.module.scss'
import { collectionObj } from 'store/report/valueTypes'
import { FilterTag } from 'pages/Investigate/CreateReport/components/ReviewContentDisplay/ReviewContentDisplay'
import { isEmpty } from 'lodash'
import { DefaultFilters } from 'pages/Investigate/CreateReport/CreateReport'

type DetailViewCollectionAddedProps = {
  collections: { mainItems: collectionObj; optionalItems: collectionObj }
  editable?: boolean
  filters?: DefaultFilters
  summaryPrompt?: string
}

const DetailViewCollectionAdded = observer((props: DetailViewCollectionAddedProps) => {
  const { collections, editable, filters, summaryPrompt } = props
  const { navViewStore } = store
  const { isNavOpen } = navViewStore

  const [collapse, setCollapse] = useState<boolean>(true)

  // const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  // const filtersConditions = (key: string) =>
  //   (!canReviewAndReceive && key === 'searches') || (!canReviewAndReceive && key === 'influencers')

  return (
    <div className={classNames(styles.dvca_container)}>
      <div
        className={classNames(styles.dvca_c_header, { [styles.dvca_c_header_expand]: collapse })}
        onClick={() => setCollapse(!collapse)}>
        <div
          className={classNames(styles.dvca_c_header_left_container, {
            [styles.dvca_c_header_left_container_expand]: !collapse,
          })}>
          <p className={styles.dvca_c_header_text}>Related content</p>
        </div>
        <div className={styles.dvca_c_dropdown_icon_container}>
          <DropdownArrowIcon
            className={classNames(styles.dvca_c_dropdown_icon, { [styles.dvca_c_rotate_dropdown_icon]: !collapse })}
          />
        </div>
      </div>
      <div className={styles.dvca_content_main_container}>
        <div
          className={classNames(styles.dvca_content_container, {
            [styles.dvca_content_container_collapse]: !collapse,
            [styles.dvca_cc_with_active_menu]: isNavOpen && !editable,
            [styles.dvca_cc_without_active_menu]: !isNavOpen && !editable,
            [styles.dvca_cc_edit_with_active_menu]: isNavOpen && editable,
            [styles.dvca_cc_edit_without_active_menu]: !isNavOpen && editable,
          })}>
          <div className={styles.filters}>
            <>
              <span className={styles.filters_title}>All of these</span>
              <div className={styles.filters_list}>
                {Object.entries(collections.mainItems).map(([key, value]) =>
                  isEmpty(value) ? null : (
                    <FilterTag label={key} value={value?.map((item: any) => item.name)?.join(', ')} />
                  ),
                )}
              </div>
            </>

            <>
              <span className={styles.filters_title}>At least one</span>
              <div className={styles.filters_list}>
                {Object.entries(collections.optionalItems).map(([key, value]) =>
                  isEmpty(value) ? null : (
                    <FilterTag label={key} value={value?.map((item: any) => item.name)?.join(', ')} />
                  ),
                )}
              </div>
            </>

            <>
              <span className={styles.filters_title}>Filters</span>
              <div className={styles.filters_list}>
                {filters &&
                  Object.entries(filters).map(([key, value]) =>
                    isEmpty(value) ? null : (
                      <FilterTag label={key} value={value?.map((item: any) => item).join(', ')} />
                    ),
                  )}
              </div>
            </>
          </div>
          <div className={styles.summary}>
            <div className={styles.heading}>
              <ReportButtonIcon /> Summarization prompt
            </div>
            <div className={styles.text}>{summaryPrompt}</div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default DetailViewCollectionAdded
