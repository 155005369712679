import { useState } from 'react'
import { Form, Input } from 'antd'
import { observer } from 'mobx-react-lite'

import AddBrandTextArea from './AddBrandTextArea'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/sparkle.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/icons/flag/loader_icon.svg'

const AddSearchStep = ({
  isSearchActive,
  isTopicCreation,
  isBooleanActive,
  handleSearchClick,
  convertBooleanClick,
  convertBooleanLoading,
}: {
  isSearchActive: boolean
  isBooleanActive: Boolean
  isTopicCreation: boolean
  handleSearchClick: () => void
  convertBooleanClick: () => void
  convertBooleanLoading: boolean
}) => {
  const [isCoPilotClosed, setIsCoPilotClosed] = useState(false)
  return (
    <>
      <label className='creation-flow__brand-name'>
        <span className='input-label'>
          {isTopicCreation ? 'Topic Name' : 'Brand name'} <span className='asterisk'>*</span>
        </span>
        <Form.Item
          name='brandName'
          rules={[{ required: true, message: `Please input ${isTopicCreation ? 'topic' : 'brand'} name` }]}
          shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input placeholder='Add name' />
        </Form.Item>
      </label>
      <div className='creation-flow__heading'>
        {isCoPilotClosed && (
          <div className='creation-flow__heading__spark-icon' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
            <SparkleIcon />
          </div>
        )}
        {!isCoPilotClosed && (
          <div className='creation-flow__heading__text' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
            Close Co-Pilot
          </div>
        )}
        {isCoPilotClosed && (
          <>
            <div className='creation-flow__heading__text' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
              Pendulum Co-Pilot
            </div>
            <div className='creation-flow__heading__info-icon'>
              <InfoIcon />
            </div>
          </>
        )}
      </div>

      {!isCoPilotClosed && (
        <>
          <AddBrandTextArea label='Pendulum Co-Pilot Search' name='userSearchString' isRequiredField={false} />

          <ButtonImage
            loading={convertBooleanLoading}
            disabled={!isBooleanActive || convertBooleanLoading}
            icon={LoaderIcon}
            onClick={convertBooleanClick}
            className='creation-flow__btn'>
            Convert to boolean
          </ButtonImage>
        </>
      )}

      <AddBrandTextArea label='Boolean Keyword Search' name='booleanKeyword' isRequiredField />

      <ButtonImage
        loading={false}
        disabled={!isSearchActive}
        onClick={handleSearchClick}
        type='button'
        className='creation-flow__btn preview-btn'>
        Search
      </ButtonImage>
    </>
  )
}

export default observer(AddSearchStep)
