import { useState } from 'react'
import classNames from 'classnames'
import { Button, Input, Row } from 'antd'

import PulseLeftComponent from './PulseLeftComponent'
import BaseModal from 'components/BaseModal/BaseModal'
import PulseRightComponent from './PulseRightComponent'
import PulseCenterComponent from './PulseCenterComponent'
import AddBrandWizard from 'components/Asset/BrandsDashboard/components/AddBrandWizard/AddBrandWizard'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as CrossIcon } from 'assets/images/cross_black.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warning-icon_modal.svg'
import { ReactComponent as DiamondWithBoxIcon } from 'assets/images/diamond_with_box.svg'
import { ReactComponent as SearchWithEnvelopIcon } from 'assets/images/search_with_envelop.svg'
import { ReactComponent as SearchWithWorldMapIcon } from 'assets/images/search_with_world_map.svg'

import './PulseDashboard.scss'

const PulseDashboard = () => {
  const [isCloseModalOpened, setIsCloseModalOpened] = useState<string>('')
  const [isAddNewTabModalOpened, setIsAddNewTabModalOpened] = useState(false)
  const [isCreateNewModalOpened, setIsCreateBewModalOpened] = useState(false)
  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState(false)
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false)

  const handleCreateNewClick = () => setIsCreateBewModalOpened(true)

  const handlePowerInsightsClick = () => {}

  const handleCreateEntity = (entity: 'topic' | 'brand' | 'followedTopic') => {
    setIsCreateBewModalOpened(false)
    switch (entity) {
      case 'brand':
        setIsCreateAssetModalOpen(true)
        break
      case 'topic':
        setIsCreateTopicModalOpen(true)
        break
      case 'followedTopic':
        setIsCreateTopicModalOpen(true)
        break

      default:
        break
    }
  }

  return (
    <div className='pulse-dashboard'>
      <div className='pulse-dashboard-tabs--container'>
        <div className='pulse-dashboard-tabs'>
          <div className={classNames('pulse-dashboard-tabs__tab', { active: true })}>
            <span className='pulse-dashboard-tabs__tab__title'>Pulse - All content</span>
            <span className='pulse-dashboard-tabs__tab__count'>
              <span className='pulse-dashboard-tabs__tab__count__text'>27</span>
            </span>
            <Button icon={<CrossIcon />} onClick={() => setIsCloseModalOpened('id')} />
          </div>
          <div className={classNames('pulse-dashboard-tabs__tab', { active: false })}>
            <span className='pulse-dashboard-tabs__tab__title'>Threatening conversations</span>
            <span className='pulse-dashboard-tabs__tab__count'>
              <span className='pulse-dashboard-tabs__tab__count__text'>27</span>
            </span>
            <Button icon={<CrossIcon />} onClick={() => setIsCloseModalOpened('id')} />
          </div>
        </div>
        <Button className='add-tabs-btn' onClick={() => setIsAddNewTabModalOpened(true)}>
          <PlusIcon />
        </Button>
      </div>
      <Row>
        <PulseLeftComponent />
        <PulseCenterComponent />
        <PulseRightComponent
          handleCreateNewClick={handleCreateNewClick}
          handlePowerInsightsClick={handlePowerInsightsClick}
        />
      </Row>

      <BaseModal
        closable={false}
        open={!!isCloseModalOpened}
        className='warning-modal'
        onCancel={() => setIsCloseModalOpened('')}
        footer={
          <>
            <Button type='text' onClick={() => setIsCloseModalOpened('')}>
              Cancel
            </Button>
            <Button type='primary' onClick={() => null} loading={false}>
              Close
            </Button>
          </>
        }>
        <div className='warning-modal__content'>
          <div className='warning-modal__content__icon'>
            <WarningIcon />
          </div>
          <div className='warning-modal__content__info'>
            <div className='warning-modal__content__info__title'>Are you sure you want to close the tab?</div>
            <div className='warning-modal__content__info__description'>
              Closing the tab will result in loosing all your content selections. This action cannot be undone
            </div>
          </div>
        </div>
      </BaseModal>

      <BaseModal
        closable
        isHeaderBordered
        isFooterBordered
        title='Add new tab'
        open={isAddNewTabModalOpened}
        onCancel={() => setIsAddNewTabModalOpened(false)}
        footer={
          <>
            <Button type='text' onClick={() => setIsAddNewTabModalOpened(false)}>
              Cancel
            </Button>
            <Button type='primary' onClick={() => null} loading={false}>
              Confirm
            </Button>
          </>
        }>
        <div className='tab-creation-content'>
          <label>
            <span className='tab-creation-content__tab-label'>
              Tab name
              <span className='tab-creation-content__asterisk'>*</span>
            </span>
            <Input placeholder='Threatening conversations' key={'tab-creation-content__input'} />
          </label>
        </div>
      </BaseModal>

      <BaseModal
        closable
        className='create-by-choice-modal'
        title={
          <div className='create-by-choice-modal__title'>
            <div className='create-by-choice-modal__title__text'>Create new item</div>
            <div className='create-by-choice-modal__title__description'>
              Select the type of item you want to create.
            </div>
          </div>
        }
        open={isCreateNewModalOpened}
        onCancel={() => setIsCreateBewModalOpened(false)}
        footer={null}>
        <div className='create-by-choice-modal__content'>
          <div className='option' onClick={() => handleCreateEntity('brand')}>
            <div className='option-icon'>
              <DiamondWithBoxIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Brand</div>
              <div className='option-info__description'>
                Add a new brand to track and analyze related online conversations and brand mentions.
              </div>
            </div>
          </div>
          <div className='option' onClick={() => handleCreateEntity('topic')}>
            <div className='option-icon'>
              <SearchWithWorldMapIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Horizon topic</div>
              <div className='option-info__description'>
                Create a topic that extends beyond your existing data lake by indexing new data sources.
              </div>
            </div>
          </div>
          <div className='option' onClick={() => handleCreateEntity('followedTopic')}>
            <div className='option-icon'>
              <SearchWithEnvelopIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Followed topic</div>
              <div className='option-info__description'>
                Create a topic that uses data that already exists in your data lake or Library.
              </div>
            </div>
          </div>
        </div>
      </BaseModal>

      {isCreateAssetModalOpen && (
        <AddBrandWizard
          setSelectedBrand={() => null}
          editedBrandData={null}
          isTopicCreation={false}
          handleCloseModal={() => {
            setIsCreateAssetModalOpen(false)
          }}
        />
      )}

      {isCreateTopicModalOpen && (
        <AddBrandWizard
          setSelectedBrand={() => null}
          editedBrandData={null}
          isTopicCreation={true}
          handleCloseModal={() => {
            setIsCreateTopicModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default PulseDashboard
