import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { CreateReportObj, CreateReportSectionObj, EntitiesObj, ReportType } from 'store/report/valueTypes'
import { Unknown } from 'utils/commonTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { RecurrenceOptions } from 'pages/Investigate/CreateReport/steps/Recurrence'
import AddEditContentSection from 'pages/Investigate/CreateReport/components/AddEditContentSection'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const convertLocalTimeToUTC = (time: string) => {
  const timeWithDate = dayjs().startOf('day').format('YYYY-MM-DD') + ' ' + time
  const localTime = dayjs(timeWithDate).utc()
  return parseInt(localTime.format('HH'), 10)
}

const reportType = {
  ADHOC: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

export const defaultSelectedContentItems = {
  brand: [] as Unknown[],
  flags: [] as Unknown[],
  topics: [] as Unknown[],
  searches: [] as Unknown[],
  influencers: [] as Unknown[],
  bookmarks: [] as Unknown[],
}

export type DefaultSelectedContentItems = {
  brand: Unknown[]
  flags: Unknown[]
  topics: Unknown[]
  searches: Unknown[]
  influencers: Unknown[]
  bookmarks: Unknown[]
}

export type DefaultFilters = {
  language: Unknown[]
  platform: Unknown[]
  sentiment: Unknown[]
  source: Unknown[]
}

type SectionItems = {
  mainItems: DefaultSelectedContentItems
  optionalItems: DefaultSelectedContentItems
  filters: DefaultFilters
  isNew?: boolean
}

export type SectionObjType = SectionItems & CreateReportSectionObj

const DetailViewReportEdit = ({
  sectionId,
  onClose,
  summary,
  closeCreateAndOpenSuccessModal,
}: {
  sectionId: string
  onClose: () => void
  summary: { content: any; is_v2?: boolean }
  closeCreateAndOpenSuccessModal: (data: any) => void
}) => {
  const { reportMainViewStore } = store
  const { reportDetails, isConfirmLoading } = reportMainViewStore
  const [sections, setSections] = useState<SectionObjType[]>([])
  const [recurrenceOptions, setRecurrenceOptions] = useState<RecurrenceOptions>({
    selectedDay: 'Monday',
    deliveryTime: '12:00 AM',
    recurrenceType: reportType.DAILY as ReportType,
    dateRange: { startDate: new Date(), endDate: new Date() },
  })
  const [sectionToEdit, setSectionToEdit] = useState<SectionObjType | undefined>()

  useEffect(() => {
    if (reportDetails) {
      const startDate = reportDetails.report_instance_history[0]?.start_date
      const endDate = reportDetails.report_instance_history[0]?.end_date
      setRecurrenceOptions({
        selectedDay: reportDetails.weekly_report_day || 'Monday',
        deliveryTime: reportDetails.report_hour || '12:00 AM',
        recurrenceType: reportDetails.report_type,
        dateRange:
          reportDetails.report_type === 'ADHOC'
            ? {
                startDate: dayjs(startDate).toDate() || new Date(),
                endDate: dayjs(endDate).toDate() || new Date(),
              }
            : { startDate: new Date(), endDate: new Date() },
      })

      const formattedSections = reportDetails.sections.map((section) => {
        const mainItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))
        const optionalItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))

        section.entities?.forEach((entity) => {
          const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
          if (entity.type === 'ASSET') {
            mainItems.brand.push(formattedEntity)
          } else if (entity.type === 'BOOKMARK') {
            mainItems.bookmarks.push(formattedEntity)
          } else if (entity.type === 'NARRATIVE') {
            mainItems.searches.push(formattedEntity)
          } else if (entity.type === 'WATCHLIST') {
            mainItems.influencers.push(formattedEntity)
          } else if (entity.type === 'FLAG') {
            mainItems.flags.push(formattedEntity)
          }
        })
        section.refined_entities?.forEach((entity) => {
          const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
          if (entity.type === 'ASSET') {
            optionalItems.brand.push(formattedEntity)
          } else if (entity.type === 'BOOKMARK') {
            optionalItems.bookmarks.push(formattedEntity)
          } else if (entity.type === 'NARRATIVE') {
            optionalItems.searches.push(formattedEntity)
          } else if (entity.type === 'WATCHLIST') {
            optionalItems.influencers.push(formattedEntity)
          } else if (entity.type === 'FLAG') {
            optionalItems.flags.push(formattedEntity)
          }
        })
        return {
          ...section,
          title: section.title || '',
          summary_prompt: section.summary_prompt || '',
          mainItems,
          optionalItems,
          filters: section.filters || ({ language: [], platform: [], sentiment: [], source: [] } as DefaultFilters),
        }
      })
      const sectionToEdit = formattedSections.find((section) => section.id === sectionId)
      setSectionToEdit(sectionToEdit)
      setSections(formattedSections)
    }
  }, [reportDetails])

  const closeAddEditSection = (section?: SectionObjType) => {
    if (!section) {
      onClose()
      return
    }

    const entities: EntitiesObj[] = []
    const refined_entities: EntitiesObj[] = []
    const { mainItems, optionalItems, title, summary_prompt, filters } = section

    Object.keys(mainItems).forEach((type) => {
      mainItems[type as keyof typeof mainItems].forEach((item: any) => {
        entities.push({ type: item.type, id: item.id, value: item.name })
      })
    })
    Object.keys(optionalItems).forEach((type) => {
      optionalItems[type as keyof typeof optionalItems].forEach((item: any) => {
        refined_entities.push({ type: item.type, id: item.id, value: item.name })
      })
    })

    const editedSection = {
      title,
      summary_prompt,
      entities: entities,
      refined_entities: refined_entities,
      filters,
    }

    const newSections = reportDetails.sections
      .map((data) => {
        if (data.id === section.id) return editedSection
        return data
      })
      .map((data) => ({
        title: data.title || '',
        summary_prompt: data.summary_prompt || '',
        entities: data.entities,
        refined_entities: data.refined_entities,
        filters: data.filters,
      }))

    const { name, report_type, is_enabled } = reportDetails

    const body: CreateReportObj = {
      is_enabled,
      report_type,
      name,
      sections: newSections,
    }
    if (recurrenceOptions.recurrenceType === 'DAILY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
    }
    if (recurrenceOptions.recurrenceType === 'WEEKLY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
      body.weekly_report_day = recurrenceOptions.selectedDay
    }
    if (recurrenceOptions.recurrenceType === 'ADHOC') {
      body.start_date = dayjs(recurrenceOptions.dateRange.startDate.toISOString()).utc().format('YYYY-MM-DD')
      body.end_date = dayjs(recurrenceOptions.dateRange.endDate.toISOString()).utc().format('YYYY-MM-DD')
    }

    closeCreateAndOpenSuccessModal(body)
  }

  return (
    <>
      {sectionToEdit && (
        <AddEditContentSection
          closeAddEditSection={closeAddEditSection}
          isOpenAddNewSection={Boolean(sectionToEdit)}
          sectionToEdit={sectionToEdit}
          recurrenceOptions={recurrenceOptions}
          sections={sections}
          reviewStep
          summary={summary}
          loading={isConfirmLoading}
        />
      )}
    </>
  )
}

export default observer(DetailViewReportEdit)
