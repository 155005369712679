import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import IssueCard from './IssueCard'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import Pagination from 'components/Pagination/Pagination'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { store } from 'store'
import { SnippetFilterPayload } from 'models/models'
import { openNotification } from 'services/Util/openNotification'
import { getDaysDifference } from 'services/Util/getDaysDifference'

import { NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-icon.svg'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/empty-state.svg'

import './FollowedIssues.scss'

const FollowedIssues = ({
  onSelectFlag,
  selectedBrand,
  subStore = 'assets',
  isOnlyView = false,
  handleBrowseMoreClick,
}: {
  isOnlyView?: boolean
  subStore?: 'assets' | 'vectors'
  handleBrowseMoreClick?: () => void
  onSelectFlag: (flag: FlagListObjType) => void
  selectedBrand: AssetListObjType | NarrativeListDataType
}) => {
  const { flagsStore } = store
  const { fetchFlagsListWithConditions, flagsLoading, flagsList } = flagsStore
  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    per_page: 5,
  })
  const { snippetsFilter, getSnippetFilterPayload, getDate, fetchInsightsMetrics } = store[`${subStore}Store`]
  const [isFlagsMetricsLoading, setIsFlagsMetricsLoading] = useState(false)
  const [flagsWithMetrics, setFlagsWithMetrics] = useState<any>([])

  const getActiveTopicsData = async () => {
    const requestParams: FlagListParamsType = {
      page: 1,
      per_page: 5,
      sort: 'name:asc',
      is_active: true,
    }
    await fetchFlagsListWithConditions({ params: requestParams })
  }

  useEffect(() => {
    getActiveTopicsData()
  }, [])

  useEffect(() => {
    if (flagsList.items.length > 0 && selectedBrand) {
      fetchAllActiveFlagMetrics(flagsList.items)
    }
  }, [flagsList, selectedBrand, snippetsFilter.days])

  const renderConditions = useMemo(() => {
    if (
      selectedBrand &&
      selectedBrand?.conditions &&
      Object.keys((selectedBrand as AssetListObjType)?.conditions)?.length
    ) {
      return [(selectedBrand as AssetListObjType)?.conditions]
    }
    return []
  }, [selectedBrand])

  const fetchMetricsForFlag = async (flag: FlagListObjType, requestParams: { q: string; measure?: string }) => {
    if (!flag.conditions) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          skip: true,
        },
      }
    }

    const requestData = { conditions: [...renderConditions, flag.conditions] }
    const views = await fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true })
    let results
    if (views.total_value > 0) {
      results = await Promise.allSettled([
        fetchInsightsMetrics({
          params: { ...requestParams, measure: 'impression' },
          data: requestData,
          fullResponse: true,
        }),
        fetchInsightsMetrics({
          params: { ...requestParams, measure: 'engagement' },
          data: requestData,
          fullResponse: true,
        }),
      ])
    }

    const getResultValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error' ? 0 : result.value.total_value
      }
      return 0
    }

    const viewsCount = views?.total_value || 0
    const impressionsCount = views.total_value > 0 && results ? getResultValue(results[0]) : 0
    const engagementCount = views.total_value > 0 && results ? getResultValue(results[1]) : 0

    if (viewsCount === 0 && impressionsCount === 0 && engagementCount === 0) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          engagement: 0,
          skip: true,
        },
      }
    }

    return {
      id: flag.id,
      metrics: {
        views: viewsCount,
        impressions: impressionsCount,
        engagement: engagementCount,
        skip: false,
      },
    }
  }

  const fetchAllActiveFlagMetrics = async (flags: FlagListObjType[]) => {
    setIsFlagsMetricsLoading(true)
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)

      //use community,platform,sentiment and flags filter,
      const requestParams: { q: string; metric?: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate},risk_score:gte:0,risk_score:lte:100${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }

      // loop through all flags and fetch metric for each flag
      const promises = flags.map((flag) => fetchMetricsForFlag(flag, requestParams))
      // Wait for all promises to settle and process results
      const results = await Promise.allSettled(promises)

      const metrics: Record<string, any> = {}

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })

      const onlyFlagsWithMetrics = flags
        .map((flag) => ({ ...flag, metric: metrics[flag.id] }))
        .filter((flag) => !flag.metric.skip)

      setFlagsWithMetrics(onlyFlagsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
      setIsFlagsMetricsLoading(false)
    }
  }

  if (!flagsLoading && !isFlagsMetricsLoading && flagsWithMetrics.length === 0) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Followed Topics</span>
        <div className='followed-issues-empty-state'>
          <InfluencerEmptyStateImage />
          {flagsList.items.length === 0 && (
            <>
              <h5>You are currently not following any topics</h5>
              <p>Start by creating a topic or follow a suggested topic</p>
              <h6 onClick={handleBrowseMoreClick}>Click here to get started </h6>
            </>
          )}
          {flagsList.items.length > 0 && (
            <>
              <h5 className='followed-issues-empty-state__message'>
                Topics you are following have no data for the selected time range. You can modify the time range or
                adjust your topic selection using the link below.
              </h5>
              <h6 onClick={handleBrowseMoreClick}>Browse more</h6>
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <span className='followed-issues-heading'>
        <span className='monitor-assets__graph__title'>Followed Topics</span>
        <InfoTooltip text='These topics represent the most viewed content, drawn from the top 1,000 posts with the highest engagement and views.' />
      </span>
      <div className='followed-issues'>
        {!flagsLoading &&
          !isFlagsMetricsLoading &&
          flagsWithMetrics.map((flag: FlagListObjType) => (
            <IssueCard
              key={`${flag.id}flagId`}
              flag={flag}
              onSelectFlag={onSelectFlag}
              subStore={subStore}
              isOnlyView={isOnlyView}
            />
          ))}
        {!flagsLoading && !isFlagsMetricsLoading && (
          <div className='followed-issues__controls'>
            <Pagination
              totalCount={flagsList.total_count}
              currentPage={paginationValues.page}
              pageSize={paginationValues.per_page}
              onPageChange={(page) => {
                let requestParams: FlagListParamsType = {
                  page,
                  per_page: paginationValues.per_page,
                  sort: 'name:asc',
                  is_active: true,
                }
                setPaginationValues({ page, per_page: paginationValues.per_page })
                fetchFlagsListWithConditions({ params: requestParams })
              }}
            />
            <ButtonImage icon={ArrowIcon} className='browse-more-btn' onClick={handleBrowseMoreClick}>
              Browse More
            </ButtonImage>
          </div>
        )}

        {(flagsLoading || isFlagsMetricsLoading) &&
          Array(5)
            .fill(null)
            .map(() => <IssueCard emptyState onSelectFlag={() => {}} />)}
      </div>
    </>
  )
}

export default observer(FollowedIssues)
