import { Avatar, Button, Col, Dropdown, Radio, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import BrandSentiment from './components/BrandSentiment/BrandSentiment'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PortfolioShareChart from './components/PortfolioShareChart/PortfolioShareChart'
import PlatformDistributionCard from './components/PlatformDistributionCard/PlatformDistributionCard'
import BrandsListVertical from './components/BrandsListVertical/BrandsListVertical'
import MediaTypeSelector from './components/MediaTypeSelector/MediaTypeSelector'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as PowerInsightsIcon } from 'assets/images/icons/powerinsight_icon.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import InfluencerTable from './components/InfluencerTable/InfluencerTable'
import SuggestedIssues from './components/SuggestedIssues/SuggestedIssues'
import FollowedIssues from './components/FollowedIssues/FollowedIssues'
import DailyExecutiveSummary from './components/DailyExecutiveSummary/DailyExecutiveSummary'
import SnippetsDiscovery from './components/SnippetsDiscovery/SnippetsDiscovery'
import { DownloadOption } from './components/DownloadOption'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'
import { MonitorGraph } from './components/MonitorGraph/MonitorGraph'
import PowerInsightTabsListVertical from './components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'
import classNames from 'classnames'
import { DetailsObjType } from 'types/types'
import { IBarChartData } from 'models/models'
import AddBrandWizard from './components/AddBrandWizard/AddBrandWizard'
import { MaybeNull } from 'utils/commonTypes'
import TopicsListModal from './components/TopicsListModal/TopicsListModal'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import InfoTooltip from './components/InfoTooltip/InfoTooltip'
import BaseModal from 'components/BaseModal/BaseModal'
import { CreatorDetailsPage } from 'components/Monitor/PowerInsights/Creators/CreatorDetailsPage/CreatorDetailsPage'
import { ReactComponent as DashboardIcon } from 'assets/images/icons/assets/dashboard.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/assets/list.svg'
import { ReactComponent as BanIcon } from 'assets/images/icons/assets/ban.svg'
import './BrandDashboard.scss'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { openNotification } from 'services/Util/openNotification'
import { flagsAPI } from 'api/api'
import usePermissions from 'hooks/usePermissions'
import DashboardEmptyState from 'components/DashboardEmptyState/DashboardEmptyState'

const BRANDS = 'Brands'
const customGraphColors = {
  shadeColorUp: '#eeeff4',
  lineColorUp: '#A84BD3',
  shadeColorDown: '#eeeff4',
  lineColorDown: '#A84BD3',
  theme: 'purple',
}

const BrandDashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showBrandList, setShowBrandList] = useState(true)
  const [isTopicsModal, setIsTopicsModal] = useState<boolean>(false)
  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [itemToView, setItemToView] = useState<FlagListObjType | IBarChartData | undefined>()
  const [itemToViewType, setItemToViewType] = useState('')
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('metrics')
  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState<boolean>(false)
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState<boolean>(false)
  const [editedBrandData, setEditedBrandData] = useState<MaybeNull<AssetListObjType>>(null)
  const { assetsStore, tenantsStore, loaderStore, flagsStore } = store
  const {
    previousInfo,
    fetchPowerInsightsForDashboardPage,
    brandDashboardDataLoading,
    assetsLoading,
    assetsList,
    snippetsFilter,
    fetchAssetsList,
    assetsCategories,
    fetchCategories,
    fetchSnippets,
    setSnippetsFilter,
    resetSnippetFilters,
    selectedBrand,
    setSelectedBrand,
    isCreatorModalOpen,
    setIsCreatorModalOpen,
    corporateCommunicationBrandsView,
    updateCorporateCommunicationView,
    deleteAsset,
    fetchSnippetsInfo,
  } = assetsStore
  const { fetchFlagsListWithConditions } = flagsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { statsLineChartLoading, isLoadingFeed } = loaderStore
  const [isOpenBrandDeleteModal, setIsOpenBrandDeleteModal] = useState(false)
  const [triggerRefetchBrandList, setTriggerRefetchBrandList] = useState(false)
  const [isDeletingBrand, setIsDeletingBrand] = useState(false)

  const isPowerInsightsMode = location.pathname?.includes('/brand/power-insights')
  const brandLoading = assetsLoading || customThemeLoading || brandDashboardDataLoading
  const userCanCreateAsset = usePermissions('assets') === 'create'

  const detailObject = {
    name: selectedBrand?.name,
    conditions: selectedBrand?.conditions,
    mode: 'asset',
    id: selectedBrand?.id,
    activeTab: selectedPowerInsightTab,
  }

  const getPowerInsightDataOnLanding = (brand: AssetListObjType) => {
    fetchPowerInsightsForDashboardPage({
      name: brand?.name || '',
      conditions: brand?.conditions || {},
      mode: 'asset',
      id: brand?.id || '',
    })
    const setSnippetsFilter = store.assetsModalStore.setSnippetsFilter
    setSnippetsFilter(snippetsFilter)
  }

  useEffectOnce(() => {
    updateCorporateCommunicationView('dashboard')
  })

  useEffectOnce(() => {
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      navigate('/')
    }
  }, [isCorporateCommunicationsTheme, customThemeLoading])

  useEffect(() => {
    if (assetsCategories.length === 0) return
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    const params: AssetListParamsType = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory?.category_id}`,
    }
    fetchAssetsList({ params: params })
  }, [assetsCategories])

  useEffect(() => {
    if (!assetsLoading && assetsList.items.length > 0) {
      const brand = assetsList.items.filter((brand) => brand.is_active)[0]
      if (!selectedBrand) {
        if (brand && !isPowerInsightsMode) {
          setSelectedBrand(brand)
          getPowerInsightDataOnLanding(brand)
          return
        }
      }
      if (!brand) setSelectedBrand(undefined)
    }
  }, [assetsList])

  useEffect(() => {
    if (isPowerInsightsMode && selectedBrand) {
      fetchSnippets()
    }
  }, [selectedBrand])

  if (brandLoading) {
    return (
      <div className='spinner-container'>
        <Spin />
      </div>
    )
  }

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)

  const openSnippetsDiscovery = ({
    watchlist,
    flag,
    brand,
  }: {
    watchlist?: IBarChartData | null
    flag?: FlagListObjType | null
    brand?: AssetListObjType | null
  }) => {
    const { setSnippetsFilter } = store.assetsModalStore

    setSnippetsFilter({
      ...snippetsFilter,
      flags: {
        name: flag ? flag.name : snippetsFilter?.flags?.name || '',
        conditions: flag ? flag?.conditions || {} : snippetsFilter?.flags?.conditions || {},
      },
      watchlist: watchlist ? watchlist?.channel_urls : snippetsFilter.watchlist,
      assets: brand
        ? { name: brand.name, conditions: brand?.conditions }
        : selectedBrand?.conditions
        ? { name: selectedBrand.name, conditions: selectedBrand?.conditions }
        : snippetsFilter.assets,
      filter_sentiment: brand?.filter_sentiment ? brand?.filter_sentiment : snippetsFilter.filter_sentiment,
      filter_platform: brand?.filter_platform ? brand?.filter_platform : snippetsFilter.filter_platform,
    })
    setIsOpenSnippetsDiscovery(true)
  }

  const closeSnippetsDiscovery = () => {
    if (itemToView) {
      setItemToView(undefined)
      setItemToViewType('')
    }
    const { setSnippetsFilter } = store.assetsModalStore
    setSnippetsFilter(snippetsFilter)
    setIsOpenSnippetsDiscovery(false)
  }

  const onSelectFlag = (flag: FlagListObjType) => {
    setItemToView(flag)
    setItemToViewType('flag')
    openSnippetsDiscovery({ flag })
  }

  const onSelectWatchlist = (watchlist: IBarChartData) => {
    setItemToView({ ...watchlist, name: watchlist.entity_name })
    openSnippetsDiscovery({ watchlist })
  }

  const powerInsightMenuOptions = [
    {
      label: (
        <div className='action-item'>
          <EditIcon /> Edit
        </div>
      ),
      onClick: () => {
        if (selectedBrand) setEditedBrandData(selectedBrand)
        setIsCreateAssetModalOpen(true)
      },
      key: 'edit-item',
    },
    {
      label: (
        <div className='action-item'>
          <DeleteIcon /> Delete
        </div>
      ),
      onClick: () => {
        setIsOpenBrandDeleteModal(true)
      },
      key: 'delete-item',
    },
    {
      label: (
        <div className='action-item'>
          <BanIcon /> Deactivate
        </div>
      ),
      onClick: () => {
        deactivateBrand()
      },
      key: 'deactivate-item',
    },
  ]

  const handleSelectBrand = (brand?: AssetListObjType) => {
    fetchPowerInsightsForDashboardPage({
      name: brand?.name || '',
      conditions: brand?.conditions || {},
      mode: 'asset',
      id: brand?.id || '',
    })
    setSelectedBrand(brand)
    const { setSnippetsFilter } = store.assetsModalStore
    setSnippetsFilter(snippetsFilter)
  }

  const deactivateBrand = async () => {
    if (!selectedBrand) return
    try {
      await flagsAPI.toggleFlagStatus({ ids: [selectedBrand.id], status: 'deactivate' })
      openNotification({
        type: 'info',
        message: 'Brand deactivated',
      })
      setTriggerRefetchBrandList(true)
    } catch (error) {}
  }

  const deleteBrandHandler = () => {
    if (!selectedBrand) return
    setIsDeletingBrand(true)
    deleteAsset({ id: selectedBrand.id })
      .then((res: any | 'error') => {
        if (res !== 'error') {
          openNotification({
            type: 'info',
            message: 'Brand deleted successfully',
          })
        }
      })
      .finally(() => {
        setIsOpenBrandDeleteModal(false)
        setTriggerRefetchBrandList(true)
        setIsDeletingBrand(false)
      })
  }

  const onEditSnippetDiscoveryViewItem = (itemToView: any) => {
    setEditedBrandData(itemToView)
    setIsCreateTopicModalOpen(true)
  }

  return (
    <div className='brand-dashboard'>
      <BrandsListVertical
        selectedBrand={selectedBrand}
        showBrandList={showBrandList}
        brandLoading={brandLoading}
        setSelectedBrand={handleSelectBrand}
        handleCreateNewBrand={(brand) => {
          if (brand) setEditedBrandData(brand)
          setIsCreateAssetModalOpen(true)
        }}
        triggerRefetchBrandList={triggerRefetchBrandList}
        setTriggerRefetchBrandList={setTriggerRefetchBrandList}
      />

      {!selectedBrand && !brandLoading && (
        <DashboardEmptyState
          title='There is nothing here yet'
          description='Start by adding your first brand'
          buttonText='Add new brand'
          onClick={() => setIsCreateAssetModalOpen(true)}
          canCreate={userCanCreateAsset}
        />
      )}

      {selectedBrand && (
        <div className='brand-dashboard-stats'>
          <MediaTypeSelector />
          <div className={classNames('filters-section', { powerinsight_mood: isPowerInsightsMode })}>
            <div onClick={() => setShowBrandList(!showBrandList)} className='brand-selected'>
              <Avatar src={selectedBrand?.metadata?.brand_url || profileImage} />
              <h6 className='title'>{selectedBrand?.name}</h6>
            </div>
            <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
              <ExecutiveSummaryIcon />
              Executive Summary
              <ExpandIcon />
            </div>
            {isPowerInsightsMode && (
              <div className='power-insights-options'>
                <span>Power Insights</span>
                <Dropdown
                  menu={{ items: powerInsightMenuOptions }}
                  placement='bottomLeft'
                  disabled={!userCanCreateAsset}>
                  <ThreeDotsIcon
                    className={classNames({
                      'three-dot-icon-disabled': !userCanCreateAsset,
                    })}
                  />
                </Dropdown>
              </div>
            )}
            {isOpenBrandDeleteModal && (
              <DeleteConfirmationModal
                title='Are you sure you want to delete the selected Brand?'
                onClose={() => setIsOpenBrandDeleteModal(false)}
                onContinue={deleteBrandHandler}
                disabledSecondButton={isDeletingBrand}
              />
            )}
            <div className='date-filter'>
              <DateFilter
                days={[1, 7, 30, 90]}
                pageType={'list'}
                subStore={'assets'}
                onClick={() => handleSelectBrand(selectedBrand)}
                addCustomDateOption={isPowerInsightsMode}
              />
              {!isPowerInsightsMode && (
                <ButtonImage onClick={() => openSnippetsDiscovery({})} className='fhc_sc_button'>
                  Show all Snippets
                </ButtonImage>
              )}
              {!isPowerInsightsMode && (
                <ButtonImage
                  onClick={() => {
                    setSnippetsFilter({
                      ...snippetsFilter,
                    })
                    navigate('power-insights/metrics')
                  }}
                  className='fhc_pi_button'
                  icon={PowerInsightsIcon}>
                  Power Insights
                </ButtonImage>
              )}
              {isPowerInsightsMode && (
                <ButtonImage
                  onClick={() => {
                    resetSnippetFilters()
                    getPowerInsightDataOnLanding(selectedBrand)
                    navigate('/brand')
                  }}
                  className='fhc_sc_button bt_dashboard'
                  icon={LeftArrowIcon}>
                  Back to dashboard
                </ButtonImage>
              )}
              {isCorporateCommunicationsTheme && (
                <Radio.Group
                  value={corporateCommunicationBrandsView}
                  className='radio_group_view'
                  onChange={(e) => {
                    updateCorporateCommunicationView(e.target.value)
                    navigate(e.target.value === 'list' ? '/assets' : '/brand')
                  }}>
                  <Radio.Button value='list'>
                    <div className='radio_group_icon_wrapper'>
                      <ListIcon />
                    </div>
                  </Radio.Button>
                  <Radio.Button value='dashboard'>
                    <div className='radio_group_icon_wrapper'>
                      <DashboardIcon />
                    </div>
                  </Radio.Button>
                </Radio.Group>
              )}
            </div>
            {isOpenExecutiveSummaryModal && (
              <DailyExecutiveSummary onClose={closeExecutiveSummaryModal} subStore={'assets'} />
            )}
            {isOpenSnippetsDiscovery && (
              <SnippetsDiscovery
                onClose={closeSnippetsDiscovery}
                selectedItem={selectedBrand}
                itemToView={itemToView}
                itemToViewType={itemToViewType}
                setItemToView={setItemToView}
                onEditCurrentItemToView={onEditSnippetDiscoveryViewItem}
              />
            )}
          </div>

          {selectedBrand && !isPowerInsightsMode && (
            <div>
              <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
                <Col span={18}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row className='dashboard-widgets__card__sub-row'>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedBrand}
                            type='Mentions'
                            key='Mentions'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            tooltipText='Represents the total number of times the selected brand was mentioned across all platforms.'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedBrand}
                            type='Views'
                            key='Views'
                            measure='impression'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            tooltipText='Indicates how many times the selected brand was potentially seen by audiences.'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedBrand}
                            type='Engagement'
                            key='Engagement'
                            measure='engagement'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            tooltipText='Shows how actively audiences are interacting with content that mentions the selected brand through likes, comments, shares, and other engagement metrics, depending on the platform.'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <SuggestedIssues selectedItem={selectedBrand} item='Brand' />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <FollowedIssues
                        isOnlyView
                        onSelectFlag={onSelectFlag}
                        selectedBrand={selectedBrand}
                        handleBrowseMoreClick={() => setIsTopicsModal(true)}
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <InfluencerTable onRowClick={onSelectWatchlist} />
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[0, 24]}>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PortfolioShareChart
                        openSnippetDiscovery={(brand) => openSnippetsDiscovery({ brand })}
                        showMetricSelection
                        isPowerInsightsMode={isPowerInsightsMode}
                        additionalConditions={selectedBrand?.conditions}
                        skipBrandIntersection={true}
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <BrandSentiment
                        showMetricSelection
                        selectedBrand={selectedBrand}
                        openSnippetDiscovery={(brand) => openSnippetsDiscovery({ brand: brand as AssetListObjType })}
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <div id='trendChart'>
                        <div className='chart-heading'>
                          <span className='monitor-assets__graph__title'>Current Trend</span>
                          <span className='chart-heading__info'>
                            <DownloadOption elementToCapture='#trendChart' />
                            <InfoTooltip text='This widget helps you track the volume of your mentions and impressions over time. You can also filter this widget by sentiment, which lets you view the volume of your mentions over time broken down by whether those mentions are positive, neutral and negative.' />
                          </span>
                        </div>
                        <MonitorGraph
                          subStore='assets'
                          dataLoading={statsLineChartLoading}
                          resizeChartTrigger={showBrandList}
                          selectedItem={selectedBrand}
                        />
                      </div>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PlatformDistributionCard
                        isVerticalDisplay
                        selectedBrand={selectedBrand}
                        openSnippetDiscovery={(brand) => openSnippetsDiscovery({ brand: brand as AssetListObjType })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          {isPowerInsightsMode && (
            <Row
              gutter={24}
              justify={'start'}
              align={'top'}
              className={classNames('dashboard-widgets', { powerinsight_dashboard: isPowerInsightsMode })}>
              <Col style={{ width: '226', minWidth: '221px' }}>
                <PowerInsightTabsListVertical
                  setSelectedPowerInsightTab={setSelectedPowerInsightTab}
                  selectedPowerInsightTab={selectedPowerInsightTab}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                <Row gutter={[24, 24]} className='power-insights-and-snippets'>
                  <Col span={14} className='power-insights-container'>
                    <Row>
                      <Col span={24} className='power-insights-components'>
                        <Outlet
                          context={{
                            selectedBrand: selectedBrand,
                            subStore: 'assets',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10} className='snippet-section-column'>
                    <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                      <Row>
                        <Col span={24} className='dashboard-snippet-section'>
                          <SnippetListWithFilters
                            detailObject={detailObject as DetailsObjType}
                            activePowerInsightTab={selectedPowerInsightTab}
                            subStore='assets'
                            uniqueScrollId='brand-dashboard__id'
                          />
                        </Col>
                      </Row>
                    </Spin>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      )}
      {isCreateAssetModalOpen && (
        <AddBrandWizard
          setSelectedBrand={setSelectedBrand}
          editedBrandData={editedBrandData}
          isTopicCreation={false}
          handleCloseModal={() => {
            if (editedBrandData) setEditedBrandData(null)
            setIsCreateAssetModalOpen(false)
          }}
        />
      )}

      {isCreateTopicModalOpen && (
        <AddBrandWizard
          setSelectedBrand={setSelectedBrand}
          editedBrandData={editedBrandData}
          isTopicCreation={true}
          handleCloseModal={(updatedData: any) => {
            if (editedBrandData) setEditedBrandData(null)
            if (isOpenSnippetsDiscovery && updatedData) {
              setItemToView(updatedData)
              openSnippetsDiscovery({ flag: updatedData })
            }
            setIsCreateTopicModalOpen(false)
          }}
        />
      )}

      {isTopicsModal && (
        <TopicsListModal
          onSelectFlag={() => null}
          handleCloseModal={async (isDataChanged) => {
            setIsTopicsModal(false)
            if (isDataChanged) {
              const requestParams: FlagListParamsType = {
                page: 1,
                per_page: 5,
                sort: 'name:asc',
                is_active: true,
              }
              await fetchFlagsListWithConditions({ params: requestParams })
            }
          }}
          selectedBrand={selectedBrand as AssetListObjType}
          handleTopicCreate={() => {
            setIsCreateTopicModalOpen(true)
            setIsTopicsModal(false)
          }}
        />
      )}
      <BaseModal
        open={isCreatorModalOpen}
        isHeaderBordered
        isFooterBordered
        title='Creator Page'
        onCancel={() => {
          setIsCreatorModalOpen(false)
          setSnippetsFilter(previousInfo.filter)
          fetchSnippetsInfo(previousInfo.item)
        }}
        className='creator-page-modal'
        footer={
          <Button
            type='primary'
            onClick={() => {
              setIsCreatorModalOpen(false)
              setSnippetsFilter(previousInfo.filter)
              fetchSnippetsInfo(previousInfo.item)
            }}>
            Close
          </Button>
        }>
        <div className='creator-page-modal__content'>
          <CreatorDetailsPage
            subStore={'assets'}
            isBackButtonEnabled={false}
            parentDetailObj={null}
            previousItem={selectedBrand}
          />
          <div className='creator-page-modal__content__snippets'>
            <SnippetListWithFilters
              subStore={'assets'}
              uniqueScrollId='create-page-modal__id'
              detailObject={detailObject as DetailsObjType}
            />
            <Spin spinning={isLoadingFeed} className='creator-page-modal__snippets-loading' />
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export default observer(BrandDashboard)
