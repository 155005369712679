import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Spin, Tooltip } from 'antd'
import millify from 'millify'
import classNames from 'classnames'

import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import AreaChartSection from 'components/Monitor/AreaChartSection/AreaChartSection'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { ReportFieldParams } from 'store/report/valueTypes'
import DetailPanelFeeds from 'components/Investigate/Reports/DetailPanelFeeds/DetailPanelFeeds'
import DetailViewEmptyData from 'components/Investigate/Reports/DetailViewEmptyData/DetailViewEmptyData'
import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as FlagIcon } from 'assets/images/icons/flag/flag_icon.svg'
import { ReactComponent as AddIcon } from 'assets/images/icons/monitor/add_icon.svg'
import { ReactComponent as LowRiskIcon } from 'assets/images/icons/monitor/low_risk_icon.svg'
import { ReactComponent as HighRiskIcon } from 'assets/images/icons/monitor/high_risk_icon.svg'
import {
  ConditionsDataType,
  ConditionsPayloadParamsType,
  ConditionsPayloadType,
  NarrativeListDataType,
  RiskFlagCategoryType,
} from 'types/types'
import './VectorDetailSection.scss'
import usePermissions from 'hooks/usePermissions'

type ActionButtonEventType = 'ADD' | 'CREATE_FLAG' | 'ALERT' | 'REPORT'

type ActionButtonObjType = {
  id: string
  title: string
  elementTitle?: string
  icon: React.ElementType
  eventName: ActionButtonEventType
  disabled: boolean
}

type VectorDetailSectionProps = {
  selectedObj: NarrativeListDataType | null
  setOpenMoveToModal: (flag: boolean) => void
}

const tabsName = {
  narratives: 'Narrative',
  communities: 'Community',
  watchlists: 'Watchlist',
}

const PER_PAGE = 10

const addClassName = (riskObj: RiskFlagCategoryType) => {
  const riskValue = riskObj.flagsCount?.currentFlagCount! - riskObj.flagsCount?.prevFlagCount!
  if (riskValue === 0 && riskObj.flagsCount?.currentFlagCount === 0) {
    return ' vdsc_tc_ac_l_chunk_disabled'
  }
  if (riskValue < 0) {
    return ' vdsc_tc_ac_l_chunk_low_risk'
  }
  return ''
}

const VectorDetailSection = observer((props: VectorDetailSectionProps) => {
  const navigate = useNavigate()
  const { vectorsStore, reportMainViewStore, tenantsStore } = store
  const { selectedTab, getDate, snippetsFilter, fetchInsightsMetrics, fetchConditionsPayload, fetchRiskFlags } =
    vectorsStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { reportSectionFeeds, feedsPayload, isReportFeedsLoading, fetchReportFeedsHandler } = reportMainViewStore
  const { selectedObj, setOpenMoveToModal } = props
  const [riskFlagsList, setRiskFlagsList] = useState<RiskFlagCategoryType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const canAddToList = usePermissions('library') === 'full_access'

  const actionButtons: ActionButtonObjType[] = [
    {
      id: '0',
      title: changeLabelTextBasedOnTheme('Alerts', isCorporateCommunicationsTheme),
      icon: AlertIcon,
      eventName: 'ALERT',
      disabled: true,
      elementTitle: 'Create an Alert',
    },
    {
      id: '1',
      title: changeLabelTextBasedOnTheme('Report', isCorporateCommunicationsTheme),
      icon: ReportIcon,
      eventName: 'REPORT',
      disabled: true,
      elementTitle: 'Create an AI-Assisted Report',
    },
    { id: '2', title: 'Create flag', icon: FlagIcon, eventName: 'CREATE_FLAG', disabled: true },
  ]

  const renderConditions = useMemo(() => {
    if (selectedObj && Object.keys(selectedObj).length) {
      if (selectedTab === 'narratives' && selectedObj.mode === 'narrative') {
        return [{ narratives: [(selectedObj as NarrativeListDataType).narrative_number] }]
      } else if (selectedTab === 'communities' && selectedObj.mode === 'communities') {
        return [{ communities: [(selectedObj as NarrativeListDataType).community_number] }]
      } else if (selectedTab === 'watchlists' && selectedObj.mode === 'watchlist') {
        const uniqueChannelUrls = (selectedObj as NarrativeListDataType).channels!.reduce(
          (accumulator: string[], item) => {
            const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
            return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
          },
          [],
        )

        return [{ channel_urls: uniqueChannelUrls }]
      }
    }
    return []
  }, [selectedTab, selectedObj])

  const buildQueryString = (startDate: string, endDate: string) => {
    let query = `start_date:gte:${startDate},end_date:lte:${endDate}`

    if (snippetsFilter.filter_platform) {
      query += `,platform:in:${snippetsFilter.filter_platform}`
    }

    return query
  }

  const fetchReportFeedsMethod = () => {
    const requestParams = {
      q: `${buildQueryString(getDate.startDate, getDate.endDate)},risk_score:gte:0,risk_score:lte:100`,
    }

    const params: ReportFieldParams = {
      q: requestParams.q,
      per_page: PER_PAGE,
      page: 1,
      sort: 'upload_date:desc',
    }

    let requestPayload = JSON.parse(JSON.stringify(feedsPayload))
    if (renderConditions.length) {
      requestPayload.conditions = [...JSON.parse(JSON.stringify(requestPayload.conditions)), ...renderConditions]

      fetchReportFeedsHandler(params, requestPayload)
    }
  }

  useEffect(() => {
    if (selectedObj && Object.keys(selectedObj).length) {
      fetchReportFeedsMethod()
    }
  }, [selectedObj, renderConditions, snippetsFilter.days])

  const insightsMetricsHandler = async (object: RiskFlagCategoryType): Promise<void> => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    const requestParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
    }
    const requestParamsForPrev = {
      q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}`,
    }
    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: [object.conditions, ...renderConditions] as ConditionsPayloadType[],
      }

      const [currentFlagCount, prevFlagCount] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData }),
      ])
      object.flagsCount = { currentFlagCount, prevFlagCount }
    }
  }

  const fetchDataForObject = async (object: RiskFlagCategoryType): Promise<RiskFlagCategoryType> => {
    // Assuming you have an API endpoint to fetch data based on object.id
    const requestParams: ConditionsPayloadParamsType = {
      q: `category_id:eq:${object.category_id}`,
      is_asset: true,
    }

    const conditionObj: ConditionsPayloadType | 'error' = await fetchConditionsPayload(requestParams, true)

    if (conditionObj !== 'error') {
      if (Object.keys(conditionObj).length) {
        object.conditions = conditionObj
        await insightsMetricsHandler(object)
      } else {
        object.conditions = conditionObj
        object.flagsCount = { currentFlagCount: 0, prevFlagCount: 0 }
      }
    } else {
      object.conditions = {}
      object.flagsCount = { currentFlagCount: 0, prevFlagCount: 0 }
    }

    return object
  }

  const addNewDataToObjects = async (): Promise<RiskFlagCategoryType[] | 'error'> => {
    const riskFlagData: RiskFlagCategoryType[] | 'error' = await fetchRiskFlags({ is_asset: true })
    if (riskFlagData !== 'error') {
      const updatedObjects: RiskFlagCategoryType[] = await Promise.all(
        riskFlagData.map(async (obj) => {
          return await fetchDataForObject(obj)
        }),
      )

      return updatedObjects
    }
    return 'error'
  }

  useEffect(() => {
    if (selectedObj && Object.keys(selectedObj).length) {
      setIsLoading(true)
      addNewDataToObjects().then((res: RiskFlagCategoryType[] | 'error') => {
        if (res !== 'error') {
          const sortedRes = res.sort((a, b) => {
            if (a.flagsCount !== undefined && b.flagsCount !== undefined) {
              return b.flagsCount.currentFlagCount! - a.flagsCount.currentFlagCount!
            }
            return 0
          })
          setRiskFlagsList(sortedRes)
        }
        setIsLoading(false)
      })
    }
  }, [snippetsFilter.days, selectedObj])

  return (
    <Spin
      size='large'
      spinning={isLoading || isReportFeedsLoading}
      wrapperClassName='vdsc_spinner_wrapper'
      className='vdsc_spinner'>
      <div className='vector_detail_section_container'>
        {selectedObj ? (
          <div className='vdsc_top_container'>
            <p className={`vdsc_type_text vdsc_type_text_${selectedTab}`}>
              {tabsName[selectedTab as keyof typeof tabsName]}
            </p>
            <p className='vdsc_heading'>{selectedObj?.name}</p>
            <div className='vdsc_tc_buttons_container'>
              {!isCorporateCommunicationsTheme &&
                actionButtons.map((btn) => (
                  <Tooltip title={btn.elementTitle || ''} placement='bottom'>
                    <ButtonImage
                      key={btn.id}
                      icon={btn.icon}
                      className='vdsc_tc_bc_button'
                      onClick={() => {}}
                      aria-label={btn.elementTitle}
                      disabled={btn.disabled}>
                      {btn.title}
                    </ButtonImage>
                  </Tooltip>
                ))}
              {selectedObj?.present_in_folder ? (
                <ButtonImage
                  className={classNames('vdsc_tc_bc_button vdsc_tc_bc_button_open', {
                    single_button: isCorporateCommunicationsTheme,
                  })}
                  onClick={() => {
                    const state = {
                      name: selectedObj.name,
                      conditions: selectedObj.conditions,
                      mode: selectedObj.mode,
                      id: selectedObj?.id,
                      disabledEditButton: true,
                    }

                    const targetRoute = isCorporateCommunicationsTheme ? 'power-insights/metrics' : `details`
                    const setNavigationInfo = store.mainStore.setNavigationInfo
                    setNavigationInfo({ comingFrom: 'library', to: targetRoute, pageSize: PER_PAGE, pageNumber: 1 })
                    navigate(targetRoute, {
                      state: JSON.parse(JSON.stringify(state)),
                    })
                  }}>
                  Open {isCorporateCommunicationsTheme ? 'Topics' : selectedObj.mode}
                </ButtonImage>
              ) : (
                <ButtonImage
                  icon={AddIcon}
                  className={classNames('vdsc_tc_bc_button', { single_button: isCorporateCommunicationsTheme })}
                  onClick={() => {
                    setOpenMoveToModal(true)
                  }}
                  disabled={!canAddToList}>
                  Add to List
                </ButtonImage>
              )}
            </div>
            {/* .................. */}
            <div className='vdsc_tc_count_container'>
              <div className='vdsc_tc_cc_chunk'>
                <p className='vdsc_tc_cc_c_count'>{millify(reportSectionFeeds.total_count || 0)}</p>
                <p className='vdsc_tc_cc_c_text'>Total number of posts</p>
              </div>
              {/* <div className='vdsc_tc_cc_chunk'>
            <p className='vdsc_tc_cc_c_count'>123</p>
            <p className='vdsc_tc_cc_c_text'>Users added this item to their list</p>
          </div> */}
            </div>
            {/* ................. */}
            <div className='vdsc_tc_desc_container'>
              <p className='vdsc_tc_dc_heading'>Description</p>
              <p className='vdsc_tc_dc_text'>{selectedObj?.description} </p>
            </div>
            {/* ...................... */}
            <div className='vdsc_tc_lang_container'>
              <p className='vdsc_tc_lc_heading'>Supported languages</p>
              <div className='vdsc_tc_lc_lists'>
                {selectedObj?.multi_lang_kwd_expression &&
                  Object.keys(selectedObj?.multi_lang_kwd_expression).length &&
                  Object.keys(selectedObj?.multi_lang_kwd_expression).map((lang) => (
                    <p key={lang} className='vdsc_tc_lc_l_chunk_text'>
                      {SUPPORTED_LANGUAGES_CODE[lang]}
                    </p>
                  ))}
              </div>
            </div>
            {/* ............................ */}
            <div className='vdsc_tc_assets_container'>
              <p className='vdsc_tc_ac_heading'>{isCorporateCommunicationsTheme ? 'Brands ' : 'Assets '} mentioned</p>
              {isCorporateCommunicationsTheme && (
                <div className='vdsc_tc_ac_lists'>
                  {(isCorporateCommunicationsTheme
                    ? riskFlagsList.filter((flag) => flag.name === 'Brands')
                    : riskFlagsList
                  )?.map((flag) => {
                    const riskValue = flag.flagsCount?.currentFlagCount! - flag.flagsCount?.prevFlagCount!
                    return (
                      <div key={flag.category_id} className={`vdsc_tc_ac_l_chunk ${addClassName(flag)}`}>
                        <p className='vdsc_tc_ac_lc_title'>{flag.name}</p>
                        <div className='vdsc_tc_ac_lc_bottom'>
                          <p className='vdsc_tc_ac_lcb_text'>
                            {millify(Number(flag.flagsCount?.currentFlagCount) || 0)}{' '}
                            {riskValue ? (
                              <span className='vdsc_tc_ac_lcb_t_part'>
                                {riskValue > 0 ? '+' : ''}
                                {millify(riskValue)}{' '}
                                {riskValue > 0 ? (
                                  <HighRiskIcon width={8} height={8} className='vdsc_tc_ac_lcb_tp_icon' />
                                ) : (
                                  <LowRiskIcon width={8} height={8} className='vdsc_tc_ac_lcb_tp_icon' />
                                )}
                              </span>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            {/* ........................... */}
            <div className='vdsc_tc_stats_container'>
              <p className='vdsc_tc_sc_heading'>Statistics</p>
              <AreaChartSection className='vdsc_tc_sc_area_chart_container' data={selectedObj} subStore='vectors' />
            </div>
            {/* .................. */}
            <div className='vdsc_tc_snippets_container'>
              <p className='vdsc_tc_sc_heading'>Last 10 snippets</p>
              <div className='vdsc_tc_snippets'>
                {reportSectionFeeds.items.length ? (
                  reportSectionFeeds.items.map((feed) => (
                    <DetailPanelFeeds key={feed.document_id} author={feed?.author} {...feed} data={feed} />
                  ))
                ) : (
                  <DetailViewEmptyData
                    className='dprsc_tab_cc_feeds_empty_container'
                    title='No Data available for this text chunk'
                    desc='Try to select any other text chunk. For the moment there is no data available for this chunk.'
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Spin>
  )
})

export default VectorDetailSection
