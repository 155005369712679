import { observer } from 'mobx-react-lite'
import { useEffect, useState, useCallback } from 'react'
import { store } from 'store'
import { AssetListObjType } from 'store/asset/types'
import { CustomPieChart } from 'components/common/PieChart/PieChart'
import { DownloadOption } from '../DownloadOption'
import { SnippetFilterPayload } from 'models/models'
import EmptyState from './EmptyState'
import { ConditionsPayloadType } from 'types/types'
import { Select } from 'antd'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import './styles.scss'

const PortfolioShareChart = ({
  additionalConditions,
  openSnippetDiscovery,
  subStore = 'assets',
  showMetricSelection,
  isPowerInsightsMode,
  skipBrandIntersection = false,
}: {
  additionalConditions?: ConditionsPayloadType
  openSnippetDiscovery?: (asset: AssetListObjType) => void
  subStore?: 'assets' | 'vectors'
  showMetricSelection?: boolean
  isPowerInsightsMode?: boolean
  skipBrandIntersection?: boolean
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { assetsList, assetsLoading } = store.assetsStore
  const { graphColorPalette, setFilterChips, filterChips, fetchInsightsMetrics, getDate } = store[`${subStore}Store`]

  const { snippetsFilter, getSnippetFilterPayload } = store[`${subStore}ModalStore`]

  const [chartData, setChartData] = useState<{ name: string; total_value: number }[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeMetric, setActiveMetric] = useState<'posts' | 'impression' | 'engagement'>('posts')

  const createDataForChart = useCallback(async () => {
    setLoading(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const assetsToFetch = assetsList.items.filter((asset: AssetListObjType) => Object.keys(asset.conditions).length > 0)

    //use community,platform,sentiment and flags filter,
    const requestParams: any = {
      q: `start_date:gte:${getDate.startDate},end_date:lte:${getDate.endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    if (activeMetric !== 'posts') {
      requestParams.measure = activeMetric
    }

    try {
      const responses = await Promise.all(
        assetsToFetch.map((asset: any) => {
          const conditions = [asset.conditions]
          if (additionalConditions && !skipBrandIntersection) {
            conditions.push(additionalConditions)
          }

          return fetchInsightsMetrics({ params: requestParams, data: { conditions: conditions } })
        }),
      )
      const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
      setChartData(
        assetsToFetch.map((asset, index) => ({
          name: asset.name,
          total_value: responses[index] !== 'error' ? responses[index] : 0,
        })),
      )
      setTotalCount(totalCount)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setLoading(false)
    }
  }, [assetsList.items, getDate, activeMetric, additionalConditions])

  useEffect(() => {
    if (!assetsLoading && assetsList.total_count > 0) {
      createDataForChart()
    }
  }, [assetsLoading, assetsList, snippetsFilter, additionalConditions, activeMetric])

  const onSentimentClicked = (brand: any) => {
    if (!openSnippetDiscovery) return
    const asset = assetsList.items.find((item) => item.name === brand.name)

    if (asset) {
      if (isPowerInsightsMode)
        setFilterChips({
          ...filterChips,
          assets: {
            ...filterChips.assets,
            value: asset.name,
          },
        })
      openSnippetDiscovery(asset)
    }
  }

  if (assetsLoading || loading) {
    return <EmptyState title='Portfolio Share of Voice' />
  }

  return (
    <div id='portfolioChart'>
      <div className='chart-heading'>
        <span className='monitor-assets__graph__title'>Portfolio Share of Voice</span>
        <span className='chart-heading__info'>
          <DownloadOption elementToCapture='#portfolioChart' contentScrollable />
          {showMetricSelection && (
            <InfoTooltip text='Represents the percentage of total mentions each brand has compared to others that are currently active. This chart helps you to understand brand visibility and dominance in conversations.' />
          )}
        </span>
      </div>

      <CustomPieChart
        height={192}
        width={350}
        cx={65}
        data={chartData}
        colorPalette={graphColorPalette.Executives}
        totalCount={totalCount}
        narrativeTotal={totalCount}
        onClickCell={onSentimentClicked}
        showLabelsBasedOnSelection
        millifyStats
        customLegendWrapperClass='custom-pie-chart__scrollable'
        snippetsFilter={snippetsFilter}
      />
      {showMetricSelection && (
        <Select onChange={(value) => setActiveMetric(value)} value={activeMetric} className='selectMetric'>
          <Select.Option value='posts'>Mentions</Select.Option>
          <Select.Option value='impression'>Views</Select.Option>
          <Select.Option value='engagement'>Engagement</Select.Option>
        </Select>
      )}
    </div>
  )
}

export default observer(PortfolioShareChart)
