import { Checkbox, Modal, Radio, Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { store } from 'store'
import moment from 'moment'

import { ReactComponent as ArrowIcon } from 'assets/images/short_down_arrow.svg'

import { SuccessModal } from './SuccessModal/SuccessModal'
import { SubStore } from 'types/types'

import './ClipReports.scss'

type ReportInfo = {
  name: string
  maximum_clips: number
  include_titles: boolean
  include_summary: boolean
}

interface ClipReportsModalProps {
  subStore: SubStore
  isModalVisible: boolean
  isForModal?: boolean
  setIsModalVisible: (state: boolean) => void
}

export const ClipReports = observer((props: ClipReportsModalProps) => {
  const { subStore, isModalVisible, setIsModalVisible, isForModal = false } = props

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)
  const [showError, setShowError] = useState(false)
  const [reportInfo, setReportInfo] = useState<ReportInfo>({
    name: '',
    maximum_clips: 20,
    include_titles: false,
    include_summary: false,
  })
  const { snippetsFilter, snippetsFilterCount, snippetsTotal, createClipReport } =
    store[`${subStore}${isForModal ? 'ModalStore' : 'Store'}`]
  const { isLoadingClipReport } = store['loaderStore']

  const onDownload = async () => {
    if (reportInfo.name.trim().length === 0) {
      setShowError(true)
    } else {
      setShowError(false)
      const response = await createClipReport(reportInfo)
      if (response) {
        setIsSuccessModalVisible(true)
        setIsModalVisible(false)
        setReportInfo({
          name: '',
          maximum_clips: 20,
          include_titles: false,
          include_summary: false,
        })
        try {
          if (response?.file_path?.length > 0) {
            const link = document.createElement('a')
            link.href = response.file_path
            link.click()
          } else throw new Error('download link not working')
        } catch (error: any) {
          console.log(error.response)
        }
      }
    }
  }

  const onCancel = () => {
    setIsModalVisible(false)
    setReportInfo({
      name: '',
      maximum_clips: 20,
      include_titles: false,
      include_summary: false,
    })
    setShowError(false)
  }

  const Title = (
    <div className='clip-report-modal__header'>
      <span className='clip-report-modal__header__title'>Download clip report</span>
      <span className='clip-report-modal__header__description'>
        Download a comprehensive report of all clips that match selected filters.
      </span>
    </div>
  )

  const Footer = (
    <div className='clip-report-modal__footer'>
      <button className='clip-report-modal__footer__button clip-report-modal__footer__cancel' onClick={onCancel}>
        Cancel
      </button>
      <button className='clip-report-modal__footer__button clip-report-modal__footer__primary' onClick={onDownload}>
        Download
      </button>
    </div>
  )

  const getDateInfo = () => {
    if (snippetsFilter.days?.custom.isApplied) {
      return `from ${moment(snippetsFilter.days.custom.dateRange[0]).format('MMM DD, YYYY')} to ${moment(
        snippetsFilter.days.custom.dateRange[1],
      ).format('MMM DD, YYYY')}`
    } else {
      return `from ${moment().subtract(snippetsFilter.days.noOfDays, 'd').format('MMM DD, YYYY')} to ${moment().format(
        'MMM DD, YYYY',
      )}`
    }
  }
  return (
    <>
      <Modal
        open={isModalVisible}
        width={567}
        className='clip-report-modal'
        title={Title}
        footer={Footer}
        onCancel={onCancel}
        destroyOnClose={true}>
        <Spin spinning={isLoadingClipReport}>
          <div className='clip-report-modal__content'>
            <div className='clip-report-modal__report_title'>
              <label className='clip-report-modal__report_title__label'>Report Title</label>
              <input
                className='clip-report-modal__report_title__input'
                type='text'
                placeholder='Port Strike: Impact to Walmart'
                value={reportInfo.name}
                onChange={(e) =>
                  setReportInfo((prev) => {
                    return { ...prev, name: e.target.value }
                  })
                }
              />
              {showError && <span className='clip-report-modal__report_title__error'>Required field</span>}{' '}
            </div>
            <div className='clip-report-modal__info_container'>
              <div className='clip-report-modal__info_container__selection_container'>
                <span className='clip-report-modal__info_container__selection_container__label'>
                  You are about to download a clip report with:
                </span>
                <span className='clip-report-modal__info_container__selection_container__text'>
                  {`${snippetsTotal || 0} clips selected`}
                </span>
                <span className='clip-report-modal__info_container__selection_container__date'>{getDateInfo()}</span>
              </div>
              <div className='clip-report-modal__info_container__filters'>
                <span className='clip-report-modal__info_container__filters__text'>{`${snippetsFilterCount} filters applied`}</span>
              </div>
            </div>
            <div className='clip-report-modal__info_container_2'>
              <span className='clip-report-modal__info_container_2__description'>
                Toggle options to customize your report with preferred format, title, summary, and watchlist
                conversation details:
              </span>

              <div className='clip-report-modal__info_container_2__div'>
                <span className='clip-report-modal__info_container_2__div__label'>Clips amount</span>
                <Select
                  className='clip-report-modal__info_container_2__select'
                  popupClassName='clip-report-modal_dropdown'
                  size='large'
                  suffixIcon={<ArrowIcon height={20} width={20} />}
                  placeholder={'Choose amount'}
                  onChange={(value) => {
                    setReportInfo((prev) => {
                      return { ...prev, maximum_clips: Number(value || 20) }
                    })
                  }}>
                  <Select.Option value='20'>Top 20 clips</Select.Option>
                  <Select.Option value='30'>Top 30 clips</Select.Option>
                </Select>
              </div>
              <div className='clip-report-modal__info_container_2__div'>
                <span className='clip-report-modal__info_container_2__div__label'> Include in the report</span>
                <div className='clip-report-modal__info_container_2__div_2'>
                  <div className='clip-report-modal__info_container_2__div_3'>
                    <Checkbox
                      checked={reportInfo.include_titles}
                      onChange={(e) =>
                        setReportInfo((prev) => {
                          return { ...prev, include_titles: e.target.checked }
                        })
                      }
                    />
                    <span className='clip-report-modal__info_container_2__div_3__label'>Clip Titles</span>
                  </div>
                  <div className='clip-report-modal__info_container_2__div_3'>
                    <Checkbox
                      checked={reportInfo.include_summary}
                      onChange={(e) =>
                        setReportInfo((prev) => {
                          return { ...prev, include_summary: e.target.checked }
                        })
                      }
                    />
                    <span className='clip-report-modal__info_container_2__div_3__label'>Executive Summary</span>
                  </div>
                  <div className='clip-report-modal__info_container_2__div_3'>
                    <Checkbox />
                    <span className='clip-report-modal__info_container_2__div_3__label'>Watchlist Conversations</span>
                  </div>
                </div>
              </div>
              <div className='clip-report-modal__info_container_2__div'>
                <span className='clip-report-modal__info_container_2__div__label'>File format</span>
                <Radio.Group className='clip-report-modal__radio' disabled>
                  <Radio value='pdf' className='clip-report-modal__info_container_2__div_3__label'>
                    PDF
                  </Radio>
                  <Radio className='clip-report-modal__info_container_2__div_3__label' value='word'>
                    Word document
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <SuccessModal isModalVisible={isSuccessModalVisible} setIsModalVisible={setIsSuccessModalVisible} />
    </>
  )
})
