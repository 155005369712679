import { Button } from 'antd'
import { ReactComponent as PulseDashboardIcon } from 'assets/images/pulse.svg'

const PulseDashboardTitle = () => {
  return (
    <div className='module-title-container'>
      <Button className='module-title-container__btn'>
        <PulseDashboardIcon />
      </Button>
      <span className='module-title-container__title'>Pendulum Pulse</span>
    </div>
  )
}

export default PulseDashboardTitle
