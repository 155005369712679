import dayjs from 'dayjs'
import millify from 'millify'
import TagManager from 'react-gtm-module'
import { API, defineNarrativeAPI, monitorAPI, powerInsightsAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import {
  ICommunity,
  ICommunityDropdown,
  INarrativeDropdown,
  ISnippetParams,
  IUniqueContent,
  ISingleExecutiveData,
} from 'models/models'
import { MainStore } from 'store/main/main.store'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'
import { LANGUAGE_METRICS_DIMENSIONS } from 'settings/settings'

export class DefineNarrativeStore extends MainStore {
  formError: string = ''
  isLoadingNarrative: boolean = false
  publishNarrativeLoading: boolean = false
  modalData: 'notify' | 'workLater' | 'loseChanges' | undefined = undefined
  formData: any = {}
  narrativeDetailsData: any = {}
  booleanSearchWord: string | null = null
  twitterMode: boolean = false

  matchingResults: number = 0
  showResults: boolean = false

  narrativeFilterData: INarrativeDropdown[] = []
  communityFilterData: ICommunityDropdown[] = []

  tenant_id: string = ''

  contentLoader: boolean = false

  userSearched: boolean = false

  matchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' = 'noSearch'
  textualMatchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' | 'loading' = 'noSearch'

  keywords: string[] = []

  listFilter: {
    filter: 'Total impressions' | 'Risk score' | 'Date'
    mode: 'Ascending' | 'Descending'
  } = { filter: 'Total impressions', mode: 'Descending' }

  tableCommunities: ICommunity[] = []

  //TODO: give them a proper type
  twitterPosts: any[] = []
  newsArticles: any[] = []

  twitterMatchingResults: number = 0
  newsMatchingResults: number = 0

  selectedLanguages: string[] = []
  booleanSearchTranslation: { [x: string]: string } = {}
  selectedTranslationTab: string = 'Default'
  selectedTabLanguage: string = 'Default'

  searchedText: string = ''
  isPublishingNarrative: boolean = false
  prevValue: { name: string; description: string } = {
    name: '',
    description: '',
  }
  constructor() {
    super()
    makeObservable(this, {
      formError: observable,
      isLoadingNarrative: observable,
      publishNarrativeLoading: observable,
      modalData: observable,
      formData: observable,
      booleanSearchWord: observable,
      twitterMode: observable,
      matchingResults: observable,
      showResults: observable,
      narrativeFilterData: observable,
      communityFilterData: observable,
      tenant_id: observable,
      contentLoader: observable,
      userSearched: observable,
      matchingResultsState: observable,
      textualMatchingResultsState: observable,
      keywords: observable,
      tableCommunities: observable,
      twitterPosts: observable,
      newsArticles: observable,
      twitterMatchingResults: observable,
      newsMatchingResults: observable,
      pagination: observable,
      textualUniqueContentList: observable,
      normalUniqueContentList: observable,
      selectedLanguages: observable,
      booleanSearchTranslation: observable,
      selectedTranslationTab: observable,
      selectedTabLanguage: observable,
      isPublishingNarrative: observable,
      narrativeDetailsData: observable,
      searchedText: observable,
      setModalData: action.bound,
      setFormData: action.bound,
      setBooleanSearchWord: action.bound,
      setMatchingResults: action.bound,
      setShowResults: action.bound,
      setTenantId: action.bound,
      setUserSearched: action.bound,
      setKeywords: action.bound,
      setListFilter: action.bound,
      setTableCommunities: action.bound,
      setSelectedLanguages: action.bound,
      setBooleanSearchTranslation: action.bound,
      setSelectedTranslationTab: action.bound,
      setSelectedTabLanguage: action.bound,
      setSearchedText: action.bound,
      setIsPublishingNarrative: action.bound,
      prevValue: observable,
      setPrevValue: action.bound,
      setNarrativeDetails: action.bound,
      getData: action.bound,
      setPublishNarrativeLoading: action.bound,
    })
  }
  setIsPublishingNarrative = (value: boolean) => {
    this.isPublishingNarrative = value
  }
  pagination = {
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
  }

  textualUniqueContentList: IUniqueContent[] = []
  normalUniqueContentList: IUniqueContent[] = []

  get normalUniqueContent() {
    if (this.normalUniqueContentList.length === 0) {
      const contentList: IUniqueContent[] = []
      const postIDs: string[] = []
      this.snippets.forEach((element) => {
        if (contentList.length >= 5) return
        if (postIDs.includes(element.title)) return

        postIDs.push(element.title)
        contentList.push({
          title: element.title,
          website: element.sourcePlatform,
          id: element.id,
          imgLink: '',
          link: element.openSourceLink,
        })
      })

      this.setNormalUniqueContent(contentList)
    }
    return this.normalUniqueContentList
  }

  get getModeConditions() {
    const { booleanSearch: keywords_expression, subset: narratives, focusCommunities: communities } = this.formData

    let keywords_expression_value: string = ''

    if (this.selectedTranslationTab === this.selectedTabLanguage) {
      keywords_expression_value = keywords_expression
    } else if (this.booleanSearchTranslation[this.selectedTranslationTab]?.length > 0) {
      keywords_expression_value = this.booleanSearchTranslation[this.selectedTranslationTab]
    } else {
      keywords_expression_value = keywords_expression
    }

    return [
      {
        keywords_expression: keywords_expression_value,
      },
      { narratives: this.getNumberArrayFromID({ array: narratives, mode: 'narrative' }) },
      { communities: this.getNumberArrayFromID({ array: communities, mode: 'communities' }) },
    ]
  }

  setPagination = ({ current }: { current: number }) => {
    this.pagination = { ...this.pagination, current }
  }

  setNormalUniqueContent = (list: IUniqueContent[]) => {
    if (list.length === 0) return
    this.normalUniqueContentList = list
  }

  setPublishNarrativeLoading = (state: boolean) => {
    this.publishNarrativeLoading = state
  }

  setBooleanSearchWord = (word: string) => {
    this.booleanSearchWord = word
  }
  setPrevValue = (obj: { name: string; description: string }) => {
    this.prevValue = obj
  }
  resetUniqueContentLists = () => {
    this.textualUniqueContentList = []
    this.normalUniqueContentList = []
  }

  updateMatchingResultsState = () => {
    if (this.matchingResults === 0) {
      if (!this.userSearched) {
        this.matchingResultsState = 'noSearch'
        return
      }
      this.matchingResultsState = 'noResultsBack'
      return
    }
    if (this.matchingResults > 6000000) {
      this.matchingResultsState = 'tooMany'
      return
    }
    this.matchingResultsState = 'normal'
  }

  updateUserSearched = () => {
    this.userSearched = true
  }

  setTableLoader = (state: boolean) => {
    this.contentLoader = state
  }

  closeModal = () => {
    this.modalData = undefined
  }

  setSelectedLanguages = (languages: string[]) => {
    this.selectedLanguages = languages
  }

  setBooleanSearchTranslation = (translation: { [x: string]: string }) => {
    this.booleanSearchTranslation = translation
  }

  setSelectedTranslationTab = (language: string) => {
    this.selectedTranslationTab = language
  }
  setSelectedTabLanguage = (language: string) => {
    this.selectedTabLanguage = language
  }

  fetchDropdowns = async () => {
    try {
      const [narrativesDropdownData, communitiesDropdownData] = await Promise.all([
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'narrative',
        }),
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'community',
        }),
      ])
      this.setCreatorsFilter(communitiesDropdownData.data.items)
      this.setNarrativesFilter(narrativesDropdownData.data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getNumberArrayFromID = ({ array, mode }: { array: string[]; mode: 'narrative' | 'communities' }) => {
    if (!array || array?.length === 0) return []

    switch (mode) {
      case 'narrative':
        return this.narrativeFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.narrative_number
          })
      case 'communities':
        return this.communityFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.community_number
          })
    }
  }

  setCreatorsFilter = (data: ICommunityDropdown[]) => {
    this.communityFilterData = data
  }

  setNarrativesFilter = (data: INarrativeDropdown[]) => {
    this.narrativeFilterData = data
  }

  setFormData = (formData: any) => {
    this.formData = formData
  }

  setMatchingResults = (matchingResults: number) => {
    this.matchingResults = matchingResults
    this.updateMatchingResultsState()
  }

  setShowResults = (state: boolean) => {
    this.showResults = state
  }

  setSearchedText = (text: string) => {
    this.searchedText = text
  }

  addFormData = (newFormData: any) => {
    this.formData = { ...this.formData, ...newFormData }
  }

  setNarrativeDetails = (data: any) => {
    this.narrativeDetailsData = data
  }

  fetchSnippets = async ({ appendMode }: ISnippetParams = {}, isBack: boolean = false) => {
    try {
      if (this.snippets.length >= this.matchingResults && this.snippets.length > 0) return

      this.setTableLoader(true)

      const { booleanSearch: keywords_expression } = this.formData
      if (!isBack) this.setBooleanSearchWord(keywords_expression)

      const page = Math.floor(this.snippets.length / 50) + 1
      const payload = await this.getSnippetFilterPayload()
      payload.page = page
      payload.per_page = 50

      const { data: res } = await monitorAPI.loadFeed(payload)

      this.setIsFeedEmpty(false)
      this.setSnippetsTotal(res.total_count)
      this.setMatchingResults(res.total_count || 0)
      this.setShowResults(true)

      let creators: string[] = []
      const processedSnippets = res.items.map((snippet: any, index: number) => {
        if (snippet?.channel_id) creators.push(snippet?.channel_id)

        let text = snippet.snippet_text
        if (this.snippetsFilter.days && snippet?.translated_snippet?.translated_text)
          text = snippet?.translated_snippet?.translated_text

        return {
          /* HACK: added the index so all the ids are unique */
          id: snippet?.post_id + index,
          snippet_text: text,
          title: snippet?.post_title,
          date: snippet?.upload_date,
          riskScore: (snippet?.risk_score || 0).toFixed(0),
          views: snippet?.impression_count,
          viewsMilify: millify(snippet?.impression_count || 0) || 0,
          playSourceLink: snippet?.media_url,
          openSourceLink: snippet?.post_url,
          sourcePlatform: snippet?.platform,
          channelId: snippet?.channel_id,
          author: {},
          duration: snippet?.duration,
          offset: snippet?.offset,
          type: 'define',
          sentiment: snippet.sentiment,
          translatedSnippet: snippet.translated_snippet,
        }
      })

      if (!appendMode) {
        this.resetUniqueContentLists()
        this.setSnippets(processedSnippets)
      } else this.appendSnippets(processedSnippets)

      this.setPagination({ current: Math.floor(this.snippets.length / this.pagination.pageSize || 1) })

      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      if (creators) await this.getCreators(creators)

      this.updateUserSearched()
      this.setTableLoader(false)
    } catch (e: any) {
      this.setUserSearched(false)
      this.setTableLoader(false)
      LogService.error(e)
      LogService.error({ message: 'Error in defineNarrative store', error: e })
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  publishNarrative = async () => {
    try {
      this.setPublishNarrativeLoading(true)
      this.setIsPublishingNarrative(true)
      const {
        name,
        description,
        focusCommunities: communities,
        booleanQuery: keywords_expression,
        subset: parent_narrative_ids,
        linkContent: related_contents,
        twitterSwitch,
        enableRetweet,
        verifiedOnly,
        profileCountry,
        twitterExpansion,
      } = this.formData

      let payload: any = {
        name,
        type: twitterSwitch ? 'textual' : 'keyword',
        description,
        communities,
        keywords_expression,
        parent_narrative_ids,
        related_contents: related_contents,
        tenant_id: this.tenant_id,
        enable_retweet: enableRetweet,
        verified_only: verifiedOnly,
        profile_country: profileCountry,
        twitter_expansion: twitterExpansion,
      }
      if (Object.keys(this.booleanSearchTranslation)?.length) {
        const languageTranslation = this.booleanSearchTranslation
        delete languageTranslation['Default']
        payload['multi_lang_kwd_expression'] = languageTranslation
      }

      const resp = await defineNarrativeAPI.publishNarrative(payload)

      // google analytics publish narrative
      TagManager.dataLayer({
        dataLayer: {
          event: 'publish_narrative',
          entityName: name,
          user_id: resp.owner_id,
          entityDescription: description,
          booleanQuery: keywords_expression,
          narrativeFilter: parent_narrative_ids,
          narrativeType: payload.type,
          relatedContents: related_contents,
          tenantId: payload.tenant_id,
          enableRetweet: enableRetweet,
          verifiedOnly: verifiedOnly,
          profileCountry: profileCountry,
        },
      })

      if (resp.id) {
        this.setModalData('notify')
      }
      this.setIsPublishingNarrative(false)
      return resp
    } catch (e: any) {
      this.setIsPublishingNarrative(false)
      if (Object.keys(this.booleanSearchTranslation)?.length) {
        this.booleanSearchTranslation['Default'] = this.formData['booleanQuery']
      }
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setPublishNarrativeLoading(false)
    }
  }

  republishNarrative = async (narrativeID: string) => {
    try {
      this.setPublishNarrativeLoading(true)
      const {
        name,
        description,
        focusCommunities: communities,
        subset: parent_narrative_ids,
        linkContent: related_contents,
        twitterSwitch,
        booleanQuery,
        enableRetweet,
        verifiedOnly,
        profileCountry,
        twitterExpansion,
      } = this.formData

      let payload: any = {
        name,
        type: twitterSwitch ? 'textual' : 'keyword',
        description,
        communities,
        keywords_expression: booleanQuery,
        parent_narrative_ids,
        related_contents: related_contents,
        tenant_id: this.tenant_id,
        enable_retweet: enableRetweet,
        verified_only: verifiedOnly,
        profile_country: profileCountry,
        twitter_expansion: twitterExpansion,
      }
      if (Object.keys(this.booleanSearchTranslation)?.length) {
        const languageTranslation = this.booleanSearchTranslation
        delete languageTranslation['Default']
        payload['multi_lang_kwd_expression'] = languageTranslation
      }

      const resp = await defineNarrativeAPI.publishNarrative(payload, narrativeID)

      if (resp.id) {
        this.setModalData('notify')
      }
      return resp
    } catch (error: any) {
      if (Object.keys(this.booleanSearchTranslation)?.length) {
        this.booleanSearchTranslation['Default'] = this.formData['booleanQuery']
      }
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setPublishNarrativeLoading(false)
    }
  }

  setModalData = (data: 'notify' | 'workLater' | 'loseChanges') => {
    this.modalData = data
  }

  saveNarrative = async () => {
    try {
      this.setPublishNarrativeLoading(true)
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanQuery: keywords_expression,
        twitterSwitch,
      } = this.formData

      const resp = await defineNarrativeAPI.saveNarrative({
        name,
        description,
        related_contents: related_contents ? [related_contents] : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: twitterSwitch ? 'textual' : 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setPublishNarrativeLoading(false)
    }
  }

  getTimeSpan = ({ rangeInDays = 1 }: { rangeInDays?: number } = { rangeInDays: 1 }) => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const pastDate = dayjs().subtract(rangeInDays, 'days').format('YYYY-MM-DD')

    return {
      startDate: pastDate,
      endDate: currentDate,
    }
  }

  fetchCommunities = async () => {
    try {
      const { data } = await API.get({
        route: 'community',
        page: 1,
        pageSize: 50,
        getError: true,
      })
      const ids = data.items.map((el: any) => el.community_number)
      this.getCommunities(ids)
      this.setTableCommunities(data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setTableCommunities = (communities: ICommunity[]) => {
    this.tableCommunities = communities
  }

  setKeywords = (keywords: string[]) => {
    this.keywords = keywords
  }

  setTenantId = (id: string) => {
    this.tenant_id = id
  }

  getData = async (id: string) => {
    try {
      const { data: narrativeData } = await API.get({ route: 'narrative', id })

      const {
        name,
        description,
        parent_narratives: subset,
        communities: focusCommunities,
        boolean_query: booleanSearch,
        multi_lang_kwd_expression,
      } = narrativeData
      this.setNarrativeDetails(narrativeData)
      this.setPrevValue({ name: name || '', description: description || '' })
      this.addFormData({
        name,
        description: description || '',
        booleanQuery: booleanSearch,
        booleanSearch,
        subset,
        focusCommunities,
      })
      if (multi_lang_kwd_expression) {
        this.addFormData({ ...this.formData, languages: Object.keys(multi_lang_kwd_expression) })

        this.setSelectedLanguages(Object.keys(multi_lang_kwd_expression))
        this.setBooleanSearchTranslation({ Default: booleanSearch, ...multi_lang_kwd_expression })
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editNarrative = async (narrativeID?: string) => {
    if (!narrativeID) return
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanQuery: keywords_expression,
      } = this.formData

      const resp = await defineNarrativeAPI.editNarrative({
        id: narrativeID,
        name,
        description,
        related_contents: related_contents ? related_contents.join(',') : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setListFilter = (filter: any) => {
    this.listFilter = filter
    this.setPagination({ current: 1 })
    this.setSnippets([])
    this.fetchSnippets({})
  }

  setUserSearched = (state: boolean) => {
    this.userSearched = state
  }

  fetchBooleanSearchTranslation = async (language?: string) => {
    const { booleanSearch: keywords_expression, languages } = this.formData
    let filterLanguage: string[] = JSON.parse(JSON.stringify(languages))

    if (language && Object.keys(this.booleanSearchTranslation).includes(language)) {
      this.setBooleanSearchTranslation({
        ...this.booleanSearchTranslation,
        [language]: keywords_expression,
      })
      return { [language]: keywords_expression }
    } else if (Object.keys(this.booleanSearchTranslation).length && languages.length && this.selectedLanguages.length) {
      filterLanguage = languages.filter((ele: string) => !Object.keys(this.booleanSearchTranslation).includes(ele))
    }

    let booleanTranslation = this.booleanSearchTranslation
    const filteredData = Object.keys(booleanTranslation)
      ?.filter((item) => languages?.includes(item))
      ?.reduce((acc: { [x: string]: string }, key) => {
        acc[key] = booleanTranslation[key]
        return acc
      }, {})

    if (filterLanguage.length > 0) {
      const response = await defineNarrativeAPI.getBooleanSearchTextTranslation({
        boolean_query: filteredData['Default'] || keywords_expression,
        languages: filterLanguage,
      })

      this.setBooleanSearchTranslation({
        ...filteredData,
        Default: language === 'Default' ? keywords_expression : filteredData['Default'] || keywords_expression,
        ...response,
      })
      return response
    } else if (Object.keys(booleanTranslation).length !== Object.keys(filteredData).length) {
      this.setBooleanSearchTranslation({
        ...filteredData,
        Default: language === 'Default' ? keywords_expression : filteredData['Default'] || keywords_expression,
      })
    }
  }

  fetchPowerInsights = async () => {
    const payload = await this.getSnippetFilterPayload()

    const languageMetricsPromises: Promise<any>[] = []
    LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
      languageMetricsPromises.push(
        API.getMetricsUsingDimension({
          ...payload,
          dimension,
        }),
      )
    })
    const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)
    const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}
    languageMetricsResolved.forEach((metric, index) => {
      if (metric.status === 'rejected') return

      const metricData = metric as PromiseFulfilledResult<any>

      let metricName = LANGUAGE_METRICS_DIMENSIONS[index]
      languageMetricsProcessed[metricName] = metricData.value
    })

    const [
      { data: postsLineChart },
      { data: postSentimentsLineChart },
      { data: postPlatformsLineChart },
      { data: impressionsLineChart },
      { data: impressionSentimentsLineChart },
      { data: impressionPlatformsLineChart },
      { data: engagementLineChart },
      { data: engagementSentimentsLineChart },
      { data: engagementPlatformsLineChart },
      { data: dataHeatMap },
      { items: beeSwarmData },
      peopleData,
      companiesData,
      countriesMapData,
      summaryStatisticsData,
      languagesData,
    ] = await Promise.all([
      powerInsightsAPI.lineChart.getLineChartData(payload),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        dimension: 'sentiment',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        dimension: 'platform',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
        dimension: 'sentiment',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
        dimension: 'platform',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'engagement',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'engagement',
        dimension: 'sentiment',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'engagement',
        dimension: 'platform',
      }),
      // Get the heat map communities data for last 24 hours only
      powerInsightsAPI.treeMap.getTreeMapData(payload),
      // Get the Bee Swarm chart data
      powerInsightsAPI.beeSwarm.getBeeSwarmData(payload),
      this.fetchPeopleData(payload),
      this.fetchCompaniesData(payload),
      this.fetchCountriesData(payload),
      this.fetchSummaryData(payload),
      this.fetchLanguagesData(payload),
    ])

    this.beeSwarmVideoNodes = this.getFormattedBeeSwarmVideoNodes(beeSwarmData)

    this.setPowerInsightsData({
      ...this.powerInsightsData,
      summary: {
        executiveSummary: summaryStatisticsData.executiveSummary,
        statistics: [
          {
            label: 'Snippets',
            value: millify(summaryStatisticsData.statistics?.total_snippets),
          },
          {
            label: 'Unique Creators',
            value: millify(summaryStatisticsData.statistics?.creator_count),
          },
          {
            label: 'Companies',
            value: millify(summaryStatisticsData.statistics?.company_count),
          },
          {
            label: 'People',
            value: millify(summaryStatisticsData.statistics?.people_count),
          },
          {
            label: 'Organizations',
            value: millify(summaryStatisticsData.statistics?.country_count),
          },
          {
            label: 'Views',
            value: millify(summaryStatisticsData.statistics?.total_views),
          },
        ],
        platformsDistribution: summaryStatisticsData.platformsDistribution,
      },
      lineChartData: {
        posts: {
          all: { data_points: postsLineChart.data_points },
          sentiment: postSentimentsLineChart,
          platform: postPlatformsLineChart,
        },
        impressions: {
          all: { data_points: impressionsLineChart.data_points },
          sentiment: impressionSentimentsLineChart,
          platform: impressionPlatformsLineChart,
        },
        engagement: {
          all: { data_points: engagementLineChart.data_points },
          sentiment: engagementSentimentsLineChart,
          platform: engagementPlatformsLineChart,
        },
      },
      heatMapData: {
        data_points: dataHeatMap.data_points,
      },
      beeSwarmNodesData: this.getFormattedBeeSwarmVideoNodes(beeSwarmData),
      languageRisks: languageMetricsProcessed,
      peopleData: {
        completeData: peopleData.data_points,
        data: peopleData.total_count > 8 ? peopleData.data_points.slice(0, 8) : peopleData.data_points,
      },
      companyData: {
        completeData: companiesData.data_points,
        data: companiesData.total_count > 8 ? companiesData.data_points.slice(0, 8) : companiesData.data_points,
      },
      countriesMapData: countriesMapData.data_points,
      languagesData: { data: languagesData.data_points },
    })
  }

  resetStore = () => {
    this.isFeedEmpty = true
    this.showResults = false
    this.twitterMode = false
    this.formError = ''
    this.isLoadingNarrative = false
    this.modalData = undefined
    this.formData = {}
    this.narrativeFilterData = []
    this.communityFilterData = []
    this.userSearched = false
    this.listFilter = { filter: 'Total impressions', mode: 'Descending' }
    this.matchingResultsState = 'noSearch'
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.pagination = {
      current: 1,
      pageSize: 50,
      showSizeChanger: false,
    }
    this.snippets = []
    this.twitterPosts = []
    this.newsArticles = []
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.booleanSearchWord = ''
    this.selectedLanguages = []
    this.booleanSearchTranslation = {}
  }
}
