import { Tooltip } from 'antd'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { SendFeedbackModal } from '../common/Feedback/SendFeedbackModal'

import LogoText from 'assets/images/pendulum.png'
import LogoMob from 'assets/images/LogoiconMobile.svg'
import FullLogoSonar from 'assets/images/sonar-new-logo.svg'
import ShortLogoSonar from 'assets/images/sonar-new-icon.svg'
import { ReactComponent as LogoutIcon } from 'assets/images/logout_icon.svg'
import { ReactComponent as MonitorIcon } from 'assets/images/monitor_icon.svg'
import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg'
import { ReactComponent as FeedbackIcon } from 'assets/images/icons/feedback.svg'
import { ReactComponent as FlagIcon } from 'assets/images/icons/flag/flag_icon.svg'
import { ReactComponent as LibraryIcon } from 'assets/images/icons/library_icon.svg'
import { ReactComponent as OpenNavIcon } from 'assets/images/open_side_nav_icon.svg'
import { ReactComponent as RiskwandIcon } from 'assets/images/icons/riskwand_icon.svg'
import { ReactComponent as MyStuffIcon } from 'assets/images/icons/my_struff_icon.svg'
import { ReactComponent as InfluencersIcon } from 'assets/images/influencers-icon.svg'
import { ReactComponent as AssetIcon } from 'assets/images/icons/outline/asset_icon.svg'
import { ReactComponent as VectorIcon } from 'assets/images/icons/outline/vector_icon.svg'

import { store } from 'store'

import usePermissions from 'hooks/usePermissions'

import './LeftNavigation.scss'

export const LeftNavigation = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const { feedbackStore, userStore, tenantsStore, navViewStore } = store
  const { submitFeedback } = feedbackStore
  const { setUser } = userStore
  const {
    fetchCustomTheme,
    customTheme,
    customThemeLoading,
    changeLabelTextBasedOnTheme,
    isCorporateCommunicationsTheme,
    fetchAdditionalPlatforms,
  } = tenantsStore
  const { isNavOpen, setIsNavOpen } = navViewStore

  const [isSendFeedbackModal, setIsSendFeedbackModal] = useState(false)
  const wrapperRef = useRef<any>()

  const canViewRiskWand = usePermissions('riskWand') === 'no_access'

  useEffect(() => {
    fetchCustomTheme()
    fetchAdditionalPlatforms()
  }, [])

  useEffect(() => {
    function handleClickOutsideTooltip(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event?.target)) {
        setIsNavOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideTooltip)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTooltip)
    }
  })

  async function signOut() {
    await Auth.signOut()

    setUser({
      given_name: null,
      family_name: null,
      userName: null,
      userEmail: null,
      tenantId: null,
      userId: null,
      roleId: null,
      subId: null,
    })
    navigate('/', { replace: true })
    const setSelectedBrand = store.assetsStore.setSelectedBrand
    const setSelectedSearchItem = store.vectorsStore.setSelectedSearchItem
    sessionStorage.removeItem('snippetDateFilter')
    localStorage.removeItem('user-tos-status')
    setSelectedBrand(undefined)
    setSelectedSearchItem(null)
    localStorage.removeItem('user-tos-assigned-admins')
  }

  const isPageActive = (currentPage: string) => {
    return location.pathname.startsWith(currentPage)
  }

  const handleNavigationClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      event.target instanceof HTMLAnchorElement ||
      event.target instanceof HTMLSpanElement ||
      event.target instanceof HTMLButtonElement
    ) {
      setIsNavOpen(false)
    }
  }

  useEffect(() => {
    const pageTitles: { [key: string]: string } = {
      brand: 'Brands',
      searches: 'Topics',
      assets: 'Assets',
      flags: 'Flags',
      vectors: isCorporateCommunicationsTheme ? 'Topics' : 'Vectors',
      influencers: 'Influencers',
      library: 'Library',
      reports: 'Reports',
      'anticipatory-intelligence': 'RiskWand',
      investigate: 'My Stuff',
      settings: 'Settings',
    }

    const currentPath = Object.keys(pageTitles).find((key) => location.pathname.includes(key))
    if (currentPath) {
      document.title = pageTitles[currentPath]
    }
  }, [location.pathname])

  const toggleIconTitle = !isNavOpen ? 'Expand' : 'Collapse'

  return (
    <>
      <SendFeedbackModal
        isModalVisible={isSendFeedbackModal}
        setIsModalVisible={setIsSendFeedbackModal}
        submitFeedback={submitFeedback}
      />

      <nav
        className={classNames('c-left-navigation', { 'collapsed-nav': !isNavOpen })}
        onClick={handleNavigationClick}
        ref={wrapperRef}>
        <div className={`c-left-navigation__logo-container`}>
          <Link
            to={isCorporateCommunicationsTheme ? '/brand' : '/assets'}
            className={`c-left-navigation__logo-box${isNavOpen ? ' c-left-navigation__logo-full-box' : ''}`}>
            {!customThemeLoading ? (
              <>
                {customTheme === 'SONAR' ? (
                  isNavOpen ? (
                    <img
                      className='c-left-navigation__logo-container--logoSonar c-left-navigation__logo-container--logo'
                      src={FullLogoSonar}
                      alt='Push Button'
                    />
                  ) : (
                    <img
                      className='c-left-navigation__logo-container--logoSonar c-left-navigation__logo-container--logo'
                      src={ShortLogoSonar}
                      alt='Push Button'
                    />
                  )
                ) : (
                  <>
                    {!isNavOpen ? (
                      <img
                        className='c-left-navigation__logo-container--logoDisc c-left-navigation__logo-container--logo'
                        src={LogoMob}
                        alt='Push Button'
                      />
                    ) : (
                      <img
                        className='c-left-navigation__logo-container--logoText c-left-navigation__logo-container--logo'
                        src={LogoText}
                        alt='Push Button'
                      />
                    )}
                  </>
                )}
              </>
            ) : null}
          </Link>
          <div className='c-left-navigation__logo-push-btn' onClick={() => setIsNavOpen(!isNavOpen)}>
            <Tooltip
              title={toggleIconTitle}
              overlayClassName={`c-left-navigation__tooltip ${toggleIconTitle}`}
              placement='right'>
              <OpenNavIcon aria-label={toggleIconTitle} />
            </Tooltip>
          </div>
        </div>
        <ul className='c-left-navigation__links-container'>
          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip
              title={isNavOpen ? '' : changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme)}
              overlayClassName='c-left-navigation__tooltip'
              placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive(isCorporateCommunicationsTheme ? '/brand' : '/assets')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to={isCorporateCommunicationsTheme ? '/brand' : '/assets'}>
                <AssetIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>{changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme)}</span>
              </NavLink>
            </Tooltip>
          </li>
          {!isCorporateCommunicationsTheme && (
            <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
              <Tooltip
                title={isNavOpen ? '' : changeLabelTextBasedOnTheme('Flags', isCorporateCommunicationsTheme)}
                overlayClassName='c-left-navigation__tooltip'
                placement='left'>
                <NavLink
                  className={() => {
                    const isActive = isPageActive('/flags')
                    return (
                      'c-left-navigation__links-container__link-item__link' +
                      (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                    )
                  }}
                  to='/flags'>
                  <FlagIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                  <span>{changeLabelTextBasedOnTheme('Flags', isCorporateCommunicationsTheme)}</span>
                </NavLink>
              </Tooltip>
            </li>
          )}

          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip
              title={isNavOpen ? '' : changeLabelTextBasedOnTheme('Vectors', isCorporateCommunicationsTheme)}
              overlayClassName='c-left-navigation__tooltip'
              placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive(isCorporateCommunicationsTheme ? '/topics' : '/vectors')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to={isCorporateCommunicationsTheme ? '/topics' : '/vectors'}>
                <VectorIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>{changeLabelTextBasedOnTheme('Vectors', isCorporateCommunicationsTheme)}</span>
              </NavLink>
            </Tooltip>
          </li>
          {isCorporateCommunicationsTheme && (
            <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
              <Tooltip
                title={isNavOpen ? '' : 'Influencers'}
                overlayClassName='c-left-navigation__tooltip'
                placement='left'>
                <NavLink
                  className={() => {
                    const isActive = isPageActive('/influencers')
                    return (
                      'c-left-navigation__links-container__link-item__link' +
                      (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                    )
                  }}
                  to='/influencers'>
                  <InfluencersIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                  <span>Influencers</span>
                </NavLink>
              </Tooltip>
            </li>
          )}
          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip title={isNavOpen ? '' : 'Library'} overlayClassName='c-left-navigation__tooltip' placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive('/library')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to='/library'>
                <LibraryIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>Library</span>
              </NavLink>
            </Tooltip>
          </li>

          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip
              title={isNavOpen ? '' : changeLabelTextBasedOnTheme('Monitor', isCorporateCommunicationsTheme)}
              overlayClassName='c-left-navigation__tooltip'
              placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive('/reports')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to={'/reports'}>
                <MonitorIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>{changeLabelTextBasedOnTheme('Monitor', isCorporateCommunicationsTheme)}</span>
              </NavLink>
            </Tooltip>
          </li>

          {!canViewRiskWand && (
            <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
              <Tooltip
                title={isNavOpen ? '' : changeLabelTextBasedOnTheme('RiskWand', isCorporateCommunicationsTheme)}
                overlayClassName='c-left-navigation__tooltip'
                placement='left'>
                <NavLink
                  className={() => {
                    const isActive = isPageActive('/anticipatory-intelligence')
                    return (
                      'c-left-navigation__links-container__link-item__link' +
                      (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                    )
                  }}
                  to='/anticipatory-intelligence/welcome'>
                  <RiskwandIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                  <span>{changeLabelTextBasedOnTheme('RiskWand', isCorporateCommunicationsTheme)}</span>
                </NavLink>
              </Tooltip>
            </li>
          )}
          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip title={isNavOpen ? '' : 'My Stuff'} overlayClassName='c-left-navigation__tooltip' placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive('/investigate')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to='/investigate'>
                <MyStuffIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>My Stuff</span>
              </NavLink>
            </Tooltip>
          </li>

          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__fill-white c-left-navigation-bottom-section'>
            <Tooltip
              title={isNavOpen ? '' : 'Send Feedback'}
              overlayClassName='c-left-navigation__tooltip'
              placement='left'>
              <button
                onClick={() => setIsSendFeedbackModal(true)}
                className='c-left-navigation__links-container__link-item__link c-left-navigation__links-container__signout'
                type='button'>
                <FeedbackIcon className='c-left-navigation__links-container__link-item__fill-white' />
                <span>Send Feedback</span>
              </button>
            </Tooltip>
          </li>

          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__stroke-white'>
            <Tooltip title={isNavOpen ? '' : 'Settings'} overlayClassName='c-left-navigation__tooltip' placement='left'>
              <NavLink
                className={() => {
                  const isActive = isPageActive('/settings')
                  return (
                    'c-left-navigation__links-container__link-item__link' +
                    (isActive ? ' c-left-navigation__links-container__link-item__link--link-active' : '')
                  )
                }}
                to='/settings/profile'>
                <SettingsIcon className='c-left-navigation__links-container__link-item__stroke-white' />
                <span>Settings</span>
              </NavLink>
            </Tooltip>
          </li>

          <li className='c-left-navigation__links-container__link-item c-left-navigation__links-container__link-item__fill-white'>
            <Tooltip title={isNavOpen ? '' : 'Log out'} overlayClassName='c-left-navigation__tooltip' placement='left'>
              <button
                className='c-left-navigation__links-container__link-item__link c-left-navigation__links-container__signout'
                type='button'
                onClick={signOut}>
                <LogoutIcon className='c-left-navigation__links-container__link-item__fill-white' />
                <span>Log out</span>
              </button>
            </Tooltip>
          </li>
        </ul>
      </nav>
    </>
  )
})
