import { Spin } from 'antd'
import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import VirtualList, { ListRef } from 'rc-virtual-list'

import { CategoriesTypes, OptionTypes } from './PulseLeftComponent'
import Checkbox from 'components/common/Checkbox/Checkbox'

type PulseDDListOptionsPropsTypes = {
  options: any
  loading: boolean
  onScroll?: () => void
  category: CategoriesTypes
  handleModalSelectedContentItems: ({ value, name, category }: OptionTypes) => void
  modalSelectedContentItems: {
    topics: OptionTypes[]
    searches: OptionTypes[]
    brand: OptionTypes[]
    flags: OptionTypes[]
    influencers: OptionTypes[]
    bookmarks: OptionTypes[]
  }
}

const PulseDDListOptions: React.FC<PulseDDListOptionsPropsTypes> = observer(
  ({ options, loading = false, onScroll, category, modalSelectedContentItems, handleModalSelectedContentItems }) => {
    const listRef = useRef<ListRef>(null)

    const ContainerHeight = 350
    const ItemHeight = 44

    return (
      <Spin spinning={loading}>
        <VirtualList
          ref={listRef}
          className='custom-select-options-container'
          data={options}
          itemHeight={ItemHeight}
          height={ContainerHeight}
          itemKey='id'
          onScroll={(e) => {
            if (Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === ContainerHeight) onScroll?.()
          }}>
          {(item) => {
            return (
              <div
                key={item.value}
                className='custom-option'
                onClick={() =>
                  handleModalSelectedContentItems({ value: item.value, name: item.label, category, ...item })
                }>
                <Checkbox
                  checked={modalSelectedContentItems[category]?.some((selected) => selected.value === item.value)}
                  onClick={() => null}
                  isPropagation={false}
                />
                <span>{item.label}</span>
              </div>
            )
          }}
        </VirtualList>
      </Spin>
    )
  },
)

export default PulseDDListOptions
