import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState, ReactNode } from 'react'
import { SearchOutlined, CloseOutlined, MinusOutlined, CheckOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Drawer, Dropdown, Form, Input, MenuProps, Radio, Select, SelectProps, Tag, Tooltip } from 'antd'

import Platform from 'components/Vectors/Platform'
import BaseModal from 'components/BaseModal/BaseModal'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import RangeSlider from 'components/Investigate/Reports/RangeSlider/RangeSlider'

import { ReactComponent as SortIcon } from 'assets/images/sort-icon.svg'
import { ReactComponent as PluseIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import { ReactComponent as FilterIcon } from 'assets/images/filtericon.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-icon.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/icons/flag/loader_icon.svg'
import { ReactComponent as BookmarkBulkFeedIcon } from 'assets/images/bookmark-icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/icons/report/download-icon.svg'
import { ReactComponent as ExportBulkFeedIcon } from 'assets/images/export-bulk-feed-icon.svg'
import { ReactComponent as PositiveSentimentIcon } from 'assets/images/positive-sentiment-icon.svg'
import { ReactComponent as NeutralIconSentimentIcon } from 'assets/images/neutral-sentiment-icon.svg'
import { ReactComponent as NegativeIconSentimentIcon } from 'assets/images/negative-sentiments-icon.svg'

import { PLATFORM_ICONS_GREY, SUPPORTED_LANGUAGES } from 'settings/settings'

import { store } from 'store'

import classNames from 'classnames'
import millify from 'millify'
import { FeedExport } from 'components/Monitor/PowerInsights/FeedExport/FeedExport'
import { ClipReports } from 'components/Monitor/PowerInsights/ClipReports/ClipReports'
import { AddSnippetToBookmarksListModal } from 'components/Investigate/Bookmarks/AddSnippetToBookmarksListModal/AddSnippetToBookmarksListModal'
import { DetailsObjType, SubStore } from 'types/types'
import { isArray } from 'lodash'
import { FilterChips } from 'components/Monitor/FilterChips/FilterChips'
import './SnippetListWithFilters.scss'
import { Unknown } from 'utils/commonTypes'

const SENTIMENT_ICONS = [
  { title: 'Positive', icon: <PositiveSentimentIcon /> },
  { title: 'Neutral', icon: <NeutralIconSentimentIcon height={20} /> },
  { title: 'Negative', icon: <NegativeIconSentimentIcon /> },
]
const CONTENT_SOURCES = [
  { key: 'image_caption', value: 'Caption' },
  { key: 'ocr', value: 'OCR' },
  { key: 'description', value: 'Description' },
  { key: 'transcript', value: 'Transcript' },
  { key: 'title', value: 'Title' },
]

type TagRender = SelectProps['tagRender']

const LanguageLabel = ({ text = '', selected = false }) => (
  <div className={classNames('language-label')}>
    <span>{text}</span>
    {selected && <CheckOutlined />}
  </div>
)

const SnippetListWithFilters = ({
  subStore = 'assets',
  detailObject,
  uniqueScrollId,
  isCustomEmptyState,
  emptyStateTitle,
  emptyStateDescription,
  emptyStateIcon,
  activePowerInsightTab,
  containFiltersInComponent = false,
}: {
  subStore?: SubStore
  uniqueScrollId: string
  isCustomEmptyState?: boolean
  emptyStateTitle?: string
  emptyStateDescription?: string
  activePowerInsightTab?: string
  containFiltersInComponent?: boolean
  detailObject?: DetailsObjType | null
  emptyStateIcon?: JSX.Element | ReactNode
}) => {
  const {
    snippets,
    snippetsFilter,
    activeItem,
    snippetsFilterInfo,
    resetSnippetFilters,
    setSnippetsFilter,
    setSnippetsFilterInfo,
    snippetsTotal,
    setIsTranslatingFeed,
    fetchSnippetsInfo,
    fetchUserBookmarkedSnippets,
    defaultPlatforms,
  } = store[`${subStore}Store`]

  const { isLoadingDefaultPlatforms } = store.loaderStore

  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false)
  const [openNewBookmarkModal, setOpenNewBookmarkModal] = useState(false)
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false)
  const [selectedSnippets, setSelectedSnippets] = useState<string[]>([])
  const [appliedFilters, setAppliedFilters] = useState<any>(snippetsFilter)
  const [searchInput, setSearchInput] = useState(snippetsFilter.filter_keyword)

  const { bookmarksData, fetchAllBookmarksForFeed } = store.bookmarkStore

  const [openExportModal, setOpenExportModal] = useState(false)
  const [isBookmarkModalVisible, setIsBookmarkModalVisible] = useState(false)
  const [isClipReportModalVisible, setIsClipReportModalVisible] = useState(false)
  const [showSearchInput, setShowSearchInput] = useState(false)

  const activeSortButton = (order: 'Ascending' | 'Descending') =>
    classNames({ isActive: snippetsFilter.sort_mode === order })

  const isDefaultSortFilter = snippetsFilter.sort === 'Upload Date' && snippetsFilter.sort_mode === 'Descending'
  const sortByTitle = !isDefaultSortFilter ? `${snippetsFilter.sort} (${snippetsFilter.sort_mode})` : 'Sort by'

  useEffect(() => {
    fetchAllBookmarksForFeed()
  }, [])

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    fetchSnippetsInfo((detailObject as DetailsObjType) || activeItem)
  }, [snippetsFilter, isLoadingDefaultPlatforms])

  useEffect(() => {
    setAppliedFilters(snippetsFilter)
  }, [snippetsFilter])

  const onLanguageSelect = (value: string) => {
    const modFilter = { ...snippetsFilter }
    modFilter['lang'] = value
    setSnippetsFilter(modFilter)
    const filter = {
      language: true,
    }
    setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
    setIsTranslatingFeed(true)
  }
  const onBookmarkSelect = (id: any) => {}

  const handleChangeSortDropdown = (open: boolean) => {
    setIsSortDropdownOpen(open)
  }

  const handleSelectedSnippets = (snippetId: string) => {
    setSelectedSnippets((prev) => {
      if (prev?.includes(snippetId)) return prev?.filter((id) => id !== snippetId)
      return [...prev, snippetId]
    })
  }

  const handleApplyFilters = () => {
    setOpenFiltersDrawer(false)
    setSnippetsFilter({ ...snippetsFilter, ...appliedFilters })
  }

  const appliedFiltersCount = useMemo(() => {
    const platforms = snippetsFilter.filter_platform
      ? isArray(snippetsFilter.filter_platform)
        ? snippetsFilter.filter_platform
        : snippetsFilter.filter_platform.split(',')
      : []
    const snippetSource = snippetsFilter.snippet_source ? snippetsFilter.snippet_source.split(',') : []
    const sentiments = snippetsFilter.filter_sentiment
      ? isArray(snippetsFilter.filter_sentiment)
        ? snippetsFilter.filter_sentiment
        : snippetsFilter.filter_sentiment.split(',')
      : []

    const platformsApplied =
      platforms.length > defaultPlatforms?.split(',')?.length
        ? platforms.length - defaultPlatforms?.split(',')?.length
        : 0

    const arrayFilterCount = platformsApplied + snippetSource.length + sentiments.length
    const isRiskScoreModified = snippetsFilter.filter_risk?.[0] !== 0 || snippetsFilter.filter_risk?.[1] !== 100
    return arrayFilterCount + (isRiskScoreModified ? 1 : 0)
  }, [appliedFilters, defaultPlatforms])

  const handleMultiSelectValues = (value: string, key: 'snippet_source' | 'filter_sentiment' | 'filter_platform') => {
    setAppliedFilters((prevFilters: any) => {
      const currentValues = prevFilters[key] ? prevFilters[key]?.split(',') : []
      const newValues = currentValues.includes(value)
        ? currentValues.filter((item: any) => item !== value)
        : [...currentValues, value]
      return {
        ...prevFilters,
        [key]: newValues.join(),
      }
    })
  }

  const languages: MenuProps['items'] = Object.entries(SUPPORTED_LANGUAGES)?.map(([key, value]) => ({
    key: value,
    label: <LanguageLabel selected={snippetsFilter?.lang === value} text={key} />,
    onClick: () => onLanguageSelect(value),
    style: snippetsFilter?.lang === value ? { backgroundColor: '#eaecf0' } : {},
  }))

  const languagesCombinedOptions = useMemo(() => {
    const options: MenuProps['items'] = languages
    if (!snippetsFilter.lang) {
      options.unshift({
        key: 'eng',
        label: <LanguageLabel selected text='English (Default)' />,
        style: { backgroundColor: '#eaecf0' },
      })
    }
    if (snippetsFilter.lang) {
      options.push({
        type: 'divider',
      })
      options.push({
        key: 'reset',
        label: (
          <>
            <SyncOutlined style={{ marginRight: '8px' }} />
            Reset to default
          </>
        ),
        onClick: () => setSnippetsFilter({ ...snippetsFilter, lang: undefined }),
      })
    }
    return options
  }, [snippetsFilter])

  const bookmarks: MenuProps['items'] = bookmarksData?.items?.map((item) => ({
    key: item.id,
    label: item.name,
    onClick: () => onBookmarkSelect(item.id),
  }))

  const platformOptions = PLATFORM_ICONS_GREY?.map((platform) => ({
    label: <Platform platform={platform?.title.toLowerCase()} />,
    value: platform?.title?.toLowerCase(),
  }))

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}>
        {label}
      </Tag>
    )
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className='pi-dropdown-item'>
          <LanguageIcon />
          <span>Translate all snippets</span>
        </div>
      ),
      children: languagesCombinedOptions,
      expandIcon: () => null,
    },
    {
      key: '2',
      onClick: () => setIsBookmarkModalVisible(true),
      label: (
        <div className='pi-dropdown-item'>
          <BookmarkBulkFeedIcon />
          <span>Bookmark all snippets</span>
        </div>
      ),
    },
    {
      key: '3',
      onClick: () => setOpenExportModal(true),
      label: (
        <div className='pi-dropdown-item'>
          <ExportBulkFeedIcon />
          <span>Export all snippets</span>
        </div>
      ),
    },
    {
      key: '4',
      onClick: () => setIsClipReportModalVisible(true),
      label: (
        <div className='pi-dropdown-item'>
          <DownloadIcon />
          <span>Download clip report</span>
        </div>
      ),
    },
  ]

  const updateSnippetFilters = (key: string, value: string) => {
    setSnippetsFilter({ ...snippetsFilter, [key]: value })
  }

  const toggleSearchInput = () => {
    setShowSearchInput(!showSearchInput)
  }

  const filterDrawerProps: any = containFiltersInComponent ? { getContainer: false } : {}

  return (
    <div className='sl-with-filters-container-wrapper' id='snippet-filter-and-feed'>
      <div className='sl-with-filters-container'>
        <div className='sl-with-filters-container__header'>
          <div className='sl-with-filters-container__header__info'>
            <div className='sl-with-filters-container__header__info-title'>Snippets Feed</div>
            <div className='sl-with-filters-container__header__info-count'>{millify(snippetsTotal || 0)} snippets</div>
          </div>
          <div className='sl-with-filters-container__header__search'>
            <SearchOutlined onClick={toggleSearchInput} />
            {showSearchInput && (
              <Input
                size='large'
                placeholder='Search by keyword'
                onChange={(event) => setSearchInput(event.target.value)}
                value={searchInput}
                onPressEnter={() => setSnippetsFilter({ ...snippetsFilter, filter_keyword: searchInput })}
                style={{ border: 'none', backgroundColor: 'transparent', height: '40px' }}
              />
            )}
            {showSearchInput && (
              <span className='sl-with-filters-container__header__search__close'>
                <MinusOutlined />
                <CloseOutlined onClick={toggleSearchInput} />
              </span>
            )}
          </div>
        </div>
        <div className='sl-with-filters-container__filters-section'>
          <div className='sl-with-filters-container__filters-section__left-section'>
            <Button icon={<FilterIcon />} onClick={() => setOpenFiltersDrawer(true)}>
              Filters {appliedFiltersCount ? `(${appliedFiltersCount})` : null}
            </Button>
            <Dropdown
              placement='bottomCenter'
              trigger={['click']}
              open={isSortDropdownOpen}
              onOpenChange={handleChangeSortDropdown}
              overlayClassName='snippet-list__options__dropdown sort-dropdown'
              dropdownRender={() => (
                <div className='sort-dropdown__menu'>
                  <div className='sort-dropdown__menu__title'>SORT BY</div>

                  <div className='sort-dropdown__menu--sort-by-selection'>
                    <div
                      className='sort-dropdown__menu__sort-by'
                      onClick={() => updateSnippetFilters('sort', 'Upload Date')}>
                      <Radio
                        className='alert-radio'
                        checked={snippetsFilter.sort === 'Upload Date'}
                        onChange={() => null}
                      />
                      <div className='sort-dropdown__menu__sort-by__text'>Upload Date</div>
                    </div>
                    <div
                      className='sort-dropdown__menu__sort-by'
                      onClick={() => updateSnippetFilters('sort', 'Risk score')}>
                      <Radio
                        className='alert-radio'
                        checked={snippetsFilter.sort === 'Risk score'}
                        onChange={() => null}
                      />
                      <div className='sort-dropdown__menu__sort-by__text'>Risk Score</div>
                    </div>
                    <div
                      className='sort-dropdown__menu__sort-by'
                      onClick={() => updateSnippetFilters('sort', 'Total Impressions')}>
                      <Radio
                        className='alert-radio'
                        checked={snippetsFilter.sort === 'Total Impressions'}
                        onChange={() => null}
                      />
                      <div className='sort-dropdown__menu__sort-by__text'>Total Impressions</div>
                    </div>
                  </div>

                  <div className='sort-dropdown__menu__order-by'>
                    <div className='sort-dropdown__menu__order-by__title'>ORDER BY</div>
                    <div className='sort-dropdown__menu__order-by__btn'>
                      <Button
                        className={activeSortButton('Ascending')}
                        onClick={() => {
                          updateSnippetFilters('sort_mode', 'Ascending')
                        }}>
                        Ascending
                      </Button>
                      <Button
                        className={activeSortButton('Descending')}
                        onClick={() => {
                          updateSnippetFilters('sort_mode', 'Descending')
                        }}>
                        Descending
                      </Button>
                    </div>
                  </div>
                </div>
              )}>
              <Button icon={<SortIcon />}>{sortByTitle}</Button>
            </Dropdown>

            {(appliedFiltersCount > 0 || !isDefaultSortFilter) && (
              <Button
                icon={<LoaderIcon />}
                className='reset-btn'
                onClick={() => {
                  resetSnippetFilters()
                }}>
                Reset
              </Button>
            )}
          </div>
          <div className='sl-with-filters-container__filters-section__right-section'>
            <Dropdown
              menu={{ items }}
              placement='bottomRight'
              overlayClassName='snippet-list__options__dropdown'
              trigger={['click']}>
              <div className='dots'>
                <ThreeDotsIcon />
              </div>
            </Dropdown>
          </div>
        </div>
        {detailObject && activePowerInsightTab && (
          <FilterChips
            subStore={subStore}
            detailObj={detailObject as DetailsObjType}
            activePowerInsightTab={activePowerInsightTab}
          />
        )}

        <SnippetList
          mode={'asset'}
          isDashboardSnippet
          subStore={subStore}
          showSearchChip={false}
          searchedDataResults={100}
          emptyStateIcon={emptyStateIcon}
          uniqueScrollId={uniqueScrollId}
          emptyStateTitle={emptyStateTitle}
          selectedSnippets={selectedSnippets}
          isCustomEmptyState={isCustomEmptyState}
          emptyStateDescription={emptyStateDescription}
          handleSelectedSnippets={handleSelectedSnippets}
        />

        <Drawer
          height={68}
          mask={false}
          destroyOnClose
          closeIcon={null}
          placement='bottom'
          getContainer={false}
          // open={!!selectedSnippets?.length}
          open={false}
          headerStyle={{ display: 'none' }}
          style={{ position: 'absolute', bottom: '0px' }}>
          <div className='snippet-list__bottom-actions'>
            <span className='snippet-list__bottom-actions__count'>
              {selectedSnippets?.length > 1 ? 'Snippets ' : 'Snippet '}
              {`(${selectedSnippets?.length}) `}selected
            </span>
            <div className='snippet-list__bottom-actions__actions'>
              <Dropdown
                menu={{ items: languages }}
                placement='topLeft'
                overlayClassName='snippet-list__options__dropdown language-dropdown'
                trigger={['click']}>
                <Tooltip title='Translate'>
                  <Button icon={<LanguageIcon />} />
                </Tooltip>
              </Dropdown>
              <Dropdown
                menu={{ items: bookmarks }}
                placement='topLeft'
                overlayClassName='snippet-list__options__dropdown language-dropdown bookmarks'
                trigger={['click']}
                dropdownRender={(menus) => (
                  <div className='bookmarks-dropdown__menu'>
                    <span className='bookmarks-dropdown__menu__header'>Add snippets to bookmark list:</span>
                    {menus}
                    <div className='bookmarks-dropdown__menu__footer' onClick={() => setOpenNewBookmarkModal(true)}>
                      <PluseIcon /> <span>Add to a new list</span>
                    </div>
                  </div>
                )}>
                <Tooltip title='Bookmark'>
                  <Button icon={<BookmarkBulkFeedIcon />} />
                </Tooltip>
              </Dropdown>
              <Tooltip title='Export'>
                <Button icon={<ExportBulkFeedIcon />} />
              </Tooltip>
              <Tooltip title='Download clip report'>
                <Button icon={<DownloadIcon />} />
              </Tooltip>
            </div>
            <Tooltip title='Cancel'>
              <Button icon={<CloseIcon />} className='close-icon' onClick={() => setSelectedSnippets([])} />
            </Tooltip>
          </div>
        </Drawer>

        <BaseModal
          title={
            <div className='bookmark-modal__title'>
              <div className='bookmark-modal__title__text'>Add to a new bookmark list</div>
              <div className='bookmark-modal__title__description'>
                You are about to bookmark all filtered snippets to the new list.
              </div>
            </div>
          }
          open={openNewBookmarkModal}
          isHeaderBordered
          isFooterBordered
          closable
          onCancel={() => setOpenNewBookmarkModal(false)}
          className='new-bookmark-modal'
          footer={
            <>
              <Button type='text' onClick={() => setOpenNewBookmarkModal(false)}>
                Cancel
              </Button>
              <Button type='primary' onClick={() => null} loading={false}>
                Confirm
              </Button>
            </>
          }>
          <div className='new-bookmark-modal__content'>
            <label>
              <span className='input-label'>
                List name <span className='asterisk'>*</span>
              </span>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Please input list name' }]}
                shouldUpdate={(prev, current) => prev.field !== current.field}>
                <Input placeholder='Kinetic Risk' key={'watchlist-add-details-name-input'} />
              </Form.Item>
            </label>
            <label>
              <span className='input-label'>Description</span>
              <Form.Item name='description' shouldUpdate={(prev, current) => prev.field !== current.field}>
                <Input.TextArea
                  placeholder='Add any helpful description'
                  key={'watchlist-add-details-description-textarea'}
                />
              </Form.Item>
            </label>
          </div>
        </BaseModal>
      </div>

      <Drawer
        width={580}
        mask={false}
        destroyOnClose
        closable={false}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
            <span>Filters</span>
            <CloseIcon onClick={() => setOpenFiltersDrawer(false)} style={{ cursor: 'pointer' }} />
          </div>
        }
        closeIcon={<CloseIcon />}
        onClose={() => setOpenFiltersDrawer(false)}
        placement='right'
        open={openFiltersDrawer}
        headerStyle={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          textAlign: 'right',
        }}
        style={
          containFiltersInComponent
            ? {
                position: 'absolute',
                right: '0px',
                bottom: '0px',
              }
            : {}
        }
        className='snippet-list-filters-drawer'
        footer={
          <>
            <Button type='text' onClick={() => setOpenFiltersDrawer(false)}>
              Cancel
            </Button>
            <Button type='primary' onClick={handleApplyFilters} loading={false}>
              Apply Filters
            </Button>
          </>
        }
        {...filterDrawerProps}>
        <div className='snippet-list__right-actions'>
          <label className='platform-selection'>
            <span className='platform-selection__title'>Platform</span>
            <Select
              allowClear
              maxTagCount={'responsive'}
              mode='tags'
              tagRender={tagRender}
              style={{ width: '100%' }}
              options={platformOptions}
              popupClassName={'platform-selection__dropdown'}
              placeholder='All Platforms'
              onClear={() => setAppliedFilters((prev: Unknown) => ({ ...prev, filter_platform: '' }))}
              value={
                isArray(appliedFilters?.filter_platform)
                  ? appliedFilters?.filter_platform
                  : appliedFilters?.filter_platform
                  ? appliedFilters?.filter_platform.split(',')
                  : []
              }
              onSelect={(value) => handleMultiSelectValues(value, 'filter_platform')}
              onDeselect={(value) => {
                setAppliedFilters((filters: any) => {
                  const platforms = filters?.['filter_platform']?.split(',') || []
                  const filtered = platforms.filter((platform: string) => platform !== value)
                  return { ...filters, filter_platform: filtered.join(',') }
                })
              }}
            />
          </label>
          <div className='content-source-selection'>
            <div className='content-source-selection__title'>Content Source</div>
            <div className='content-source-selection__sources'>
              {CONTENT_SOURCES?.map((item) => (
                <Button
                  onClick={() => handleMultiSelectValues(item.value, 'snippet_source')}
                  className={classNames({
                    isActive:
                      appliedFilters?.snippet_source && appliedFilters.snippet_source.split(',')?.includes(item.value),
                  })}>
                  {item.value}
                </Button>
              ))}
            </div>
          </div>
          <div className='sentiment-selection'>
            <div className='sentiment-selection__title'>Sentiment</div>
            <div className='sentiment-selection__sentiments'>
              {SENTIMENT_ICONS?.map((item) => (
                <Button
                  icon={item.icon}
                  onClick={() => handleMultiSelectValues(item.title, 'filter_sentiment')}
                  className={classNames({
                    isActive:
                      appliedFilters?.filter_sentiment &&
                      appliedFilters.filter_sentiment.split(',').includes(item.title),
                  })}>
                  {item.title}
                </Button>
              ))}
            </div>
          </div>
          <div className='risk-selection'>
            <p className='risk-selection__title'>Risk Score (%)</p>
            <div className='risk-selection__input'>
              <RangeSlider
                value={{ min: appliedFilters.filter_risk[0], max: appliedFilters.filter_risk[1] }}
                onChange={(value) =>
                  setAppliedFilters((prev: any) => ({ ...prev, filter_risk: [value.min, value.max] }))
                }
              />
            </div>
          </div>
        </div>
      </Drawer>
      <FeedExport openModal={openExportModal} setOpenModal={setOpenExportModal} subStore={subStore} />
      <ClipReports
        isModalVisible={isClipReportModalVisible}
        setIsModalVisible={setIsClipReportModalVisible}
        subStore={subStore}
      />
      <AddSnippetToBookmarksListModal
        snippets={snippets}
        isModalVisible={isBookmarkModalVisible}
        closeModal={() => {
          setIsBookmarkModalVisible(false)
          fetchUserBookmarkedSnippets()
        }}
      />
    </div>
  )
}

export default observer(SnippetListWithFilters)
