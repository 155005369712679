import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as CardParagraphSkeleton } from 'assets/images/icons/dashboard/card-paragraph-skeleton.svg'
import { Button, Skeleton } from 'antd'
import { SuggestFlagObject } from 'store/flags/types'
import fallBackImage from 'assets/images/icons/dashboard/fallback-image.png'
import './SuggestedIssues.scss'

export default function IssueCard({
  emptyState = false,
  issue,
  followIssue,
  isFollowCalled,
}: {
  emptyState?: boolean
  issue?: SuggestFlagObject
  followIssue: (issueId: string) => void
  isFollowCalled?: boolean
}) {
  if (emptyState || !issue) {
    return (
      <div className='issue-card'>
        <Skeleton.Image className='skeleton-image' />
        <h6 className='skeleton-title'>
          <Skeleton paragraph />
        </h6>
        <p className='description'>
          <CardParagraphSkeleton />
        </p>
        <ButtonImage onClick={() => {}} className='fhc_sc_button follow-btn'>
          Follow Topic
        </ButtonImage>
      </div>
    )
  }

  return (
    <div className='issue-card'>
      <div className='img-wrapper'>
        <img src={issue.image_url || fallBackImage} alt='Issue' className='img' />
        {/* <div className='stats'>
          <div className='item'>
            <h6>Mentions</h6>
            <p>439.1K</p>
          </div>
          <div className='item'>
            <h6>Impressions</h6>
            <p>249.1K</p>
          </div>
          <div className='item'>
            <h6>Engagment</h6>
            <p>439.1K</p>
          </div>
        </div> */}
      </div>

      <h6 className='title'>{issue.title}</h6>
      <p className='description'>{issue.summary}</p>
      <Button
        onClick={() => followIssue(issue.id)}
        className='fhc_sc_button follow-btn'
        disabled={isFollowCalled}
        loading={isFollowCalled}>
        Follow Topic
      </Button>
    </div>
  )
}
