import { useState } from 'react'
import RecurrenceCard from '../components/RecurrenceCard'
import StepsHeader from '../components/StepsHeader'
import { ReactComponent as DropdownIcon } from 'assets/images/icon-dropmenu-wider.svg'
import { Button, Col, Popover, Row, Select } from 'antd'
import InputForTime from 'pages/PulseDashboard/InputForTime'

import DateTimeRangeFilter from 'components/common/DateTimeRangeFilter/DateTimeRangeFilter'
import moment from 'moment'
import { ReportDayType, ReportType } from 'store/report/valueTypes'
import './commonStyles.scss'

const DESCRIPTION =
  "Choose a recurrence that fits your needs, and we'll automatically send the report based on your selection. Once selected, the report will be sent to your email automatically according to the schedule."

const weeklySubscriptionDays = [
  { label: 'Every Monday', value: 'Monday' },
  { label: 'Every Tuesday', value: 'Tuesday' },
  { label: 'Every Wednesday', value: 'Wednesday' },
  { label: 'Every Thursday', value: 'Thursday' },
  { label: 'Every Friday', value: 'Friday' },
  { label: 'Every Saturday', value: 'Saturday' },
  { label: 'Every Sunday', value: 'Sunday' },
]

export type RecurrenceOptions = {
  recurrenceType: ReportType
  selectedDay: ReportDayType
  deliveryTime: string
  dateRange: {
    startDate: Date
    endDate: Date
  }
}
type Props = {
  recurrenceOptions: RecurrenceOptions
  setRecurrenceOptions: (options: RecurrenceOptions) => void
  editMode?: boolean
}

const reportType = {
  ADHOC: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

export const RecurrenceForm = ({ editMode, recurrenceOptions, setRecurrenceOptions }: Props) => {
  const [openDailyTimePicker, setOpenDailyTimePicker] = useState(false)
  const [isOpenDateRange, setIsOpenDateRange] = useState(false)

  const dailyTimePickerContent = (
    <InputForTime
      value={recurrenceOptions.deliveryTime}
      onChange={(time) => setRecurrenceOptions({ ...recurrenceOptions, deliveryTime: time })}
    />
  )

  const isSelected = (type: string) => recurrenceOptions.recurrenceType === type

  return (
    <>
      <StepsHeader
        title={editMode ? 'Select reoccurrence' : 'Recurrence'}
        stepText={editMode ? '' : 'Step 1'}
        description={DESCRIPTION}
      />
      <div className='recurrenc-cards'>
        <RecurrenceCard
          title='Daily'
          description='Recurring report summarizing content from the past 24 hours.'
          onClick={() => setRecurrenceOptions({ ...recurrenceOptions, recurrenceType: reportType.DAILY as ReportType })}
          selected={isSelected(reportType.DAILY)}>
          <div className='recurrence-forms'>
            <div className='input'>
              <p className='label'>Delivery time</p>
              <Popover
                content={dailyTimePickerContent}
                trigger='click'
                open={openDailyTimePicker && isSelected(reportType.DAILY)}
                onOpenChange={(visible) => setOpenDailyTimePicker(visible)}
                className='ime-picker-popover'
                arrowContent={null}
                placement='bottomLeft'>
                <>
                  <Button className='time-picker-trigger' disabled={!isSelected(reportType.DAILY)}>
                    {recurrenceOptions.deliveryTime}{' '}
                    {openDailyTimePicker && isSelected(reportType.DAILY) ? <DropdownIcon /> : <DropdownIcon />}
                  </Button>
                </>
              </Popover>
            </div>
          </div>
        </RecurrenceCard>
        <RecurrenceCard
          title='Weekly'
          description='Recurring report summarizing content from the last 7 days from day selected.'
          onClick={() =>
            setRecurrenceOptions({ ...recurrenceOptions, recurrenceType: reportType.WEEKLY as ReportType })
          }
          selected={isSelected(reportType.WEEKLY)}>
          <div className='recurrence-forms'>
            <div className='input'>
              <p className='label'>Delivery time</p>
              <Select
                placement='bottomRight'
                size='large'
                onChange={(value) => setRecurrenceOptions({ ...recurrenceOptions, selectedDay: value })}
                value={recurrenceOptions.selectedDay}
                getPopupContainer={(trigger) => trigger.parentNode}
                className='weekDaySelector'
                disabled={!isSelected(reportType.WEEKLY)}>
                {weeklySubscriptionDays.map((day) => (
                  <Select.Option value={day.value}>{day.label}</Select.Option>
                ))}
              </Select>
            </div>
            <div className='input'>
              <p className='label'>Delivery time</p>
              <Popover
                content={dailyTimePickerContent}
                trigger='click'
                open={openDailyTimePicker && isSelected(reportType.WEEKLY)}
                onOpenChange={(visible) => setOpenDailyTimePicker(visible)}
                className='ime-picker-popover'
                arrowContent={null}
                placement='bottomLeft'>
                <>
                  <Button className='time-picker-trigger' disabled={!isSelected(reportType.WEEKLY)}>
                    {recurrenceOptions.deliveryTime} <DropdownIcon />
                  </Button>
                </>
              </Popover>
            </div>
          </div>
        </RecurrenceCard>
        <RecurrenceCard
          title='Single'
          description='One time report summarizing content from a specified date range.'
          onClick={() => setRecurrenceOptions({ ...recurrenceOptions, recurrenceType: reportType.ADHOC as ReportType })}
          selected={isSelected(reportType.ADHOC)}>
          <div className='recurrence-forms'>
            <div className='input'>
              <p className='label'>Time range</p>
              <Button
                className='time-picker-trigger'
                disabled={!isSelected(reportType.ADHOC)}
                onClick={() => setIsOpenDateRange(true)}>
                {moment(recurrenceOptions.dateRange.startDate).format('MM/DD/YYYY')} -{' '}
                {moment(recurrenceOptions.dateRange.endDate).format('MM/DD/YYYY')} <DropdownIcon />
              </Button>
            </div>
            {isOpenDateRange && (
              <DateTimeRangeFilter
                dateRange={recurrenceOptions.dateRange}
                open={isOpenDateRange}
                onConfirm={({ startDateAndTime, endDateAndTime }) => {
                  setRecurrenceOptions({
                    ...recurrenceOptions,
                    dateRange: { startDate: startDateAndTime, endDate: endDateAndTime },
                  })
                  setIsOpenDateRange(false)
                }}
                onClose={() => setIsOpenDateRange(false)}
                hideTimePicker={true}
              />
            )}
          </div>
        </RecurrenceCard>
      </div>
    </>
  )
}
const Recurrence = (props: Props) => {
  return (
    <Row>
      <Col span={6}></Col>
      <Col span={12}>
        <RecurrenceForm {...props} />
      </Col>
      <Col span={6}></Col>
    </Row>
  )
}

export default Recurrence
