import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import { store } from 'store'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { Col, Row, Steps, Typography } from 'antd'
import { ReactComponent as ArrowRight } from 'assets/images/icons/dashboard/arrow-right.svg'
import Recurrence, { RecurrenceOptions } from './steps/Recurrence'
import Content from './steps/Content'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import Summarization from './steps/Summarization'
import Review from './steps/Review'
import { CreateReportObj, CreateReportSectionObj, EntitiesObj, ReportType } from 'store/report/valueTypes'
import { Unknown } from 'utils/commonTypes'
import EditRecurrence from './components/EditRecurrence'
import AddEditContentSection from './components/AddEditContentSection'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import './CreateReport.scss'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const { Text } = Typography

const convertLocalTimeToUTC = (time: string) => {
  const timeWithDate = dayjs().startOf('day').format('YYYY-MM-DD') + ' ' + time
  const localTime = dayjs(timeWithDate).utc()
  return parseInt(localTime.format('HH'), 10)
}

const CancelBreadCrumb = () => {
  const previousPath = '/reports'
  return (
    <div className='rbc_container'>
      <Link className='rbc_c_link' to={previousPath} replace={true}>
        <CloseIcon className='rbc_c_l_close_icon' />
        Cancel
      </Link>
      <div className='rbc_text'>
        <Text className='rbc_c_create_text'>Create new</Text>
        <ArrowRight />
        <Text className='rbc_c_text'>Report</Text>
      </div>
    </div>
  )
}

const { Step } = Steps

const reportType = {
  ADHOC: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

export const defaultSelectedContentItems = {
  brand: [] as Unknown[],
  flags: [] as Unknown[],
  topics: [] as Unknown[],
  searches: [] as Unknown[],
  influencers: [] as Unknown[],
  bookmarks: [] as Unknown[],
}

export type DefaultSelectedContentItems = {
  brand: Unknown[]
  flags: Unknown[]
  topics: Unknown[]
  searches: Unknown[]
  influencers: Unknown[]
  bookmarks: Unknown[]
}

export type DefaultFilters = {
  language: Unknown[]
  platform: Unknown[]
  sentiment: Unknown[]
  source: Unknown[]
}

const defaultSection = {
  id: uuidv4(),
  title: '',
  summary_prompt: '',
  entities: [],
  mainItems: defaultSelectedContentItems,
  optionalItems: defaultSelectedContentItems,
  filters: { language: [], platform: [], sentiment: [], source: [] },
}

type SectionItems = {
  mainItems: DefaultSelectedContentItems
  optionalItems: DefaultSelectedContentItems
  filters: DefaultFilters
  isNew?: boolean
}

export type SectionObjType = SectionItems & CreateReportSectionObj

const CreateReport = () => {
  const navigate = useNavigate()
  const { reportMainViewStore } = store
  const { createReportHandler } = reportMainViewStore
  const [createReportStep, setCreateReportStep] = useState(0)
  const { breadcrumbStore } = store
  const { setBreadcrumbData } = breadcrumbStore
  const [reportName, setReportName] = useState('')
  const [sections, setSections] = useState<SectionObjType[]>([defaultSection])
  const [errors, setErrors] = useState({})
  const [openEditRecurrence, setOpenEditRecurrence] = useState(false)
  const [recurrenceCurrentState, setRecurrenceCurrentState] = useState<any>()
  const [recurrenceOptions, setRecurrenceOptions] = useState<RecurrenceOptions>({
    selectedDay: 'Monday',
    deliveryTime: '12:00 AM',
    recurrenceType: reportType.DAILY as ReportType,
    dateRange: { startDate: new Date(), endDate: new Date() },
  })
  const [sectionToEdit, setSectionToEdit] = useState<SectionObjType | null>()
  const [isCreatingReport, setIsCreatingReport] = useState(false)

  useEffect(() => {
    setBreadcrumbData([{ id: UtilService.generateUuid(), title: <CancelBreadCrumb /> }])
    return () => setBreadcrumbData([{ id: UtilService.generateUuid(), title: 'Reports' }])
  }, [])

  const createReport = () => {
    setIsCreatingReport(true)
    const sectionsFormatted = sections.map(({ title, summary_prompt, mainItems, optionalItems, filters }) => {
      const entities: EntitiesObj[] = []
      const refined_entities: EntitiesObj[] = []

      Object.keys(mainItems).forEach((type) => {
        mainItems[type as keyof typeof mainItems].forEach((item: any) => {
          entities.push({ type: item.type, id: item.id, value: item.name })
        })
      })
      Object.keys(optionalItems).forEach((type) => {
        optionalItems[type as keyof typeof optionalItems].forEach((item: any) => {
          refined_entities.push({ type: item.type, id: item.id, value: item.name })
        })
      })

      return {
        title,
        summary_prompt,
        entities: entities,
        refined_entities: refined_entities,
        filters,
      }
    })

    let body: CreateReportObj = {
      name: reportName,
      report_type: recurrenceOptions.recurrenceType,
      sections: sectionsFormatted,
    }
    if (recurrenceOptions.recurrenceType === 'DAILY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
    }
    if (recurrenceOptions.recurrenceType === 'WEEKLY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
      body.weekly_report_day = recurrenceOptions.selectedDay
    }
    if (recurrenceOptions.recurrenceType === 'ADHOC') {
      body.start_date = dayjs(recurrenceOptions.dateRange.startDate.toISOString()).utc().format('YYYY-MM-DD')
      body.end_date = dayjs(recurrenceOptions.dateRange.endDate.toISOString()).utc().format('YYYY-MM-DD')
    }

    createReportHandler(body).then((res) => {
      setIsCreatingReport(false)
      if (res === 'error') {
        return
      }
      navigate('/reports')
    })
  }

  const goBack = () => {
    if (createReportStep === 0) return
    setCreateReportStep(createReportStep - 1)
  }

  const goForward = () => {
    if (createReportStep === 1) {
      if (sections[0].title === '') {
        setErrors({ ...errors, contentTitle: 'Required' })
        return
      }
    }
    if (createReportStep === 3) {
      if (reportName === '') {
        setErrors({ ...errors, reportName: 'Required' })
        return
      } else {
        createReport()
        return
      }
    }
    setCreateReportStep(createReportStep + 1)
  }

  const updateSection = (section: SectionObjType) => setSections([section])

  const openEditRecurrenceForm = () => {
    setRecurrenceCurrentState(recurrenceOptions)
    setOpenEditRecurrence(true)
  }

  const closeRecurrenceForm = (save?: boolean) => {
    if (!save && recurrenceCurrentState) {
      setRecurrenceOptions(recurrenceCurrentState)
    }
    setOpenEditRecurrence(false)
  }

  const openSectionToEdit = (section: SectionObjType) => {
    setSectionToEdit(section)
  }

  const closeAddEditSection = (section?: SectionObjType) => {
    if (!section) {
      setSectionToEdit(null)
      return
    }
    const isSectionEdit = sections.findIndex((data) => data.id === section.id)
    if (isSectionEdit > -1) {
      setSections(
        sections.map((data) => {
          if (data.id === section.id) return section
          return data
        }),
      )
    } else {
      const { isNew, ...data } = section
      setSections([...sections, data])
    }
    setSectionToEdit(null)
  }

  const addNewSection = () => {
    setSectionToEdit({ ...defaultSection, id: uuidv4(), isNew: true })
  }

  return (
    <div className='create-report'>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <div className='steps'>
            <Steps current={createReportStep} labelPlacement='vertical'>
              <Step title='Recurrence' />
              <Step title='Content' />
              <Step title='Summarization' />
              <Step title='Review' />
            </Steps>
          </div>
        </Col>
        <Col span={6}></Col>
      </Row>

      <div className='content'>
        {createReportStep === 0 && (
          <Recurrence recurrenceOptions={recurrenceOptions} setRecurrenceOptions={setRecurrenceOptions} />
        )}
        {createReportStep === 1 && (
          <Content section={sections[0]} updateSection={updateSection} errors={errors} setErrors={setErrors} />
        )}
        {createReportStep === 2 && (
          <Summarization section={sections[0]} updateSection={updateSection} recurrenceOptions={recurrenceOptions} />
        )}
        {createReportStep === 3 && (
          <Review
            recurrenceType={recurrenceOptions.recurrenceType}
            sections={sections}
            reportName={reportName}
            setReportName={setReportName}
            errors={errors}
            setErrors={setErrors}
            setOpenEditRecurrence={openEditRecurrenceForm}
            openSectionToEdit={openSectionToEdit}
            addNewSection={addNewSection}
          />
        )}
        <EditRecurrence
          openEditRecurrence={openEditRecurrence}
          closeRecurrenceForm={closeRecurrenceForm}
          recurrenceOptions={recurrenceOptions}
          setRecurrenceOptions={setRecurrenceOptions}
        />
        {sectionToEdit && (
          <AddEditContentSection
            closeAddEditSection={closeAddEditSection}
            isOpenAddNewSection={Boolean(sectionToEdit)}
            sectionToEdit={sectionToEdit}
            recurrenceOptions={recurrenceOptions}
            sections={sections}
          />
        )}
      </div>

      <Row className='actions-row'>
        <Col span={6}></Col>
        <Col span={12}>
          <div className='actions'>
            {createReportStep !== 0 && (
              <ButtonImage onClick={goBack} className='cancel-btn'>
                Back
              </ButtonImage>
            )}
            <ButtonImage onClick={goForward} className='continue-btn' loading={isCreatingReport}>
              {createReportStep === 3 ? 'Save' : 'Continue'}
            </ButtonImage>
          </div>
        </Col>
        <Col span={6}></Col>
      </Row>
    </div>
  )
}

export default observer(CreateReport)
