import millify from 'millify'
import { Button, Skeleton } from 'antd'
import classNames from 'classnames'

import Summary from './Summary'
import Checkbox from 'components/common/Checkbox/Checkbox'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { FlagListObjType } from 'store/flags/types'

import issueImage from 'assets/images/icons/dashboard/issue.png'
import { ReactComponent as CheckButton } from 'assets/images/check-icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'

import './FollowedIssues.scss'
import usePermissions from 'hooks/usePermissions'

export default function IssueCard({
  flag,
  onSelectFlag,
  selectedTopics,
  subStore = 'assets',
  isOnlyView = false,
  emptyState = false,
  newlyFollowedTopic,
  handleFollowUnFollow,
  handleTopicSelection,
  followTopicLoading,
  onEditTopic,
}: {
  isOnlyView?: boolean
  emptyState?: boolean
  selectedTopics?: string[]
  newlyFollowedTopic?: string
  followTopicLoading?: string
  subStore?: 'assets' | 'vectors'
  flag?: FlagListObjType | undefined
  handleTopicSelection?: (id: string) => void
  onSelectFlag: (flag: FlagListObjType) => void
  handleFollowUnFollow?: (id: string, active: boolean) => void
  onEditTopic?: (flag: FlagListObjType) => void
}) {
  const canCreateTopic = usePermissions('flags') === 'create'

  if (emptyState || !flag) {
    return (
      <div className='followed-issue-card'>
        <Skeleton.Image className='skeleton-image' active />
        <div className='summary'>
          <h6 className='skeleton-title'>
            <Skeleton paragraph active />
          </h6>
          <p className='skeleton-description'>
            <Skeleton paragraph active />
          </p>
        </div>
        <div
          className={classNames('stats', {
            'stats-edit-mode': !isOnlyView,
          })}>
          <div className='item'>
            <h6>Mentions</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          {isOnlyView && (
            <>
              <div className='item'>
                <h6>Impressions</h6>
                <p className='skeleton-stats-item'>
                  <Skeleton paragraph active />
                </p>
              </div>
              <div className='item'>
                <h6>Engagment</h6>
                <p className='skeleton-stats-item'>
                  <Skeleton paragraph active />
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames('followed-issue-card', {
        selected: selectedTopics?.includes(flag?.id),
        isOnlyView: isOnlyView,
      })}
      onClick={() => onSelectFlag(flag)}>
      <div className='img-wrapper'>
        <img src={issueImage} alt='Issue' className='img' />
      </div>
      <div className='summary'>
        <h6 className='title'>{flag.name}</h6>
        <Summary flag={flag} subStore={subStore} discard={flag.metric?.skip} />
      </div>
      <div
        className={classNames('stats', {
          'stats-edit-mode': !isOnlyView,
        })}>
        {newlyFollowedTopic !== flag?.id && (
          <>
            <div className='item'>
              <h6>Mentions</h6>
              <p>{millify(flag.metric?.views || 0)}</p>
            </div>
            {isOnlyView && (
              <>
                <div className='item'>
                  <h6>Impressions</h6>
                  <p>{millify(flag.metric?.impressions || 0)}</p>
                </div>
                <div className='item'>
                  <h6>Engagment</h6>
                  <p>{millify(flag.metric?.engagement || 0)}</p>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {!isOnlyView && (
        <Button
          className={classNames('followed-issue-card__follow-btn', {
            'followed-issue-card__unfollow-btn': flag?.is_active,
          })}
          loading={followTopicLoading === flag?.id}
          disabled={followTopicLoading === flag?.id}
          onClick={(e) => {
            e.stopPropagation()
            handleFollowUnFollow?.(flag?.id, flag?.is_active)
          }}>
          {flag?.is_active ? 'Unfollow' : 'Follow'}
        </Button>
      )}
      {!isOnlyView && (
        <Button
          className='followed-issue-card__follow-btn'
          onClick={() => onEditTopic?.(flag)}
          style={{ cursor: !canCreateTopic ? 'not-allowed' : 'pointer' }}
          disabled={!canCreateTopic}>
          <EditIcon /> Edit
        </Button>
      )}

      {newlyFollowedTopic === flag?.id && (
        <ButtonImage
          className='followed-issue-card__loading-btn'
          onClick={(e) => {
            e.stopPropagation()
            handleFollowUnFollow?.(flag?.id || '', flag?.is_active || false)
          }}
          icon={CheckButton}>
          Followed
        </ButtonImage>
      )}
      <Checkbox
        className='followed-issue-card__checkbox-btn'
        isPropagation
        checked={selectedTopics?.includes(flag?.id)}
        onClick={() => handleTopicSelection?.(flag?.id)}
      />
    </div>
  )
}
