import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Button, Col, Dropdown, Row, Spin } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { store } from 'store'

import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'

import BaseModal from 'components/BaseModal/BaseModal'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import DailyExecutiveSummary from '../Asset/BrandsDashboard/components/DailyExecutiveSummary/DailyExecutiveSummary'
import { CreatorDetailsPage } from 'components/Monitor/PowerInsights/Creators/CreatorDetailsPage/CreatorDetailsPage'
import PowerInsightTabsListVertical from '../Asset/BrandsDashboard/components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'

import { DetailsObjType, Mode, MoveTopFolderDataType, SubStore } from 'types/types'

import { CreateBookmarkModal } from 'components/Investigate/Bookmarks/CreateBookmarkModal/CreateBookmarkModal'
import { DeleteModal } from 'pages/Investigate/DeleteModal/DeleteModal'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ListSelectedRowData } from 'models/models'
import MoveToFolderModal from 'components/Monitor/MoveToFolderModal/MoveToFolderModal'
import { ReactComponent as AddFolderIcon } from 'assets/images/icons/monitor/add_folder_icon.svg'
import './PowerInsights.scss'
import '../Asset/BrandsDashboard/BrandDashboard.scss'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import usePermissions from 'hooks/usePermissions'

const backToPath: { [x: string]: string } = {
  bookmarks: '/investigate/bookmarks',
  watchlists: '/influencers',
  communities: '/influencers',
  library: '/library',
}
interface Props {
  mode: Mode
  subStore: SubStore
  skipActions?: boolean
}

const PowerInsights = (props: Props) => {
  const { subStore, skipActions = false } = props
  const location = useLocation()
  const navigate = useNavigate()
  const detailObj = location.state as DetailsObjType | null

  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('metrics')
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenMoveToFolderModal, setIsOpenMoveToFolderModal] = useState(false)

  const { tenantsStore, loaderStore, bookmarkStore, vectorsStore, toasterStore } = store
  const {
    previousInfo,
    snippetsFilter,
    fetchSnippetsInfo,
    setSnippetsFilter,
    fetchPowerInsights,
    resetSnippetFilters,
    setIsCreatorModalOpen,
    isCreatorModalOpen,
  } = store[`${subStore}Store`]
  const { handleDeleteBookmark } = bookmarkStore
  const { moveToFolder, CDUFolder, fetchFolderList, userFolderList, deleteItem } = vectorsStore
  const { isLoadingFeed } = loaderStore
  const { navigationInfo } = store.mainStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { showToaster } = toasterStore
  const canCreateVectors = usePermissions('vectors') === 'create'

  const isTypeBookmark = navigationInfo.comingFrom === 'bookmarks'
  const isTypeInfluencer = navigationInfo.comingFrom === 'watchlists' || location.pathname.includes('/influencers')

  useEffect(() => {
    if (detailObj === null) {
      navigate('../', { replace: true })
    }
    if (detailObj?.name) {
      if (detailObj?.languageKey) {
        setSnippetsFilter({
          ...snippetsFilter,
          filter_language: detailObj.languageKey === 'Default' ? 'en' : detailObj.languageKey,
        })
      }

      fetchPowerInsights(detailObj, false)
    }
  }, [detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions)])

  useEffect(() => {
    fetchSnippetsInfo(detailObj)
  }, [detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions), snippetsFilter])

  useEffect(() => {
    if (detailObj?.id) {
      fetchPowerInsights(detailObj as DetailsObjType)
    }
  }, [snippetsFilter, detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions)])

  useEffect(() => {
    if (isTypeInfluencer) {
      fetchFolderList({})
    }
  }, [isTypeInfluencer])

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)

  const moveToFolderhandler = (id: string) => {
    if (!detailObj?.id) return
    const requestBody: MoveTopFolderDataType = {
      action: 'add',
      entities: [{ entity_id: detailObj.id, entity_type: 'WATCHLIST' }],
    }
    moveToFolder({ id, data: requestBody }).then((res) => {
      if (res !== 'error') {
        showToaster({
          iconType: 'success',
          message: 'Successfully added items to your Folder',
        })
      }
      setIsOpenMoveToFolderModal(false)
    })
  }

  const items = useMemo(() => {
    const actions = [
      {
        label: (
          <div className='action-item'>
            <ShareIcon /> Share
          </div>
        ),
        onClick: () => {
          setIsOpenShareModal(true)
        },
        key: 'share-item',
      },
      {
        label: (
          <div className='action-item'>
            <EditIcon />
            Edit
          </div>
        ),
        onClick: () => {
          if (isTypeInfluencer && detailObj?.id) {
            navigate(`/influencers/watchlist_builder/${detailObj.id}`)
            return
          }
          setIsOpenEditModal(true)
        },
        key: 'rename-item',
      },
      {
        label: (
          <div className='action-item'>
            <DeleteIcon />
            Delete
          </div>
        ),
        onClick: () => {
          setIsOpenDeleteModal(true)
        },
        key: 'delete-item',
      },
    ]

    if (isTypeInfluencer) {
      actions.push({
        label: (
          <div className='action-item'>
            <AddFolderIcon />
            Move to Folder
          </div>
        ),
        onClick: () => {
          setIsOpenMoveToFolderModal(true)
        },
        key: 'move-to-folder-item',
      })
    }

    return actions
  }, [])

  const getBackButtonLabel = () => {
    if (navigationInfo.comingFrom === 'bookmark') return ' to Bookmarks'
    else if ((navigationInfo.comingFrom?.length ?? 0) > 0) {
      return ` to ${changeLabelTextBasedOnTheme(
        navigationInfo.comingFrom || '',
        isCorporateCommunicationsTheme,
      )?.toLowerCase()}`
    }
  }

  const createFolderHandler = (value: string) => {
    if (value) {
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
      })
    }
  }

  const deleteHandler = () => {
    detailObj &&
      deleteItem(detailObj as ListSelectedRowData).then((res: any | 'error') => {
        if (res !== 'error') {
          setIsOpenDeleteModal(false)
          resetSnippetFilters()
          navigate('/influencers')
        }
      })
  }

  return (
    <div className='brand-dashboard'>
      <div className='brand-dashboard-stats power-insights-mode-only'>
        <div className={classNames('filters-section', 'powerinsight_mood')}>
          <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
            <ExecutiveSummaryIcon />
            Executive summary
            <ExpandIcon />
          </div>
          <div className='power-insights-options'>
            <span>Power Insights</span>
            {!skipActions && (
              <Dropdown menu={{ items }} placement='bottomLeft' disabled={isTypeInfluencer && !canCreateVectors}>
                <ThreeDotsIcon
                  className={classNames({
                    'three-dot-icon-disabled': isTypeInfluencer && !canCreateVectors,
                  })}
                />
              </Dropdown>
            )}
          </div>
          {/* actions related modals here */}
          {isOpenEditModal && isTypeBookmark && (
            <CreateBookmarkModal
              isModalVisible
              selectedRowsData={[detailObj] as ListSelectedRowData[]}
              closeModal={() => setIsOpenEditModal(false)}
              onSuccessHandler={(bookMarkData: { name: string; description: string }) => {
                if (bookMarkData && detailObj) {
                  detailObj['name'] = bookMarkData.name
                  detailObj['description'] = bookMarkData.description
                }
              }}
            />
          )}

          <DeleteModal
            mode={'bookmark'}
            deleteButtonLoading={false}
            isModalVisible={isOpenDeleteModal && isTypeBookmark}
            setIsModalVisible={setIsOpenDeleteModal}
            deleteFlag={async () => await handleDeleteBookmark(detailObj as ListSelectedRowData)}
            callback={async () => {
              resetSnippetFilters()
              navigate('/investigate/bookmarks')
            }}
          />

          {isOpenShareModal && (isTypeBookmark || isTypeInfluencer) && (
            <ShareReportModal
              endpoint={isTypeBookmark ? 'bookmark' : 'watchlist'}
              reportId={detailObj?.id}
              title={`Share ${
                isTypeBookmark ? 'Bookmark' : isCorporateCommunicationsTheme ? 'Influencer List' : 'Watchlist'
              }`}
              desc='Invite team members'
              secondButtonText='Share'
              icon={null}
              callback={() => {}}
              onClose={() => setIsOpenShareModal(false)}
            />
          )}

          {isOpenMoveToFolderModal && isTypeInfluencer && (
            <MoveToFolderModal
              data={userFolderList.items}
              onClose={() => setIsOpenMoveToFolderModal(false)}
              onConfirm={moveToFolderhandler}
              onCreate={createFolderHandler}
            />
          )}

          {isOpenDeleteModal && isTypeInfluencer && (
            <DeleteConfirmationModal
              title={`Are you sure you want to delete selected watchlist?`}
              onClose={() => setIsOpenDeleteModal(false)}
              onContinue={deleteHandler}
            />
          )}

          {/* action related modals end here */}

          <div className='date-filter'>
            <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={subStore} addCustomDateOption={false} />
            <ButtonImage
              onClick={() => {
                resetSnippetFilters()
                navigate(backToPath[navigationInfo.comingFrom || ''], {
                  state: JSON.parse(
                    JSON.stringify({
                      tabName: navigationInfo.comingFrom,
                      comingFrom: location?.pathname,
                      pageSize: navigationInfo?.pageSize,
                      pageNumber: navigationInfo?.pageNumber,
                    }),
                  ),
                })
              }}
              className='fhc_sc_button bt_dashboard'
              icon={LeftArrowIcon}>
              Back {getBackButtonLabel()}
            </ButtonImage>
          </div>
          {isOpenExecutiveSummaryModal && (
            <DailyExecutiveSummary onClose={closeExecutiveSummaryModal} subStore={subStore} />
          )}
        </div>

        <Row
          gutter={24}
          justify={'start'}
          align={'top'}
          className={classNames('dashboard-widgets', 'powerinsight_dashboard', 'power-insights_main_dashboard')}>
          <Col style={{ width: '226', minWidth: '221px' }}>
            <PowerInsightTabsListVertical
              setSelectedPowerInsightTab={setSelectedPowerInsightTab}
              selectedPowerInsightTab={selectedPowerInsightTab}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row gutter={[24, 24]} className='power-insights-and-snippets'>
              <Col span={16} className='power-insights-container'>
                <Row>
                  <Col span={24} className='power-insights-components'>
                    <Outlet context={{ name: '', subStore: subStore }} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} className='snippet-section-column '>
                <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                  <Row>
                    <Col span={24} className='dashboard-snippet-section'>
                      <SnippetListWithFilters
                        activePowerInsightTab={selectedPowerInsightTab}
                        subStore={subStore}
                        detailObject={detailObj}
                        uniqueScrollId='new_power_insights_id'
                      />
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <BaseModal
        open={isCreatorModalOpen}
        isHeaderBordered
        isFooterBordered
        title='Creator Page'
        onCancel={() => {
          setIsCreatorModalOpen(false)
          setSnippetsFilter(previousInfo.filter)
          fetchSnippetsInfo(previousInfo.item)
        }}
        className='creator-page-modal'
        footer={
          <Button
            type='primary'
            onClick={() => {
              setIsCreatorModalOpen(false)
              setSnippetsFilter(previousInfo.filter)
              fetchSnippetsInfo(previousInfo.item)
            }}>
            Close
          </Button>
        }>
        <div className='creator-page-modal__content'>
          <CreatorDetailsPage
            subStore={subStore}
            isBackButtonEnabled={false}
            parentDetailObj={null}
            previousItem={detailObj}
          />
          <div className='creator-page-modal__content__snippets'>
            <SnippetListWithFilters
              subStore={subStore}
              uniqueScrollId='create-page-modal__id'
              detailObject={detailObj}
            />
            <Spin spinning={isLoadingFeed} className='creator-page-modal__snippets-loading' />
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export default observer(PowerInsights)
