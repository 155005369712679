import { API, tenantsAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { IActiveTenant, ITenant, ITenantConfiguration, ITenantConfigurationDetails, IUsers } from 'models/models'
import { UtilService } from 'services/Util/Util'

export class TenantsStore {
  tenants: ITenant[] = []
  tenantConfiguration: ITenantConfiguration[] = []
  tenantConfigurationDetails: ITenantConfigurationDetails[] = []
  tenantUsers: { data: IUsers[]; total: number; pageSize: number; currentPageNumber: number } = {
    data: [],
    total: 0,
    pageSize: 10,
    currentPageNumber: 0,
  }
  activeTenant: IActiveTenant | null = null
  tenantsPagination: { total: number; pageSize: number; currentPageNumber: number } = {
    total: 0,
    pageSize: 10,
    currentPageNumber: 0,
  }
  userSearchParam: string | null = null
  backURL: string = '/settings'
  customTheme: string = ''
  isCorporateCommunicationsTheme: boolean =
    localStorage.getItem('theme-vertical') === 'corporate_communications' || false
  customThemeLoading: boolean = false
  isAdditionalPlatformsFetching: boolean = false
  hasTwitterAsAdditionalPlatform: boolean = false

  constructor() {
    makeObservable(this, {
      tenants: observable,
      tenantConfiguration: observable,
      tenantConfigurationDetails: observable,
      tenantUsers: observable,
      activeTenant: observable,
      tenantsPagination: observable,
      userSearchParam: observable,
      backURL: observable,
      customTheme: observable,
      isCorporateCommunicationsTheme: observable,
      customThemeLoading: observable,
      isAdditionalPlatformsFetching: observable,
      hasTwitterAsAdditionalPlatform: observable,
      setTenants: action.bound,
      setTenantConfiguration: action.bound,
      setTenantConfigurationDetails: action.bound,
      setTenantUsers: action.bound,
      setActiveTenant: action.bound,
      setActiveTenantPlatforms: action.bound,
      setTenantsPagination: action.bound,
      setUserSearchParam: action.bound,
      setBackURL: action.bound,
      setCustomTheme: action.bound,
      setCustomThemeLoading: action.bound,
      setIfTenantHasTwitterAsAdditonalPlatform: action.bound,
      setIfAdditionalPlatformsFetching: action.bound,
    })
  }

  setTenants = (tenant: ITenant[]) => {
    this.tenants = tenant
  }

  setTenantConfiguration = (configuration: ITenantConfiguration[]) => {
    this.tenantConfiguration = configuration
  }

  setTenantConfigurationDetails = (configuration: ITenantConfigurationDetails[]) => {
    this.tenantConfigurationDetails = configuration
  }

  setTenantUsers = (usersInfo: { data: IUsers[]; total: number; pageSize: number; currentPageNumber: number }) => {
    this.tenantUsers = usersInfo
  }

  setActiveTenant = (tenant: IActiveTenant) => {
    this.activeTenant = tenant
  }

  setActiveTenantPlatforms = (platforms: string[]) => {
    if (this.activeTenant) this.activeTenant = { ...this.activeTenant, platforms }
  }

  setTenantsPagination = (pagination: { total: number; pageSize: number; currentPageNumber: number }) => {
    this.tenantsPagination = pagination
  }

  setUserSearchParam = (value: string) => {
    this.userSearchParam = value
  }

  setBackURL = (url: string) => {
    this.backURL = url
  }

  setCustomTheme = (data: { theme: string; vertical: string }) => {
    this.customTheme = data.theme
    this.isCorporateCommunicationsTheme = data.vertical === 'corporate_communications'
    localStorage.setItem('theme-vertical', data.vertical)
  }

  setCustomThemeLoading = (val: boolean) => {
    this.customThemeLoading = val
  }

  setIfTenantHasTwitterAsAdditonalPlatform = (val: boolean) => {
    this.hasTwitterAsAdditionalPlatform = val
  }
  setIfAdditionalPlatformsFetching = (val: boolean) => {
    this.isAdditionalPlatformsFetching = val
  }

  createTenant = async (payload: ITenant) => {
    try {
      const data = await tenantsAPI.createTenant(payload)
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editTenant = async (id: string, payload: ITenant) => {
    try {
      const data = await tenantsAPI.editTenant(id, payload)
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  deleteTenant = async (id: string) => {
    try {
      await tenantsAPI.deleteTenant(id)
      this.fetchTenants({ pageSize: this.tenantsPagination.pageSize, page: 1 })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editTenantConfiguration = async (id: string, key: string, payload: any) => {
    try {
      const response = await tenantsAPI.updateTenantConfiguration(id, {
        configuration_name: key,
        config_json: payload,
      })
      return response
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTenants = async ({ pageSize, page, searchParam }: { pageSize: number; page: number; searchParam?: string }) => {
    try {
      const newPage = Math.ceil(this.tenantsPagination.total / this.tenantsPagination.pageSize)
      if (newPage < page && this.tenantsPagination.total !== 0) {
        return
      }

      const data = await tenantsAPI.getTenantPagination({
        page,
        pageSize,
        sort: 'name',
        sortOrder: 'asc',
        searchParam,
      })
      this.setTenants(data.items)
      this.setTenantsPagination({
        total: data.total_count,
        pageSize: pageSize,
        currentPageNumber: page,
      })
      return data.items
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTenantDetails = async (id: string) => {
    try {
      const data = await tenantsAPI.getTenantDetails(id)
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTenantConfiguration = async () => {
    try {
      const data = await tenantsAPI.getTenantConfiguration()
      this.setTenantConfiguration(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTenantConfigDetails = async (id: string) => {
    try {
      const data: { configuration_name: string; config_json: any }[] = await tenantsAPI.getTenantDefaultPlatforms(id)
      let platforms = []
      if (data) {
        platforms = data.find((item) => item.configuration_name === 'DEFAULT_PLATFORM_SUBSETS')?.config_json
        platforms = platforms?.default_platforms || []
      }
      this.setActiveTenantPlatforms(platforms)
      this.setTenantConfigurationDetails(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTenantUsers = async ({ id, pageSize, page }: { id: string; pageSize: number; page: number }) => {
    try {
      const newPage = Math.ceil(this.tenantUsers.total / this.tenantUsers.pageSize)
      if (newPage < page && this.tenantUsers.total !== 0) {
        return
      }
      const { data } = await API.get({
        route: 'user',
        page,
        sort: 'email:asc',
        pageSize,
        customProps: { tenant_id: id },
        filter: this.userSearchParam && this.userSearchParam?.length > 0 ? `email:like:${this.userSearchParam}` : '',
        getError: true,
      })

      this.setTenantUsers({ data: data.items, total: data.total_count, pageSize: pageSize, currentPageNumber: page })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getDefaultPlatformsList = async () => {
    try {
      const platformList = await tenantsAPI.getDefaultPlatforms()
      return platformList.data
    } catch (error: any) {
      console.log(error)
    }
  }

  fetchCustomTheme = async () => {
    this.setCustomThemeLoading(true)
    try {
      const res = await tenantsAPI.getCustomTheme()
      this.setCustomTheme(res.data)
      return res.data.theme
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setCustomThemeLoading(false)
    }
  }

  fetchAdditionalPlatforms = async () => {
    this.setIfAdditionalPlatformsFetching(true)
    try {
      const res = await tenantsAPI.getAdditionalPlatforms()
      const additionalPlatforms = res.data.find(
        (config: { configuration_name: string; config_json: { platforms: string[] } }) =>
          config.configuration_name === 'ADDITIONAL_PLATFORMS',
      )?.config_json?.platforms

      if (additionalPlatforms && additionalPlatforms.includes('twitter')) {
        this.setIfTenantHasTwitterAsAdditonalPlatform(true)
      }
      return res.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIfAdditionalPlatformsFetching(false)
    }
  }

  labels = {
    Narrative: 'Horizon Topic',
    Narratives: 'Topic',
    Vectors: 'Topics',
    'My Stuff': 'My Stuff',
    Snippets: 'Snippet',
    Flags: 'Flags',
    Flag: 'Flag',
    Communities: 'Audiences',
    Watchlist: 'Influencers',
    Watchlists: 'Influencers',
    Riskwand: 'Horizon',
    Monitor: 'Reports',
    Reports: 'Briefs',
    Report: 'Briefs',
    Alerts: 'Notifications',
    'Vectors Library': 'Library',
    Assets: 'Brands',
    Asset: 'Brand',
    'Creator Hive': 'Influencer Hive',
    Map: 'Countries',
    'Language Risk': 'Sentiment+',
  }

  changeLabelTextBasedOnTheme = (text: string, isCorporateCommunicationsTheme: boolean) => {
    const lowercaseText = text?.toLowerCase()
    const lowercaseLabels = Object.fromEntries(
      Object.entries(this.labels).map(([key, value]) => [key?.toLowerCase(), value]),
    )
    if (isCorporateCommunicationsTheme && lowercaseText in lowercaseLabels) {
      return lowercaseLabels[lowercaseText as keyof typeof lowercaseLabels]
    }
    return text
  }

  resetStore = () => {
    this.tenants = []
    this.tenantConfiguration = []
    this.tenantConfigurationDetails = []
    this.tenantUsers = {
      data: [],
      total: 0,
      pageSize: 10,
      currentPageNumber: 0,
    }
    this.activeTenant = null
    this.tenantsPagination = {
      total: 0,
      pageSize: 10,
      currentPageNumber: 0,
    }
    this.userSearchParam = ''
    this.backURL = '/settings'
  }
}
