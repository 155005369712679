import { Tooltip } from 'antd'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'

export default function InfoTooltip({ text = '' }) {
  return (
    <Tooltip title={<span>{text}</span>} placement='topRight'>
      <InfoIcon height={15} width={15} style={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}
