import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { SnippetFilterPayload } from 'models/models'

interface Props extends SnippetFilterPayload {
  metric?: 'impression' | 'engagement'
  dimension?: 'platform' | 'sentiment'
}

export const getLineChartData = async ({ query, metric, dimension, sort, conditions }: Props) => {
  const metricQuery = metric ? `&metric=${metric}` : ''
  const dimensionQuery = dimension ? `&dimension=${dimension}` : ''

  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}${metricQuery}${dimensionQuery}`

  const url = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsLineChart}?q=${q}`
  const response = await axios.post(url, { conditions })

  return response
}
