import ReactMarkdown from 'react-markdown'
import React, { useState, useRef, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import ActionButtons from '../ActionButtons/ActionButtons'
import { contentObj } from '../DetailViewSectionsList/DetailViewSectionsList'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import DetailViewEmptyData from '../DetailViewEmptyData/DetailViewEmptyData'
import DetailViewCollectionAdded from '../DetailViewCollectionAdded/DetailViewCollectionAdded'
import styles from './DetailViewSectionDetail.module.scss'
import SuccessBodyModal from 'components/common/Modal/SuccessBodyModal/SuccessBodyModal'
import { ReactComponent as SearchingBar } from 'assets/images/icons/report/searching_bars.svg'
import successGif from 'assets/gifs/reportLoader.gif'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { collectionObj, EntitiesObj, sectionObj, ReportFeedsData } from 'store/report/valueTypes'
import { store } from 'store'
import dayjs from 'dayjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import usePermissions from 'hooks/usePermissions'
import { containsHTML, extractBodyContent } from 'utils/helper'
import { DefaultFilters, defaultSelectedContentItems } from 'pages/Investigate/CreateReport/CreateReport'
import DetailViewReportEdit from '../DetailViewReportEdit/DetailViewReportEdit'
import { ConditionsPayloadType } from 'types/types'

type DetailViewSectionDetailProps = {
  currentIndex?: number
  id?: string
  title?: string
  summary?: any
  summary_prompt?: string
  entities?: EntitiesObj[]
  refined_entities?: EntitiesObj[]
  filters?: DefaultFilters
  status?: string
  content_arr?: contentObj[]
  sectionData?: sectionObj
  editable?: boolean
  editableId?: string
  setEditableId?: ((val: string) => void) | undefined
  selectedTextChunkId?: string
  setSelectedTextChunkId?: ((val: string) => void) | undefined
  textChunkValue?: string
  setTextChunkValue?: ((val: string) => void) | undefined
  startDate?: string
  endDate?: string
  feeds_payload?: ReportFeedsData
  reportDetailsPanel?: boolean
  feedView?: boolean
  selectedSubSection?: string
  setSelectedSubSection?: (sectionName: string) => void
}

const DATE_FORMAT = 'YYYY-MM-DD'

const DetailViewSectionDetail = observer((props: DetailViewSectionDetailProps) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get('instance_id')
  const [isModalVisible, setIsModalVisible] = useState(false) //the edit modal
  const [isSuccessModalVisible, setIsSuccesModalVisible] = useState(false) //the success gif that we get after editing the report
  const [uniqueKey, setUniqueKey] = useState<string>(UtilService.generateUuid())

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  const {
    currentIndex,
    id,
    title,
    summary,
    summary_prompt,
    filters,
    entities,
    refined_entities,
    status,
    sectionData,
    editable = true,
    editableId,
    setEditableId,
    selectedTextChunkId,
    setSelectedTextChunkId,
    textChunkValue,
    setTextChunkValue,
    feeds_payload,
    feedView,
    selectedSubSection = '',
    setSelectedSubSection = () => {},
  } = props
  let textRef = useRef<any>(null)
  const { reportMainViewStore } = store
  const {
    updateReportHandler,
    getOrDeleteReportHandler,
    isLoading,
    reportDetailsEntities,
    setActiveItem,
    fetchSnippets,
    setSnippetsFilter,
    snippetsFilter,
    setSnippets,
    setSnippetsTotal,
    snippetsFilterInfo,
    getCreators,
  } = reportMainViewStore

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target?.innerHTML
    setTextChunkValue?.(val)
  }

  const updateItemHandler = (id: string, value: string) => {
    setEditableId?.(id)
    setTextChunkValue?.(value)
    textRef.current = document.getElementById(id)
    setTimeout(() => {
      textRef.current.focus()

      const textNode = textRef.current.lastChild

      const range = document.createRange()
      range.selectNodeContents(textNode)
      range.collapse(false)

      const sel = window.getSelection()
      sel?.removeAllRanges()
      sel?.addRange(range)
    }, 0)
  }

  const closeCreateAndOpenSuccessModal = (data: any) => {
    updateReportHandler(reportId!, data).then((res) => {
      if (res === 'error') {
        return
      }
      navigate(`/reports/${res.id}?instance_id=${res.instance_id}`, { replace: true })
      setIsSuccesModalVisible(true)
      setIsModalVisible(false)
      setTimeout(closeSuccesModal, 5000)
    })
  }

  const closeSuccesModal = () => {
    setIsSuccesModalVisible(false)
  }

  const collections: { mainItems: collectionObj; optionalItems: collectionObj } = useMemo(() => {
    const mainItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))
    const optionalItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))

    entities?.forEach((entity) => {
      const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
      if (entity.type === 'ASSET') {
        mainItems.brand.push(formattedEntity)
      } else if (entity.type === 'BOOKMARK') {
        mainItems.bookmarks.push(formattedEntity)
      } else if (entity.type === 'NARRATIVE') {
        mainItems.searches.push(formattedEntity)
      } else if (entity.type === 'WATCHLIST') {
        mainItems.influencers.push(formattedEntity)
      } else if (entity.type === 'FLAG') {
        mainItems.flags.push(formattedEntity)
      }
    })
    refined_entities?.forEach((entity) => {
      const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
      if (entity.type === 'ASSET') {
        optionalItems.brand.push(formattedEntity)
      } else if (entity.type === 'BOOKMARK') {
        optionalItems.bookmarks.push(formattedEntity)
      } else if (entity.type === 'NARRATIVE') {
        optionalItems.searches.push(formattedEntity)
      } else if (entity.type === 'WATCHLIST') {
        optionalItems.influencers.push(formattedEntity)
      } else if (entity.type === 'FLAG') {
        optionalItems.flags.push(formattedEntity)
      }
    })
    return { mainItems, optionalItems }
  }, [id])

  //.............handles reports feed...................

  const clearSnippets = () => {
    setSnippets([])
    setSnippetsTotal(0)
  }

  const fetchReportFeedsMethod = () => {
    setSelectedSubSection('')
    if (feeds_payload && feeds_payload?.conditions?.length > 0) {
      setSnippetsFilter({
        ...snippetsFilter,
        days: {
          custom: {
            dateRange: [
              reportDetailsEntities.start_date ? dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT) : '',
              reportDetailsEntities.end_date ? dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT) : '',
            ],
            isApplied: true,
          },
          noOfDays: dayjs(reportDetailsEntities.end_date).diff(dayjs(reportDetailsEntities.end_date), 'days'),
        },
      })
      setActiveItem({
        conditions: feeds_payload.conditions as ConditionsPayloadType,
        name: sectionData?.title || '',
        mode: 'report',
      })
      clearSnippets()
      fetchSnippets()
    } else {
      clearSnippets()
    }
  }

  const summaryTextKey = `${id}_${currentIndex}`
  useEffect(() => {
    if (currentIndex === 0 && feedView) {
      setSelectedTextChunkId?.(summaryTextKey)
      fetchReportFeedsMethod()
    }
  }, [currentIndex, feedView])

  const formattedSummary = sectionData?.is_v2 ? (summary ? JSON.parse(summary) : { sections: [] }) : summary
  const keyMaker = (index: number) => `${id}_sub_${index}`

  const updateSummaryTextHandler = () => {
    if (!isLoading) {
      const requestBody = {
        action: 'EDIT_SUMMARY',
        section_id: id,
        summary_text: textChunkValue,
      }
      getOrDeleteReportHandler('post', reportId!, instanceId!, requestBody).then((res) => {
        if (res !== 'error') {
          setEditableId?.('')
          getOrDeleteReportHandler('get', reportId!, instanceId!)
        }
      })
    }
  }

  const updateSummaryTextHandlerV2 = () => {
    if (!isLoading) {
      const regex = /_sub_(\d+)/
      const indexEdited = editableId?.match(regex)

      if (!indexEdited || !formattedSummary.sections) return

      const newSummarySections = formattedSummary.sections.map((summaryObj: any, index: number) => {
        if (index !== parseInt(indexEdited[1])) return summaryObj
        return { ...summaryObj, summary: textChunkValue }
      })

      const summaryObj: { title: string; sections: any[] } = {
        ...formattedSummary,
        sections: newSummarySections,
      }
      const requestBody = {
        action: 'EDIT_SUMMARY',
        section_id: id,
        summary_text: JSON.stringify(summaryObj),
      }
      getOrDeleteReportHandler('post', reportId!, instanceId!, requestBody).then((res) => {
        if (res !== 'error') {
          setEditableId?.('')
          getOrDeleteReportHandler('get', reportId!, instanceId!)
        }
      })
    }
  }

  const fetchUpdatedFeed = () => {
    if (feedView && (selectedTextChunkId !== summaryTextKey || selectedSubSection)) {
      setSelectedTextChunkId?.(summaryTextKey)
      fetchReportFeedsMethod()
    }
  }

  const selectSubSection = async (event: any, subSection: { snippets: any[]; subheading: string }) => {
    if (!feedView) return
    event.stopPropagation()
    setSelectedSubSection(subSection.subheading)
    const { snippets, creators: creatorsList } = UtilService.snippetTranslator({
      rawSnippets: subSection.snippets,
      isFilterApplied: snippetsFilterInfo.language,
    })

    let creators: string[] = creatorsList
    creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
    setSnippets(snippets)
    setSnippetsTotal(snippets.length)
    await getCreators(creators)
  }

  return (
    <div
      id={id}
      className={classNames(styles.detail_view_section_detail_container, {
        [styles.detail_view_section_detail_container_selected]:
          feedView && selectedTextChunkId === summaryTextKey && !selectedSubSection,
      })}
      onClick={fetchUpdatedFeed}>
      <div className={styles.dvsdc_header}>
        <p className={styles.dvsdc_header_text}>{title}</p>
        {!canReviewAndReceive && (
          <ActionButtons
            hideShareIcon={true}
            hideExtraIcons={true}
            className={styles.dvsdc_buttons}
            hideDeleteIcon={true}
            editHandler={() => setIsModalVisible(true)}
            iconContainerStyles={styles.action_icon_container}
            deleteIconStyle={styles.delete_icon_style}
          />
        )}
      </div>
      {formattedSummary && sectionData?.is_v2 && (
        <>
          {formattedSummary?.sections?.map((subSection: any, index: number) => (
            <div
              className={classNames(styles.dvsdc_section_desc_container, {
                [styles.dvsdc_section_desc_container_editable]: editable,
                [styles.dvsdc_section_desc_container_selected]:
                  subSection.subheading === selectedSubSection && feedView,
              })}
              onClick={(event) => selectSubSection(event, subSection)}>
              <div
                key={keyMaker(index)}
                className={classNames(styles.sdc_chunk, {
                  [styles.sdc_chunk_active_edit]: editableId === keyMaker(index),
                })}>
                <h5>{subSection.subheading}</h5>
                <p
                  key={keyMaker(index)}
                  id={keyMaker(index)}
                  className={classNames(styles.sdc_chunk_text, `pdf-summary-${keyMaker(index)}`)}
                  contentEditable={editableId === keyMaker(index)}
                  suppressContentEditableWarning
                  onInput={handleChange}>
                  {subSection.summary}
                </p>
                {editable && (
                  <>
                    {!canReviewAndReceive && (
                      <div
                        className={styles.sdc_chunk_edit_icon_container}
                        onClick={(event) => {
                          event.stopPropagation()
                          updateItemHandler(keyMaker(index), subSection.summary)
                        }}>
                        <EditIcon />
                      </div>
                    )}
                    <div className={styles.sdc_chunk_buttons_container}>
                      <div>
                        <div className={styles.sdc_chunk_buttons_inner_container}>
                          <ButtonImage
                            className={styles.sdc_c_bc_cancel_button}
                            onClick={(event) => {
                              event.stopPropagation()
                              textRef.current.textContent = subSection.summary
                              setUniqueKey(UtilService.generateUuid())
                              setEditableId?.('')
                            }}>
                            Cancel
                          </ButtonImage>
                          <ButtonImage
                            disabled={textChunkValue === formattedSummary}
                            className={styles.sdc_c_bc_save_button}
                            onClick={(event) => {
                              event.stopPropagation()
                              updateSummaryTextHandlerV2()
                            }}>
                            Save
                          </ButtonImage>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
          {status !== 'QUEUED' && formattedSummary?.sections?.length === 0 && <DetailViewEmptyData />}
          {status !== 'QUEUED' && !formattedSummary.sections && (
            <DetailViewEmptyData title={formattedSummary?.message} desc='' />
          )}
          {status === 'QUEUED' && (
            <DetailViewEmptyData
              icon={SearchingBar}
              title='Summary generation in progress'
              desc='Please wait for a moment; loading usually takes 60 seconds.'
            />
          )}
        </>
      )}
      {!sectionData?.is_v2 && (
        <div
          className={classNames(styles.dvsdc_section_desc_container, {
            [styles.dvsdc_section_desc_container_editable]: editable,
          })}>
          {status === 'QUEUED' ? (
            <DetailViewEmptyData
              icon={SearchingBar}
              title='Summary generation in progress'
              desc='Please wait for a moment; loading usually takes 60 seconds.'
            />
          ) : (
            <>
              {formattedSummary ? (
                <div
                  key={summaryTextKey}
                  className={classNames(styles.sdc_chunk, {
                    [styles.sdc_chunk_active_edit]: editableId === summaryTextKey,
                  })}>
                  <p
                    key={uniqueKey}
                    id={summaryTextKey}
                    className={classNames(styles.sdc_chunk_text, `pdf-summary-${id}`)}
                    contentEditable={editableId === summaryTextKey}
                    suppressContentEditableWarning
                    onInput={handleChange}>
                    {containsHTML(formattedSummary) ? (
                      <span dangerouslySetInnerHTML={{ __html: extractBodyContent(formattedSummary || '') }} />
                    ) : (
                      <ReactMarkdown>{formattedSummary || ''}</ReactMarkdown>
                    )}
                  </p>

                  {editable && (
                    <>
                      {!canReviewAndReceive && (
                        <div
                          className={styles.sdc_chunk_edit_icon_container}
                          onClick={(event) => {
                            event.stopPropagation()
                            updateItemHandler(summaryTextKey, formattedSummary)
                          }}>
                          <EditIcon />
                        </div>
                      )}
                      <div className={styles.sdc_chunk_buttons_container}>
                        <div>
                          <div className={styles.sdc_chunk_buttons_inner_container}>
                            <ButtonImage
                              className={styles.sdc_c_bc_cancel_button}
                              onClick={(event) => {
                                event.stopPropagation()
                                textRef.current.textContent = formattedSummary
                                setUniqueKey(UtilService.generateUuid())
                                setEditableId?.('')
                              }}>
                              Cancel
                            </ButtonImage>
                            <ButtonImage
                              disabled={textChunkValue === formattedSummary}
                              className={styles.sdc_c_bc_save_button}
                              onClick={(event) => {
                                event.stopPropagation()
                                updateSummaryTextHandler()
                              }}>
                              Save
                            </ButtonImage>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <DetailViewEmptyData />
              )}
            </>
          )}
        </div>
      )}

      <DetailViewCollectionAdded
        collections={collections}
        editable={editable}
        filters={filters}
        summaryPrompt={summary_prompt}
      />

      {/* ...........the edit section button in detail ................... */}
      {isModalVisible && id && (
        <DetailViewReportEdit
          sectionId={id}
          onClose={() => setIsModalVisible(false)}
          summary={{ content: formattedSummary, is_v2: sectionData?.is_v2 }}
          closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
        />
      )}
      {isSuccessModalVisible && (
        <SuccessBodyModal
          visibilityController={isSuccessModalVisible}
          gif={successGif}
          heading='Report is updating ...'
          subheading='it can take up to 2 minutes for updating a report'
        />
      )}
    </div>
  )
})

export default DetailViewSectionDetail
