import { ReactNode } from 'react'

import './BrandDataFetching.scss'

const BrandDataFetching = ({
  emptyStateTitle = '',
  emptyStateDescription = '',
  emptyStateIcon = '',
}: {
  emptyStateTitle?: string
  emptyStateDescription?: string
  emptyStateIcon?: JSX.Element | ReactNode
}) => {
  return (
    <div className='data-fetching__main-content brand-snippets-empty-state'>
      {emptyStateIcon}
      <h3>{emptyStateTitle}</h3>
      <p>{emptyStateDescription}</p>
    </div>
  )
}

export default BrandDataFetching
