import classNames from 'classnames'
import { Modal, ModalProps } from 'antd'
import { observer } from 'mobx-react-lite'

import './BaseModal.scss'

type BaseModalTypes = {
  isHeaderBordered?: boolean
  isFooterBordered?: boolean
}

type ModalPropsTypes = BaseModalTypes & ModalProps

const BaseModal: React.FC<ModalPropsTypes> = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      className={classNames(
        'base-modal',
        { [`${props.className}`]: !!props.className },
        { isHeaderBordered: props.isHeaderBordered },
        { isFooterBordered: props.isFooterBordered },
      )}>
      {children}
    </Modal>
  )
}

export default observer(BaseModal)
