import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import { store } from 'store'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { Col, Row, Spin, Typography } from 'antd'
import { ReactComponent as ArrowRight } from 'assets/images/icons/dashboard/arrow-right.svg'
import { RecurrenceOptions } from './steps/Recurrence'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import Review from './steps/Review'
import { CreateReportObj, CreateReportSectionObj, EntitiesObj, ReportType } from 'store/report/valueTypes'
import { Unknown } from 'utils/commonTypes'
import EditRecurrence from './components/EditRecurrence'
import AddEditContentSection from './components/AddEditContentSection'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffectOnce } from 'react-use'
import './CreateReport.scss'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const { Text } = Typography

const convertLocalTimeToUTC = (time: string) => {
  const timeWithDate = dayjs().startOf('day').format('YYYY-MM-DD') + ' ' + time
  const localTime = dayjs(timeWithDate).utc()
  return parseInt(localTime.format('HH'), 10)
}

const CancelBreadCrumb = () => {
  const previousPath = '/reports'
  return (
    <div className='rbc_container'>
      <Link className='rbc_c_link' to={previousPath} replace={true}>
        <CloseIcon className='rbc_c_l_close_icon' />
        Cancel
      </Link>
      <div className='rbc_text'>
        <Text className='rbc_c_create_text'>Edit</Text>
        <ArrowRight />
        <Text className='rbc_c_text'>Report</Text>
      </div>
    </div>
  )
}

const reportType = {
  ADHOC: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

export const defaultSelectedContentItems = {
  brand: [] as Unknown[],
  flags: [] as Unknown[],
  topics: [] as Unknown[],
  searches: [] as Unknown[],
  influencers: [] as Unknown[],
  bookmarks: [] as Unknown[],
}

export type DefaultSelectedContentItems = {
  brand: Unknown[]
  flags: Unknown[]
  topics: Unknown[]
  searches: Unknown[]
  influencers: Unknown[]
  bookmarks: Unknown[]
}

export type DefaultFilters = {
  language: Unknown[]
  platform: Unknown[]
  sentiment: Unknown[]
  source: Unknown[]
}

const defaultSection = {
  id: uuidv4(),
  title: '',
  summary_prompt: '',
  mainItems: defaultSelectedContentItems,
  optionalItems: defaultSelectedContentItems,
  filters: { language: [], platform: [], sentiment: [], source: [] },
}

type SectionItems = {
  mainItems: DefaultSelectedContentItems
  optionalItems: DefaultSelectedContentItems
  filters: DefaultFilters
  isNew?: boolean
}

export type SectionObjType = SectionItems & CreateReportSectionObj

const EditReport = () => {
  const { reportId } = useParams()
  const navigate = useNavigate()
  const { reportMainViewStore, breadcrumbStore } = store
  const { reportDetails, getOrDeleteReportHandler, updateReportHandler, isLoading } = reportMainViewStore
  const { setBreadcrumbData } = breadcrumbStore
  const [reportName, setReportName] = useState('')
  const [sections, setSections] = useState<SectionObjType[]>([])
  const [errors, setErrors] = useState({})
  const [openEditRecurrence, setOpenEditRecurrence] = useState(false)
  const [recurrenceCurrentState, setRecurrenceCurrentState] = useState<any>()
  const [recurrenceOptions, setRecurrenceOptions] = useState<RecurrenceOptions>({
    selectedDay: 'Monday',
    deliveryTime: '12:00 AM',
    recurrenceType: reportType.DAILY as ReportType,
    dateRange: { startDate: new Date(), endDate: new Date() },
  })
  const [sectionToEdit, setSectionToEdit] = useState<SectionObjType | null>()
  const [isCreatingReport, setIsCreatingReport] = useState(false)
  const { state } = useLocation()

  useEffectOnce(() => {
    if (!reportId) return
    getOrDeleteReportHandler('get', reportId)
  })

  useEffect(() => {
    if (reportDetails) {
      const dateRange = reportDetails.report_instance_history[0]
      setReportName(reportDetails.name)
      setRecurrenceOptions({
        selectedDay: reportDetails.weekly_report_day || 'Monday',
        deliveryTime: reportDetails.report_hour || '12:00 AM',
        recurrenceType: reportDetails.report_type,
        dateRange:
          reportDetails.report_type === 'ADHOC'
            ? {
                startDate: dateRange.start_date ? dayjs(dateRange.start_date).toDate() : new Date(),
                endDate: dateRange.end_date ? dayjs(dateRange.end_date).toDate() : new Date(),
              }
            : { startDate: new Date(), endDate: new Date() },
      })

      const formattedSections = reportDetails.sections.map((section) => {
        const mainItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))
        const optionalItems = JSON.parse(JSON.stringify(defaultSelectedContentItems))

        section.entities?.forEach((entity) => {
          const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
          if (entity.type === 'ASSET') {
            mainItems.brand.push(formattedEntity)
          } else if (entity.type === 'BOOKMARK') {
            mainItems.bookmarks.push(formattedEntity)
          } else if (entity.type === 'NARRATIVE') {
            mainItems.searches.push(formattedEntity)
          } else if (entity.type === 'WATCHLIST') {
            mainItems.influencers.push(formattedEntity)
          } else if (entity.type === 'FLAG') {
            mainItems.flags.push(formattedEntity)
          }
        })
        section.refined_entities?.forEach((entity) => {
          const formattedEntity = { ...entity, label: entity.value, value: entity.id, name: entity.value }
          if (entity.type === 'ASSET') {
            optionalItems.brand.push(formattedEntity)
          } else if (entity.type === 'BOOKMARK') {
            optionalItems.bookmarks.push(formattedEntity)
          } else if (entity.type === 'NARRATIVE') {
            optionalItems.searches.push(formattedEntity)
          } else if (entity.type === 'WATCHLIST') {
            optionalItems.influencers.push(formattedEntity)
          } else if (entity.type === 'FLAG') {
            optionalItems.flags.push(formattedEntity)
          }
        })
        return {
          ...section,
          title: section.title || '',
          summary_prompt: section.summary_prompt || '',
          mainItems,
          optionalItems,
          filters: section.filters || ({ language: [], platform: [], sentiment: [], source: [] } as DefaultFilters),
        }
      })
      setSections(formattedSections)
    }
  }, [reportDetails])

  useEffect(() => {
    setBreadcrumbData([{ id: UtilService.generateUuid(), title: <CancelBreadCrumb /> }])
    return () => setBreadcrumbData([{ id: UtilService.generateUuid(), title: 'Reports' }])
  }, [])

  const editReport = () => {
    setIsCreatingReport(true)
    const sectionsFormatted = sections.map(({ title, summary_prompt, mainItems, optionalItems, filters }) => {
      const entities: EntitiesObj[] = []
      const refined_entities: EntitiesObj[] = []

      Object.keys(mainItems).forEach((type) => {
        mainItems[type as keyof typeof mainItems].forEach((item: any) => {
          entities.push({ type: item.type, id: item.id, value: item.name })
        })
      })
      Object.keys(optionalItems).forEach((type) => {
        optionalItems[type as keyof typeof optionalItems].forEach((item: any) => {
          refined_entities.push({ type: item.type, id: item.id, value: item.name })
        })
      })

      return {
        title,
        summary_prompt,
        entities: entities,
        refined_entities: refined_entities,
        filters,
      }
    })

    let body: CreateReportObj = {
      name: reportName,
      report_type: recurrenceOptions.recurrenceType,
      sections: sectionsFormatted,
    }

    body.is_enabled = reportDetails.is_enabled

    if (recurrenceOptions.recurrenceType === 'DAILY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
    }
    if (recurrenceOptions.recurrenceType === 'WEEKLY') {
      body.report_hour = convertLocalTimeToUTC(recurrenceOptions.deliveryTime)
      body.weekly_report_day = recurrenceOptions.selectedDay
    }
    if (recurrenceOptions.recurrenceType === 'ADHOC') {
      body.start_date = dayjs(recurrenceOptions.dateRange.startDate.toISOString()).utc().format('YYYY-MM-DD')
      body.end_date = dayjs(recurrenceOptions.dateRange.endDate.toISOString()).utc().format('YYYY-MM-DD')
    }

    updateReportHandler(reportDetails.id, body)
      .then((res) => {
        if (res === 'error') {
          return
        }
        if (state.comingFrom && res?.instance_id) {
          navigate(`${state.comingFrom}?instance_id=${res.instance_id}`)
        } else if (state.comingFrom) {
          const instanceId = reportDetails.report_instance_history[0]?.instance_id
          navigate(`${state.comingFrom}?instance_id=${instanceId}`)
        } else {
          navigate('/reports')
        }
      })
      .finally(() => {
        setIsCreatingReport(false)
      })
  }

  const goBack = () => {
    if (state.comingFrom) {
      const instanceId = reportDetails.report_instance_history[0]?.instance_id
      navigate(`${state.comingFrom}?instance_id=${instanceId}`)
      return
    }
    navigate('/reports')
  }

  const openEditRecurrenceForm = () => {
    setRecurrenceCurrentState(recurrenceOptions)
    setOpenEditRecurrence(true)
  }

  const closeRecurrenceForm = (save?: boolean) => {
    if (!save && recurrenceCurrentState) {
      setRecurrenceOptions(recurrenceCurrentState)
    }
    setOpenEditRecurrence(false)
  }

  const openSectionToEdit = (section: SectionObjType) => {
    setSectionToEdit(section)
  }

  const closeAddEditSection = (section?: SectionObjType) => {
    if (!section) {
      setSectionToEdit(null)
      return
    }
    const isSectionEdit = sections.findIndex((data) => data.id === section.id)
    if (isSectionEdit > -1) {
      setSections(
        sections.map((data) => {
          if (data.id === section.id) return section
          return data
        }),
      )
    } else {
      const { isNew, ...data } = section
      setSections([...sections, data])
    }
    setSectionToEdit(null)
  }

  const addNewSection = () => {
    setSectionToEdit({ ...defaultSection, id: uuidv4(), isNew: true })
  }

  return (
    <Spin spinning={isLoading}>
      <div className={'create-report'}>
        <div className='content content__editing'>
          <Review
            recurrenceType={recurrenceOptions.recurrenceType}
            sections={sections}
            reportName={reportName}
            setReportName={setReportName}
            errors={errors}
            setErrors={setErrors}
            setOpenEditRecurrence={openEditRecurrenceForm}
            openSectionToEdit={openSectionToEdit}
            addNewSection={addNewSection}
            editMode={Boolean(reportId)}
          />
          <EditRecurrence
            openEditRecurrence={openEditRecurrence}
            closeRecurrenceForm={closeRecurrenceForm}
            recurrenceOptions={recurrenceOptions}
            setRecurrenceOptions={setRecurrenceOptions}
          />
          {sectionToEdit && (
            <AddEditContentSection
              closeAddEditSection={closeAddEditSection}
              isOpenAddNewSection={Boolean(sectionToEdit)}
              sectionToEdit={sectionToEdit}
              recurrenceOptions={recurrenceOptions}
              sections={sections}
            />
          )}
        </div>

        <Row className={'actions-row'}>
          <Col span={6}></Col>
          <Col span={12}>
            <div className='actions'>
              <ButtonImage onClick={goBack} className='cancel-btn'>
                Cancel
              </ButtonImage>
              <ButtonImage onClick={editReport} className='continue-btn' loading={isCreatingReport}>
                Save
              </ButtonImage>
            </div>
          </Col>
          <Col span={6}></Col>
        </Row>
      </div>
    </Spin>
  )
}

export default observer(EditReport)
