import { ReactNode } from 'react'
import { makeAutoObservable } from 'mobx'

type BreadcrumbDataObj = {
  id: string
  title: string | ReactNode
  titleKey?: string
}

export class BreadcrumbStore {
  rootPage: string | JSX.Element = ''
  breadcrumbData: BreadcrumbDataObj[] = []
  constructor() {
    makeAutoObservable(this)
  }

  setBreadcrumbData = (breadcrumbs: BreadcrumbDataObj[]) => {
    this.breadcrumbData = breadcrumbs
  }
  setRootPage = (page: string | JSX.Element) => {
    this.rootPage = page
  }
}
