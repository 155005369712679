import { Pagination as AntDPagination } from 'antd'

import './Pagination.scss'

const Pagination = ({
  pageSize,
  totalCount,
  currentPage,
  onPageChange,
}: {
  pageSize: number
  totalCount: number
  currentPage: number
  onPageChange: (page: number) => void
}) => {
  return (
    <AntDPagination
      showLessItems
      hideOnSinglePage
      total={totalCount}
      pageSize={pageSize}
      showSizeChanger={false}
      onChange={onPageChange}
      className='custom-pagination'
      defaultCurrent={currentPage || 1}
    />
  )
}

export default Pagination
