import { action, makeObservable, observable } from 'mobx'
import {
  AnticipatorySideDetails,
  IAnticipatorySelectedFlagInfo,
  IAttentionFlag,
  IAttentionFlagInfoMenu,
  IFlags,
  INarrativeCategoryAdvanceData,
  INarrativeInfoCategory,
  IRisks,
  ISnippetParams,
  IThreatAttentionFlagsData,
  IThreatAttentionFlagsDataListView,
  IThreatDataPosts,
  IThreatRisksDataListView,
  IThreatTopic,
  SearchConditions,
  SnippetFilterPayload,
  LanguageRisk,
  IElectionRaces,
  IElectionIssues,
  IRiskWandPostsResponse,
} from 'models/models'

import { anticipatoryIntelligence, API, defineNarrativeAPI, monitorAPI, tenantsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import millify from 'millify'
import { LogService } from 'services/Log/Log'
import {
  MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL,
  MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL,
  MONITOR_SNIPPET_MODE_VALYE_BY_LABEL,
  MONITOR_SNIPPET_SORT_VALUE_BY_LABEL,
} from 'settings/settings'
import { MainStore } from 'store/main/main.store'

export class AnticipatoryIntelligenceStore extends MainStore {
  userTenant: string = ''
  chartName: 'chord' | 'list' | '3d' | 'election' = 'chord'
  tenantInfo: IAttentionFlagInfoMenu = {
    created: '',
    domain: '',
    id: '',
    industry: '',
    last_updated: '',
    logo_url: '',
    name: '',
  }
  threatTopics: IThreatTopic = {}
  threatDataPosts: IThreatDataPosts = {}
  activeThreatTopic: string[] = []
  risks: IRisks = {}
  riskThreatDataListView: IThreatRisksDataListView = {}
  riskThreatDataListViewCheck: string[] = []
  attentionFlags: IAttentionFlag = {}
  attentionFlagsThreatDataListView: IThreatAttentionFlagsDataListView = {}
  attentionFlagsThreatDataListViewCheck: string[] = []
  riskAttentionFlagData: IThreatAttentionFlagsData = {}
  electionIssues: IElectionIssues = {}
  electionRaces: IElectionRaces = {}
  electionRacesData: IThreatRisksDataListView = {}
  electionRacesDataCheck: string[] = []
  electionAttentionFlagsData: IThreatRisksDataListView = {}
  electionAttentionFlagsDataCheck: string[] = []
  electionRacesAttentionFlagData: IThreatAttentionFlagsData = {}
  recentRiskFetchedName: string[] = []
  recentAttentionFlagFetchedName: string[] = []
  recentRiskAttentionFlagsFetchedName: string[] = []
  recentElectionIssuesRacesFetchedName: string[] = []
  recentElectionIssuesAttentionFlagsFetchedName: string[] = []
  recentElectionRacesAttentionFlagFetchedName: string[] = []
  selectedFlagListView: IAnticipatorySelectedFlagInfo = { name: '', type: '' }
  isSideDetailsOpen: string = ''
  sideDetails: AnticipatorySideDetails = {
    topic: '',
    category: '',
    riskOrFlagName: '',
    tab: '',
    title: '',
  }
  activeThreatNarrative: INarrativeInfoCategory = {
    description: '',
    narrative_number: 0,
    narrative_id: '',
    flags: {
      is_forcasting_active: false,
      is_language_risk_active: false,
      is_mainstream_coverage_active: false,
      is_narrative_risk_active: false,
      is_negative_sentiment_active: false,
    },
    subItemName: '',
  }
  activeThreatNarrativeTitle: string = ''
  activeThreatNarrativeAdvancedData: INarrativeCategoryAdvanceData = {
    summaryHints: '',
  }
  activeThreatNarrativeConditions: SearchConditions[] = []

  constructor() {
    super()
    makeObservable(this, {
      userTenant: observable,
      chartName: observable,
      tenantInfo: observable,
      threatTopics: observable,
      threatDataPosts: observable,
      activeThreatTopic: observable,
      risks: observable,
      riskThreatDataListView: observable,
      riskThreatDataListViewCheck: observable,
      attentionFlags: observable,
      attentionFlagsThreatDataListView: observable,
      attentionFlagsThreatDataListViewCheck: observable,
      riskAttentionFlagData: observable,
      electionIssues: observable,
      electionRaces: observable,
      electionRacesData: observable,
      electionRacesDataCheck: observable,
      electionAttentionFlagsData: observable,
      electionAttentionFlagsDataCheck: observable,
      electionRacesAttentionFlagData: observable,
      recentRiskFetchedName: observable,
      recentAttentionFlagFetchedName: observable,
      recentRiskAttentionFlagsFetchedName: observable,
      recentElectionIssuesRacesFetchedName: observable,
      recentElectionIssuesAttentionFlagsFetchedName: observable,
      recentElectionRacesAttentionFlagFetchedName: observable,
      selectedFlagListView: observable,
      isSideDetailsOpen: observable,
      sideDetails: observable,
      activeThreatNarrative: observable,
      activeThreatNarrativeTitle: observable,
      activeThreatNarrativeAdvancedData: observable,
      activeThreatNarrativeConditions: observable,
      setUserTenant: action.bound,
      setChartName: action.bound,
      setTenantInfo: action.bound,
      setThreatTopics: action.bound,
      setThreatDataPosts: action.bound,
      setActiveThreatTopic: action.bound,
      setRisks: action.bound,
      setRiskThreatDataListView: action.bound,
      setRiskThreatDataListViewCheck: action.bound,
      setAttentionFlags: action.bound,
      setAttentionFlagsThreatDataListView: action.bound,
      setAttentionFlagsThreatDataListViewCheck: action.bound,
      setElectionIssues: action.bound,
      setElectionRaces: action.bound,
      setElectionRacesDataCheck: action.bound,
      setElectionAttentionFlagsDataCheck: action.bound,
      setPostsCount: action.bound,
      setCategoryPostsCount: action.bound,
      setFlags: action.bound,
      setRecentRiskFetchedName: action.bound,
      setRecentAttentionFlagFetchedName: action.bound,
      setRecentRiskAttentionFlagsFetchedName: action.bound,
      setRecentElectionIssuesRacesFetchedName: action.bound,
      setRecentElectionIssuesAttentionFlagsFetchedName: action.bound,
      setRecentElectionRacesAttentionFlagFetchedName: action.bound,
      setSelectedFlagListView: action.bound,
      setIsSideDetailsOpen: action.bound,
      setSideDetails: action.bound,
      setActiveThreatNarrative: action.bound,
      setActiveThreatNarrativeTitle: action.bound,
      setActiveThreatNarrativeAdvancedData: action.bound,
      setActiveThreatNarrativeConditions: action.bound,
      updateRiskThreatDataListView: action.bound,
      updateRiskThreatDataFlagsListView: action.bound,
      updateAttentionFlagsThreatDataListView: action.bound,
      updateAttentionFlagsThreatDataFlagsListView: action.bound,
      updateRiskAttentionFlagData: action.bound,
      updateRiskAttentionFlagDataFlags: action.bound,
      updateElectionIssuesRacesData: action.bound,
      updateElectionIssuesRacesDataFlags: action.bound,
      updateElectionAttentionFlagsData: action.bound,
      updateElectionAttentionFlagsDataFlags: action.bound,
      updateElectionRacesAttentionFlagData: action.bound,
      updateElectionRacesAttentionFlagDataFlags: action.bound,
    })
  }

  get tableData() {
    return UtilService.getRiskWand(
      this.threatTopics,
      this.attentionFlagsThreatDataListView,
      this.riskThreatDataListView,
      this.threatDataPosts,
      this.selectedFlagListView,
    )
  }

  get getModeConditions() {
    let conditions: SearchConditions[] = []
    if (this.activeThreatNarrative?.conditions && Object.keys(this.activeThreatNarrative?.conditions).length > 0) {
      conditions = [...this.activeThreatNarrative.conditions]
    } else {
      if (typeof this.activeThreatNarrative?.narrative_number === 'number') {
        conditions.push({ narratives: [this.activeThreatNarrative.narrative_number] })
      } else {
        this.activeThreatNarrative?.narrative_number.forEach((item: number) => {
          return conditions.push({ narratives: [item] })
        })
      }
    }
    return conditions
  }

  setUserTenant = (email: string) => {
    this.userTenant = email
  }

  setChartName = (name: 'chord' | 'list' | '3d' | 'election') => {
    this.chartName = name
  }

  setTenantInfo = (info: IAttentionFlagInfoMenu) => {
    this.tenantInfo = info
  }

  setThreatTopics = (topics: IThreatTopic) => {
    this.threatTopics = topics
  }

  setThreatDataPosts = (threat: string, key: string, count: number) => {
    if (this.threatDataPosts[threat])
      this.threatDataPosts[threat][key] = (this.threatDataPosts[threat][key] || 0) + count
    else {
      this.threatDataPosts[threat] = {}
      this.threatDataPosts[threat][key] = (this.threatDataPosts[threat][key] || 0) + count
    }
  }

  setActiveThreatTopic = (name: string[]) => {
    this.activeThreatTopic = name
  }

  setRisks = (risks: IRisks) => {
    this.risks = risks
  }

  setRiskThreatDataListView = (data: IThreatRisksDataListView) => {
    this.riskThreatDataListView = data
  }

  setRiskThreatDataListViewCheck = (data: string, action: 'add' | 'remove') => {
    if (action === 'add') this.riskThreatDataListViewCheck.push(data)
    else {
      const filteredArray = this.riskThreatDataListViewCheck.filter((value) => value !== data)
      this.riskThreatDataListViewCheck = filteredArray
    }
  }

  setAttentionFlags = (items: any) => {
    const flags: any = {}
    const keys = Object.keys(items)
    keys.forEach((key) => {
      const flag = items[key]
      flags[key] = {
        created: flag.created,
        createdBy: flag.created_by,
        description: flag.description,
        displayLabel: flag.display_label,
        id: flag.id,
        insightCondition: flag.insight_condition,
        insightType: flag.insight_type,
        name: flag.name,
        tenantIds: flag.tenant_ids,
        userIds: flag.user_ids,
      }
    })
    this.attentionFlags = flags
  }

  setAttentionFlagsThreatDataListView = (data: IThreatAttentionFlagsDataListView) => {
    this.attentionFlagsThreatDataListView = data
  }

  setAttentionFlagsThreatDataListViewCheck = (data: string, action: 'add' | 'remove') => {
    if (action === 'add') this.attentionFlagsThreatDataListViewCheck.push(data)
    else {
      const filteredArray = this.attentionFlagsThreatDataListViewCheck.filter((value) => value !== data)
      this.attentionFlagsThreatDataListViewCheck = filteredArray
    }
  }

  setElectionIssues = (issues: IElectionIssues) => {
    this.electionIssues = issues
  }

  setElectionRaces = (races: IElectionRaces) => {
    this.electionRaces = races
  }

  setElectionRacesDataCheck = (data: string, action: 'add' | 'remove') => {
    if (action === 'add') this.electionRacesDataCheck.push(data)
    else {
      const filteredArray = this.electionRacesDataCheck.filter((value) => value !== data)
      this.electionRacesDataCheck = filteredArray
    }
  }

  setElectionAttentionFlagsDataCheck = (data: string, action: 'add' | 'remove') => {
    if (action === 'add') this.electionAttentionFlagsDataCheck.push(data)
    else {
      const filteredArray = this.electionAttentionFlagsDataCheck.filter((value) => value !== data)
      this.electionAttentionFlagsDataCheck = filteredArray
    }
  }

  setPostsCount = (topic: string, count: number, metricValues: { metric_value: number }[]) => {
    this.threatTopics[topic].noOfPosts = count
    this.threatTopics[topic].metricValues = metricValues
  }

  setCategoryPostsCount = (
    topic: string,
    category: string,
    count: number,
    metricValues: { metric_value: number }[],
  ) => {
    const allCategories = this.threatTopics[topic].categories
    const index = allCategories.findIndex((el) => el.name === category)
    this.threatTopics[topic].categories[index].noOfPosts = count
    this.threatTopics[topic].categories[index].metricValues = metricValues
  }

  setFlags = (topic: string, category: string, flags: IFlags) => {
    const allCategories = this.threatTopics[topic].categories
    const index = allCategories.findIndex((el) => el.name === category)
    this.threatTopics[topic].categories[index].flags = flags
  }

  setRecentRiskFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentRiskFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentRiskFetchedName = name
  }

  setRecentAttentionFlagFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentAttentionFlagFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentAttentionFlagFetchedName = name
  }

  setRecentRiskAttentionFlagsFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentRiskAttentionFlagsFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentRiskAttentionFlagsFetchedName = name
  }

  setRecentElectionIssuesRacesFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentElectionIssuesRacesFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentElectionIssuesRacesFetchedName = name
  }

  setRecentElectionIssuesAttentionFlagsFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentElectionIssuesAttentionFlagsFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentElectionIssuesAttentionFlagsFetchedName = name
  }

  setRecentElectionRacesAttentionFlagFetchedName = (name: string | string[], state: 'add' | 'remove') => {
    if (state === 'add' && !Array.isArray(name)) this.recentElectionRacesAttentionFlagFetchedName.push(name)
    else if (state === 'remove' && Array.isArray(name)) this.recentElectionRacesAttentionFlagFetchedName = name
  }

  setSelectedFlagListView = (flagInfo: IAnticipatorySelectedFlagInfo) => {
    this.selectedFlagListView = flagInfo
  }

  setIsSideDetailsOpen = (state: string) => {
    this.isSideDetailsOpen = state
  }

  setSideDetails = (info: AnticipatorySideDetails) => {
    this.sideDetails = info
  }

  setActiveThreatNarrative = (item: INarrativeInfoCategory) => {
    this.activeThreatNarrative = item
  }

  setActiveThreatNarrativeTitle = (narrative: string) => (this.activeThreatNarrativeTitle = narrative)

  setActiveThreatNarrativeAdvancedData = (data: any, key: 'summaryHints') => {
    this.activeThreatNarrativeAdvancedData[key] = data
  }

  setActiveThreatNarrativeConditions = (conditions: SearchConditions[]) => {
    this.activeThreatNarrativeConditions = conditions
  }

  updateRiskThreatDataListView = (threatKey: string, categoryKey: string, data: IRiskWandPostsResponse[]) => {
    if (this.riskThreatDataListView[threatKey]) {
      if (!this.riskThreatDataListView[threatKey][categoryKey]) {
        this.riskThreatDataListView[threatKey][categoryKey] = {}
      }
      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name && element?.name.length > 0) {
          const conditions = element?.ids?.split(',')?.map((id: string) => {
            return { narratives: [Number(id)] }
          })
          this.setThreatDataPosts(threatKey, element?.name, element?.total_value)
          this.riskThreatDataListView[threatKey][categoryKey][element?.name] = {
            ...this.riskThreatDataListView[threatKey][categoryKey][element?.name],
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            conditions,
          }
        }
        this.setRiskThreatDataListViewCheck(`${threatKey}_${categoryKey}_${element?.name}`, 'remove')
      })

      this.setRecentRiskFetchedName(`${threatKey},,${categoryKey}`, 'add')
    } else {
      this.riskThreatDataListView[threatKey] = {}
      this.riskThreatDataListView[threatKey][categoryKey] = {}

      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          const conditions = element?.ids?.split(',')?.map((id: string) => {
            return { narratives: [Number(id)] }
          })
          this.setThreatDataPosts(threatKey, element?.name, element?.total_value)
          this.riskThreatDataListView[threatKey][categoryKey][element?.name] = {
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            flags: {
              is_forcasting_active: false,
              is_language_risk_active: false,
              is_mainstream_coverage_active: false,
              is_narrative_risk_active: false,
              is_negative_sentiment_active: false,
            },
            conditions,
          }
        }
        this.setRiskThreatDataListViewCheck(`${threatKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentRiskFetchedName(`${threatKey},,${categoryKey}`, 'add')
    }
  }

  updateRiskThreatDataFlagsListView = (threatKey: string, categoryKey: string, name: string, data: IFlags) => {
    if (this.riskThreatDataListView[threatKey]) {
      if (!this.riskThreatDataListView[threatKey][categoryKey]) {
        this.riskThreatDataListView[threatKey][categoryKey] = {}
      }

      const obj = {
        ...this.riskThreatDataListView[threatKey][categoryKey][name],
      }
      obj.flags = data
      this.riskThreatDataListView[threatKey][categoryKey][name] = obj
    } else {
      this.riskThreatDataListView[threatKey] = {}
      this.riskThreatDataListView[threatKey][categoryKey] = {}

      this.riskThreatDataListView[threatKey][categoryKey][name] = {
        name: name,
        flags: data,
      }
    }
  }

  updateAttentionFlagsThreatDataListView = (threatKey: string, categoryKey: string, data: IRiskWandPostsResponse[]) => {
    if (this.attentionFlagsThreatDataListView[threatKey]) {
      if (!this.attentionFlagsThreatDataListView[threatKey][categoryKey]) {
        this.attentionFlagsThreatDataListView[threatKey][categoryKey] = {}
      }
      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          this.setThreatDataPosts(threatKey, element?.name, element?.total_value)
          this.attentionFlagsThreatDataListView[threatKey][categoryKey][element?.name] = {
            ...this.attentionFlagsThreatDataListView[threatKey][categoryKey][element?.name],
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            conditions: element?.conditions,
          }
        }
        this.setAttentionFlagsThreatDataListViewCheck(`${threatKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentAttentionFlagFetchedName(`${threatKey},,${categoryKey}`, 'add')
    } else {
      this.attentionFlagsThreatDataListView[threatKey] = {}
      this.attentionFlagsThreatDataListView[threatKey][categoryKey] = {}

      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          this.setThreatDataPosts(threatKey, element?.name, element?.total_value)
          this.attentionFlagsThreatDataListView[threatKey][categoryKey][element?.name] = {
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            flags: {
              is_forcasting_active: false,
              is_language_risk_active: false,
              is_mainstream_coverage_active: false,
              is_narrative_risk_active: false,
              is_negative_sentiment_active: false,
            },
            conditions: element?.conditions,
          }
        }
        this.setAttentionFlagsThreatDataListViewCheck(`${threatKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentAttentionFlagFetchedName(`${threatKey},,${categoryKey}`, 'add')
    }
  }

  updateAttentionFlagsThreatDataFlagsListView = (
    threatKey: string,
    categoryKey: string,
    name: string,
    data: IFlags,
  ) => {
    if (this.attentionFlagsThreatDataListView[threatKey]) {
      if (!this.attentionFlagsThreatDataListView[threatKey][categoryKey]) {
        this.attentionFlagsThreatDataListView[threatKey][categoryKey] = {}
      }
      const obj = {
        ...this.attentionFlagsThreatDataListView[threatKey][categoryKey][name],
      }
      obj.flags = data
      this.attentionFlagsThreatDataListView[threatKey][categoryKey][name] = obj
    } else {
      this.attentionFlagsThreatDataListView[threatKey] = {}
      this.attentionFlagsThreatDataListView[threatKey][categoryKey] = {}

      this.attentionFlagsThreatDataListView[threatKey][categoryKey][name] = {
        name: name,
        flags: data,
      }
    }
  }

  updateRiskAttentionFlagData = (key: string, data: any) => {
    if (this.riskAttentionFlagData[key]) {
      if ((data?.name?.length || 0) > 0) {
        this.riskAttentionFlagData[key][data?.name] = {
          ...this.riskAttentionFlagData[key][data?.name],
          name: data?.name,
          noOfPosts: data?.total_value || 0,
          metricValues: data?.data_points,
          conditions: data?.conditions,
        }
        this.setRecentRiskAttentionFlagsFetchedName(`${key},,${data?.name}`, 'add')
      }
    } else {
      this.riskAttentionFlagData[key] = {}
      if ((data?.name?.length || 0) > 0) {
        this.riskAttentionFlagData[key][data?.name] = {
          name: data?.name,
          noOfPosts: data?.total_value || 0,
          metricValues: data?.data_points,
          conditions: data?.conditions,
          flags: {
            is_forcasting_active: false,
            is_language_risk_active: false,
            is_mainstream_coverage_active: false,
            is_narrative_risk_active: false,
            is_negative_sentiment_active: false,
          },
        }
        this.setRecentRiskAttentionFlagsFetchedName(`${key},,${data?.name}`, 'add')
      }
    }
  }

  updateRiskAttentionFlagDataFlags = (key: string, name: string, data: IFlags) => {
    if (this.riskAttentionFlagData[key]) {
      const obj = {
        ...this.riskAttentionFlagData[key][name],
      }
      obj.flags = data
      this.riskAttentionFlagData[key][name] = obj
    } else {
      this.riskAttentionFlagData[key] = {}
      this.riskAttentionFlagData[key][name] = {
        name: name,
        flags: data,
      }
    }
  }

  updateElectionIssuesRacesData = (issueKey: string, categoryKey: string, data: IRiskWandPostsResponse[]) => {
    if (this.electionRacesData[issueKey]) {
      if (!this.electionRacesData[issueKey][categoryKey]) {
        this.electionRacesData[issueKey][categoryKey] = {}
      }
      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name && element?.name.length > 0) {
          this.electionRacesData[issueKey][categoryKey][element?.name] = {
            ...this.electionRacesData[issueKey][categoryKey][element?.name],
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            conditions: element?.conditions,
          }
        }
        this.setElectionRacesDataCheck(`${issueKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentElectionIssuesRacesFetchedName(`${issueKey},,${categoryKey}`, 'add')
    } else {
      this.electionRacesData[issueKey] = {}
      this.electionRacesData[issueKey][categoryKey] = {}

      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          this.electionRacesData[issueKey][categoryKey][element?.name] = {
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            flags: {
              is_forcasting_active: false,
              is_language_risk_active: false,
              is_mainstream_coverage_active: false,
              is_narrative_risk_active: false,
              is_negative_sentiment_active: false,
            },
            conditions: element?.conditions,
          }
        }
        this.setElectionRacesDataCheck(`${issueKey}_${categoryKey}_${element?.name}`, 'remove')
      })

      this.setRecentElectionIssuesRacesFetchedName(`${issueKey},,${categoryKey}`, 'add')
    }
  }

  updateElectionIssuesRacesDataFlags = (issueKey: string, categoryKey: string, name: string, data: IFlags) => {
    if (this.electionRacesData[issueKey]) {
      if (!this.electionRacesData[issueKey][categoryKey]) {
        this.electionRacesData[issueKey][categoryKey] = {}
      }

      const obj = {
        ...this.electionRacesData[issueKey][categoryKey][name],
      }
      obj.flags = data
      this.electionRacesData[issueKey][categoryKey][name] = obj
    } else {
      this.electionRacesData[issueKey] = {}
      this.electionRacesData[issueKey][categoryKey] = {}

      this.electionRacesData[issueKey][categoryKey][name] = {
        name: name,
        flags: data,
      }
    }
  }

  updateElectionAttentionFlagsData = (issueKey: string, categoryKey: string, data: IRiskWandPostsResponse[]) => {
    if (this.electionAttentionFlagsData[issueKey]) {
      if (!this.electionAttentionFlagsData[issueKey][categoryKey]) {
        this.electionAttentionFlagsData[issueKey][categoryKey] = {}
      }
      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          this.electionAttentionFlagsData[issueKey][categoryKey][element?.name] = {
            ...this.electionAttentionFlagsData[issueKey][categoryKey][element?.name],
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            conditions: element?.conditions,
          }
        }
        this.setElectionAttentionFlagsDataCheck(`${issueKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentElectionIssuesAttentionFlagsFetchedName(`${issueKey},,${categoryKey}`, 'add')
    } else {
      this.electionAttentionFlagsData[issueKey] = {}
      this.electionAttentionFlagsData[issueKey][categoryKey] = {}

      data?.forEach((element: IRiskWandPostsResponse) => {
        if (element?.name.length > 0) {
          this.electionAttentionFlagsData[issueKey][categoryKey][element?.name] = {
            name: element?.name,
            noOfPosts: element?.total_value || 0,
            metricValues: element?.data_points,
            flags: {
              is_forcasting_active: false,
              is_language_risk_active: false,
              is_mainstream_coverage_active: false,
              is_narrative_risk_active: false,
              is_negative_sentiment_active: false,
            },
            conditions: element?.conditions,
          }
        }
        this.setElectionAttentionFlagsDataCheck(`${issueKey}_${categoryKey}_${element?.name}`, 'remove')
      })
      this.setRecentElectionIssuesAttentionFlagsFetchedName(`${issueKey},,${categoryKey}`, 'add')
    }
  }

  updateElectionAttentionFlagsDataFlags = (issueKey: string, categoryKey: string, name: string, data: IFlags) => {
    if (this.electionAttentionFlagsData[issueKey]) {
      if (!this.electionAttentionFlagsData[issueKey][categoryKey]) {
        this.electionAttentionFlagsData[issueKey][categoryKey] = {}
      }

      const obj = {
        ...this.electionAttentionFlagsData[issueKey][categoryKey][name],
      }
      obj.flags = data
      this.electionAttentionFlagsData[issueKey][categoryKey][name] = obj
    } else {
      this.electionAttentionFlagsData[issueKey] = {}
      this.electionAttentionFlagsData[issueKey][categoryKey] = {}

      this.electionAttentionFlagsData[issueKey][categoryKey][name] = {
        name: name,
        flags: data,
      }
    }
  }

  updateElectionRacesAttentionFlagData = (key: string, data: any) => {
    if (this.electionRacesAttentionFlagData[key]) {
      if ((data?.name?.length || 0) > 0) {
        this.electionRacesAttentionFlagData[key][data?.name] = {
          ...this.electionRacesAttentionFlagData[key][data?.name],
          name: data?.name,
          noOfPosts: data?.total_value || 0,
          metricValues: data?.data_points,
          conditions: data?.conditions,
        }
        this.setRecentElectionRacesAttentionFlagFetchedName(`${key},,${data?.name}`, 'add')
      }
    } else {
      this.electionRacesAttentionFlagData[key] = {}
      if ((data?.name?.length || 0) > 0) {
        this.electionRacesAttentionFlagData[key][data?.name] = {
          name: data?.name,
          noOfPosts: data?.total_value || 0,
          metricValues: data?.data_points,
          conditions: data?.conditions,
          flags: {
            is_forcasting_active: false,
            is_language_risk_active: false,
            is_mainstream_coverage_active: false,
            is_narrative_risk_active: false,
            is_negative_sentiment_active: false,
          },
        }
        this.setRecentElectionRacesAttentionFlagFetchedName(`${key},,${data?.name}`, 'add')
      }
    }
  }

  updateElectionRacesAttentionFlagDataFlags = (key: string, name: string, data: IFlags) => {
    if (this.electionRacesAttentionFlagData[key]) {
      const obj = {
        ...this.electionRacesAttentionFlagData[key][name],
      }
      obj.flags = data
      this.electionRacesAttentionFlagData[key][name] = obj
    } else {
      this.electionRacesAttentionFlagData[key] = {}
      this.electionRacesAttentionFlagData[key][name] = {
        name: name,
        flags: data,
      }
    }
  }

  getCategoriesTableData = (activeThreatTopic: string) => {
    if (this.attentionFlagsThreatDataListView && this.riskThreatDataListView) {
      let data = UtilService.getRiskWandCategoriesTableData(
        this.threatTopics[activeThreatTopic].categories,
        this.attentionFlagsThreatDataListView[activeThreatTopic],
        this.riskThreatDataListView[activeThreatTopic],
        this.selectedFlagListView,
        activeThreatTopic,
      )
      data = data.filter((item) => Object.keys(item).length > 0)
      return data
    } else return []
  }

  fetchTenantInfo = async (id: string) => {
    const data = await tenantsAPI.getTenantDetails(id)
    this.setTenantInfo(data)
    return data
  }

  fetchThreatTopics = async (isDetailSection: boolean = false) => {
    try {
      const topics = await anticipatoryIntelligence.getThreatTopics()
      if (topics) {
        this.setThreatTopics(topics)
        if (!isDetailSection) this.fetchThreatDetails()
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchRisks = async () => {
    try {
      const risks = await anticipatoryIntelligence.getRisks()
      if (risks) {
        this.setRisks(risks)
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchAttentionFlags = async () => {
    try {
      const attentionFlags = await anticipatoryIntelligence.getScorecardsAttentionFlags()
      if (attentionFlags) {
        this.setAttentionFlags(attentionFlags)
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchElectionIssues = async () => {
    try {
      const issues = await anticipatoryIntelligence.getIssues()
      if (issues) {
        this.setElectionIssues(issues)
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchElectionRaces = async () => {
    try {
      const races = await anticipatoryIntelligence.getRaces()
      if (races) {
        this.setElectionRaces(races)
      }
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchThreatDetails = async () => {
    const topicsName = Object.keys(this.threatTopics)
    for (const index in topicsName) {
      let topic = this.threatTopics[topicsName[index]]
      const categoryNarrativeIds = topic.categories.map((category) => category.narrative_number)

      this.fetchNoOfPosts([], topic.label, true, [{ narratives: categoryNarrativeIds }]).then((response) => {
        this.setPostsCount(topicsName[index], response?.total_value || 0, response?.data_points)
      })
      for (const category in topic.categories) {
        this.fetchNoOfPosts([topic.categories[category].narrative_number]).then((response) => {
          this.setCategoryPostsCount(
            topicsName[index],
            topic.categories[category].name,
            response?.total_value || 0,
            response?.data_points,
          )
        })

        this.fetchFlags([topic.categories[category].narrative_number]).then((response) => {
          if (response) {
            this.setFlags(topicsName[index], topic.categories[category].name, response?.data)
          }
        })
      }
    }
  }

  fetchRisksDetailsListView = async (isDetailSection: boolean = false) => {
    const risks = Object.keys(this.risks)
    const topics = Object.keys(this.threatTopics)

    for (const topicIndex in topics) {
      let topic = this.threatTopics[topics[topicIndex]]
      for (const categoryIndex in topic.categories) {
        let category = topic.categories[categoryIndex]
        const promises = []

        for (const index in risks) {
          let risk = this.risks[risks[index]]
          promises.push(getPosts(this.fetchNoOfPosts, category.narrative_number, risk.narrative_number, risks[index]))

          if (!this.riskThreatDataListViewCheck.length) this.setRiskThreatDataListViewCheck('start', 'add')

          this.setRiskThreatDataListViewCheck(`${topics[topicIndex]}_${category.name}_${risks[index]}`, 'add')

          if (!isDetailSection) {
            this.fetchFlags([risk.narrative_number, category.narrative_number]).then((response) => {
              if (response) {
                this.updateRiskThreatDataFlagsListView(
                  `${topics[topicIndex]}`,
                  category.name,
                  risks[index],
                  response?.data,
                )
              }
            })
          }
        }

        Promise.all(promises).then((response) => {
          this.updateRiskThreatDataListView(topics[topicIndex], category.name, response)
        })
      }
    }

    function getPosts(fetchNoOfPosts: any, narrativeId1: number, narrativeId2: number, name: string) {
      return fetchNoOfPosts([narrativeId1, narrativeId2], name)
    }
  }

  fetchAttentionFlagsDetailsListView = async (isDetailSection: boolean = false) => {
    const attentionFlags = Object.keys(this.attentionFlags)
    const topics = Object.keys(this.threatTopics)

    for (const topicIndex in topics) {
      let topic = this.threatTopics[topics[topicIndex]]
      for (const categoryIndex in topic.categories) {
        let category = topic.categories[categoryIndex]
        const promises = []

        for (const index in attentionFlags) {
          let attentionFlag = this.attentionFlags[attentionFlags[index]]
          if (attentionFlag && Object.keys(attentionFlag).length > 0 && attentionFlag?.insightCondition?.length > 0) {
            let ids: any = attentionFlag?.insightCondition?.toString()
            const idPromises = []
            let conditions: any = []

            if (attentionFlag.insightType !== 'SEARCH_TERM') {
              const modeData: any =
                attentionFlag.insightType === 'NARRATIVES'
                  ? { mode: 'narrative', name: 'narratives', key: 'narrative_number' }
                  : { mode: 'community', name: 'communities', key: 'community_number' }

              ids = ids.replace('{', '')
              ids = ids.replace('}', '')
              ids = ids.split(',')

              for (const index in ids) {
                const id = ids[index]

                const obj: any = {}
                obj[id] = ''
                idPromises.push(API.get({ route: modeData.mode, id }))
              }
              let numbers = await Promise.all(idPromises).then((response) => {
                return response.map(({ data: item }) => {
                  const obj: any = {}
                  const id = item?.id
                  obj[id] = item[modeData.key]
                  return item[modeData.key]
                })
              })

              let obj: any = {}
              numbers = numbers.filter((item) => item !== '')
              obj[modeData.name] = [...numbers]
              conditions.push(obj)
            } else
              conditions.push({
                keywords_expression: ids,
              })

            conditions.push({ narratives: [category.narrative_number.toString()] })
            promises.push(getPosts(this.fetchNoOfPosts, attentionFlags[index], conditions))
            if (!this.attentionFlagsThreatDataListViewCheck.length)
              this.setAttentionFlagsThreatDataListViewCheck('start', 'add')

            this.setAttentionFlagsThreatDataListViewCheck(
              `${topics[topicIndex]}_${category.name}_${attentionFlags[index]}`,
              'add',
            )

            if (!isDetailSection) {
              this.fetchFlags([0, category.narrative_number], true, conditions).then((response) => {
                if (response) {
                  this.updateAttentionFlagsThreatDataFlagsListView(
                    `${topics[topicIndex]}`,
                    category.name,
                    attentionFlags[index],
                    response,
                  )
                }
              })
            }
          }
        }

        Promise.all(promises).then((response) => {
          this.updateAttentionFlagsThreatDataListView(topics[topicIndex], category.name, response)
        })
      }
    }

    function getPosts(fetchNoOfPosts: any, name?: string, conditions?: { [x: string]: string }) {
      return fetchNoOfPosts([], name, true, conditions)
    }
  }

  fetchRiskAttentionFlagsDetails = async (isDetailSection: boolean = false) => {
    const attentionFlags = Object.keys(this.attentionFlags)
    const risks = Object.keys(this.risks)

    for (const index in attentionFlags) {
      let attentionFlag = this.attentionFlags[attentionFlags[index]]

      for (const riskIndex in risks) {
        let risk = this.risks[risks[riskIndex]]
        if (attentionFlag && Object.keys(attentionFlag).length > 0 && attentionFlag?.insightCondition?.length > 0) {
          let ids: any = attentionFlag?.insightCondition?.toString()
          const idPromises = []
          let conditions: any = []

          if (attentionFlag.insightType !== 'SEARCH_TERM') {
            const modeData: any =
              attentionFlag.insightType === 'NARRATIVES'
                ? { mode: 'narrative', name: 'narratives', key: 'narrative_number' }
                : { mode: 'community', name: 'communities', key: 'community_number' }

            ids = ids.replace('{', '')
            ids = ids.replace('}', '')
            ids = ids.split(',')

            for (const idIndex in ids) {
              const id = ids[idIndex]
              const obj: any = {}
              obj[id] = ''
              idPromises.push(API.get({ route: modeData.mode, id }))
            }

            let numbers = await Promise.all(idPromises).then((response) => {
              return response.map(({ data: item }) => {
                const obj: any = {}
                const id = item.id
                obj[id] = item[modeData.key]
                return item[modeData.key]
              })
            })
            let obj: any = {}

            numbers = numbers.filter((item) => item !== '')
            obj[modeData.name] = [...numbers]
            conditions.push(obj)
          } else conditions.push({ keywords_expression: ids })

          conditions.push({ narratives: [risk.narrative_number.toString()] })

          this.fetchNoOfPosts([], risks[riskIndex], true, conditions).then((response) => {
            this.updateRiskAttentionFlagData(`${attentionFlags[index]}`, response)
          })
          if (!isDetailSection) {
            this.fetchFlags([0, risk.narrative_number], true, conditions).then((response) => {
              if (response) {
                this.updateRiskAttentionFlagDataFlags(`${attentionFlags[index]}`, risks[riskIndex], response)
              }
            })
          }
        }
      }
    }
  }

  fetchIssuesRacesDetails = async (isDetailSection: boolean = false) => {
    const races = Object.keys(this.electionRaces)
    const issues = Object.keys(this.electionIssues)

    for (const issueIndex of issues) {
      let issue = this.electionIssues[issueIndex]

      for (const categoryIndex of issue.categories) {
        let category = categoryIndex
        const promises = []

        for (const index of races) {
          let race = this.electionRaces[index]
          promises.push(
            getPosts(this.fetchNoOfPosts, index, [
              { narratives: race.narrative_numbers },
              { narratives: [category.narrative_number] },
            ]),
          )

          if (!this.electionRacesDataCheck.length) this.setElectionRacesDataCheck('start', 'add')

          this.setElectionRacesDataCheck(`${issueIndex}_${category.name}_${index}`, 'add')

          if (!isDetailSection) {
            this.fetchFlags([0, category.narrative_number], true, [
              { narratives: race.narrative_numbers },
              { narratives: [category.narrative_number] },
            ]).then((response: any) => {
              if (response) {
                this.updateElectionIssuesRacesDataFlags(`${issueIndex}`, category.name, index, response)
              }
            })
          }
        }

        Promise.all(promises).then((response) => {
          this.updateElectionIssuesRacesData(issueIndex, category.name, response)
        })
      }
    }

    function getPosts(fetchNoOfPosts: any, name?: string, conditions?: SearchConditions[]) {
      return fetchNoOfPosts([], name, true, conditions)
    }
  }

  fetchIssuesAttentionFlagsDetails = async (isDetailSection: boolean = false) => {
    const attentionFlags = Object.keys(this.attentionFlags)
    const issues = Object.keys(this.electionIssues)

    for (const issueIndex of issues) {
      let issue = this.electionIssues[issueIndex]

      for (const categoryIndex of issue.categories) {
        let category = categoryIndex
        const promises = []

        for (const index of attentionFlags) {
          let attentionFlag = this.attentionFlags[index]

          if (attentionFlag && Object.keys(attentionFlag).length > 0 && attentionFlag?.insightCondition?.length > 0) {
            let ids: any = attentionFlag?.insightCondition?.toString()
            const idPromises = []
            let conditions: any = []

            if (attentionFlag.insightType !== 'SEARCH_TERM') {
              const modeData: any =
                attentionFlag.insightType === 'NARRATIVES'
                  ? { mode: 'narrative', name: 'narratives', key: 'narrative_number' }
                  : { mode: 'community', name: 'communities', key: 'community_number' }

              ids = ids.replace('{', '')
              ids = ids.replace('}', '')
              ids = ids.split(',')

              for (const index in ids) {
                const id = ids[index]

                const obj: any = {}
                obj[id] = ''
                idPromises.push(API.get({ route: modeData.mode, id }))
              }
              let numbers = await Promise.all(idPromises).then((response) => {
                return response.map(({ data: item }) => {
                  const obj: any = {}
                  const id = item.id
                  obj[id] = item[modeData.key]
                  return item[modeData.key]
                })
              })

              let obj: any = {}
              numbers = numbers.filter((item) => item !== '')
              obj[modeData.name] = [...numbers]
              conditions.push(obj)
            } else
              conditions.push({
                keywords_expression: ids,
              })

            conditions.push({ narratives: [category.narrative_number.toString()] })
            promises.push(getPosts(this.fetchNoOfPosts, index, conditions))

            if (!this.electionAttentionFlagsDataCheck.length) this.setElectionAttentionFlagsDataCheck('start', 'add')

            this.setElectionAttentionFlagsDataCheck(`${issueIndex}_${category.name}_${index}`, 'add')

            if (!isDetailSection) {
              this.fetchFlags([0, category.narrative_number], true, conditions).then((response) => {
                if (response) {
                  this.updateElectionAttentionFlagsDataFlags(`${issueIndex}`, category.name, index, response)
                }
              })
            }
          }
        }

        Promise.all(promises).then((response) => {
          this.updateElectionAttentionFlagsData(issueIndex, category.name, response)
        })
      }
    }

    function getPosts(fetchNoOfPosts: any, name?: string, conditions?: SearchConditions[]) {
      return fetchNoOfPosts([], name, true, conditions)
    }
  }

  fetchRacesAttentionFlagsDetails = async (isDetailSection: boolean = false) => {
    const attentionFlags = Object.keys(this.attentionFlags)
    const races = Object.keys(this.electionRaces)

    for (const index of attentionFlags) {
      let attentionFlag = this.attentionFlags[index]

      for (const raceIndex of races) {
        let race = this.electionRaces[raceIndex]
        if (attentionFlag && Object.keys(attentionFlag).length > 0 && attentionFlag?.insightCondition?.length > 0) {
          let ids: any = attentionFlag?.insightCondition?.toString()
          const idPromises = []
          let conditions: any = []

          if (attentionFlag.insightType !== 'SEARCH_TERM') {
            const modeData: any =
              attentionFlag.insightType === 'NARRATIVES'
                ? { mode: 'narrative', name: 'narratives', key: 'narrative_number' }
                : { mode: 'community', name: 'communities', key: 'community_number' }

            ids = ids.replace('{', '')
            ids = ids.replace('}', '')
            ids = ids.split(',')

            for (const idIndex in ids) {
              const id = ids[idIndex]
              const obj: any = {}
              obj[id] = ''
              idPromises.push(API.get({ route: modeData.mode, id }))
            }

            let numbers = await Promise.all(idPromises).then((response) => {
              return response.map(({ data: item }) => {
                const obj: any = {}
                const id = item.id
                obj[id] = item[modeData.key]
                return item[modeData.key]
              })
            })
            let obj: any = {}

            numbers = numbers.filter((item) => item !== '')
            obj[modeData.name] = [...numbers]
            conditions.push(obj)
          } else conditions.push({ keywords_expression: ids })

          conditions.push({ narratives: race.narrative_numbers })

          this.fetchNoOfPosts([], raceIndex, true, conditions).then((response) => {
            this.updateElectionRacesAttentionFlagData(`${index}`, response)
          })
          if (!isDetailSection) {
            this.fetchFlags([0, race.narrative_number], true, conditions).then((response) => {
              if (response) {
                this.updateElectionRacesAttentionFlagDataFlags(`${index}`, raceIndex, response)
              }
            })
          }
        }
      }
    }
  }

  fetchNoOfPosts = async (id: number[], name?: string, isCloseRange?: boolean, conditions?: SearchConditions[]) => {
    try {
      const payload = await this.snippetFilterPayload()
      const startDate = this.getDate.startDate
      const endDate = this.getDate.endDate
      if (isCloseRange) {
        const response = await anticipatoryIntelligence.getPostsCount({
          ...payload,
          name: name || '',
          conditions: conditions || [],
        })
        return response
      } else {
        const response = await defineNarrativeAPI.getNarrativeCount({
          narrativeId: id,
          startDate,
          endDate,
          name: name || '',
        })
        return response
      }
    } catch (error: any) {
      console.log(error)

      // const response = error.response
      // UtilService.openError({
      //   requestId: response?.data?.request_id || '',
      //   statusCode: response?.status || 400,
      //   message: response?.data?.err_msg || '',
      // })
    }
  }

  fetchFlags = async (id: number[], isCloseRange?: boolean, conditions?: SearchConditions[]) => {
    try {
      const startDate = this.getDate.startDate
      const endDate = this.getDate.endDate
      if (isCloseRange) {
        const response = await anticipatoryIntelligence.getAttentionFlags({
          startDate,
          endDate,
          conditions: conditions || {},
        })
        return response
      } else {
        const response = await API.get({
          customAPI: process.env.REACT_APP_SEARCH_API,
          route: 'personnelFlags',
          customProps: { narrative_ids: id, start_date: startDate, end_date: endDate },
          isPromise: true,
        })
        return response
      }
    } catch (error: any) {
      console.log(error)

      // const response = error.response
      // UtilService.openError({
      //   requestId: response?.data?.request_id || '',
      //   statusCode: response?.status || 400,
      //   message: response?.data?.err_msg || '',
      // })
    }
  }

  fetchSummary = async () => {
    try {
      const payload = await this.snippetFilterPayload()

      let summaryHints: string[] = []
      summaryHints.push(this.sideDetails.category, this.sideDetails.riskOrFlagName)
      this.setActiveThreatNarrativeAdvancedData('', 'summaryHints')

      let info: any = {}
      if (this.sideDetails.tab === 'close-range') {
        if (!this.tenantInfo.name.length) {
          info = await this.fetchTenantInfo(this.userTenant || '')
          summaryHints.push(info?.name)
        } else summaryHints.push(this.tenantInfo.name)
      }

      const data = await anticipatoryIntelligence.getSummary({
        ...payload,
        summaryHints,
      })
      this.setActiveThreatNarrativeAdvancedData(data.summary_text, 'summaryHints')
    } catch (error) {
      LogService.error({ error: error, message: 'Error in fetch summary method.' })
    }
  }
  snippetFilterPayload = async (skip?: string) => {
    const payload: SnippetFilterPayload = {
      page: 1,
      per_page: 1,
      query: {
        date: '',
        platform: '',
        sentiment: '',
        source: '',
        risk: '',
        translationLanguage: '',
        people: '',
        politician: '',
        company: '',
        country: '',
        language: '',
        community: '',
        languageRisk: '',
      },
      sort: '',
      powerInsightSort: '',
      conditions: [],
    }

    const categoryLookup = {
      All: 'a',
      Negative: 'a',
      Obscene: 'obscene_language',
      'Identity Hate': 'hate_language',
      Toxic: 'toxic_language',
      Threat: 'threat_language',
      Source: 'a',
      /** A graph cannot be one of the following categories */
      NarrativeAll: '',
      NarrativeMainstream: '',
      NarrativeRisk: '',
      NarrativeNegative: '',
    }
    const startDate = this.getDate.startDate
    const endDate = this.getDate.endDate

    payload.query.date = `start_date:gte:${startDate},end_date:lte:${endDate}`

    let languageRisk: LanguageRisk = categoryLookup[this.selectedExecutiveCategory] as LanguageRisk

    if (languageRisk === 'a') {
      languageRisk = null
    }

    payload.query.languageRisk = languageRisk ? `,lang_risk:in:[${languageRisk}]` : ''

    payload.query.platform = this.snippetsFilter?.filter_platform
      ? `,platform:in:${JSON.stringify(this.snippetsFilter?.filter_platform)}`.replaceAll('"', '')
      : ''
    payload.query.sentiment = this.snippetsFilter?.filter_sentiment?.length
      ? this.snippetsFilter?.filter_sentiment
        ? `,sentiment:in:${JSON.stringify(this.snippetsFilter?.filter_sentiment)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.source = this.snippetsFilter?.snippet_source?.length
      ? this.snippetsFilter?.snippet_source
        ? `,snippet_source:in:${JSON.stringify(this.snippetsFilter?.snippet_source)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.risk =
      this.snippetsFilter?.filter_risk?.length === 2
        ? `,risk_score:gte:${this.snippetsFilter?.filter_risk[0]},risk_score:lte:${this.snippetsFilter?.filter_risk[1]}`
        : ''
    payload.query.people = this.snippetsFilter?.people ? `,people:in:[${this.snippetsFilter?.people}]` : ''
    payload.query.politician = this.snippetsFilter?.politician
      ? `,politician:in:[${this.snippetsFilter?.politician}]`
      : ''
    payload.query.company = this.snippetsFilter?.company ? `,company:in:[${this.snippetsFilter?.company}]` : ''
    payload.query.country = this.snippetsFilter?.country ? `,country:in:[${this.snippetsFilter?.country}]` : ''
    payload.query.language = this.snippetsFilter?.filter_language?.length
      ? `,lang:in:[${this.snippetsFilter.filter_language}]`
      : ''
    payload.query.community = this.snippetsFilter?.community
      ? `,community_number:eq:${this.snippetsFilter?.community}`
      : ''
    payload.query.translationLanguage = this.snippetsFilter?.lang?.length ? `&lang=${this.snippetsFilter?.lang}` : ''

    payload.sort = this.snippetsFilter
      ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[this.snippetsFilter.sort]}:${
          MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[this.snippetsFilter.sort_mode]
        }`
      : ''

    payload.powerInsightSort = this.powerInsightsSortFilter
      ? `${MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL[this.powerInsightsSortFilter.sort]}:${
          MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL[this.powerInsightsSortFilter.sort_mode]
        }`
      : ''

    payload.conditions = [...payload.conditions, ...this.getModeConditions]

    if (this.snippetsFilter?.filter_keyword && this.snippetsFilter?.filter_keyword?.length > 0) {
      payload.conditions.push({
        keywords_expression: this.snippetsFilter?.filter_keyword,
      })
    }

    if (this.snippetsFilter?.watchlist && this.snippetsFilter?.watchlist?.length > 0) {
      payload.conditions.push({
        channel_urls: this.snippetsFilter?.watchlist,
      })
    }

    if (skip !== 'flags') {
      const filterData = this.snippetsFilter.attentionFlags
      if (filterData && Object.keys(filterData).length > 0) {
        const allFlags = Object.keys(filterData)
        const promises = []
        for (const element in allFlags) {
          const key = allFlags[element]
          const nameKey = allFlags[element].split(',')[1]
          const ids = filterData[key]

          if (nameKey !== 'keywords_expression' && Array.isArray(ids)) {
            const modeData: any =
              nameKey === 'narratives'
                ? { mode: 'narrative', key: 'narrative_number' }
                : { mode: 'community', key: 'community_number' }

            for (const idKey in ids) {
              const id = ids[idKey]
              const obj: any = {}
              obj[id] = ''
              promises.push(API.get({ route: modeData.mode, id }))
            }

            let responses = await Promise.all(promises)
            responses = responses.map(({ data: item }) => {
              if (item && item[modeData.key]) {
                const obj: any = {}
                const id = item.id
                obj[id] = item[modeData.key]
                return item[modeData.key]
              } else return ''
            })

            let obj: any = {}
            responses = responses.filter((item) => item && item !== '')
            obj[nameKey] = [...responses]
            if (responses.length > 0) payload.conditions.push(obj)
          } else {
            payload.conditions.push({
              keywords_expression: filterData[allFlags[element]] as string,
            })
          }
        }
      }
    }

    return payload
  }

  fetchSnippets = async (
    { isSorting, isRisk }: ISnippetParams = {
      isSorting: false,
      isRisk: false,
    },
  ) => {
    try {
      if (this.snippets.length >= this.snippetsTotal && this.snippets.length !== 0 && !isSorting) return

      const page = isSorting ? this.snippetsCurrentPage : Math.floor(this.snippets.length / 50) + 1
      const payload = await this.snippetFilterPayload()
      this.setSnippetsCurrentPage(page)
      payload.page = page
      payload.per_page = 50

      const { data } = await monitorAPI.loadFeed(payload)

      if (!data?.items.length) this.setIsFeedEmpty(false)
      this.setSnippetsTotal(data.total_count)

      let creators: string[] = []
      const snippets = data.items.map((snippet: any, index: number) => {
        creators.push(snippet?.channel_id)

        let author: { [key: string]: any } = {}

        let text = snippet.snippet_text
        if (this.snippetsFilter.days && snippet?.translated_snippet?.translated_text)
          text = snippet?.translated_snippet?.translated_text

        return {
          /* HACK: added the index so all the ids are unique */
          id: snippet.post_id + index,
          snippet_text: text,
          title: snippet.post_title,
          date: snippet.upload_date,
          riskScore: snippet?.risk_score?.toFixed(0),
          views: snippet.impression_count,
          viewsMilify: millify(snippet.impression_count || 0),
          views_diff: snippet.impression_diff_count,
          views_diff_milify: millify(snippet.impression_diff_count || 0),
          playSourceLink: snippet?.media_url,
          openSourceLink: snippet?.post_url,
          sourcePlatform: snippet.platform,
          channelId: snippet?.channel_id,
          snippetsFilter: this?.snippetsFilter,
          narratives: [this?.activeThreatNarrative.id],
          author,
          duration: snippet.duration,
          offset: snippet.offset,
          sentiment: snippet.sentiment,
          postUrl: snippet.post_url,
          documentId: snippet.document_id,
          feedback: 'none',
          translatedSnippet: snippet.translated_snippet,
        }
      })

      if (isSorting) this.setSnippets(snippets)
      else this.appendSnippets(snippets)

      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      await this.getCreators(creators)
    } catch (e) {
      LogService.error({ error: e, message: 'Error in fetch snippets method.' })
    }
  }

  resetStore = () => {
    this.threatTopics = {}
    this.risks = {}
    this.riskThreatDataListView = {}
    this.attentionFlags = {}
    this.attentionFlagsThreatDataListView = {}
    this.recentRiskFetchedName = []
    this.recentAttentionFlagFetchedName = []
    this.recentRiskAttentionFlagsFetchedName = []
    this.recentElectionIssuesRacesFetchedName = []
    this.recentElectionIssuesAttentionFlagsFetchedName = []
    this.recentElectionRacesAttentionFlagFetchedName = []
    this.selectedFlagListView = { name: '', type: '' }
    this.activeThreatNarrative = {
      description: '',
      narrative_number: 0,
      narrative_id: '',
      flags: {
        is_forcasting_active: false,
        is_language_risk_active: false,
        is_mainstream_coverage_active: false,
        is_narrative_risk_active: false,
        is_negative_sentiment_active: false,
      },
      subItemName: '',
    }
    this.activeThreatNarrativeTitle = ''
    this.activeThreatNarrativeAdvancedData = {
      summaryHints: '',
    }
    this.activeThreatNarrativeConditions = []
  }
}
