import { observer } from 'mobx-react-lite'
import { Form, Input, Progress, Spin, Tooltip } from 'antd'
import { store } from 'store'

import { ReactComponent as InfoIcon } from 'assets/images/icons/monitor/info_icon_24.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'

import { ConditionsDataType, ConditionsPayloadParamsType } from 'types/types'
import { SnippetFilterPayload } from 'models/models'
import { useCallback, useEffect, useState } from 'react'
import { anticipatoryIntelligence } from 'api/api'
import { evaluateNarrative } from 'api/defineNarrative/evaluateNarrative.api'

import { AddBrandFormDataType } from './types'
import './AddBrandWizard.scss'

type MetricStats = {
  mentions: number
  impression: number
  engagement: number
}
const Metrics = { mentions: 'Mentions', impression: 'Impressions', engagement: 'Engagement' }
interface StatsInfoBlockProps {
  metric: string
  value: number
}
interface TopicsInfoStatsProps {
  form: AddBrandFormDataType
  fetchInsightsMetrics: ({
    params,
    data,
    fullResponse,
  }: {
    params: ConditionsPayloadParamsType
    data: ConditionsDataType
    fullResponse?: boolean
  }) => Promise<any>
}
const TopicsInfoStats = (props: TopicsInfoStatsProps) => {
  const [qualityLoading, setQualityLoading] = useState(false)
  const { form, fetchInsightsMetrics } = props
  const [statsLoading, setStatsLoading] = useState(false)
  const [stats, setStats] = useState<MetricStats>({
    mentions: 0,
    impression: 0,
    engagement: 0,
  })
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [summary, setSummary] = useState('')
  const [evaluationResult, setEvaluationResult] = useState<number>(0)

  const { getSnippetFilterPayload } = store.vectorsStore

  useEffect(() => {
    fetchData()
  }, [form['brandName']])

  const fetchData = useCallback(async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload('', false)
    fetchDataForMetric(payload)
    fetchSummary(payload)
    evaluateTopic(payload)
  }, [form['brandName']])

  const fetchSummary = async (payload: SnippetFilterPayload) => {
    try {
      setSummaryLoading(true)
      const data = payload
      data['conditions'] = [{ keywords_expression: form['booleanKeyword'] }, ...payload.conditions]
      const response = await anticipatoryIntelligence.getSummary(data)
      if (response && response?.summary_text) setSummary(response.summary_text)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setSummaryLoading(false)
    }
  }

  const fetchDataForMetric = async (payload: SnippetFilterPayload) => {
    try {
      setStatsLoading(true)
      const query = payload.query

      const responses = await Promise.all(
        Object.keys(Metrics).map((metric) => {
          const requestParams: any = {
            q: `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`,
          }
          if (metric !== 'mentions') requestParams.measure = metric
          return fetchInsightsMetrics({
            params: requestParams,
            data: {
              conditions: [{ keywords_expression: form['booleanKeyword'] }, ...payload.conditions],
            },
          })
        }),
      )

      setStats({
        mentions: responses[0] || 0,
        impression: responses[1] || 0,
        engagement: responses[2] || 0,
      })
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setStatsLoading(false)
    }
  }

  const evaluateTopic = async (payload: SnippetFilterPayload) => {
    try {
      setQualityLoading(true)
      const response = await evaluateNarrative({
        ...payload,
        copilot_text: form['booleanKeyword'],
      })
      setEvaluationResult(Number(Number(response?.evaluation_score || 0).toFixed(0)) || 0)
    } catch (error) {
      console.error('Failed to evaluate topic', error)
    } finally {
      setQualityLoading(false)
    }
  }

  const getScoreQuality = () => {
    if (evaluationResult === 0) return 'Not enough data'
    else if (evaluationResult > 70) return 'Great'
    else if (evaluationResult >= 40) return 'Average'
    else if (evaluationResult < 40) return 'Poor'
  }

  return (
    <div className='creation-flow__info-section'>
      <span className='title'>{form['brandName'] || ''}</span>
      <Spin spinning={statsLoading}>
        <div className='stats-box'>
          {Object.keys(Metrics).map((item) => (
            <StatsInfoBlock
              metric={Metrics[item as keyof typeof Metrics]}
              value={stats[item as keyof typeof Metrics]}
            />
          ))}
        </div>
      </Spin>
      <Form.Item hidden name='brandName'>
        <Input placeholder='Add name' />
      </Form.Item>
      <Form.Item hidden name={'booleanKeyword'}>
        <Input.TextArea />
      </Form.Item>
      <Spin spinning={qualityLoading}>
        <div className='quality'>
          <div className='quality__title-container'>
            <span className='quality__title-container__text'>Match quality</span>
            {!qualityLoading && <span className='quality__title-container__tag'>{getScoreQuality()}</span>}{' '}
            {!qualityLoading && evaluationResult < 40 && (
              <Tooltip
                title={
                  'Your search criteria returned results that quite broad. Further refine your search with additional keywords and filters to improve your analysis.'
                }
                placement='bottom'>
                <InfoIcon />
              </Tooltip>
            )}
          </div>
          <div className='quality__bar'>
            <Progress percent={evaluationResult} strokeWidth={28} showInfo={false} />
            <span
              className='progress-text quality__bar__text'
              style={{
                right: evaluationResult > 10 ? 100 - evaluationResult + 1 + '%' : '90%',
                color: evaluationResult > 10 ? 'white' : 'black',
              }}>
              {evaluationResult}%
            </span>
          </div>
        </div>
      </Spin>
      {(summaryLoading || summary.length > 0) && (
        <Spin spinning={summaryLoading}>
          <div className='quality summary'>
            <div className='quality__title-container'>
              <ExecutiveSummaryIcon />
              <span className='quality__title-container__text'>Executive summary</span>
            </div>
            <p className='summary__text'>{summary}</p>
          </div>
        </Spin>
      )}
    </div>
  )
}

const StatsInfoBlock = (props: StatsInfoBlockProps) => {
  const { metric, value } = props
  return (
    <div className='stats-box__container'>
      <span className='stats-box__metric-title'>{metric}</span>
      <span className='stats-box__metric-value'>{value}</span>
    </div>
  )
}
export default observer(TopicsInfoStats)
