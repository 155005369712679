import { store } from 'store'
import { Col, Spin } from 'antd'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/dashboard/arrow-up.svg'

import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'

import './PulseDashboard.scss'
import millify from 'millify'

const PulseCenterComponent = () => {
  const { mainStore, loaderStore } = store
  const { isLoadingFeed, isLoadingDefaultPlatforms } = loaderStore
  const {
    snippetsFilter,
    setSnippetsFilter,
    refetchSnippetsForActiveItem,
    newSnippetsFound,
    fetchSnippetsTotalCount,
    setStartPollingForSnippetsCountUpdate,
    defaultPlatforms,
  } = mainStore

  const FIVE_MINUTES_IN_MILLISECONDS = 300000

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    const intervalId = setInterval(() => {
      fetchSnippetsTotalCount()
    }, FIVE_MINUTES_IN_MILLISECONDS)

    return () => {
      clearInterval(intervalId)
      setSnippetsFilter({ ...snippetsFilter, date_key: '' })
      setStartPollingForSnippetsCountUpdate(false)
    }
  }, [isLoadingDefaultPlatforms, defaultPlatforms])

  return (
    <Col span={12} flex={'auto'} className='pulse-dashboard__snippets-container'>
      <SnippetListWithFilters uniqueScrollId='pulse-centered-component' subStore='main' />
      {isLoadingFeed && (
        <div className='pulse_snippets_loader--mask'>
          <Spin spinning={true} className='pulse_snippets_loader' />
        </div>
      )}
      {newSnippetsFound > 0 && (
        <div className='new-snippets-banner' onClick={refetchSnippetsForActiveItem}>
          <ArrowUpIcon /> {millify(newSnippetsFound || 0)} new posts
        </div>
      )}
    </Col>
  )
}

export default observer(PulseCenterComponent)
