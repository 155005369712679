import { Button, Col, Drawer, Dropdown, Radio, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { memo, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { AssetListObjType, EntityTypes } from 'store/asset/types'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'
import DailyExecutiveSummary from 'components/Asset/BrandsDashboard/components/DailyExecutiveSummary/DailyExecutiveSummary'
import SnippetsDiscovery from 'components/Asset/BrandsDashboard/components/SnippetsDiscovery/SnippetsDiscovery'
import Filters from 'components/Asset/BrandsDashboard/components/Filters/Filters'
import FollowedIssues from 'components/Asset/BrandsDashboard/components/FollowedIssues/FollowedIssues'
import InfluencerTable from 'components/Asset/BrandsDashboard/components/InfluencerTable/InfluencerTable'
import PortfolioShareChart from 'components/Asset/BrandsDashboard/components/PortfolioShareChart/PortfolioShareChart'
import BrandSentiment from 'components/Asset/BrandsDashboard/components/BrandSentiment/BrandSentiment'
import { DownloadOption } from 'components/Asset/BrandsDashboard/components/DownloadOption'
import PlatformDistributionCard from 'components/Asset/BrandsDashboard/components/PlatformDistributionCard/PlatformDistributionCard'
import { MonitorGraph } from 'components/Asset/BrandsDashboard/components/MonitorGraph/MonitorGraph'
import SearchList from 'components/Vectors/Dashboard/SearchList/SearchList'
import { DetailsObjType, NarrativeListDataType } from 'types/types'
import BrandDiscussions from 'components/Vectors/Dashboard/BrandDiscussions/BrandDiscussions'
import classNames from 'classnames'
import PowerInsightTabsListVertical from 'components/Asset/BrandsDashboard/components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'
import { ReactComponent as PowerInsightsIcon } from 'assets/images/icons/powerinsight_icon.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import AlertModal from 'components/AlertModal/AlertModal'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { IBarChartData, ListSelectedRowData } from 'models/models'
import TopicsListModal from 'components/Asset/BrandsDashboard/components/TopicsListModal/TopicsListModal'
import AddBrandWizard from 'components/Asset/BrandsDashboard/components/AddBrandWizard/AddBrandWizard'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import InfoTooltip from 'components/Asset/BrandsDashboard/components/InfoTooltip/InfoTooltip'
import './TopicsDashboard.scss'
import BaseModal from 'components/BaseModal/BaseModal'
import { CreatorDetailsPage } from 'components/Monitor/PowerInsights/Creators/CreatorDetailsPage/CreatorDetailsPage'
import { ReactComponent as ListIcon } from 'assets/images/icons/assets/list.svg'
import { ReactComponent as DashboardIcon } from 'assets/images/icons/assets/dashboard.svg'
import usePermissions from 'hooks/usePermissions'
import DashboardEmptyState from 'components/DashboardEmptyState/DashboardEmptyState'
import { MaybeNull } from 'utils/commonTypes'

const customGraphColors = {
  shadeColorUp: '#eeeff4',
  lineColorUp: '#A84BD3',
  shadeColorDown: '#eeeff4',
  lineColorDown: '#A84BD3',
  theme: 'purple',
}

const TopicsDashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showSidebar, setShowSidebar] = useState(false)
  const [isTopicsModal, setIsTopicsModal] = useState(false)
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false)
  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [itemToView, setItemToView] = useState<FlagListObjType | IBarChartData | undefined>()
  const [assetToView, setAssetToView] = useState<AssetListObjType | undefined>()
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('metrics')
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isNarrativeCreation, setIsNarrativeCreation] = useState<boolean>(false)
  const { tenantsStore, loaderStore, vectorsStore, assetsStore } = store
  const {
    corporateCommunicationView,
    updateCorporateCommunicationView,
    fetchFolderList,
    narrativeLoading,
    snippetsFilter,
    resetSnippetFilters,
    setSnippetsFilter,
    fetchSnippets,
    deleteItem,
    fetchPowerInsightsForDashboardPage,
    setSnippets,
    selectedSearchItem,
    setSelectedSearchItem,
    isCreatorModalOpen,
    setIsCreatorModalOpen,
  } = vectorsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { fetchCategories } = assetsStore
  const { statsLineChartLoading, isLoadingFeed } = loaderStore
  const { fetchFlagsListWithConditions } = store.flagsStore
  const [openShareSearchItemModal, setOpenShareSearchItemModal] = useState(false)
  const [isOpenDeleteSearchItemModal, setOpenDeleteSearchItemModal] = useState(false)
  const [isOpenSearchItemAlertModal, setOpenSearchItemAlertModal] = useState(false)
  const [triggerReplaceItem, setTriggerReplaceItem] = useState(false)
  const [isDeletingSearchItem, setIsDeletingSearchItem] = useState(false)
  const [triggerUpdateItem, setTriggerUpdateItem] = useState<{ alertTurnedOff: boolean } | undefined>()
  const [itemToViewType, setItemToViewType] = useState('')
  const [topicToEdit, setTopicToEdit] = useState<MaybeNull<AssetListObjType>>()
  const canCreateVectors = usePermissions('vectors') === 'create'

  const isPowerInsightsMode = location.pathname?.includes('/topics/power-insights')

  const detailObject = {
    name: selectedSearchItem?.name,
    conditions: selectedSearchItem?.conditions,
    mode: 'narrative',
    id: selectedSearchItem?.id,
    activeTab: selectedPowerInsightTab,
  }

  const shareModalTitle = {
    narrative: 'Share Narrative',
    watchlist: 'Share Watchlist',
  }

  useEffectOnce(() => {
    updateCorporateCommunicationView('dashboard')
  })

  useEffectOnce(() => {
    if (isPowerInsightsMode) return
    fetchFolderList({ params: {} })
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      navigate('/')
    }
  }, [isCorporateCommunicationsTheme, customThemeLoading])

  useEffect(() => {
    if (selectedSearchItem) {
      getPowerInsightDataOnLanding(selectedSearchItem as NarrativeListDataType)
    }
  }, [selectedSearchItem])

  useEffect(() => {
    if (isPowerInsightsMode && selectedSearchItem) {
      fetchSnippets()
    }
  }, [selectedSearchItem])

  const getPowerInsightDataOnLanding = (searchItem: NarrativeListDataType) => {
    fetchPowerInsightsForDashboardPage({
      name: searchItem?.name || '',
      conditions: searchItem?.conditions || {},
      mode: 'narrative',
      id: searchItem?.id || '',
    })
    const { setSnippetsFilter } = store.vectorsModalStore
    setSnippetsFilter(snippetsFilter)
  }

  const handleSelectBrand = (searchItem?: NarrativeListDataType) => {
    fetchPowerInsightsForDashboardPage({
      name: searchItem?.name || '',
      conditions: searchItem?.conditions || {},
      mode: 'narrative',
      id: searchItem?.id || '',
    })
    setSelectedSearchItem(searchItem as NarrativeListDataType)
    const { setSnippetsFilter } = store.vectorsModalStore
    setSnippetsFilter(snippetsFilter)
  }

  const deleteHandler = () => {
    selectedSearchItem?.id &&
      deleteItem({ ...selectedSearchItem, ownerId: '' } as ListSelectedRowData).then((res: any | 'error') => {
        if (res !== 'error') {
          setOpenDeleteModal(false)
        }
      })
  }

  const searchItemActions = (searchItem: NarrativeListDataType) => [
    {
      label: (
        <div className='action-item'>
          <ShareIcon /> Share
        </div>
      ),
      onClick: () => {
        setOpenShareSearchItemModal(true)
      },
      key: 'share-item',
    },
    {
      label: (
        <div className='action-item'>
          {searchItem?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          Alerts
        </div>
      ),
      onClick: () => {
        setOpenSearchItemAlertModal(true)
      },
      key: 'alert-item',
    },
    {
      label: (
        <div className='action-item'>
          <EditIcon />
          Edit
        </div>
      ),
      onClick: () => {
        if (searchItem.permission_level !== 'EDITOR') return
        const state = {
          ...selectedSearchItem,
          comingFrom: '/topics',
          editMode: true,
        }
        navigate('/vectors/details', { state: JSON.parse(JSON.stringify(state)) })
      },
      disabled: searchItem.permission_level !== 'EDITOR',
      key: 'rename-item',
    },
    {
      label: (
        <div className='action-item'>
          <DeleteIcon />
          Delete
        </div>
      ),
      onClick: () => {
        if (searchItem.permission_level !== 'EDITOR') return
        setOpenDeleteSearchItemModal(true)
      },
      key: 'delete-item',
      disabled: searchItem.permission_level !== 'EDITOR',
    },
  ]

  if (customThemeLoading) {
    return (
      <div className='spinner-container'>
        <Spin />
      </div>
    )
  }

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)

  const openSnippetsDiscovery = ({
    watchlist,
    flag,
    brand,
  }: {
    watchlist?: IBarChartData
    flag?: FlagListObjType
    brand?: AssetListObjType | NarrativeListDataType
  }) => {
    const { setSnippetsFilter } = store.vectorsModalStore
    setSnippetsFilter({
      ...snippetsFilter,
      flags: {
        name: flag ? flag.name : selectedSearchItem?.name || '',
        conditions: flag ? flag?.conditions || {} : selectedSearchItem?.conditions || {},
      },
      watchlist: watchlist ? watchlist?.channel_urls : snippetsFilter.watchlist,

      assets: brand
        ? { name: brand.name, conditions: brand?.conditions }
        : selectedSearchItem?.conditions
        ? { name: selectedSearchItem.name, conditions: selectedSearchItem?.conditions }
        : snippetsFilter.assets,
      filter_sentiment: brand?.filter_sentiment ? brand?.filter_sentiment : snippetsFilter.filter_sentiment,
      filter_platform: brand?.filter_platform ? brand?.filter_platform : snippetsFilter.filter_platform,
    })
    setIsOpenSnippetsDiscovery(true)
  }

  const closeSnippetsDiscovery = () => {
    setItemToViewType('')
    setSnippets([])
    setIsOpenSnippetsDiscovery(false)
    setItemToView(undefined)
    setAssetToView(undefined)
  }

  const onSelectBrandDiscussionItem = (asset: AssetListObjType) => {
    setAssetToView(asset)
    openSnippetsDiscovery({ brand: asset })
  }

  const onSelectFlag = (flag: FlagListObjType) => {
    setItemToView(flag)
    setItemToViewType('flag')
    openSnippetsDiscovery({ flag })
  }

  const onSelectWatchlist = (watchlist: IBarChartData) => {
    setItemToView({ ...watchlist, name: watchlist.entity_name })
    openSnippetsDiscovery({ watchlist })
  }

  const onSearchItemDelete = () => {
    if (!selectedSearchItem) return
    setIsDeletingSearchItem(true)
    deleteItem({ ...selectedSearchItem, ownerId: selectedSearchItem.owner_id } as ListSelectedRowData)
      .then((res: any | 'error') => {
        if (res !== 'error') {
          setTriggerReplaceItem(true)
        }
      })
      .finally(() => {
        setIsDeletingSearchItem(false)
        setOpenDeleteSearchItemModal(false)
      })
  }

  const onAlertUpdateSuccess = (status: boolean) => {
    setTriggerUpdateItem({ alertTurnedOff: status })
  }

  const onEditSnippetDiscoveryViewItem = (itemToView: any) => {
    setTopicToEdit(itemToView)
  }

  return (
    <div className='search-dashboard'>
      <Drawer
        width={500}
        forceRender
        placement={'left'}
        open={showSidebar}
        className='search-list-drawer'
        onClose={() => setShowSidebar(false)}>
        <SearchList
          selectedSearchItem={selectedSearchItem as NarrativeListDataType}
          setSelectedSearchItem={(searchItem) => handleSelectBrand(searchItem as NarrativeListDataType)}
          showSidebar={true}
          triggerReplaceItem={triggerReplaceItem}
          setTriggerReplaceItem={setTriggerReplaceItem}
          triggerUpdateItem={triggerUpdateItem}
          setTriggerUpdateItem={setTriggerUpdateItem}
          setIsCreateTopicModalOpen={setIsCreateTopicModalOpen}
          setIsNarrativeCreation={setIsNarrativeCreation}
        />
      </Drawer>

      {!selectedSearchItem && !narrativeLoading && (
        <DashboardEmptyState
          buttonText='Add new topic'
          title='There is nothing here yet'
          description='Start by adding your first topic'
          onClick={() => setIsCreateTopicModalOpen(true)}
          canCreate={canCreateVectors}
        />
      )}

      {selectedSearchItem && (
        <div className='search-dashboard-stats'>
          <Spin spinning={narrativeLoading && !selectedSearchItem}>
            {selectedSearchItem && (
              <>
                <div className={classNames('filters-section', { powerinsight_mood: isPowerInsightsMode })}>
                  <div onClick={() => setShowSidebar(!showSidebar)} className='brand-selected'>
                    <h6 className='title'>{selectedSearchItem?.name}</h6>
                  </div>
                  <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
                    <ExecutiveSummaryIcon />
                    Executive Summary
                    <ExpandIcon />
                  </div>
                  {isPowerInsightsMode && selectedSearchItem && (
                    <div className='power-insights-options'>
                      <span>Power Insights</span>
                      <Dropdown
                        menu={{ items: searchItemActions(selectedSearchItem) }}
                        placement='bottomLeft'
                        disabled={!canCreateVectors}>
                        <ThreeDotsIcon />
                      </Dropdown>
                    </div>
                  )}
                  {selectedSearchItem && isOpenDeleteSearchItemModal && (
                    <DeleteConfirmationModal
                      title='Are you sure you want to delete this search?'
                      desc='This action cannot be undone.'
                      onClose={() => {
                        setOpenDeleteSearchItemModal(false)
                      }}
                      onContinue={onSearchItemDelete}
                      disabledSecondButton={isDeletingSearchItem}
                    />
                  )}
                  {isOpenSearchItemAlertModal && (
                    <AlertModal
                      entity_id={selectedSearchItem?.id as string}
                      entity_type={
                        selectedSearchItem?.mode === 'community'
                          ? null
                          : (selectedSearchItem?.mode?.toUpperCase() as EntityTypes)
                      }
                      onClose={() => setOpenSearchItemAlertModal(false)}
                      open={isOpenSearchItemAlertModal}
                      alertId={selectedSearchItem?.alert_id as string}
                      onSuccess={() => onAlertUpdateSuccess(false)}
                      onDeleteSuccess={() => onAlertUpdateSuccess(true)}
                    />
                  )}
                  {selectedSearchItem && openShareSearchItemModal && (
                    <ShareReportModal
                      endpoint={selectedSearchItem?.mode as ShareReportEndpoint}
                      reportId={selectedSearchItem.id}
                      title='Share Topic'
                      desc='Share this topic with one or several team members'
                      secondButtonText='Share'
                      icon={null}
                      onClose={() => {
                        setOpenShareSearchItemModal(false)
                      }}
                    />
                  )}
                  <div className='date-filter'>
                    <DateFilter
                      days={[1, 7, 30, 90]}
                      pageType={'list'}
                      subStore={'vectors'}
                      onClick={() => handleSelectBrand(selectedSearchItem as NarrativeListDataType)}
                    />
                    {!isPowerInsightsMode && (
                      <ButtonImage onClick={() => openSnippetsDiscovery({})} className='fhc_sc_button'>
                        Show all snippets
                      </ButtonImage>
                    )}
                    {!isPowerInsightsMode && (
                      <ButtonImage
                        onClick={(e) => {
                          e.preventDefault()
                          setSnippetsFilter({
                            ...snippetsFilter,
                            flags: {
                              name: selectedSearchItem?.name || '',
                              conditions: selectedSearchItem?.conditions || {},
                            },
                          })
                          navigate('power-insights/metrics')
                        }}
                        className='fhc_pi_button'
                        icon={PowerInsightsIcon}
                        type='button'>
                        Power Insights
                      </ButtonImage>
                    )}
                    {isPowerInsightsMode && (
                      <ButtonImage
                        onClick={(e) => {
                          e.preventDefault()
                          resetSnippetFilters()
                          navigate('/topics')
                        }}
                        className='fhc_sc_button bt_dashboard'
                        icon={LeftArrowIcon}>
                        Back to dashboard
                      </ButtonImage>
                    )}
                    {isCorporateCommunicationsTheme && (
                      <Radio.Group
                        value={corporateCommunicationView}
                        className='radio_group_view'
                        onChange={(e) => {
                          updateCorporateCommunicationView(e.target.value)
                          navigate(e.target.value === 'list' ? '/vectors' : '/topics')
                        }}>
                        <Radio.Button value='list'>
                          <div className='radio_group_icon_wrapper'>
                            <ListIcon />
                          </div>
                        </Radio.Button>
                        <Radio.Button value='dashboard'>
                          <div className='radio_group_icon_wrapper'>
                            <DashboardIcon />
                          </div>
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </div>
                  {isOpenExecutiveSummaryModal && (
                    <DailyExecutiveSummary onClose={closeExecutiveSummaryModal} subStore='vectors' />
                  )}
                  {isOpenSnippetsDiscovery && (
                    <SnippetsDiscovery
                      onClose={closeSnippetsDiscovery}
                      selectedItem={selectedSearchItem as NarrativeListDataType}
                      itemToView={itemToView || assetToView}
                      subStore='vectors'
                      itemToViewType={itemToViewType}
                      setItemToView={setItemToView}
                      onEditCurrentItemToView={onEditSnippetDiscoveryViewItem}
                    />
                  )}
                </div>
                {selectedSearchItem && !isPowerInsightsMode && (
                  <div>
                    <Filters />
                    <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
                      <Col span={18}>
                        <Row gutter={[0, 24]}>
                          <Col span={24}>
                            <Row className='dashboard-widgets__card__sub-row'>
                              <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                                <MentionViewCard
                                  selectedItem={selectedSearchItem}
                                  type='Mentions'
                                  key='Mentions'
                                  customGraphColors={customGraphColors}
                                  chartWrapperClassName='cards-chart-wrapper'
                                  subStore='vectors'
                                  tooltipText='Represents the total number of times the selected topic was mentioned across all platforms.'
                                />
                              </Col>
                              <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                                <MentionViewCard
                                  selectedItem={selectedSearchItem}
                                  type='Views'
                                  key='Views'
                                  measure='impression'
                                  customGraphColors={customGraphColors}
                                  chartWrapperClassName='cards-chart-wrapper'
                                  subStore='vectors'
                                  tooltipText='Indicates how many times the selected topic was potentially seen by audiences.'
                                />
                              </Col>
                              <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                                <MentionViewCard
                                  selectedItem={selectedSearchItem}
                                  type='Engagement'
                                  key='Engagement'
                                  measure='engagement'
                                  customGraphColors={customGraphColors}
                                  chartWrapperClassName='cards-chart-wrapper'
                                  subStore='vectors'
                                  tooltipText='Shows how actively audiences are interacting with content that mentions the selected topic through likes, comments, shares, and other engagement metrics, depending on the platform.'
                                />
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col span={24} className='dashboard-widgets__card'>
                      <SuggestedIssues selectedItem={selectedSearchItem} item='Topic' />
                    </Col> */}
                          <Col span={24} className='dashboard-widgets__card'>
                            <BrandDiscussions
                              selectedItem={selectedSearchItem}
                              onSelectItem={onSelectBrandDiscussionItem}
                              subStore='vectors'
                            />
                          </Col>
                          <Col span={24} className='dashboard-widgets__card'>
                            <FollowedIssues
                              isOnlyView
                              selectedBrand={selectedSearchItem}
                              onSelectFlag={onSelectFlag}
                              subStore='vectors'
                              handleBrowseMoreClick={() => setIsTopicsModal(true)}
                            />
                          </Col>
                          <Col span={24} className='dashboard-widgets__card'>
                            <InfluencerTable subStore='vectors' onRowClick={onSelectWatchlist} />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row gutter={[0, 24]}>
                          <Col span={24} className='dashboard-widgets__card'>
                            <PortfolioShareChart
                              additionalConditions={selectedSearchItem.conditions}
                              subStore='vectors'
                              showMetricSelection
                              isPowerInsightsMode={isPowerInsightsMode}
                              openSnippetDiscovery={(brand) => openSnippetsDiscovery({ brand })}
                            />
                          </Col>
                          <Col span={24} className='dashboard-widgets__card'>
                            <BrandSentiment
                              subStore='vectors'
                              selectedBrand={selectedSearchItem}
                              showMetricSelection
                              openSnippetDiscovery={(brand) =>
                                openSnippetsDiscovery({ brand: brand as AssetListObjType })
                              }
                            />
                          </Col>
                          <Col span={24} className='dashboard-widgets__card'>
                            <div id='trendChart'>
                              <div className='chart-heading'>
                                <span className='monitor-assets__graph__title'>Current Trend</span>
                                <span className='chart-heading__info'>
                                  <DownloadOption elementToCapture='#trendChart' />
                                  <InfoTooltip text='This widget helps you track the volume of your mentions and impressions over time. You can also filter this widget by sentiment, which lets you view the volume of your mentions over time broken down by whether those mentions are positive, neutral and negative.' />
                                </span>
                              </div>
                              <MonitorGraph
                                subStore='vectors'
                                dataLoading={statsLineChartLoading}
                                resizeChartTrigger={showSidebar}
                                selectedItem={selectedSearchItem}
                              />
                            </div>
                          </Col>
                          <Col span={24} className='dashboard-widgets__card'>
                            <PlatformDistributionCard
                              isVerticalDisplay
                              subStore='vectors'
                              selectedBrand={selectedSearchItem}
                              openSnippetDiscovery={(brand) => openSnippetsDiscovery({ brand })}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}

                {isPowerInsightsMode && (
                  <Row
                    gutter={24}
                    justify={'start'}
                    align={'top'}
                    className={classNames('dashboard-widgets', { powerinsight_dashboard: isPowerInsightsMode })}>
                    <Col style={{ width: '226', minWidth: '221px' }}>
                      <PowerInsightTabsListVertical
                        setSelectedPowerInsightTab={setSelectedPowerInsightTab}
                        selectedPowerInsightTab={selectedPowerInsightTab}
                      />
                    </Col>
                    <Col style={{ flex: 1 }}>
                      <Row gutter={[24, 24]}>
                        <Col span={15}>
                          <Row>
                            <Col span={24} className='power-insights-components'>
                              <Outlet
                                context={{
                                  selectedBrand: selectedSearchItem,
                                  subStore: 'vectors',
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={9} className='snippet-section-column'>
                          <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                            <Row>
                              <Col span={24} className='dashboard-snippet-section'>
                                <SnippetListWithFilters
                                  detailObject={detailObject as DetailsObjType}
                                  activePowerInsightTab={selectedPowerInsightTab}
                                  subStore='vectors'
                                  uniqueScrollId='vector-dashboard_id'
                                />
                              </Col>
                            </Row>
                          </Spin>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Spin>
        </div>
      )}

      {isShareModalOpen && selectedSearchItem?.id && (
        <ShareReportModal
          endpoint={selectedSearchItem?.mode as ShareReportEndpoint}
          reportId={selectedSearchItem?.id}
          title={shareModalTitle[selectedSearchItem?.mode as 'narrative' | 'watchlist']}
          desc='Invite team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
      {openAlertSuccessModal && (
        <SuccessModal
          title='Alert Created Successfully'
          desc='You will start receive new alerts according to the selected preferences'
          onClose={() => setOpenAlertSuccessModal(false)}
          closeButtonText='Continue'
        />
      )}
      <AlertModal
        entity_id={selectedSearchItem?.id as string}
        entity_type={
          selectedSearchItem?.mode === 'community' ? null : (selectedSearchItem?.mode?.toUpperCase() as EntityTypes)
        }
        onClose={() => setIsAlertModalOpen(false)}
        open={isAlertModalOpen}
        alertId={selectedSearchItem?.alert_id as string}
        onSuccess={async () => {
          setOpenAlertSuccessModal(true)
        }}
        onDeleteSuccess={() => {}}
      />

      {openDeleteModal && selectedSearchItem?.id && (
        <DeleteConfirmationModal
          title={`Are you sure you want to delete selected item`}
          onClose={() => setOpenDeleteModal(false)}
          onContinue={deleteHandler}
        />
      )}

      {isCreateTopicModalOpen && (
        <AddBrandWizard
          //@ts-ignore
          setSelectedBrand={setSelectedSearchItem}
          editedBrandData={null}
          isTopicCreation={true}
          handleCloseModal={() => {
            setIsCreateTopicModalOpen(false)
          }}
          isNarrative={isNarrativeCreation}
        />
      )}
      {topicToEdit && (
        <AddBrandWizard
          setSelectedBrand={() => {}}
          editedBrandData={topicToEdit}
          isTopicCreation={true}
          handleCloseModal={(updatedData: any) => {
            if (isOpenSnippetsDiscovery && updatedData) {
              setItemToView(updatedData)
              openSnippetsDiscovery({ flag: updatedData })
            }
            setTopicToEdit(null)
          }}
        />
      )}

      {isTopicsModal && (
        <TopicsListModal
          subStore='vectors'
          onSelectFlag={() => null}
          handleCloseModal={async (isDataChanged) => {
            setIsTopicsModal(false)
            if (isDataChanged) {
              const requestParams: FlagListParamsType = {
                page: 1,
                per_page: 5,
                sort: 'name:asc',
                is_active: true,
              }
              await fetchFlagsListWithConditions({ params: requestParams })
            }
          }}
          selectedBrand={selectedSearchItem as NarrativeListDataType}
          handleTopicCreate={() => {
            setIsNarrativeCreation(false)
            setIsCreateTopicModalOpen(true)
            setIsTopicsModal(false)
          }}
        />
      )}

      <BaseModal
        open={isCreatorModalOpen}
        isHeaderBordered
        isFooterBordered
        title='Creator Page'
        onCancel={() => setIsCreatorModalOpen(false)}
        className='creator-page-modal'
        footer={
          <Button type='primary' onClick={() => setIsCreatorModalOpen(false)}>
            Close
          </Button>
        }>
        <div className='creator-page-modal__content'>
          <CreatorDetailsPage subStore={'vectors'} isBackButtonEnabled={false} parentDetailObj={null} />
          <div className='creator-page-modal__content__snippets'>
            <SnippetListWithFilters
              subStore={'vectors'}
              uniqueScrollId='create-page-modal__id'
              detailObject={detailObject as DetailsObjType}
            />
            <Spin spinning={isLoadingFeed} className='creator-page-modal__snippets-loading' />
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export default memo(observer(TopicsDashboard))
