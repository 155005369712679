import React from 'react'
import { ReactComponent as EditIcon } from 'assets/images/icons/report/edit-icon-size-20.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as EmailIcon } from 'assets/images/icons/report/email-icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/icons/report/download-icon.svg'
import styles from './ActionButtons.module.scss'
import classNames from 'classnames'

type ActionButtonsProps = {
  className?: string
  deleteHandler?: () => void
  shareHandler?: () => void
  editHandler?: () => void
  emailHandler?: () => void
  downloadHandler?: () => void
  hideDeleteIcon?: boolean
  hideShareIcon?: boolean
  hideEditIcon?: boolean
  hideExtraIcons?: boolean
  showTooltip?: boolean
  disabledButtons?: string[]
  iconContainerStyles?: string
  deleteIconStyle?: string
}

function ActionButtons(props: ActionButtonsProps) {
  const {
    className,
    deleteHandler,
    shareHandler,
    editHandler,
    emailHandler,
    downloadHandler,
    hideDeleteIcon,
    hideShareIcon,
    hideEditIcon,
    hideExtraIcons,
    showTooltip,
    disabledButtons = [],
    iconContainerStyles = '',
    deleteIconStyle = '',
  } = props
  return (
    <div
      className={classNames(
        styles.action_button_container,
        { [styles.abc_icon_container_gap]: hideShareIcon },
        className,
      )}>
      {!hideDeleteIcon && (
        <div
          className={classNames(styles.abc_icon_container, iconContainerStyles, {
            [styles.abc_icon_container_tooltip]: showTooltip,
          })}
          data-tooltip='Delete'
          onClick={(e) => {
            e.stopPropagation()
            deleteHandler?.()
          }}>
          <DeleteIcon className={(styles.abc_icon, deleteIconStyle)} />
        </div>
      )}
      {!hideShareIcon && (
        <div
          className={classNames(styles.abc_icon_container, iconContainerStyles, {
            [styles.abc_icon_container_tooltip]: showTooltip,
          })}
          data-tooltip='Share'
          onClick={(e) => {
            e.stopPropagation()
            shareHandler?.()
          }}>
          <ShareIcon className={styles.abc_icon} />
        </div>
      )}
      {!hideEditIcon && (
        <div
          className={classNames(styles.abc_icon_container, iconContainerStyles, {
            [styles.abc_icon_container_tooltip]: showTooltip,
            [styles.abc_icon_container_disabled]: disabledButtons.includes('edit'),
          })}
          data-tooltip='Edit'
          onClick={(e) => {
            e.stopPropagation()
            editHandler?.()
          }}>
          <EditIcon className={styles.abc_icon} />
        </div>
      )}
      {!hideExtraIcons && (
        <>
          <div
            className={classNames(styles.abc_icon_container, {
              [styles.abc_icon_container_tooltip]: showTooltip,
            })}
            data-tooltip='Send as email'
            onClick={(e) => {
              e.stopPropagation()
              emailHandler?.()
            }}>
            <EmailIcon className={styles.abc_icon} />
          </div>
          <div
            className={classNames(styles.abc_icon_container, {
              [styles.abc_icon_container_tooltip]: showTooltip,
              [styles.abc_icon_container_disabled]: disabledButtons.includes('delete'),
            })}
            data-tooltip='Download as PDF'
            onClick={(e) => {
              e.stopPropagation()
              downloadHandler?.()
            }}>
            <DownloadIcon className={styles.abc_icon} />
          </div>
        </>
      )}
    </div>
  )
}

export default ActionButtons
