import { Form, Input } from 'antd'
import { store } from 'store'

const AddDetails = () => {
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = store.tenantsStore
  return (
    <div className='add-details'>
      <label>
        <span className='input-label'>
          {changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)} name{' '}
          <span className='asterisk'>*</span>
        </span>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'Please input watchlist name' }]}
          shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input placeholder='Kinetic Risk' key={'watchlist-add-details-name-input'} />
        </Form.Item>
      </label>
      <label>
        <span className='input-label'>Description</span>
        <Form.Item name='description' shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input.TextArea placeholder='Kinetic Risk' key={'watchlist-add-details-description-textarea'} />
        </Form.Item>
      </label>
    </div>
  )
}

export default AddDetails
