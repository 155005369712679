import { Tabs } from 'antd'
import StepsHeader from '../components/StepsHeader'
import { SectionObjType } from '../CreateReport'
import { useEffect, useState } from 'react'
import { RecurrenceOptions } from './Recurrence'
import { getPromptSimulation } from 'api/monitor/powerInsights/summary/getPromptSimulation'
import { ReactComponent as SimulatingPrompt } from 'assets/images/icons/report/simulating.svg'
import { containsHTML, extractBodyContent } from 'utils/helper'
import ReactMarkdown from 'react-markdown'
import { getConditionsForSimulation, getQueryForSimulations } from './Summarization'
import DetailViewEmptyData from 'components/Investigate/Reports/DetailViewEmptyData/DetailViewEmptyData'
import './commonStyles.scss'

const DESCRIPTION =
  'Review the updated section and ensure the summarized content accurately reflects the main topics. Use this opportunity to confirm or adjust any details before finalizing.'

type Props = {
  editMode?: boolean
  section: SectionObjType
  updateSection: (sections: SectionObjType) => void
  recurrenceOptions: RecurrenceOptions
  reviewStep?: boolean
  summary?: { content: any; is_v2?: boolean }
}

export const ReviewUpdatedSection = ({ section, recurrenceOptions, summary }: Props) => {
  const [activeTab, setActiveTab] = useState('1')
  const [dailyExecutiveSummary, setDailyExecutiveSummary] = useState<any[]>([])
  const [isLoadingExecutiveSummary, setIsLoadingExecutiveSummary] = useState(false)
  const [summaryFetchingError, setSummaryFetchingError] = useState(false)

  const fetchDailyExecutiveSummary = async () => {
    setIsLoadingExecutiveSummary(true)
    const promptPayload: any = {
      prompt: section.summary_prompt,
      payload: {
        query: getQueryForSimulations(section.filters, recurrenceOptions),
        conditions: getConditionsForSimulation(section.mainItems, section.optionalItems),
      },
    }
    try {
      const { data } = await getPromptSimulation(promptPayload)
      if (!data.response_code) {
        setDailyExecutiveSummary(data.sections)
      }
      if (data.response_code === 'NO_DATA') {
        setDailyExecutiveSummary([])
      }
    } catch (error) {
      setSummaryFetchingError(true)
    } finally {
      setIsLoadingExecutiveSummary(false)
    }
  }

  useEffect(() => {
    fetchDailyExecutiveSummary()
  }, [])

  const retryFetchingSummary = () => {
    setIsLoadingExecutiveSummary(true)
    setSummaryFetchingError(false)
    fetchDailyExecutiveSummary()
  }

  return (
    <div className='review-updated-section'>
      <StepsHeader title='Review the updated section' stepText='Step 3 of 3' description={DESCRIPTION} />
      <Tabs
        activeKey={activeTab}
        items={[
          {
            label: 'Updated version',
            key: '1',
          },
          {
            label: 'Previous version',
            key: '2',
          },
        ]}
        onChange={(activeKey) => setActiveTab(activeKey)}
      />
      {activeTab === '2' && (
        <div className='summary-section'>
          {summary?.content && summary?.is_v2 && (
            <>
              {summary?.content?.sections?.map((subSection: any) => (
                <div className='dvsdc_section_desc_container'>
                  <div className='sdc_chunk'>
                    <h5>{subSection.subheading}</h5>
                    <p className='sdc_chunk_text'>{subSection.summary}</p>
                  </div>
                </div>
              ))}
            </>
          )}
          {!summary?.is_v2 && (
            <div className='dvsdc_section_desc_container'>
              <div className='sdc_chunk'>
                <p className='sdc_chunk_text'>
                  {containsHTML(summary?.content) ? (
                    <span dangerouslySetInnerHTML={{ __html: extractBodyContent(summary?.content || '') }} />
                  ) : (
                    <ReactMarkdown>{summary?.content || ''}</ReactMarkdown>
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === '1' && (
        <>
          {isLoadingExecutiveSummary && (
            <div className='review-updated-section__empty-state'>
              <SimulatingPrompt />
              <h5>We are simulating your prompt</h5>
              <p>
                We’re currently processing your data and finalizing your simulation. This usually takes around 1-3
                minutes.
              </p>
            </div>
          )}

          {!isLoadingExecutiveSummary && summaryFetchingError && (
            <div className='summary-sections-empty summary-error'>
              <span className='summary-error-text'>Failed to load the summary. Please try again now or later.</span>
              <button className='summary-retry-error' onClick={retryFetchingSummary}>
                Retry
              </button>
            </div>
          )}
          {dailyExecutiveSummary?.length > 0 && (
            <div className='summary-section'>
              {dailyExecutiveSummary.map((subSection: any) => (
                <div className='dvsdc_section_desc_container'>
                  <div className='sdc_chunk'>
                    <h5>{subSection.subheading}</h5>
                    <p className='sdc_chunk_text'>{subSection.summary}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {!isLoadingExecutiveSummary && !summaryFetchingError && dailyExecutiveSummary?.length === 0 && (
            <DetailViewEmptyData />
          )}
        </>
      )}
    </div>
  )
}
