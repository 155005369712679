import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'
import { store } from 'store'
import { useUserRole } from 'hooks/UserRoleContext'

type Props = {
  element: JSX.Element
  allowedRoles: string[]
}

export const PrivateRoute = observer(({ element, allowedRoles }: Props): JSX.Element => {
  const userName = store.userStore.userName
  const userRole = useUserRole()?.userRole
  const isCorporateCommunicationsTheme = store.tenantsStore.isCorporateCommunicationsTheme

  const isAllowed = allowedRoles.includes(userRole)

  if (!userName) {
    return <Navigate replace to='/signin' />
  }

  if (!isAllowed) {
    return <Navigate replace to={isCorporateCommunicationsTheme ? '/brands' : '/assets'} />
  }

  return element
})
