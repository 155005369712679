import { store } from 'store'
import { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { Avatar, Button, Col, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as SpiralIcon } from 'assets/images/spiral_stars.svg'

import SearchStats from './SearchStats'
import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { isEmpty } from 'lodash'

const PulseRightComponent = ({
  handleCreateNewClick,
  handlePowerInsightsClick,
}: {
  handleCreateNewClick: () => void
  handlePowerInsightsClick: () => void
}) => {
  const { vectorsStore } = store

  const {
    snippetsFilter,
    fetchWatchlist,
    fetchNarratives,
    watchListLoading,
    narrativeLoading,
    watchlistListData,
    narrativesListData,
    paginationValues: searchPagination,
    setPaginationValues: setSearchPagination,
  } = vectorsStore

  const { activeItem } = store.mainStore

  const firstCallRequestParams = {
    page: 1,
    per_page: 5,
    sort: 'name:asc',
  }

  const customGraphColors = useMemo(
    () => ({
      shadeColorUp: '#eeeff4',
      lineColorUp: '#A84BD3',
      shadeColorDown: '#eeeff4',
      lineColorDown: '#A84BD3',
      theme: 'purple',
    }),
    [],
  )

  const mentionViewCards = useMemo(
    () => [
      { type: 'Mentions', key: 'Mentions' },
      { type: 'Impressions', key: 'Views', measure: 'impression' },
      { type: 'Engagement', key: 'engagement', measure: 'engagement' },
    ],
    [],
  )

  type cardTypes = 'Mentions' | 'Impressions' | 'Views' | 'Engagement'
  type keyTypes = 'impression' | 'engagement' | 'impressions'

  const isNarrativePaginationActive = narrativesListData.items?.length < narrativesListData.total_count

  useEffect(() => {
    fetchNarratives({ params: firstCallRequestParams })
    fetchWatchlist({ params: firstCallRequestParams })
  }, [snippetsFilter])

  return (
    <Col span={6} className='pulse-dashboard__searches-container'>
      {!isEmpty(activeItem?.conditions) ? (
        <div className='searches-container__metrics-container'>
          <div className='pulse-actions'>
            <Button
              type='primary'
              icon={<SpiralIcon />}
              className='power-insights-btn'
              onClick={handlePowerInsightsClick}>
              Power Insights
            </Button>
            <Button icon={<PlusIcon />} className='create-new-btn' onClick={handleCreateNewClick}>
              Create new
            </Button>
          </div>
          <div className='searches-container__metrics-container'>
            {mentionViewCards.map((card) => (
              <div className='searches-container__metrics-container__card' key={card.key}>
                <MentionViewCard
                  selectedItem={activeItem as any}
                  type={card.type as cardTypes}
                  key={card.key}
                  measure={card.measure as keyTypes}
                  subStore={'main'}
                  customGraphColors={customGraphColors}
                  chartWrapperClassName='cards-chart-wrapper'
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className='searches'>
            <div className='searches__pagination'>
              <div
                className={classNames('searches__pagination__back-button pagination-btn', {
                  disabled_pagination: searchPagination.page <= 1,
                })}
                onClick={() => {
                  if (searchPagination.page > 1) {
                    setSearchPagination({ ...searchPagination, page: searchPagination.page - 1 })
                    fetchNarratives({ params: { ...firstCallRequestParams, page: searchPagination.page - 1 } })
                  }
                }}>
                <LeftOutlined />
              </div>
              <div className='searches__pagination__text'>Searches</div>
              <div
                className={classNames('searches__pagination__forward-button pagination-btn', {
                  disabled_pagination: !isNarrativePaginationActive,
                })}
                onClick={() => {
                  if (isNarrativePaginationActive) {
                    setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
                    fetchNarratives({ params: { ...firstCallRequestParams, page: searchPagination.page + 1 } })
                  }
                }}>
                <RightOutlined />
              </div>
            </div>
            <Spin spinning={narrativeLoading}>
              <div className='searches__listing'>
                {narrativesListData.items?.map((item, index) => (
                  <SearchStats subStore='vectors' itemData={item} listItemNumber={index + 1} />
                ))}
              </div>
            </Spin>
          </div>

          <div className='watchlist' style={{ overflow: 'scroll', height: '385px' }}>
            <div className='watchlist__label'>Featured Watchlists</div>
            <Spin spinning={watchListLoading}>
              <div className='watchlist__content'>
                {watchlistListData.items?.map((item) => (
                  <div className='watchlist__content__item' key={item?.name}>
                    <div className='watchlist__content__item__avatars'>
                      <Avatar.Group maxCount={3} size={'large'}>
                        <Avatar src={profileImage} size={'large'} />
                      </Avatar.Group>
                    </div>
                    <div className='watchlist__content__item__stats'>
                      <div className='watchlist__content__item__stats__label'>{item?.name}</div>
                      {/* @ts-ignore */}
                      <div className='watchlist__content__item__stats__value'>{item?.total_channels} creators</div>
                    </div>
                  </div>
                ))}
              </div>
            </Spin>
          </div>
        </>
      )}
    </Col>
  )
}

export default observer(PulseRightComponent)
