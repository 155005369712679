import { Button } from 'antd'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as EmptyFileIcon } from 'assets/images/empty_file.svg'

import './DashboardEmptyState.scss'

const DashboardEmptyState = ({
  title = '',
  buttonText = '',
  description = '',
  onClick,
  canCreate = true,
}: {
  title: string
  buttonText: string
  description: string
  onClick: () => void
  canCreate?: boolean
}) => {
  return (
    <div className='no-brand-placeholder'>
      <div className='no-brand-placeholder__logo'>
        <EmptyFileIcon />
      </div>
      <div className='no-brand-placeholder__info'>
        <div className='no-brand-placeholder__info__title'>{title}</div>
        <div className='no-brand-placeholder__info__description'>{description}</div>
      </div>
      <Button icon={<PlusIcon />} type='primary' onClick={onClick} disabled={!canCreate}>
        {buttonText}
      </Button>
    </div>
  )
}

export default DashboardEmptyState
