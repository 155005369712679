import { Drawer } from 'antd'
import { ContentForm } from '../steps/Content'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { useState } from 'react'
import { SummarizationForm } from '../steps/Summarization'
import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg'
import { ReactComponent as PencilIcon } from 'assets/images/icons/report/pencil.svg'
import { SectionObjType } from '../CreateReport'
import { RecurrenceOptions } from '../steps/Recurrence'
import { ReviewUpdatedSection } from '../steps/ReviewUpdatedSection'
import './commonStyles.scss'

export default function AddEditContentSection({
  isOpenAddNewSection,
  closeAddEditSection,
  sectionToEdit,
  recurrenceOptions,
  sections,
  reviewStep,
  summary,
  loading,
}: {
  isOpenAddNewSection: boolean
  closeAddEditSection: (section?: SectionObjType) => void
  sectionToEdit: SectionObjType
  recurrenceOptions: RecurrenceOptions
  sections: SectionObjType[]
  reviewStep?: boolean
  summary?: { content: any; is_v2?: boolean }
  loading?: boolean
}) {
  const [sectionStep, setSectionStep] = useState(1)
  const [errors, setErrors] = useState({})
  const [section, setSection] = useState(sectionToEdit)

  const onContinue = () => {
    if ((!reviewStep && sectionStep === 2) || (reviewStep && sectionStep === 3)) {
      closeAddEditSection(section)
      return
    }
    if (section.title === '') {
      setErrors({ contentTitle: 'Required' })
      return
    }
    if (sections.find((data) => data.title === section.title && section.id !== data.id)) {
      setErrors({ contentTitle: 'A section with same name already exists' })
      return
    }
    setSectionStep(sectionStep + 1)
  }

  const onCancel = () => {
    if (sectionStep !== 1) {
      setSectionStep(sectionStep - 1)
      return
    }
    closeAddEditSection()
  }

  const lastStep = reviewStep ? 3 : 2

  return (
    <Drawer open={isOpenAddNewSection} onClose={() => closeAddEditSection()} width={718} closable={false}>
      <div className='add-edit-content'>
        <div className='add-edit-content__header'>
          <div className='title'>
            <PencilIcon />
            <span>{section.isNew ? 'Add' : 'Edit'} section</span>
          </div>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => closeAddEditSection()} />
        </div>
        <div className='add-edit-content__content'>
          {sectionStep === 1 && (
            <ContentForm
              editMode
              section={section}
              updateSection={setSection}
              errors={errors}
              setErrors={setErrors}
              reviewStep={reviewStep}
            />
          )}
          {sectionStep === 2 && (
            <SummarizationForm
              editMode
              section={section}
              updateSection={setSection}
              recurrenceOptions={recurrenceOptions}
              reviewStep={reviewStep}
            />
          )}
          {sectionStep === 3 && (
            <ReviewUpdatedSection
              editMode
              section={section}
              updateSection={setSection}
              recurrenceOptions={recurrenceOptions}
              summary={summary}
            />
          )}
        </div>
        <div className='add-edit-content__review-form-actions'>
          <ButtonImage onClick={onCancel} className='cancel-btn'>
            {sectionStep === 1 ? 'Cancel' : 'Back'}
          </ButtonImage>
          <ButtonImage onClick={onContinue} className='continue-btn' loading={loading}>
            {sectionStep !== lastStep ? 'Continue' : reviewStep ? 'Confirm & save' : 'Save'}
          </ButtonImage>
        </div>
      </div>
    </Drawer>
  )
}
