import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'
import { ReactComponent as LowRiskIcon } from 'assets/images/icons/monitor/low_risk_icon.svg'
import { ReactComponent as HighRiskIcon } from 'assets/images/icons/monitor/high_risk_icon.svg'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType, NarrativeListDataType } from 'types/types'
import './AllPostSection.scss'
import { AssetListObjType } from 'store/asset/types'
import { SubStore } from 'types/types'
import { IListItem } from 'models/models'

type RenderALLPostType = {
  itemData: NarrativeListDataType | AssetListObjType | IListItem
  forGridView?: boolean
  className?: string
  forAsset?: boolean
  isBookMarksListing?: boolean
  subStore: SubStore
}

const AllPostSection = observer((props: RenderALLPostType) => {
  const { itemData, className = '', forGridView, forAsset, isBookMarksListing } = props
  const { fetchInsightsMetrics, selectedTab, getDate, snippetsFilter } = store[`${props.subStore}Store`]

  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })

  const renderConditions = useMemo(() => {
    if (forAsset) {
      if (Object.keys((itemData as AssetListObjType).conditions).length) {
        return [(itemData as AssetListObjType).conditions]
      }
      return []
    }
    if (isBookMarksListing) {
      if ((itemData as IListItem)?.entity_info?.length) return [{ document_ids: (itemData as IListItem)?.entity_info }]
      return []
    }
    if (selectedTab === 'narratives') {
      return [{ narratives: [(itemData as NarrativeListDataType).narrative_number] }]
    } else if (selectedTab === 'communities') {
      return [{ communities: [(itemData as NarrativeListDataType).community_number] }]
    } else if (selectedTab === 'watchlists') {
      const uniqueChannelUrls = (itemData as NarrativeListDataType).channels!.reduce((accumulator: string[], item) => {
        const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
        return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
      }, [])

      return [{ channel_urls: uniqueChannelUrls }]
    }
    return []
  }, [selectedTab, itemData, forAsset])

  const buildQueryString = (startDate: string, endDate: string) => {
    let query = `start_date:gte:${startDate},end_date:lte:${endDate}`

    if (snippetsFilter.filter_platform) {
      query += `,platform:in:${snippetsFilter.filter_platform}`
    }

    return query
  }

  const insightsMetricsHandler = async () => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    const requestParams = {
      q: buildQueryString(startDate, endDate),
    }

    const requestParamsForPrev = {
      q: buildQueryString(prevStartDateFormat, prevEndDateFormat),
    }

    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }

      const [currentFlagCount, prevFlagCount] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData }),
      ])
      setPostsCount({
        currentFlagCount: currentFlagCount === 'error' ? 0 : currentFlagCount,
        prevFlagCount: prevFlagCount === 'error' ? 0 : prevFlagCount,
      })
    } else {
      setPostsCount({
        currentFlagCount: null,
        prevFlagCount: null,
      })
    }
  }

  useEffect(() => {
    insightsMetricsHandler()
  }, [renderConditions, snippetsFilter.days])

  const renderUI = useMemo(() => {
    const riskValue = postsCount.currentFlagCount! - postsCount.prevFlagCount!
    if (riskValue === 0) {
      return
    }
    if (forGridView) {
      if (riskValue > 0) {
        return (
          <>
            <span className='apc_trending_count'>+{millify(riskValue)}</span>
            <HighRiskIcon />
          </>
        )
      }
      return (
        <>
          <span className='apc_trending_count'>{millify(riskValue)}</span>
          <LowRiskIcon className='apc_low_icon' />
        </>
      )
    }
    return (
      <>
        <UpArrowIcon className='apc_arrow_icon' />
        <span className='apc_trending_count'>{millify(Math.abs(riskValue))}</span>
      </>
    )
  }, [forGridView, postsCount])

  const postCount = millify(postsCount?.currentFlagCount || 0)
  const displayPostCount = postCount === '0' ? '-' : postCount

  return (
    <>
      {postsCount.currentFlagCount === null || postsCount.prevFlagCount === null ? (
        <div className={`all_posts_container ${className}`}>
          <span className='apc_count'>-</span>
        </div>
      ) : (
        <div
          className={`all_posts_container${
            postsCount.currentFlagCount - postsCount.prevFlagCount < 0 ? ' all_posts_container_down' : ''
          } ${className}`}>
          <span className='apc_count'>{displayPostCount}</span>
          {postCount !== '0' && renderUI}
        </div>
      )}
    </>
  )
})

export default AllPostSection
