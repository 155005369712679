import { store } from 'store'
import { Menu, Dropdown } from 'antd'
import { observer } from 'mobx-react-lite'
import { Card } from 'components/common/Card/Card'
import { Heading } from 'components/common/Heading/Heading'
import { Divider, Table, Form, Select } from 'antd'
import { ReactComponent as IconDropdownWhite } from 'assets/images/dropdown-white.svg'

import { AddUserForm } from './AddUserForm/AddUserForm'
import { Button } from 'components/common/Button/Button'
import { ActionModal } from 'components/common/ActionModal/ActionModal'

import './Users.scss'
import usePermissions from 'hooks/usePermissions'

export const Users = observer((): JSX.Element => {
  const { userStore, loaderStore } = store

  const {
    usersTableData,
    roles,
    isDeleteModalVisible,
    isAddUserModalVisible,
    setUserModal,
    setDeleteModal,
    setPredeleteUser,
    preDeleteUsername,
    updateUserRole,
    tenantId,
  } = userStore

  const roleNameLookup = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    PENDULUM_ADMIN: 'PENDULUM_ADMIN',
    VIEWER: 'VIEWER',
    SCORECARD_USER: 'SCORECARD_USER',
    DEPLOYMENT_STRATEGIST: 'DEPLOYMENT_STRATEGIST',
    BUILDER: 'BUILDER',
    ANALYST: 'ANALYST',
  }

  const { isLoadingUsers } = loaderStore

  const [form] = Form.useForm()

  const { Option } = Select
  const canManageUsers = usePermissions('manageTenant') === 'full_access'

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: '1',
      // sorter: (a: IUser, b: IUser) => a.username.localeCompare(b.username),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '2',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: '3',
      width: '20%',
      render: (roleId: string, data: any) => {
        let role_Id = data?.role
        let id = roles.find((el) => el.role === role_Id)?.id

        return (
          <Select
            showSearch
            onChange={async (newId) => {
              await updateUserRole({ userId: data.id, newRoleId: newId, username: data.username, oldRoleId: role_Id })

              setDeleteModal(false)
            }}
            defaultValue={id}
            value={id}
            style={{ width: '100%' }}>
            {roles?.map((role) => {
              return (
                <Option key={role.id} value={role.id}>
                  {roleNameLookup[role.role]}
                </Option>
              )
            })}
          </Select>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'UserStatus',
      key: '4',
    },
    {
      dataIndex: 'username',
      key: '5',
      render: (_: string, data: any) => {
        return (
          <div className='c-edit-column'>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() => {
                      setPredeleteUser(data?.username)
                      setDeleteModal(true)
                    }}>
                    <div>Delete user</div>
                  </Menu.Item>
                  <Menu.Item
                    key='2'
                    onClick={() => {
                      resetPassword(data)
                    }}>
                    <div>Reset password</div>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              className='c-edit-dropdown'
              overlayClassName='c-edit-dropdown__overlay'>
              <IconDropdownWhite />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const validateAndCreateUser = async () => {
    if (!isLoadingUsers) {
      await form.validateFields()
      const user = {
        given_name: form.getFieldValue('given_name'),
        family_name: form.getFieldValue('family_name'),
        username: form.getFieldValue('email'),
        email: form.getFieldValue('email'),
        temporary_password: form.getFieldValue('temporaryPassword'),
        role_id: form.getFieldValue('role'),
        notify_user: form.getFieldValue('notifyUser'),
        tenant_id: tenantId,
      }
      await userStore.saveUser(user)
    }
  }

  const resetPassword = async (data: any) => {
    if (!isLoadingUsers) {
      const user = {
        username: data.username,
        admin_reset_password: true,
      }
      await userStore.resetUser(user)
    }
  }

  const deleteUser = async () => {
    if (!isLoadingUsers) {
      userStore.deleteUser(preDeleteUsername)
    }
  }

  return (
    <Card className='c-users u-p-t-32' variant='1'>
      <section className='c-users__heading'>
        <Heading level='1' variant='2' className='u-m-b-8'>
          Manage users
        </Heading>

        <Button
          type='secondary'
          onClick={() => {
            if (!canManageUsers) return
            setUserModal(true)
          }}
          disabled={!canManageUsers}>
          + Add new user
        </Button>
      </section>

      <Divider plain />

      <Table
        columns={columns}
        dataSource={usersTableData}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
        loading={isLoadingUsers}
      />

      {/*add user modal*/}
      <ActionModal
        title='Add new user'
        okText='Save'
        visible={isAddUserModalVisible}
        isLoading={isLoadingUsers}
        overflow='none'
        maxHeight={600}
        onOk={async () => {
          try {
            await validateAndCreateUser()
            form.resetFields()
            setUserModal(false)
          } catch (e) {}
        }}
        onCancel={() => {
          setUserModal(false)
        }}>
        <AddUserForm form={form} roles={roles} />
      </ActionModal>

      {/*delete user modal*/}
      <ActionModal
        title='Delete user'
        okText='Yes, delete user'
        visible={isDeleteModalVisible}
        onOk={deleteUser}
        onCancel={() => {
          setDeleteModal(false)
        }}
        isLoading={isLoadingUsers}>
        <div className='c-users__remove-modal-div'>
          <h1>Are you sure?</h1>
          <p>This action cannot be undone.</p>
        </div>
      </ActionModal>
    </Card>
  )
})
