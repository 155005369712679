import { Slider } from 'antd'
import './RangeSlider.scss'

type RangeSliderValue = {
  min: number
  max: number
}

type RangeSliderProps = {
  value: RangeSliderValue
  onChange: (data: RangeSliderValue) => void
}

function RangeSlider(props: RangeSliderProps) {
  const { value, onChange } = props
  return (
    <div className='range_slider_container'>
      <p className='rsc_value_text'>{value.min}</p>
      <Slider
        className='rsc_slider'
        min={0}
        max={100}
        onChange={(val) => {
          const [min, max] = val
          onChange({ min, max })
        }}
        tooltip={{ open: false }}
        range={true}
        value={[value.min, value.max]}
      />
      <p className='rsc_value_text'>{value.max}</p>
    </div>
  )
}

export default RangeSlider
